import * as types from "../constants";

export const logUser = (email, displayName, organizationId) => ({
  type: types.SIGNED_IN,
  email: email,
  name: displayName,
  isAuthenticated: true,
  organizationId,
});

export const signOut = () => ({
  type: types.SIGNED_OUT,
  email: null,
  displayName: null,
  isAuthenticated: false,
});

export const changeName = (name) => ({
  type: types.CHANGE_NAME,
  name,
});

export const setRedirectAfterLogin = (path) => ({
  type: types.SET_REDIRECT_AFTER_LOGIN,
  path,
});
