import gql from "graphql-tag";

const purchaseDocketDocument = gql`
  mutation purchaseDocketDocument($input: PurchaseDocketDocumentInput!) {
    purchaseDocketDocument(input: $input) {
      error
    }
  }
`;

export default purchaseDocketDocument;
