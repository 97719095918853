import { useState, useEffect, useCallback } from "react";
import { Container, Card, Nav } from "react-bootstrap";

import { apolloClient } from "../../configs/apollo-client";

import ImportProgress from "./ImportProgress";
import TrackedDebtors from "./TrackedDebtors";
import ImportJobs from "./ImportJobs";
import listPendingJobs from "./graphql/listPendingJobs";

import "./index.scss";

type PendingJobs = {
  id: string;
  filename: string;
  records_count: number;
  job_status: string;
  created_at: string;
  updated_at: string;
}[];

export default function TrackedDebtorsPage() {
  const [currentPage, setCurrentPage] = useState<"tracked-debtors" | "imports-jobs">("tracked-debtors");
  const [pendingJobs, setPendingJobs] = useState<PendingJobs>([]);
  const [refreshTabContent, setRefreshTabContent] = useState(0);

  useEffect(() => {
    const watchedQuery = apolloClient.watchQuery({
      query: listPendingJobs,
      fetchPolicy: "network-only",
    });

    let sub: ReturnType<typeof watchedQuery.subscribe> | null = watchedQuery.subscribe({
      next(response) {
        const listPendingJobsResponse: PendingJobs = response.data.listPendingJobs;
        setPendingJobs(listPendingJobsResponse);
        // unsubscribing here will cause the query to stop updating the component when re-rendering
        sub && sub.unsubscribe();
        sub = null;
      },
      error(err) {
        console.log("[watchQuery] error", err);
      },
    });

    return () => {
      if (sub) {
        console.log("[watchQuery] Clean up");
        sub.unsubscribe();
      }
    };
  }, []);

  const onFinished = useCallback(() => setRefreshTabContent((prev) => prev + 1), []);

  return (
    <Container fluid="xxl" className="tracked-debtors">
      {pendingJobs.map((job) => (
        <ImportProgress
          key={job.id}
          filename={job.filename}
          jobId={job.id}
          onDismiss={() => setPendingJobs(pendingJobs.filter((pendingJob) => pendingJob.id !== job.id))}
          onFinished={onFinished}
        />
      ))}

      <Nav variant="tabs" className="tracked-debtors-tabs">
        <Nav.Item>
          <Nav.Link
            className="nav-link"
            active={currentPage === "tracked-debtors"}
            onClick={() => setCurrentPage("tracked-debtors")}
          >
            Tracked Customers
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className="nav-link"
            active={currentPage === "imports-jobs"}
            onClick={() => setCurrentPage("imports-jobs")}
          >
            Import Jobs
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <Card className="tracked-debtors-wrapper">
        {currentPage === "tracked-debtors" && <TrackedDebtors key={refreshTabContent} />}
        {currentPage === "imports-jobs" && <ImportJobs key={refreshTabContent} />}
      </Card>
    </Container>
  );
}
