import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

export default function PageSize({
  id,
  label = "Page size",
  value,
  onChange,
  disabled,
  options = [10, 50, 100],
  keyExtractor = option => option,
  labelExtractor = option => option,
  valueExtractor = option => option
}) {
  return (
    <InputGroup>
      <InputGroup.Text as="label" htmlFor={id}>
        {label}
      </InputGroup.Text>

      <Form.Select
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {options.map(option => (
          <option key={keyExtractor(option)} value={valueExtractor(option)}>
            {labelExtractor(option)}
          </option>
        ))}
      </Form.Select>
    </InputGroup>
  );
}
