import gql from "graphql-tag";

const saveClaimDraft = gql`
  mutation saveClaimDraft($input: EPOCSaveDraftInput!) {
    saveClaimDraft(input: $input) {
      claim_id
      updated_at
    }
  }
`;

export default saveClaimDraft;
