import { useEffect, useRef, useCallback } from "react";
import { Form } from "react-bootstrap";

interface Props {
  cols: number;
  maxLength: number;
  isInvalid: boolean;
  [key: string]: any;
}
export default function AutoHeightTextarea({
  rows,
  cols,
  maxLength,
  isInvalid,
  ...rest
}: Props) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const initialHeightRef = useRef<number>(0);

  useEffect(() => {
    const currentRef = textareaRef.current as HTMLTextAreaElement;
    currentRef.setAttribute("style", `height: ${currentRef.scrollHeight}px`);
    initialHeightRef.current = currentRef.scrollHeight;
  }, []);

  const onInput = useCallback(() => {
    const currentRef = textareaRef.current as HTMLTextAreaElement;
    currentRef.style.height = "0px";
    currentRef.style.height = `${Math.max(
      initialHeightRef.current,
      currentRef.scrollHeight
    )}px`;
  }, []);

  return (
    <Form.Control
      as="textarea"
      rows={rows}
      cols={cols}
      isInvalid={isInvalid}
      style={{ overflowY: "hidden" }}
      onInput={onInput}
      maxLength={maxLength}
      {...rest}
      ref={textareaRef}
    />
  );
}
