import { useReducer, useState, useEffect } from "react";
import { Spinner, Row, Col, Card, Button, Alert } from "react-bootstrap";
import { CloudDownload } from "react-bootstrap-icons";
import { toast } from "react-toastify";

import { apolloClient } from "../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../lib/errors";

import getClaim from "./graphql/getClaim";
import submitClaim from "./graphql/submitClaim";
import getClaimSubmissionProgress from "./graphql/getClaimSubmissionProgress";

import PaymentStep from "./Payment";
import SubmitProgress from "./SubmitProgress";

enum Step {
  GetClaim,
  Pay,
  SubmitClaim,
  CheckSubmitProgress,
  ClaimSubmitted,
  PreviewSubmittedClaim,
}

enum SubmissionStatus {
  Draft = "draft",
  Ready = "ready",
  Pending = "pending",
  Finished = "finished",
}

enum PaymentStatus {
  Unpaid = "unpaid",
  Authorized = "authorized",
  Captured = "captured",
}

interface ClaimData {
  error?: string;
  court_code?: string;
  case_no?: string;
  form_data?: string;
  submission_status?: SubmissionStatus;
  payment_status?: PaymentStatus;
  court_api_task_id?: string;
  court_api_response_status_code?: number;
  court_api_response_status_text?: string;
  court_api_response_body?: any;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

interface State {
  current_step: Step;
  claim_data?: ClaimData;
  task_id?: string;
}

enum ActionTypes {
  SetGetClaimResponse,
  SetSubmitClaimResponse,
  SetClaimProgressResponse,
  ShowPreview,
}

interface Action {
  type: ActionTypes;
  data?: any;
}

type Reducer = (state: State, action: Action) => State;
const reducer: Reducer = function reducer(state, action) {
  switch (action.type) {
    case ActionTypes.SetGetClaimResponse:
      return {
        ...state,
        ...action.data,
      };
    case ActionTypes.SetSubmitClaimResponse:
      return {
        ...state,
        task_id: action.data,
        current_step: Step.CheckSubmitProgress,
      };
    case ActionTypes.SetClaimProgressResponse:
      return {
        ...state,
        claim_data: {
          ...state.claim_data,
          ...action.data,
        },
        current_step: Step.ClaimSubmitted,
      };
    case ActionTypes.ShowPreview:
      return {
        ...state,
        current_step: Step.PreviewSubmittedClaim,
      };
  }
};

interface Props {
  match: {
    params: {
      claim_id: string;
    };
  };
  history: any;
}
export default function Claim({ match, history }: Props) {
  const {
    params: { claim_id },
  } = match;
  const [state, dispatch] = useReducer<Reducer>(reducer, {
    current_step: Step.GetClaim,
  });
  const [shouldRetry, setShouldRetry] = useState<number>(0);

  useEffect(() => {
    console.log("Getting claim");
    const watchedQuery = apolloClient.watchQuery({
      query: getClaim,
      variables: {
        claim_id,
      },
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        const getClaimResponse: ClaimData = response.data.getClaim;

        if (getClaimResponse.error) {
          toast.error(getClaimResponse.error);
          // defer history push
          setTimeout(() => history.push("/claims"), 0);
        } else if (getClaimResponse.submission_status === SubmissionStatus.Draft) {
          // defer history push
          setTimeout(
            () =>
              // redirect to edit claim
              history.push(`/epoc/edit/${claim_id}`),
            0
          );
        } else if (getClaimResponse.submission_status === SubmissionStatus.Ready) {
          dispatch({
            type: ActionTypes.SetGetClaimResponse,
            data: {
              current_step:
                getClaimResponse.payment_status === PaymentStatus.Unpaid
                  ? Step.Pay
                  : // Assuming payment_status is Authorized
                    Step.SubmitClaim,
              claim_data: getClaimResponse,
            } as State,
          });
        } else if (getClaimResponse.submission_status === SubmissionStatus.Pending) {
          dispatch({
            type: ActionTypes.SetGetClaimResponse,
            data: {
              current_step: Step.CheckSubmitProgress,
              claim_data: getClaimResponse,
              task_id: getClaimResponse.court_api_task_id,
            } as State,
          });
        } else if (getClaimResponse.submission_status === SubmissionStatus.Finished) {
          dispatch({
            type: ActionTypes.SetGetClaimResponse,
            data: {
              current_step: Step.PreviewSubmittedClaim,
              claim_data: {
                ...getClaimResponse,
                court_api_response_body: JSON.parse(getClaimResponse.court_api_response_body),
              },
            } as State,
          });
        }
      },
      error(err) {
        console.log("[watchQuery] error", err);
        toast.error(somethingWentWrong);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, [claim_id, history]);

  useEffect(() => {
    async function submit() {
      try {
        console.log("Submitting claim");
        const response = await apolloClient.mutate({
          mutation: submitClaim,
          variables: {
            claim_id,
          },
          fetchPolicy: "no-cache",
        });

        if (response.data.submitClaim.error) {
          toast.error(response.data.submitClaim.error);
          // TODO add re try button? or react to error type, in case graphql schema needs to be extended
          history.push(`/claims`);
        } else {
          dispatch({
            type: ActionTypes.SetSubmitClaimResponse,
            data: response.data.submitClaim.task_id,
          });
        }
      } catch (error) {
        console.log(error);
        toast.error(somethingWentWrong);
      }
    }

    if (state.current_step === Step.SubmitClaim) {
      submit();
    }
  }, [claim_id, history, state.current_step]);

  useEffect(() => {
    async function checkProgress() {
      console.log("Checking progress");
      const response = await apolloClient.query({
        query: getClaimSubmissionProgress,
        variables: {
          task_id: state.task_id,
        },
        fetchPolicy: "network-only",
      });

      const getClaimSubmissionProgressResponse: {
        done: boolean;
        court_api_response_status_code: number;
        court_api_response_status_text: string;
        court_api_response_body: string;
      } = response.data.getClaimSubmissionProgress;
      if (!getClaimSubmissionProgressResponse.done) {
        setTimeout(() => setShouldRetry((value) => value + 1), 3000);
      } else {
        dispatch({
          type: ActionTypes.SetClaimProgressResponse,
          data: {
            court_api_response_status_code: getClaimSubmissionProgressResponse.court_api_response_status_code,
            court_api_response_status_text: getClaimSubmissionProgressResponse.court_api_response_status_text,
            court_api_response_body: JSON.parse(getClaimSubmissionProgressResponse.court_api_response_body),
          },
        });
      }
    }

    if (state.current_step === Step.CheckSubmitProgress) {
      checkProgress();
    }
  }, [state.task_id, state.current_step, shouldRetry, state.claim_data?.case_no]);

  if ([Step.GetClaim, Step.SubmitClaim].includes(state.current_step)) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (state.current_step === Step.Pay) {
    return (
      <PaymentStep
        claimId={claim_id}
        onPurchaseConfirmed={() => {
          dispatch({
            type: ActionTypes.SetGetClaimResponse,
            data: {
              current_step: Step.SubmitClaim,
            } as State,
          });
        }}
      />
    );
  }

  if ([Step.CheckSubmitProgress, Step.ClaimSubmitted].includes(state.current_step)) {
    return (
      <SubmitProgress
        finished={state.current_step === Step.ClaimSubmitted}
        claim_data={state.claim_data}
        onShowPreview={() =>
          dispatch({
            type: ActionTypes.ShowPreview,
          })
        }
      />
    );
  }

  if (state.current_step === Step.PreviewSubmittedClaim) {
    const courtApiResponse = state.claim_data?.court_api_response_body;

    // successfull
    if (courtApiResponse.claim_number) {
      const mainDocument = courtApiResponse.documents[0];

      return (
        <>
          <Row className="mb-3">
            <Col className="mr-auto d-flex align-items-center">
              <h3 className="mb-0">
                Case {state.claim_data?.case_no} - Claim {courtApiResponse.claim_number} - Filed on{" "}
                {new Date(state.claim_data?.updated_at!).toLocaleDateString()}
              </h3>
            </Col>
            <Col sm="auto">
              <Button as="a" download href={mainDocument.download_link} className="d-flex align-items-center gap-2">
                <CloudDownload size={16} />
                Download
              </Button>
            </Col>
          </Row>

          <Card>
            <Card.Body>
              <iframe title={mainDocument.name} src={mainDocument.preview_link} width="100%" height={800} />
            </Card.Body>
          </Card>
        </>
      );
    } else if (courtApiResponse.error || courtApiResponse.errors) {
      return (
        <Alert variant="danger">
          <div className="alert-message">
            <p>
              Oops!{" "}
              {courtApiResponse.error
                ? "Something went wrong, error message from your Court:"
                : "Some validation errors occurred:"}
            </p>
            <p className="font-weight-bold">
              {courtApiResponse.error && courtApiResponse.error.detail}
              {courtApiResponse.errors && (
                <ul>
                  {(courtApiResponse.errors as Record<string, string>[]).map((error, index) => (
                    <li key={index}>
                      {error.message}
                      <br />
                      Path: {error.path}
                    </li>
                  ))}
                </ul>
              )}
            </p>
            Should you have any questions, please do not hesitate to contact us at support@bkalerts.com
          </div>
        </Alert>
      );
    }
  }

  return null;
}
