import { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";

import { Claim } from "../../types";

export default observer(function EditTextField({
  value,
  claim,
  columnKey,
  validation,
  invalidMessage,
  converter,
  maxLength,
  updateClaim,
}: {
  value: any;
  claim: Claim;
  columnKey: string;
  validation?: (value: string) => boolean;
  invalidMessage?: string;
  converter?: (value: string) => unknown;
  maxLength?: number;
  updateClaim: (claim: Claim, columnKey: string, newValue: any) => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const shouldConfirmChange = useRef(true);
  const [newValue, setNewValue] = useState(value === 0 ? value : value || "");

  function confirmChange() {
    if (!shouldConfirmChange.current) return;

    if (validation && !validation(newValue)) {
      inputRef.current?.setCustomValidity(invalidMessage || "Invalid input");
      inputRef.current?.reportValidity();
      return;
    }

    inputRef.current?.setCustomValidity("");
    inputRef.current?.reportValidity();

    // Don't convert empty strings
    const isEmptyString = typeof newValue === "string" && newValue.trim() === "";
    let newValueToSet = isEmptyString ? "" : newValue;
    if (converter && !isEmptyString) newValueToSet = converter(newValue);

    updateClaim(claim, columnKey, newValueToSet);
  }

  return (
    <Form
      noValidate
      onSubmit={(event) => {
        event.preventDefault();
        confirmChange();
      }}
    >
      <Form.Control
        autoFocus
        type="text"
        value={newValue}
        onKeyDown={(event) => {
          // Discard changes on ESC
          if (event.key === "Escape") {
            inputRef.current?.setCustomValidity("");
            inputRef.current?.reportValidity();
            shouldConfirmChange.current = false;
            claim.editing_field_key = null;
            claim.focused_cell_element?.focus();
            claim.focused_cell_element = null;
          }
        }}
        onChange={(event) => {
          setNewValue(event.target.value);
        }}
        onBlur={() => confirmChange()}
        ref={inputRef}
        size="sm"
        maxLength={maxLength}
      />
    </Form>
  );
});
