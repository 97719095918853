import { Fragment, useEffect } from "react";
import classnames from "classnames";
import { Container, Card, Spinner, Button, Accordion, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocalObservable, Observer, observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CheckLg as CheckIcon, ExclamationTriangle as WarningIcon } from "react-bootstrap-icons";
import gql from "graphql-tag";

import { apolloClient } from "../../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../../lib/errors";

import getClaimBatch from "../graphql/getClaimBatch";
import resolveClaimCaseNumber from "../graphql/resolveClaimCaseNumber";
import resolveClaimCreditor from "../graphql/resolveClaimCreditor";
import getClaimSearchCaseProgress from "../graphql/getClaimSearchCaseProgress";
import getClaimSearchCreditorProgress from "../graphql/getClaimSearchCreditorProgress";
import generateClaimForm410 from "../graphql/generateClaimForm410";
import getClaimForm410 from "../graphql/getClaimForm410";
import createBatchPackages from "../graphql/createBatchPackages";
import districts from "../Edit/districts";
import { CasesSearchResults } from "../Edit/ClaimCaseNumberResolverModal";
import { CreditorsSearchResults } from "../Edit/ClaimCreditorResolverModal";
import ClaimFormPreviewModal from "../Edit/ClaimFormPreviewModal";
import { Batch, Claim } from "../types";

type State = {
  isLoadingBatch: boolean;
  isSyncingChanges: boolean;
  batch: Batch | null;
  currentStep: "pre-processing" | "packaging" | "sending";
  expandedClaims: string[];
  loadBatch: () => void;
};

const steps: {
  key: "pre-processing" | "packaging" | "sending";
  label: string;
}[] = [
  { key: "pre-processing", label: "Pre-processing" },
  { key: "packaging", label: "Packaging files" },
  { key: "sending", label: "Sending to court" },
];

const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

interface IProps {
  match: {
    params: {
      batch_id: string;
    };
  };
}
export default function SubmitBatch({ match }: IProps) {
  console.log("[SubmitBatch] rendering");

  const {
    params: { batch_id },
  } = match;
  const history = useHistory();

  const state = useLocalObservable<State>(() => ({
    isLoadingBatch: true,
    isSyncingChanges: false,
    batch: null,
    currentStep: "pre-processing",
    expandedClaims: [],
    loadBatch: function* () {
      console.log("[SubmitBatch] loadBatch");

      try {
        this.isLoadingBatch = true;

        const {
          data: { getClaimBatch: getClaimBatchResponse },
        }: {
          data: { getClaimBatch: { error: string | null } & Batch };
        } = yield apolloClient.query({
          query: getClaimBatch,
          variables: {
            batch_id,
          },
          fetchPolicy: "network-only",
        });

        if (getClaimBatchResponse.error) {
          toast.error(getClaimBatchResponse.error, { autoClose: false });
          history.push(`/epoc/bulk/${batch_id}`);
          return;
        }

        if (getClaimBatchResponse.invalid_records_count > 0) {
          toast.error(
            `Batch has ${getClaimBatchResponse.invalid_records_count} invalid record${
              getClaimBatchResponse.invalid_records_count > 1 ? "s" : ""
            }, please fix ${getClaimBatchResponse.invalid_records_count === 1 ? "it" : "them"} before submitting.`,
            { autoClose: false }
          );
          history.push(`/epoc/bulk/${batch_id}`);
          return;
        }

        runInAction(() => {
          this.batch = getClaimBatchResponse;
          this.isLoadingBatch = false;

          const needsPreProcessing = this.batch.claims.some((claim) =>
            [
              "NEEDS_CASE_NUMBER_RESOLUTION",
              "NEEDS_CASE_NUMBER_DISAMBIGUATION",
              "SEARCHING_CASE_NUMBER",
              "CASE_NUMBER_SEARCH_ERROR",
              "NEEDS_CREDITOR_RESOLUTION",
              "NEEDS_CREDITOR_DISAMBIGUATION",
              "SEARCHING_CREDITOR",
              "CREDITOR_SEARCH_ERROR",
              "READY",
            ].includes(claim.status)
          );

          if (needsPreProcessing) this.currentStep = "pre-processing";
          else this.currentStep = "packaging";
        });
      } catch (error) {
        console.log(error);
        toast.error(somethingWentWrong, { autoClose: false });
        this.isLoadingBatch = false;
      }
    },
  }));

  useEffect(() => {
    const promise = state.loadBatch();

    // @ts-ignore
    promise.catch(() => {}); // Ignore cancellation error

    return () => {
      // @ts-ignore
      promise.cancel();
    };
  });

  return (
    <Observer>
      {() => {
        console.log("Observer rendering");

        if (state.isLoadingBatch)
          return (
            <div className="d-flex justify-content-center pt-2">
              <Spinner animation="border" variant="primary" />
            </div>
          );

        if (state.batch) {
          const batchId = state.batch.id;

          return (
            <Container fluid className="submit-batch pt-2">
              <Card>
                <Card.Body className="bg-white py-3 border-bottom shadow-sm position-sticky sticky-top">
                  <h4 className="mb-3">Submitting batch "{state.batch.name}"</h4>

                  <ul className="list-unstyled mb-0 d-flex align-items-center gap-2">
                    {steps.map(({ key, label }, index) => (
                      <Fragment key={key}>
                        <li className="d-flex align-items-center gap-1">
                          <div
                            className={classnames(
                              { "bg-primary": state.currentStep === key, "bg-secondary": state.currentStep !== key },
                              "text-white rounded-circle d-flex align-items-center justify-content-center"
                            )}
                            style={{ width: 22, height: 22 }}
                          >
                            {index + 1}
                          </div>
                          {label}
                        </li>

                        {index !== steps.length - 1 && <li style={{ width: "1.5rem" }} className="border-bottom" />}
                      </Fragment>
                    ))}
                  </ul>
                </Card.Body>

                {state.currentStep === "pre-processing" && (
                  <>
                    <Card.Body>
                      <div className="mb-2 d-flex align-items-center gap-2">
                        <Button
                          type="button"
                          variant="outline-primary"
                          size="sm"
                          onClick={() => (state.expandedClaims = state.batch!.claims.map((claim) => claim.id))}
                        >
                          Expand all
                        </Button>
                        <Button
                          type="button"
                          variant="outline-primary"
                          size="sm"
                          onClick={() => (state.expandedClaims = [])}
                        >
                          Close all
                        </Button>
                      </div>

                      <Accordion
                        alwaysOpen
                        activeKey={state.expandedClaims}
                        onSelect={(eventKey) => {
                          state.expandedClaims = eventKey as string[];
                        }}
                      >
                        {state.batch.claims.map((claim, index) => (
                          <ClaimRow key={claim.id} batchId={batchId} claim={claim} claimIndex={index} />
                        ))}
                      </Accordion>
                    </Card.Body>

                    <Card.Body className="bg-white py-3 border-top position-sticky sticky-bottom">
                      <Button
                        variant="success"
                        disabled={!state.batch.claims.every((claim) => claim.status === "COMPLETED")}
                        onClick={() => (state.currentStep = "packaging")}
                      >
                        Proceed
                      </Button>
                    </Card.Body>
                  </>
                )}

                {state.currentStep === "packaging" && (
                  <Card.Body>
                    <PackagingClaims batchId={batchId} />
                  </Card.Body>
                )}
              </Card>
            </Container>
          );
        }

        return null;
      }}
    </Observer>
  );
}

const ClaimRow = observer(function ClaimRow({
  batchId,
  claim,
  claimIndex,
}: {
  batchId: string;
  claim: Claim;
  claimIndex: number;
}) {
  console.log("[ClaimRow] rendering");

  const state = useLocalObservable(() => ({
    searchClaimCases: function* searchClaimCases() {
      try {
        console.log("[ClaimRow] searchClaimCases");

        const resolveClaimCaseNumberResponse: {
          data: {
            resolveClaimCaseNumber: { error?: string };
          };
        } = yield apolloClient.query({
          query: resolveClaimCaseNumber,
          variables: {
            batch_id: batchId,
            claim_id: claim.id,
          },
          fetchPolicy: "network-only",
        });
        console.log("[ClaimRow] response");
        console.log(resolveClaimCaseNumberResponse);

        if (resolveClaimCaseNumberResponse.data.resolveClaimCaseNumber.error) {
          toast.error(resolveClaimCaseNumberResponse.data.resolveClaimCaseNumber.error);
          claim.status = "CASE_NUMBER_SEARCH_ERROR";
          return;
        }

        // Loop to check progress
        let taskIsPending = true;
        const sleepingTime = 2000;

        let response: {
          done?: boolean;
          error?: string;
          error_code?: string;
          claim?: Claim;
        } = {};

        while (taskIsPending) {
          console.log("[ClaimRow] Polling getClaimSearchCaseProgress");
          const progressResponse: {
            data: {
              getClaimSearchCaseProgress: {
                done?: boolean;
                error?: string;
                error_code?: string;
                claim?: Claim;
              };
            };
          } = yield apolloClient.query({
            query: getClaimSearchCaseProgress,
            variables: {
              batch_id: batchId,
              claim_id: claim.id,
            },
            fetchPolicy: "network-only",
          });

          response = progressResponse.data.getClaimSearchCaseProgress;

          if (response.error) {
            toast.error(response.error);
            claim.status = "CASE_NUMBER_SEARCH_ERROR";
            return;
          }

          if (!response.done) {
            console.log(`[ClaimRow] Not done, sleeping ${sleepingTime}s`);
            yield sleep(sleepingTime);
            continue;
          } else {
            console.log("[ClaimRow] done, found cases");

            taskIsPending = false;
          }
        }

        runInAction(() => {
          if (response.claim?.status) claim.status = response.claim.status;

          claim.found_cases = response.claim?.found_cases;
          claim.cases_search_error = response.claim?.cases_search_error;
        });
      } catch (error) {
        console.log(error);
        toast.error(somethingWentWrong);
        claim.status = "CASE_NUMBER_SEARCH_ERROR";
      }
    },
    searchClaimCreditors: function* searchClaimCreditors() {
      try {
        console.log("[ClaimRow] searchClaimCreditors");

        const resolveClaimCreditorResponse: {
          data: {
            resolveClaimCreditor: { error?: string };
          };
        } = yield apolloClient.query({
          query: resolveClaimCreditor,
          variables: {
            batch_id: batchId,
            claim_id: claim.id,
          },
          fetchPolicy: "network-only",
        });
        console.log("[ClaimRow] response");
        console.log(resolveClaimCreditorResponse);

        if (resolveClaimCreditorResponse.data.resolveClaimCreditor.error) {
          toast.error(resolveClaimCreditorResponse.data.resolveClaimCreditor.error);
          claim.status = "CREDITOR_SEARCH_ERROR";
          return;
        }

        // Loop to check progress
        let taskIsPending = true;
        const sleepingTime = 2000;

        let response: {
          done?: boolean;
          error?: string;
          error_code?: string;
          claim?: Claim;
        } = {};

        while (taskIsPending) {
          console.log("[ClaimRow] Polling getClaimSearchCaseProgress");

          const progressResponse: {
            data: {
              getClaimSearchCreditorProgress: {
                done?: boolean;
                error?: string;
                error_code?: string;
                claim?: Claim;
              };
            };
          } = yield apolloClient.query({
            query: getClaimSearchCreditorProgress,
            variables: {
              batch_id: batchId,
              claim_id: claim.id,
            },
            fetchPolicy: "network-only",
          });

          response = progressResponse.data.getClaimSearchCreditorProgress;

          if (response.error) {
            toast.error(response.error, {
              autoClose: false,
            });

            claim.status = "CREDITOR_SEARCH_ERROR";
            return;
          }

          if (!response.done) {
            console.log(`[ClaimRow] Not done, sleeping ${sleepingTime}s`);
            yield sleep(sleepingTime);
            continue;
          } else {
            console.log("[ClaimRow] done, found creditor");

            taskIsPending = false;
          }
        }

        runInAction(() => {
          if (response.claim?.status) claim.status = response.claim.status;

          claim.found_creditors = response.claim?.found_creditors;
          claim.creditors_search_error = response.claim?.creditors_search_error;
        });
      } catch (error) {
        console.log(error);
        toast.error(somethingWentWrong);
        claim.status = "CREDITOR_SEARCH_ERROR";
      }
    },
    generateForm410: function* () {
      try {
        const {
          data: { generateClaimForm410: generateClaimForm410Response },
        }: {
          data: { generateClaimForm410: { error: string | null; error_code: string | null } & Claim };
        } = yield apolloClient.mutate({
          mutation: generateClaimForm410,
          variables: {
            batch_id: batchId,
            claim_id: claim.id,
          },
          fetchPolicy: "no-cache",
        });

        if (generateClaimForm410Response.error) {
          toast.error(generateClaimForm410Response.error, { autoClose: false });
          return;
        }

        runInAction(() => {
          claim.status = generateClaimForm410Response.claim.status;
        });
      } catch (error) {
        console.log(error);
        toast.error(somethingWentWrong, { autoClose: false });
      }
    },
  }));

  useEffect(() => {
    // do whatever needs to be done
    console.log("[ClaimRow] useEffect");

    let promise: any;

    if (claim.status === "NEEDS_CASE_NUMBER_RESOLUTION") {
      promise = state.searchClaimCases();

      // @ts-ignore
      promise.catch(() => {}); // Ignore cancellation error
    } else if (claim.status === "NEEDS_CREDITOR_RESOLUTION") {
      promise = state.searchClaimCreditors();

      // @ts-ignore
      promise.catch(() => {}); // Ignore cancellation error
    } else if (claim.status === "READY") {
      promise = state.generateForm410();

      // @ts-ignore
      promise.catch(() => {}); // Ignore cancellation error
    }

    return () => {
      if (promise) {
        // @ts-ignore
        promise.cancel();
      }
    };
  });

  enum PreProcessStep {
    Case = 0,
    Creditor,
    Form410,
    Completed,
  }

  const currentPreProcessStep = (function () {
    switch (claim.status) {
      case "NEEDS_CASE_NUMBER_RESOLUTION":
      case "NEEDS_CASE_NUMBER_DISAMBIGUATION":
      case "SEARCHING_CASE_NUMBER":
      case "CASE_NUMBER_SEARCH_ERROR":
        return PreProcessStep.Case;
      case "NEEDS_CREDITOR_RESOLUTION":
      case "NEEDS_CREDITOR_DISAMBIGUATION":
      case "SEARCHING_CREDITOR":
      case "CREDITOR_SEARCH_ERROR":
        return PreProcessStep.Creditor;
      case "READY":
        return PreProcessStep.Form410;
      default:
        return PreProcessStep.Completed;
    }
  })();

  const currentPreProcessStepTooltip = (function () {
    switch (claim.status) {
      case "NEEDS_CASE_NUMBER_RESOLUTION":
      case "SEARCHING_CASE_NUMBER":
        return "Searching for case number on court";
      case "NEEDS_CASE_NUMBER_DISAMBIGUATION":
        return "Please select a case";
      case "CASE_NUMBER_SEARCH_ERROR":
        return "An error occured while searching for case number";
      case "NEEDS_CREDITOR_RESOLUTION":
      case "SEARCHING_CREDITOR":
        return "Searching for creditor on court";
      case "NEEDS_CREDITOR_DISAMBIGUATION":
        return "Please select a creditor";
      case "CREDITOR_SEARCH_ERROR":
        return "An error occured while searching for creditor";
      case "READY":
        return "Generating Form 410";
      default:
        return "Ready";
    }
  })();

  const preProcessMacroSteps = ["Case search", "Creditor search", "Form 410"];

  return (
    <Accordion.Item eventKey={claim.id}>
      <Accordion.Header>
        <div className="d-flex align-items-center gap-2 flex-grow-1">
          <strong>Claim #{claimIndex + 1}</strong>

          <OverlayTrigger
            placement="top"
            trigger={["hover", "focus"]}
            overlay={<Tooltip>{currentPreProcessStepTooltip}</Tooltip>}
          >
            <div className="d-flex align-items-center" style={{ height: 24 }}>
              {preProcessMacroSteps.map((step, index) => (
                <Fragment key={step}>
                  <div
                    className={classnames("d-flex align-items-center px-2", {
                      "bg-primary bg-gradient text-white": currentPreProcessStep === index,
                      "bg-success bg-gradient text-white": currentPreProcessStep > index,
                      "bg-light bg-gradient text-dark": currentPreProcessStep < index,
                      "rounded-start": index === 0,
                      "rounded-end": index === preProcessMacroSteps.length - 1,
                    })}
                    style={{ height: 24 }}
                  >
                    {index < currentPreProcessStep ? <CheckIcon size={16} /> : <span>{index + 1}.</span>}
                    <span>{step}</span>
                  </div>

                  {index < preProcessMacroSteps.length - 1 && (
                    <div className="bg-white" style={{ width: 1, height: 24 }} />
                  )}
                </Fragment>
              ))}
            </div>
          </OverlayTrigger>

          {[
            "NEEDS_CASE_NUMBER_RESOLUTION",
            "SEARCHING_CASE_NUMBER",
            "NEEDS_CREDITOR_RESOLUTION",
            "SEARCHING_CREDITOR",
            "READY",
          ].includes(claim.status) && <Spinner animation="border" variant="primary" size="sm" />}

          {[
            "NEEDS_CASE_NUMBER_DISAMBIGUATION",
            "CASE_NUMBER_SEARCH_ERROR",
            "NEEDS_CREDITOR_DISAMBIGUATION",
            "CREDITOR_SEARCH_ERROR",
          ].includes(claim.status) && (
            <Alert variant="warning" className="mb-0">
              <div className="alert-message text-warning d-flex align-items-center gap-1 px-2 py-1">
                <WarningIcon size={16} />
                <span>Needs your attention, open to review</span>
              </div>
            </Alert>
          )}
        </div>
      </Accordion.Header>

      <Accordion.Body className="bg-white">
        <p className="lead">
          <strong>District:</strong> <span className="text-capitalize">{claim.district?.toLowerCase()}</span>{" "}
          <strong>Case no:</strong> {claim.case_no} <strong>Case title:</strong>{" "}
          <span title={claim.case_title}>{claim.case_title}</span> <strong>Creditor name:</strong>{" "}
          {claim.creditor?.name}
        </p>

        <ClaimRowBody key={claim.id} batchId={batchId} claim={claim} />
      </Accordion.Body>
    </Accordion.Item>
  );
});

const ClaimRowBody = observer(function ClaimRowBody({ batchId, claim }: { batchId: string; claim: Claim }) {
  const state = useLocalObservable(() => ({
    isSyncingChanges: false,
    selectedCaseIndex: null as number | null,
    selectedCreditor: undefined as string | null | undefined,
    formPreviewModal: { show: false } as { show: false } | { show: true; documentUrl: string },
    saveClaimCase: async function ({ case_no, case_title }: { case_no: string; case_title: string }) {
      try {
        this.isSyncingChanges = true;

        const claimId = claim.id;
        const response = await apolloClient.mutate({
          mutation: gql`
            mutation updateBatchClaim($batch_id: ID!, $claim_id: ID!, $data: UpdateBatchClaimInput!) {
              updateBatchClaim(batch_id: $batch_id, claim_id: $claim_id, data: $data) {
                error
                claim {
                  case_no
                  case_title
                  status
                }
              }
            }
          `,
          variables: {
            batch_id: batchId,
            claim_id: claimId,
            data: {
              case_title,
              case_no,
            },
          },
          fetchPolicy: "no-cache",
        });

        if (response.data.updateBatchClaim.error) {
          toast.error(response.data.updateBatchClaim.error);

          return;
        }

        runInAction(() => {
          this.isSyncingChanges = false;
          claim.case_no = response.data.updateBatchClaim.claim.case_no;
          claim.case_title = response.data.updateBatchClaim.claim.case_title;
          claim.status = response.data.updateBatchClaim.claim.status;
        });
      } catch (error) {
        console.log("[mutation] error");
        console.log(error);
        this.isSyncingChanges = false;

        toast.error(somethingWentWrong);
      }
    },
    saveClaimCreditor: async function ({
      creditor_id,
      creditor_name,
      is_new_creditor,
    }: {
      creditor_id?: string;
      creditor_name?: string;
      is_new_creditor?: boolean;
    }) {
      try {
        this.isSyncingChanges = true;

        const claimId = claim.id;
        const data = {
          ...(creditor_id ? { creditor_id } : {}),
          ...(creditor_name ? { creditor: { ...claim.creditor, name: creditor_name } } : {}),
          ...(is_new_creditor ? { is_new_creditor } : {}),
        };

        const response = await apolloClient.mutate({
          mutation: gql`
            mutation updateBatchClaim($batch_id: ID!, $claim_id: ID!, $data: UpdateBatchClaimInput!) {
              updateBatchClaim(batch_id: $batch_id, claim_id: $claim_id, data: $data) {
                error
                claim {
                  creditor {
                    name
                    address_1
                    address_2
                    address_3
                    city
                    state
                    zip
                    zip4
                    phone
                    email
                  }
                  status
                }
              }
            }
          `,
          variables: {
            batch_id: batchId,
            claim_id: claimId,
            data,
          },
          fetchPolicy: "no-cache",
        });

        if (response.data.updateBatchClaim.error) {
          toast.error(response.data.updateBatchClaim.error);

          return;
        }

        runInAction(() => {
          this.isSyncingChanges = false;
          claim.creditor = response.data.updateBatchClaim.claim.creditor;
          claim.status = response.data.updateBatchClaim.claim.status;
        });
      } catch (error) {
        console.log("[mutation] error");
        console.log(error);
        this.isSyncingChanges = false;

        toast.error(somethingWentWrong);
      }
    },
    getClaimForm410: async function () {
      try {
        this.isSyncingChanges = true;

        const response = await apolloClient.query({
          query: getClaimForm410,
          variables: {
            batch_id: batchId,
            claim_id: claim.id,
          },
          fetchPolicy: "network-only",
        });

        if (response.data.getClaimForm410.error) {
          toast.error(response.data.getClaimForm410.error);

          return;
        }

        runInAction(() => {
          this.isSyncingChanges = false;
          this.formPreviewModal = { show: true, documentUrl: response.data.getClaimForm410.url };
        });
      } catch (error) {
        console.log("[mutation] error");
        console.log(error);
        this.isSyncingChanges = false;

        toast.error(somethingWentWrong);
      }
    },
  }));

  const districtName =
    districts.find((district) => district.name.toLowerCase() === claim.district?.toLowerCase())?.formName || "";

  if (claim.status === "NEEDS_CASE_NUMBER_RESOLUTION" || claim.status === "SEARCHING_CASE_NUMBER") {
    return (
      <div className="d-flex align-items-center gap-2">
        <Spinner animation="border" variant="primary" size="sm" />

        <span>
          Looking for case number <strong>{claim.case_no}</strong> on <strong>{districtName}</strong>.
        </span>
      </div>
    );
  }

  if (claim.status === "CASE_NUMBER_SEARCH_ERROR") {
    return (
      <>
        <div className="d-flex align-items-center gap-2">
          <span>
            An error occured while searching for case number "{claim.case_no}" on {districtName}.
          </span>
        </div>
        <p className="lead">Please try again or contact support if problem persist</p>

        <Button type="button" variant="primary" onClick={() => (claim.status = "NEEDS_CASE_NUMBER_RESOLUTION")}>
          Retry
        </Button>
      </>
    );
  }

  if (claim.status === "NEEDS_CASE_NUMBER_DISAMBIGUATION") {
    return (
      <>
        {claim.found_cases && (
          <>
            {claim.found_cases.length === 0 ? (
              <p className="lead text-danger">
                We haven't found any matching case for "{claim.case_no}" on {districtName}. We won't be able to file
                this claim. Please check the correctness of the case number.
              </p>
            ) : claim.found_cases.length === 1 ? (
              <p className="lead">
                We found a case matching for "{claim.case_no}" on {districtName}. Please confirm that's what you've been
                looking for.
              </p>
            ) : (
              <p className="lead">
                We found some cases for "{claim.case_no}" on {districtName}. Please select a case.
              </p>
            )}

            <div className="mb-3">
              <CasesSearchResults
                cases={claim.found_cases}
                selectedCaseIndex={state.selectedCaseIndex}
                onSelectCase={(index) => (state.selectedCaseIndex = index)}
                selectInputName={`select-claim-found-case-${claim.id}`}
              />
            </div>

            <Button
              type="button"
              variant="primary"
              disabled={state.selectedCaseIndex === null || state.isSyncingChanges}
              onClick={() => {
                state.saveClaimCase({
                  case_no: claim.found_cases![state.selectedCaseIndex!].case_no,
                  case_title: claim.found_cases![state.selectedCaseIndex!].case_title,
                });
              }}
              className="d-flex align-items-center gap-1"
            >
              {state.isSyncingChanges ? (
                <>
                  <Spinner animation="border" size="sm" />
                  <span>Saving</span>
                </>
              ) : (
                "Confirm selection"
              )}
            </Button>
          </>
        )}
      </>
    );
  }

  if (claim.status === "NEEDS_CREDITOR_RESOLUTION" || claim.status === "SEARCHING_CREDITOR") {
    return (
      <div className="d-flex align-items-center gap-2">
        <Spinner animation="border" variant="primary" size="sm" />

        <span>
          Getting creditors list for case number <strong>{claim.case_no}</strong> on <strong>{districtName}</strong>.
        </span>
      </div>
    );
  }

  if (claim.status === "CREDITOR_SEARCH_ERROR") {
    return (
      <>
        <div className="d-flex align-items-center gap-2">
          <span>The following error occurred while searching for the creditor: "{claim.creditors_search_error}"</span>
        </div>
        <p className="lead">Please try again or contact support if problem persist</p>

        <Button type="button" variant="primary" onClick={() => (claim.status = "NEEDS_CREDITOR_RESOLUTION")}>
          Retry
        </Button>
      </>
    );
  }

  if (claim.status === "NEEDS_CREDITOR_DISAMBIGUATION") {
    return (
      <>
        {claim.found_creditors && (
          <>
            {claim.found_creditors.matching.length === 0 ? (
              <p className="lead">
                We haven't found any creditor matching the name "{claim.creditor?.name}" for the case number "
                {claim.case_no}" in the {districtName}.
              </p>
            ) : claim.found_creditors.matching.length === 1 ? (
              <p className="lead">
                We found a creditor matching the name "{claim.creditor?.name}" for the case number "{claim.case_no}" on{" "}
                {districtName}. Please confirm that's what you've been looking for.
              </p>
            ) : (
              <p className="lead">
                We found some creditors matching the name "{claim.creditor?.name}" for the case number "{claim.case_no}"
                on {districtName}. Please select a creditor.
              </p>
            )}

            <div className="mb-3">
              <CreditorsSearchResults
                creditors={claim.found_creditors}
                selectedCreditor={state.selectedCreditor}
                onSelectCreditor={(creditorId) => (state.selectedCreditor = creditorId)}
                districtName={districtName}
                selectInputName={`select-claim-creditor-${claim.id}`}
              />
            </div>

            <Button
              type="button"
              variant="primary"
              disabled={state.selectedCreditor === undefined || state.isSyncingChanges}
              onClick={() => {
                state.saveClaimCreditor({
                  ...(state.selectedCreditor
                    ? {
                        creditor_id: state.selectedCreditor,
                        creditor_name: [
                          ...(claim.found_creditors?.matching || []),
                          ...(claim.found_creditors?.not_matching || []),
                        ].find((creditor) => creditor.id === state.selectedCreditor)?.name,
                      }
                    : {}),
                  ...(!state.selectedCreditor ? { is_new_creditor: true } : {}),
                });
              }}
              className="d-flex align-items-center gap-1"
            >
              {state.isSyncingChanges ? (
                <>
                  <Spinner animation="border" size="sm" />
                  <span>Saving</span>
                </>
              ) : (
                "Confirm selection"
              )}
            </Button>
          </>
        )}
      </>
    );
  }

  if (claim.status === "READY") {
    return (
      <div className="d-flex align-items-center gap-2">
        <Spinner animation="border" variant="primary" size="sm" />

        <span>Generating Form 410</span>
      </div>
    );
  }

  if (claim.status === "COMPLETED") {
    return (
      <>
        <p className="lead">
          <strong>Attachments:</strong>{" "}
          {claim.attachments.length > 0
            ? claim.attachments.map((attachment) => attachment.filename).join(", ")
            : "this claim has no attachments"}
        </p>

        <div className="d-flex align-items-center gap-2">
          <CheckIcon size={24} className="text-success" />
          <span className="text-success">Ready to be packaged</span>
          <Button
            type="button"
            variant="outline-primary"
            className="d-flex align-items-center gap-1"
            onClick={() => state.getClaimForm410()}
            disabled={state.isSyncingChanges}
          >
            {state.isSyncingChanges ? (
              <>
                <Spinner animation="border" size="sm" />
                <span>Getting Form 410</span>
              </>
            ) : (
              "Review Form 410"
            )}
          </Button>
        </div>

        <ClaimFormPreviewModal
          show={state.formPreviewModal.show}
          onClose={() => (state.formPreviewModal = { show: false })}
          documentUrl={state.formPreviewModal.show ? state.formPreviewModal.documentUrl : null}
        />
      </>
    );
  }

  return null;
});

const PackagingClaims = observer(function PackagingClaims({ batchId }: { batchId: string }) {
  const state = useLocalObservable(() => ({
    isLoading: true,
    files: [] as Array<{ filename: string; s3_key: string; url: string }>,
    packageFiles: function* () {
      try {
        const {
          data: { createBatchPackages: createBatchPackagesResponse },
        }: {
          data: {
            createBatchPackages: {
              error: string | null;
              error_code: string | null;
              zip_files:
                | {
                    filename: string;
                    s3_key: string;
                    url: string;
                  }[]
                | null;
            };
          };
        } = yield apolloClient.mutate({
          mutation: createBatchPackages,
          variables: {
            batch_id: batchId,
          },
          fetchPolicy: "no-cache",
        });

        if (createBatchPackagesResponse.error) {
          toast.error(createBatchPackagesResponse.error, { autoClose: false });
          return;
        }

        this.isLoading = false;
        this.files = createBatchPackagesResponse.zip_files || [];
      } catch (error) {
        console.log(error);
        toast.error(somethingWentWrong, { autoClose: false });
      }
    },
  }));

  useEffect(() => {
    const promise = state.packageFiles();

    // @ts-ignore
    promise.catch(() => {}); // Ignore cancellation error

    return () => {
      // @ts-ignore
      promise.cancel();
    };
  });

  return (
    <Observer>
      {() => (
        <>
          {state.isLoading && (
            <div className="text-center">
              <h1>Packaging claims</h1>
              <h2>Please wait</h2>
              <Spinner animation="border" variant="primary" />
            </div>
          )}

          {!state.isLoading && state.files.length > 0 && (
            <>
              <h2>Done!</h2>
              <h3>Generated zip files</h3>

              <ul className="mb-0">
                {state.files.map((file) => (
                  <li key={file.filename}>
                    <a href={file.url} target="_blank" rel="noreferrer">
                      {file.filename}
                    </a>
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      )}
    </Observer>
  );
});
