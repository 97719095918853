import classNames from "classnames";
import { Form, Row, Col } from "react-bootstrap";
import { UseFormRegister, UseFormSetValue, Control, FieldErrors, Controller } from "react-hook-form";
import { usePlacesWidget } from "react-google-autocomplete";

import { FormValues } from "../interfaces";
import states from "../../lib/states.json";

interface GoogleMapsAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface Props {
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  control: Control<FormValues>;
}
export default function CreditorsAttorney({ register, errors, setValue, control }: Props) {
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      const addressComponents: GoogleMapsAddressComponent[] = place.address_components;
      console.log("addressComponents", addressComponents);
      if (!addressComponents) {
        return;
      }

      // Reference
      // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
      const address1 = [
        addressComponents.find(({ types }) => types.includes("street_number"))?.long_name || "",
        addressComponents.find(({ types }) => types.includes("route"))?.long_name || "",
      ]
        .filter(Boolean)
        .join(" ");
      const city =
        addressComponents.find(({ types }) => types.includes("locality"))?.long_name ||
        addressComponents.find(({ types }) => types.includes("neighborhood") && types.includes("political"))
          ?.long_name ||
        "";
      const state =
        addressComponents.find(({ types }) => types.includes("administrative_area_level_1"))?.short_name || "";
      const zip = addressComponents.find(({ types }) => types.includes("postal_code"))?.long_name || "";
      const zip4 = addressComponents.find(({ types }) => types.includes("postal_code_suffix"))?.long_name || "";

      setValue(
        "creditors_attorney.address",
        {
          address1,
          address2: "",
          address3: "",
          city,
          state,
          zip,
          zip4,
        },
        { shouldValidate: true }
      );
    },
    options: {
      componentRestrictions: { country: "us" },
      types: ["address"],
    },
  });

  return (
    <div id="creditors_attorney-container">
      <Form.Group controlId="creditors-attorney-name" className="mb-3">
        <Form.Label>
          Name of Attorney <span className="text-danger">(required)</span>
        </Form.Label>

        <Form.Control
          type="text"
          placeholder="John Doe"
          isInvalid={!!errors.creditors_attorney?.name?.message}
          {...register("creditors_attorney.name")}
        />

        <Form.Control.Feedback type="invalid">{errors.creditors_attorney?.name?.message}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor="creditor-address-1">
          Address where notices should be sent <span className="text-danger">(required)</span>
        </Form.Label>

        <Controller
          name="creditors_attorney.address.address1"
          control={control}
          render={({ field }) => (
            <Form.Control
              id="creditor-address-1"
              type="text"
              placeholder="123 Main St"
              className="mb-1 mw-100"
              isInvalid={!!errors.creditors_attorney?.address?.address1?.message}
              {...field}
              // @ts-ignore
              ref={ref}
              htmlSize={40}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
            />
          )}
        />

        <Form.Control
          type="text"
          placeholder="Apartment, studio, or floor"
          htmlSize={40}
          className="mb-1 mw-100"
          {...register("creditors_attorney.address.address2")}
        />

        <Form.Control
          type="text"
          placeholder="c/o"
          htmlSize={40}
          className="mw-100"
          {...register("creditors_attorney.address.address3")}
        />

        <Form.Control.Feedback type="invalid">
          {errors.creditors_attorney?.address?.address1?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Row className="mb-3 gx-0 gap-3">
        <Form.Group as={Col} sm="auto" controlId="creditor-address-city">
          <Form.Label>City</Form.Label>

          <Form.Control
            maxLength={30}
            isInvalid={!!errors.creditors_attorney?.address?.city?.message}
            {...register("creditors_attorney.address.city")}
          />

          <Form.Control.Feedback type="invalid">
            {errors.creditors_attorney?.address?.city?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} sm="auto" controlId="creditor-address-state">
          <Form.Label>State</Form.Label>

          <Form.Select
            isInvalid={!!errors.creditors_attorney?.address?.state?.message}
            {...register("creditors_attorney.address.state")}
          >
            <option value="">Choose...</option>
            {states.map(function ({ name, abbreviation }) {
              return (
                <option key={abbreviation} value={abbreviation}>
                  {name} ({abbreviation})
                </option>
              );
            })}
          </Form.Select>

          <Form.Control.Feedback type="invalid">
            {errors.creditors_attorney?.address?.state?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} sm="auto">
          <Form.Label htmlFor="creditor-address-zip-5">Zip</Form.Label>

          <div
            className={classNames("d-flex gap-1 align-items-center", {
              "is-invalid":
                !!errors.creditors_attorney?.address?.zip?.message ||
                !!errors.creditors_attorney?.address?.zip4?.message,
            })}
          >
            <Form.Control
              placeholder="12345"
              id="creditor-address-zip-5"
              maxLength={5}
              htmlSize={5}
              inputMode="numeric"
              isInvalid={!!errors.creditors_attorney?.address?.zip?.message}
              {...register("creditors_attorney.address.zip")}
            />
            -
            <Form.Control
              placeholder="1234"
              maxLength={4}
              htmlSize={4}
              inputMode="numeric"
              isInvalid={!!errors.creditors_attorney?.address?.zip4?.message}
              {...register("creditors_attorney.address.zip4")}
            />
          </div>

          <Form.Control.Feedback type="invalid">
            {errors.creditors_attorney?.address?.zip?.message || errors.creditors_attorney?.address?.zip4?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </div>
  );
}
