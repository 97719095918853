// Various Cognito Validation Rules
// https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
export default function inspectPasswordStrength(password) {
  password = password || ""; // undefined or null

  return {
    hasLowerCase: /[a-z]/.test(password),
    hasUpperCase: /[A-Z]/.test(password),
    hasNumber: /[0-9]/.test(password),
    // hasSpecialCharacter: /[=+\-^$*.\[\]{}()?"!@#%&\/\\,><':;|_~`]/.test(
    //   password
    // ),
    // hasNoSpaces: /^\S*$/.test(password),
    hasGoodSize: /\S{8,99}/.test(password)
  };
}
