import React, { useState } from "react";
import { Modal, Row, Col, Form, FormControlProps, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

import { apolloClient } from "../../configs/apollo-client";
import { somethingWentWrong } from "../../lib/errors";
import { validateTaxId, validateSSN } from "../Import/lib";

import editTrackedDebtor from "./graphql/editTrackedDebtor";

type TrackedDebtor = {
  id: string;
  ssn: string;
  taxid: string;
  full_name: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  generation: string;
  full_address: string;
  address_line_1: string;
  address_line_2: string;
  address_line_3: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  phone: string;
  last_check: string;
  created_at: string;
  updated_at: string;
};

interface FormData {
  id: string;
  ssn: string;
  taxid: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  generation: string;
  address_line_1: string;
  address_line_2: string;
  address_line_3: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  phone: string;
}

interface Props {
  show: boolean;
  onClose: (trackedDebtor?: TrackedDebtor) => void;
  data: FormData | null;
}
export default function ModifyRecordModal({ show, onClose, data }: Props) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<FormData | null>(data);
  const [errors, setErrors] = useState<{ ssn?: string; taxid?: string; last_name?: string }>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData!, [e.target.name]: e.target.value });
  };

  const onSave = async () => {
    try {
      if ((!formData?.ssn && !formData?.taxid) || (formData?.ssn && formData?.taxid)) {
        setErrors({
          ssn: "Insert SSN or Tax ID",
          taxid: "Insert SSN or Tax ID",
        });
        return;
      }

      if (formData?.ssn && !validateSSN(formData?.ssn)) {
        setErrors({
          ssn: "Invalid SSN",
        });
        return;
      }

      if (formData?.taxid && !validateTaxId(formData?.taxid)) {
        setErrors({
          taxid: "Invalid Tax ID",
        });
        return;
      }

      // check if last name is present
      if (formData?.taxid && formData?.last_name.trim() === "") {
        setErrors({
          last_name: "Company name is required",
        });
        return;
      }

      setErrors({});
      setLoading(true);

      const response = await apolloClient.mutate({
        mutation: editTrackedDebtor,
        variables: {
          input: {
            id: formData?.id,
            ssn: formData?.ssn,
            taxid: formData?.taxid,
            first_name: formData?.first_name,
            middle_name: formData?.middle_name,
            last_name: formData?.last_name,
            generation: formData?.generation,
            address_line_1: formData?.address_line_1,
            address_line_2: formData?.address_line_2,
            address_line_3: formData?.address_line_3,
            city: formData?.city,
            state: formData?.state,
            zip_code: formData?.zip_code,
            country: formData?.country,
            phone: formData?.phone,
          },
        },
        fetchPolicy: "no-cache",
      });

      toast.success("Changes have been saved");
      onClose(response.data.editTrackedDebtor as TrackedDebtor);
    } catch (error) {
      console.log(error);
      toast.error(somethingWentWrong);
    } finally {
      setLoading(false);
    }
  };

  if (!formData) {
    return null;
  }

  return (
    <Modal show={show} onHide={() => onClose()} size="lg" {...(loading ? { backdrop: "static", keyboard: false } : {})}>
      <Modal.Header closeButton={!loading}>
        <Modal.Title>Edit record</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <fieldset disabled={loading}>
          <Row className="mb-3">
            <Form.Group as={Col} sm={6} controlId="edit-ssn">
              <Form.Label>SSN</Form.Label>
              <InputMask
                mask="xxx-xx-xxxx"
                formatChars={{ x: "[x0-9]" }}
                name="ssn"
                value={formData.ssn}
                onChange={handleChange}
              >
                {(inputProps: FormControlProps) => (
                  <Form.Control
                    {...inputProps}
                    placeholder="xxx-xx-1234"
                    htmlSize={"xxx-xx-1234".length + 1}
                    isInvalid={!!errors.ssn}
                  />
                )}
              </InputMask>
              <Form.Control.Feedback type="invalid">{errors.ssn}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm={6} controlId="edit-taxid">
              <Form.Label>Tax ID</Form.Label>
              <InputMask mask="99-9999999" name="taxid" value={formData.taxid} onChange={handleChange}>
                {(inputProps: FormControlProps) => (
                  <Form.Control
                    {...inputProps}
                    placeholder="12-3456789"
                    htmlSize={"12-3456789".length + 1}
                    isInvalid={!!errors.taxid}
                  />
                )}
              </InputMask>
              <Form.Control.Feedback type="invalid">{errors.taxid}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} sm={3} controlId="edit-first_name">
              <Form.Label>First name</Form.Label>
              <Form.Control type="text" name="first_name" value={formData.first_name} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} sm={3} controlId="edit-middle_name">
              <Form.Label>Middle name</Form.Label>
              <Form.Control type="text" name="middle_name" value={formData.middle_name} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} sm={3} controlId="edit-last_name">
              <Form.Label>Last name / Company name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                isInvalid={!!errors.last_name}
              />
              <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm={3} controlId="edit-generation">
              <Form.Label>Generation</Form.Label>
              <Form.Control type="text" name="generation" value={formData.generation} onChange={handleChange} />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} sm={4} controlId="edit-address_line_1">
              <Form.Label>Address line 1</Form.Label>
              <Form.Control type="text" name="address_line_1" value={formData.address_line_1} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} sm={4} controlId="edit-address_line_2">
              <Form.Label>Address line 2</Form.Label>
              <Form.Control type="text" name="address_line_2" value={formData.address_line_2} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} sm={4} controlId="edit-address_line_3">
              <Form.Label>Address line 3</Form.Label>
              <Form.Control type="text" name="address_line_3" value={formData.address_line_3} onChange={handleChange} />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} sm={3} controlId="edit-city">
              <Form.Label>City</Form.Label>
              <Form.Control type="text" name="city" value={formData.city} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} sm={3} controlId="edit-state">
              <Form.Label>State</Form.Label>
              <Form.Control type="text" name="state" value={formData.state} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} sm={3} controlId="edit-zip_code">
              <Form.Label>Zip code</Form.Label>
              <Form.Control type="text" name="zip_code" value={formData.zip_code} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} sm={3} controlId="edit-country">
              <Form.Label>Country</Form.Label>
              <Form.Control type="text" name="country" value={formData.country} onChange={handleChange} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} sm={3} controlId="edit-phone">
              <Form.Label>Phone</Form.Label>
              <InputMask mask="+1 (999) 999-9999" name="phone" value={formData.phone} onChange={handleChange}>
                {(inputProps: FormControlProps) => (
                  <Form.Control
                    {...inputProps}
                    placeholder="+1 (555) 123-4567"
                    htmlSize={"+1 (555) 123-4567".length + 1}
                  />
                )}
              </InputMask>
            </Form.Group>
          </Row>
        </fieldset>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="secondary" onClick={() => onClose()} className="me-auto" disabled={loading}>
          Cancel
        </Button>

        <Button
          type="button"
          variant="primary"
          onClick={onSave}
          disabled={loading}
          className="d-flex align-items-center gap-2"
        >
          {loading ? (
            <>
              <Spinner animation="border" size="sm" />
              <span>Saving...</span>
            </>
          ) : (
            "Save"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
