import gql from "graphql-tag";

import { SelectedCase, Creditor, Signee } from "../steps/interfaces";
import { FormValues as DocumentationData } from "../steps/Documentation/interfaces";

const saveClaim = gql`
  mutation saveClaim($input: EPOCSaveClaimInput!) {
    saveClaim(input: $input) {
      claim_id
    }
  }
`;

export default saveClaim;

interface Arguments {
  claimId?: string;
  selectedCase: SelectedCase;
  selectedCreditor: Creditor;
  documentation: DocumentationData;
  signee: Signee;
}
export function buildMutationInput({ claimId, selectedCase, selectedCreditor, documentation, signee }: Arguments) {
  // serialize whole form and save as raw form data json string
  const rawFormData = JSON.stringify({
    selectedCase,
    selectedCreditor,
    documentation,
    signee,
  });

  const amend_claim = (function () {
    if (!documentation.amend_claim) {
      return null;
    }

    const parsed = JSON.parse(documentation.amend_claim);

    return {
      claim_number: parsed.number,
      claim_date: parsed.filed_on,
    };
  })();

  // build mutation input
  return {
    ...(claimId ? { claim_id: claimId } : {}),
    court_code: selectedCase.court_code,
    case_no: selectedCase.case_no,
    case_title: selectedCase.case_title,
    creditor: {
      // existing creditor
      ...(selectedCreditor.id
        ? {
            id: selectedCreditor.id,
            // A court requiring creditor name on the form first step?
            ...(selectedCase.creditor_name ? { name: selectedCase.creditor_name } : {}),
            // read from documentation
            phone: documentation.creditor_phone,
            email: documentation.creditor_email,
            alias: documentation.creditor_alias,
          }
        : // new creditor
          {
            id: null,
            name: selectedCreditor.name,
            address: selectedCreditor.address,
            phone: selectedCreditor.phone,
            email: selectedCreditor.email,
            alias: documentation.creditor_alias,
          }),
    },
    filed_by: documentation.filed_by,
    creditors_attorney: documentation.creditors_attorney,
    amend_claim,
    acquired_from: documentation.acquired_from_whom,
    payment_address: documentation.payment_address,
    uniform_identifier: documentation.uniform_identifier,
    other_proof_name: documentation.other_proof_name,
    debtor_number: documentation.debtor_number,
    claim_amount: Number(documentation.claim_amount),
    other_charges: documentation.include_interest === "yes",
    claim_comment: documentation.claim_comment,
    claim_basis: documentation.claim_basis,
    securities: documentation.securities
      ? {
          real_estate: !!documentation.securities.real_estate,
          vehicle: !!documentation.securities.vehicle,
          other: !!documentation.securities.other,
          other_desc: documentation.securities.other_desc,
          basis: documentation.securities.basis,
          value: Number(documentation.securities.value),
          amount_secured: Number(documentation.securities.amount_secured),
          amount_unsecured: Number(documentation.securities.amount_unsecured),
          cure_default: documentation.securities.cure_default ? Number(documentation.securities.cure_default) : null,
          annual_interest: documentation.securities.interest_rates
            ? {
                amount: Number(documentation.securities.annual_interest_rate),
                interest_type: documentation.securities.interest_rates,
              }
            : null,
        }
      : null,
    lease_default: documentation.lease_default ? Number(documentation.lease_default) : null,
    set_off: documentation.set_off,
    priorities: documentation.priorities
      ? {
          amount: Number(documentation.priorities.amount),
          code: documentation.priorities.code,
          code_507_other_sub: documentation.priorities.code_507_other_sub,
        }
      : null,
    attachments: documentation.attachments.map(({ filename, s3_key }) => ({
      filename,
      s3_key,
    })),
    signee: {
      id: signee.id,
      role: signee.role,
      signature: signee.signature,
      title: signee.title,
      company: signee.company,
      address: signee.address,
      phone: signee.phone,
      email: signee.email
    },
    raw_form_data: rawFormData,
  };
}
