import gql from "graphql-tag";

const getUserTrackedCase = gql`
  query getUserTrackedCase($case_id: ID!) {
    getUserTrackedCase(case_id: $case_id) {
      data {
        id
        case_title
        case_number
        court_code
        court_name
        date_filed
        chapter
        updated
        claim_deadline
        jurisdiction
      }
      purchase_details {
        id
        purchase_type
        payment_status
        expire_at
      }
      error
    }
  }
`;

export default getUserTrackedCase;
