import { Form } from "react-bootstrap";

interface IProps {
  options: number[];
  value: number;
  onChange: (value: number) => void;
}
export default function PageSize({ options, value, onChange }: IProps) {
  return (
    <Form.Select
      value={value}
      onChange={(event) => {
        onChange(Number(event.target.value));
      }}
      style={{ paddingTop: "0.3rem", paddingBottom: "0.3rem" }}
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option} per page
        </option>
      ))}
    </Form.Select>
  );
}
