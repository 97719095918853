import { forwardRef, useState, useEffect } from "react";
import classnames from "classnames";
import { Dropdown, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Bell as BellIcon, Check2All as CheckAllIcon, Activity as ActivityIcon } from "react-bootstrap-icons";

import { apolloClient } from "../../../configs/apollo-client";
import useTopicSubscription from "../../../lib/useTopicSubscription";

import getUserNotifications from "./graphql/getUserNotifications";
import markNotificationAsRead from "./graphql/markNotificationAsRead";
import markAllNotificationsAsRead from "./graphql/markAllNotificationsAsRead";

type Notification = {
  id: string;
  content: string;
  notification_type: string;
  is_new: boolean;
  created_at: string;
};

export default function Notifications({ organizationId }: { organizationId: string }) {
  const [notificationsResponse, setNotificationsResponse] = useState<Notification[] | undefined>(undefined);
  const newNotification = useTopicSubscription<{ message: string } | undefined>(
    organizationId ? `/notifications/${organizationId}` : null,
    undefined
  );
  const history = useHistory();

  useEffect(() => {
    const watchedQuery = apolloClient.watchQuery({
      query: getUserNotifications,
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        setNotificationsResponse(response.data.getUserNotifications as Notification[]);
        sub.unsubscribe();
      },
      error(err) {
        console.log("[watchQuery] error", err);
      },
    });

    if (newNotification) {
      toast.success(newNotification.message);
    }

    return () => {
      console.log("[watchQuery] Clean up");
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [newNotification]);

  const isLoading = notificationsResponse === undefined;
  const hasUnreadNotifications = notificationsResponse?.some((n) => n.is_new) ?? false;

  const onClickNotification = async (n: Notification) => {
    if (n.notification_type === "match") {
      // Optimistic update
      setNotificationsResponse((prev) => {
        if (prev) {
          return prev.map((notification) => {
            if (notification.id === n.id) {
              return { ...notification, is_new: false };
            }
            return notification;
          });
        }

        return prev;
      });

      // Fire and forget mutation
      apolloClient.mutate({
        mutation: markNotificationAsRead,
        variables: { id: n.id },
      });

      setTimeout(() => history.push("/matches"), 0);
    }
  };

  const onClickMarkAllAsRead = async () => {
    // Optimistic update
    setNotificationsResponse((prev) => {
      if (prev) {
        return prev.map((notification) => {
          return { ...notification, is_new: false };
        });
      }

      return prev;
    });

    // Fire and forget mutation
    apolloClient.mutate({
      mutation: markAllNotificationsAsRead,
    });
  };

  return (
    <Dropdown align="end" className="d-flex align-items-center">
      <Dropdown.Toggle as={NotificationsDropdownCustomToggle} hasUnreadNotifications={hasUnreadNotifications} />

      <Dropdown.Menu className="py-2 px-0" style={{ width: 350, "--bs-dropdown-zindex": 1100 } as React.CSSProperties}>
        <div className="d-flex align-items-center justify-content-between mb-2 px-3">
          <h4 className="mb-0">Notifications</h4>
          {!isLoading && notificationsResponse && notificationsResponse.length > 0 && (
            <Button
              type="button"
              variant="link"
              className="d-flex align-items-center gap-1 px-0"
              onClick={onClickMarkAllAsRead}
              disabled={!hasUnreadNotifications}
            >
              <CheckAllIcon size={18} />
              Mark all as read
            </Button>
          )}
        </div>

        {isLoading ? (
          <div className="px-3">
            <Spinner variant="primary" animation="border" size="sm" />
          </div>
        ) : notificationsResponse.length > 0 ? (
          <ul className="list-unstyled mb-0">
            {notificationsResponse.map((notification, index) => {
              const { id, content, notification_type, is_new, created_at } = notification;
              return (
                <li
                  key={id}
                  role="button"
                  className={classnames("d-flex gap-2 py-2 px-3", {
                    "bg-primary bg-opacity-10": is_new,
                    "bg-white": !is_new,
                    "border-bottom": index !== notificationsResponse.length - 1,
                  })}
                  onClick={() => {
                    onClickNotification(notification);
                  }}
                >
                  <div
                    className={classnames("border border-white rounded-circle", {
                      "bg-primary": is_new,
                      "bg-secondary": !is_new,
                    })}
                    style={{ width: 12, height: 12, marginTop: 5 }}
                  />
                  <div className="flex-grow-1">
                    <strong>{content}</strong>
                    <div className="text-muted">{new Date(created_at).toLocaleDateString("en-us")}</div>
                  </div>
                  <div
                    className={classnames(
                      "rounded-circle d-flex align-items-center justify-content-center text-white align-self-center",
                      {
                        "bg-primary": is_new,
                        "bg-secondary": !is_new,
                      }
                    )}
                    style={{ width: 40, height: 40 }}
                  >
                    {notification_type === "match" ? <ActivityIcon size={24} /> : ""}
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="px-3">
            <h5>There's nothing here!</h5>
            <small>We will notify you when there is an update or activity to show.</small>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const NotificationsDropdownCustomToggle = forwardRef<
  HTMLButtonElement,
  { onClick: () => {}; hasUnreadNotifications: boolean }
>(({ onClick, hasUnreadNotifications }, ref) => (
  <Button type="button" variant="secondary" ref={ref} onClick={onClick} className="position-relative">
    <BellIcon size={16} />

    {hasUnreadNotifications && (
      <div
        className="position-absolute border border-white rounded-circle bg-danger"
        style={{ width: 8, height: 8, bottom: 4, right: 9 }}
      />
    )}
  </Button>
));
