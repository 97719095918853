import gql from "graphql-tag";

const getClaimAndFilingProfiles = gql`
  query ($claim_id: ID!) {
    getClaim(claim_id: $claim_id) {
      error
      raw_form_data
      submission_status
      updated_at
    }

    getFilingProfiles {
      id
      name
      raw_form_data
      created_at
    }
  }
`;

export default getClaimAndFilingProfiles;
