import gql from "graphql-tag";

const editTrackedDebtor = gql`
  mutation editTrackedDebtor($input: EditTrackedDebtorInput!) {
    editTrackedDebtor(input: $input) {
      id
      ssn
      taxid
      full_name
      first_name
      middle_name
      last_name
      generation
      full_address
      address_line_1
      address_line_2
      address_line_3
      city
      state
      zip_code
      country
      phone
      last_check
      created_at
      updated_at
    }
  }
`;

export default editTrackedDebtor;
