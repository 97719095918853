import gql from "graphql-tag";

const disconnectApp = gql`
  mutation disconnectApp($id: ID!) {
    disconnectApp(id: $id) {
      id
      is_active
      created_at
      updated_at
    }
  }
`;

export default disconnectApp;
