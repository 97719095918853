import React, { useCallback } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Email address is invalid")
    .required("Email address is required"),
});

export default function Email({ isLoading, onEmailSubmit, onCodeReceived }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    ({ email }) => onEmailSubmit(email),
    [onEmailSubmit]
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="email" className="mb-3">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          {...register("email")}
          type="email"
          placeholder="johndoe@foobar.org"
          isInvalid={errors.email}
          disabled={isLoading}
          autoFocus
        />
        <Form.Control.Feedback type="invalid">
          {errors.email && errors.email.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Button type="submit" disabled={isLoading}>
          {isLoading ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            "Send verification code"
          )}
        </Button>
      </Form.Group>

      <Button
        type="button"
        variant="link"
        className="p-0"
        onClick={handleSubmit(onCodeReceived)}
      >
        I already received the verification code
      </Button>
    </Form>
  );
}
