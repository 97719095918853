import gql from "graphql-tag";

const getImpersonateAuthToken = gql`
  query getImpersonateAuthToken($email: String!) {
    getImpersonateAuthToken(email: $email) {
      access_token
      refresh_token
      id_token
    }
  }
`;

export default getImpersonateAuthToken;
