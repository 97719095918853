import gql from "graphql-tag";

const getClaimSubmissionProgress = gql`
  query getClaimSubmissionProgress($task_id: ID!) {
    getClaimSubmissionProgress(task_id: $task_id) {
      done
      court_api_response_status_code
      court_api_response_status_text
      court_api_response_body
    }
  }
`;

export default getClaimSubmissionProgress;
