import { useState, useEffect, useCallback } from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";

import { apolloClient } from "../../../../configs/apollo-client";
import Pagination from "../../../../components/atoms/Pagination/Pagination";
import PageSize from "../../../../components/atoms/PageSize";
import DataGridTable from "../../../../components/DataGridTable";
import listDockets from "../graphql/listDockets";
import purchaseDocketDocument from "../graphql/purchaseDocketDocument";
import getPacerUpdateProgress from "../graphql/getPacerUpdateProgress";
import { somethingWentWrong } from "../../../../lib/errors";

import Filters from "./Filters";
import DocketRow from "./DocketRow";
import headerColumns from "./headerColumns";
import BuyDocketDocumentModal from "./BuyDocketDocumentModal";

import "./styles.scss";

export default function Docket({ caseId, expandOnDocketNumber }) {
  const [isLoading, setIsLoading] = useState(true);
  const [updatingTaskId, setUpdatingTaskId] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(undefined);
  const [dateFrom, setDateFrom] = useState(undefined);
  const [dateTo, setDateTo] = useState(undefined);
  const [docketNumber, setDocketNumber] = useState(expandOnDocketNumber);
  const [selectedDocket, setSelectedDocket] = useState(
    !isNaN(Number(expandOnDocketNumber)) ? Number(expandOnDocketNumber) : undefined
  );
  const [sortBy, setSortBy] = useState("date_filed");
  const [sortOrder, setSortOrder] = useState("desc");
  const [pageSize, setPageSize] = useState(500);
  const [pageNumber, setPageNumber] = useState(1);
  const [response, setResponse] = useState(undefined);
  const [buyDocketDocument, setBuyDocketDocument] = useState({
    showModal: false,
  });

  useEffect(() => {
    setIsLoading(true);

    const watchedQuery = apolloClient.watchQuery({
      query: listDockets,
      variables: {
        input: {
          case_id: caseId,
          search_keyword: searchKeyword,
          docket_number_range: docketNumber,
          date_filed_from: dateFrom,
          date_filed_to: dateTo,
          sort_column: sortBy,
          sort_order: sortOrder,
          page_size: pageSize,
          page_number: pageNumber,
        },
      },
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        if (response.partial) {
          return;
        }

        if (response.data.listDockets.updating) {
          setUpdatingTaskId({
            task_id: response.data.listDockets.task_id,
            attempts: 0,
          });
        } else {
          setResponse({
            ...response.data.listDockets,
            entries: {
              ...response.data.listDockets.entries,
              content: response.data.listDockets.entries.content.map((content) => ({
                id: content.docket_seq,
                ...content,
              })),
            },
          });
          setIsLoading(false);
        }
      },
      error(err) {
        console.log("[watchQuery] error", err);
        setIsLoading(false);
        setResponse(undefined);
        toast.error(somethingWentWrong);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, [caseId, searchKeyword, dateFrom, dateTo, docketNumber, sortBy, sortOrder, pageSize, pageNumber]);

  useEffect(() => {
    let sub;
    let timer;

    if (updatingTaskId) {
      const { task_id, attempts } = updatingTaskId;

      const watchedQuery = apolloClient.watchQuery({
        query: getPacerUpdateProgress,
        variables: {
          task_id,
        },
        fetchPolicy: "network-only",
      });

      sub = watchedQuery.subscribe({
        next(response) {
          if (response.partial) {
            return;
          }

          if (response.data.getPacerUpdateProgress.pending) {
            timer = setTimeout(() => {
              setUpdatingTaskId({
                task_id,
                attempts: attempts + 1,
              });
            }, 2000);
          } else {
            setResponse({
              ...response.data.getPacerUpdateProgress,
              entries: {
                ...response.data.getPacerUpdateProgress.entries,
                content: response.data.getPacerUpdateProgress.entries.content.map((content) => ({
                  id: content.docket_seq,
                  ...content,
                })),
              },
            });
            setIsLoading(false);
            setUpdatingTaskId(null);
          }
        },
        error(err) {
          console.log("[watchQuery] error", err);
          setIsLoading(false);
          setResponse(undefined);
          setUpdatingTaskId(null);
          toast.error(somethingWentWrong);
        },
      });
    }

    return () => {
      console.log("[watchQuery] Clean up");
      if (sub) {
        console.log("[watchQuery] sub.unsubscribe();");
        sub.unsubscribe();
      }

      if (timer) {
        console.log("[watchQuery] clearTimeout(timer);");
        clearTimeout(timer);
      }
    };
  }, [updatingTaskId]);

  const onSubmit = useCallback(({ filterBy, docketNumber, dateFrom, dateTo }) => {
    setSearchKeyword(filterBy);
    setDocketNumber(docketNumber);
    setDateFrom(dateFrom);
    setDateTo(dateTo);
    setSortBy("date_filed");
    setSortOrder("desc");
    setPageNumber(1);
  }, []);

  const onClear = useCallback(() => {
    setSearchKeyword(undefined);
    setDocketNumber(undefined);
    setDateFrom(undefined);
    setDateTo();
    setSortBy("date_filed");
    setSortOrder("desc");
    setPageNumber(1);
  }, []);

  const onSortBy = useCallback(
    (columnId) => {
      setSortBy(columnId);
      setSortOrder(sortOrder === "desc" ? "asc" : "desc");
      setPageNumber(1);
    },
    [sortOrder]
  );

  const onChangePageSize = useCallback((ev) => {
    setPageSize(ev.target.value);
    setPageNumber(1);
  }, []);

  const onClickBuyDocketDocument = useCallback(
    async ({ document_number }) => {
      setBuyDocketDocument({
        showModal: true,
        docket_seq: selectedDocket,
        document_number,
      });
    },
    [selectedDocket]
  );

  const renderRow = useCallback(
    (row) => (
      <DocketRow
        caseId={caseId}
        row={row}
        open={row.id === selectedDocket}
        reload={row.id === selectedDocket && buyDocketDocument.reload}
        onClickBuyDocketDocument={onClickBuyDocketDocument}
      />
    ),
    [caseId, selectedDocket, onClickBuyDocketDocument, buyDocketDocument]
  );

  const keyExtractor = useCallback((row) => row.id, []);

  const isRowClickable = useCallback((row) => row.has_pdf_link_on_pacer && row.id !== selectedDocket, [selectedDocket]);

  const onRowClick = useCallback((row) => {
    setSelectedDocket(row.id);
  }, []);

  const onCloseBuyDocketDocumentModal = useCallback(() => setBuyDocketDocument({ showModal: false }), []);

  const onConfirmBuyDocketDocument = useCallback(async () => {
    try {
      const response = await apolloClient.mutate({
        mutation: purchaseDocketDocument,
        variables: {
          input: {
            case_id: caseId,
            docket_seq: buyDocketDocument.docket_seq,
            document_number: buyDocketDocument.document_number,
          },
        },
      });

      if (response.data.purchaseDocketDocument.error) {
        setBuyDocketDocument({ showModal: false });
        toast.error(somethingWentWrong);
      } else {
        setBuyDocketDocument({ showModal: false, reload: true });
        toast.success("Thanks for the purchase! You can now access the docket document");
      }
    } catch (error) {
      setBuyDocketDocument({ showModal: false });
      console.log(error);
      toast.error(somethingWentWrong);
    }
  }, [buyDocketDocument.docket_seq, buyDocketDocument.document_number, caseId]);

  function renderBody() {
    if (updatingTaskId) {
      return (
        <>
          <p>
            This request may take a little while. We're contacting the court to update the docket sheet, please wait.
          </p>
          <Spinner animation="border" variant="primary" />
        </>
      );
    }

    if (isLoading || !response) {
      return <Spinner animation="border" variant="primary" />;
    }

    return (
      <>
        <Row className="mb-2 g-0">
          <Col xs="auto" className="d-flex align-items-center">
            <Pagination
              currentPage={pageNumber}
              totalPages={response.entries.total_pages}
              disabled={isLoading}
              onClick={setPageNumber}
            />
          </Col>
          <Col xs="auto" className="d-flex align-items-center ms-2">
            <PageSize
              id="page-size"
              value={pageSize}
              onChange={onChangePageSize}
              disabled={isLoading}
              options={[500, 1000]}
            />
          </Col>
        </Row>

        <DataGridTable
          className="mb-2"
          tableClassName="dockets-table"
          highlightOnHover={true}
          isRowClickable={isRowClickable}
          headerColumns={headerColumns}
          selectableRows={false}
          sortableRows={true}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSortBy={onSortBy}
          isLoading={isLoading}
          data={response.entries.content}
          noRecordsFoundLabel={
            searchKeyword || dateFrom || dateTo || docketNumber
              ? "Dockets not found, try to refine your search."
              : "Dockets not found"
          }
          renderRow={renderRow}
          keyExtractor={keyExtractor}
          onRowClick={onRowClick}
        />

        <Row className="g-0">
          <Col xs="auto" className="d-flex align-items-center">
            <Pagination
              currentPage={pageNumber}
              totalPages={response.entries.total_pages}
              disabled={isLoading}
              onClick={setPageNumber}
            />
          </Col>
          <Col xs="auto" className="d-flex align-items-center ms-2">
            <PageSize
              id="page-size"
              value={pageSize}
              onChange={onChangePageSize}
              disabled={isLoading}
              options={[500, 1000]}
            />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Filters
            isLoading={isLoading}
            onSubmit={onSubmit}
            onClear={onClear}
            expandOnDocketNumber={expandOnDocketNumber}
          />
        </Card.Body>
      </Card>

      {renderBody()}

      <BuyDocketDocumentModal
        show={buyDocketDocument.showModal}
        onClose={onCloseBuyDocketDocumentModal}
        onConfirm={onConfirmBuyDocketDocument}
      />
    </>
  );
}
