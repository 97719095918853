import gql from "graphql-tag";

const markNotificationAsRead = gql`
  mutation markNotificationAsRead($id: ID!) {
    markNotificationAsRead(id: $id) {
      id
      content
      notification_type
      is_new
      created_at
    }
  }
`;

export default markNotificationAsRead;
