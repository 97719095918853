import { useState, useRef } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { Search as SearchIcon, X as XIcon } from "react-bootstrap-icons";

import "./SearchInput.scss";

interface Props {
  onSubmit: (searchTerm: string) => void;
}
export default function SearchInput({ onSubmit }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        const trimmed = searchTerm.trim();
        if (trimmed !== "") {
          onSubmit(trimmed);
          setSearchTerm(trimmed);
        }
      }}
      className="search-input-form"
    >
      <InputGroup className="search-wrapper">
        <div className="search-input-wrapper">
          <Form.Control
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);

              if (event.target.value.trim() === "") {
                onSubmit("");
              }
            }}
            ref={inputRef}
            size="sm"
          />
          {searchTerm.trim() !== "" && (
            <Button
              type="button"
              variant="link"
              onClick={() => {
                setSearchTerm("");
                onSubmit("");
                setTimeout(() => inputRef.current?.focus(), 0);
              }}
            >
              <XIcon size="24" />
            </Button>
          )}
        </div>

        <Button type="submit" variant="primary" size="sm">
          <SearchIcon size="16" />
        </Button>
      </InputGroup>
    </Form>
  );
}
