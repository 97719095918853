import { useState, useEffect, useCallback } from "react";
import { Card, Table, Container, Button, Spinner, Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import momentTz from "moment-timezone";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Clock as ClockIcon,
  CalendarEvent as CalendarIcon,
  FileText as FileIcon,
  Download as DownloadIcon,
  Receipt as ReceiptIcon,
  CaretDownFill as CaretDownIcon,
  CaretUpFill as CaretUpIcon,
} from "react-bootstrap-icons";

import { apolloClient } from "../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../lib/errors";
import ConfirmModal from "../../../../components/ConfirmModal";
import Pagination from "../../../../components/atoms/Pagination/Pagination";

import listOrganizationSubscriptions from "./graphql/listOrganizationSubscriptions";
import cancelOrganizationSubscription from "./graphql/cancelOrganizationSubscription";
import listOrganizationPurchases from "./graphql/listOrganizationPurchases";
import getOrganizationPurchaseInvoice from "./graphql/getOrganizationPurchaseInvoice";
import courts from "./courts.json";

type MonthlySubscription = {
  id: string;
  case_id: string;
  court_code: string;
  case_number: string;
  cost: number;
  auto_renews_on: number;
  created_at: string;
};
type DayPass = {
  id: string;
  case_id: string;
  court_code: string;
  case_number: string;
  expire_at: number;
  expired: boolean;
  created_at: string;
};
type PageSize = "TEN" | "TWENTY" | "FIFTY";
type SortBy = "purchase_type" | "court_code" | "case_number" | "created_at";
type SortOrder = "ASC" | "DESC";
type PurchaseType = "24h_pass" | "monthly_subscription" | "docket_pdf";
type ReceiptsResponse = {
  page: {
    total_records_count: number;
    total_pages: number;
  };
  data: {
    id: string;
    purchase_type: PurchaseType;
    case_id: string;
    court_code: string;
    case_number: string;
    expire_at?: number;
    docket_sequence_number?: string;
    docket_document_number: number;
    created_at: string;
    deleted_at: string | null;
  }[];
};

function getDayPassStatus(expired: boolean, expireAt: number) {
  const expireAtDate = new Date(expireAt * 1000);
  if (expired) {
    return `Expired on ${expireAtDate.toLocaleDateString()} ${expireAtDate.toLocaleTimeString()}`;
  }

  return [
    `Active, will expire on ${moment(momentTz(new Date(+expireAt * 1000)).tz("America/New_York")).format(
      "MMMM Do YYYY, h:mm a"
    )} New York time.`,
  ].join(" ");
}

function getPurchaseTypeLabel(purchaseType: PurchaseType) {
  switch (purchaseType) {
    case "24h_pass":
      return "Day pass";
    case "monthly_subscription":
      return "Subscription";
    case "docket_pdf":
      return "Docket document";
    default:
      return purchaseType;
  }
}

function getPurchaseTypeIcon(purchaseType: PurchaseType) {
  switch (purchaseType) {
    case "24h_pass":
      return ClockIcon;
    case "monthly_subscription":
      return CalendarIcon;
    case "docket_pdf":
      return FileIcon;
    default:
      return () => null;
  }
}

export default function SubscriptionsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [monthlySubscriptions, setMonthlySubscriptions] = useState<MonthlySubscription[]>([]);
  const [dayPasses, setDayPasses] = useState<DayPass[]>([]);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState<MonthlySubscription | false>(false);
  const [unsubscribeModalLoading, setUnsubscribeModalLoading] = useState(false);
  // Receipts section state variables
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState<PageSize>("TEN");
  const [sortBy, setSortBy] = useState<SortBy>("created_at");
  const [sortOrder, setSortOrder] = useState<SortOrder>("DESC");
  const [isLoadingReceipts, setIsLoadingReceipts] = useState(true);
  const [receiptsResponse, setReceiptsResponse] = useState<ReceiptsResponse | null>(null);
  const [isLoadingInvoice, setIsLoadingInvoice] = useState<false | string>(false);

  useEffect(() => {
    const watchedQuery = apolloClient.watchQuery({
      query: listOrganizationSubscriptions,
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        setMonthlySubscriptions(response.data.listOrganizationMonthlySubscriptions);
        setDayPasses(response.data.listOrganizationDayPasses);
        setIsLoading(false);
        sub.unsubscribe();
      },
      error(err) {
        console.log("[watchQuery] error", err);
        setIsLoading(false);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setIsLoadingReceipts(true);

    const watchedQuery = apolloClient.watchQuery({
      query: listOrganizationPurchases,
      variables: {
        input: {
          sort_by: {
            column: sortBy,
            order: sortOrder.toUpperCase(),
          },
          pagination: {
            page_number: pageNumber - 1,
            page_size: pageSize,
          },
        },
      },
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        setReceiptsResponse(response.data.listOrganizationPurchases);
        setIsLoadingReceipts(false);
      },
      error(err) {
        console.log("[watchQuery] error", err);
        setIsLoadingReceipts(false);
      },
    });

    return () => {
      sub.unsubscribe();
    };
  }, [pageNumber, pageSize, sortBy, sortOrder]);

  const onCloseConfirmModal = useCallback(() => setShowUnsubscribeModal(false), []);

  const onConfirmCancelSubscription = useCallback(async () => {
    if (!showUnsubscribeModal) return;

    try {
      setUnsubscribeModalLoading(true);

      const response = await apolloClient.mutate({
        mutation: cancelOrganizationSubscription,
        variables: {
          purchase_id: showUnsubscribeModal.id,
        },
      });

      const error = response.data.cancelOrganizationSubscription.error;
      if (error) {
        toast.error(error);
      } else {
        toast.success("Subscription canceled successfully!");
        setMonthlySubscriptions((prev) => prev.filter((sub) => sub.id !== showUnsubscribeModal.id));
      }
    } catch (error) {
      console.log(error);
      toast.error(somethingWentWrong);
    } finally {
      setUnsubscribeModalLoading(false);
      setShowUnsubscribeModal(false);
    }
  }, [showUnsubscribeModal]);

  const onSortBy = useCallback(
    (columnId: SortBy) => {
      setSortBy(columnId);
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
      setPageNumber(1);
    },
    [sortOrder]
  );

  const onDownloadReceipt = useCallback(async (purchaseId: string) => {
    setIsLoadingInvoice(purchaseId);

    try {
      const response = await apolloClient.query({
        query: getOrganizationPurchaseInvoice,
        variables: {
          purchase_id: purchaseId,
        },
        fetchPolicy: "cache-first",
      });

      setIsLoadingInvoice(false);

      if (response.data.getOrganizationPurchaseInvoice.url) {
        window.open(response.data.getOrganizationPurchaseInvoice.url, "_blank");
      } else {
        toast.error(somethingWentWrong);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(somethingWentWrong);
      setIsLoadingInvoice(false);
    }
  }, []);

  return (
    <>
      <Container fluid="xl" className="px-0">
        <Card>
          <Card.Body>
            <Card.Title as="h1" className="mb-0 d-flex align-items-center gap-2 text-primary">
              <CalendarIcon size="22" />
              Your active subscriptions
            </Card.Title>
          </Card.Body>

          <Table hover size="sm">
            <thead className="table-light z-2 shadow-lg">
              <tr>
                <th>Type</th>
                <th>Court code</th>
                <th>Case number</th>
                <th>Cost</th>
                <th>Auto renews on</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </td>
                </tr>
              )}
              {!isLoading &&
                monthlySubscriptions.map((subscription) => {
                  const court = courts.find((court) => court.code === subscription.court_code);

                  return (
                    <tr key={subscription.id}>
                      <td>Case lookup</td>
                      <td>
                        {court ? (
                          <>
                            <span>{court.name}</span> <span>({subscription.court_code})</span>
                          </>
                        ) : (
                          subscription.court_code
                        )}
                      </td>
                      <td>
                        <Link to={`/cases/${subscription.case_id}`} className="btn btn-link px-0">
                          {subscription.case_number}
                        </Link>
                      </td>
                      <td>${subscription.cost.toFixed(2)}</td>
                      <td>
                        {subscription.auto_renews_on &&
                          new Date(subscription.auto_renews_on * 1000).toLocaleDateString()}
                      </td>
                      <td>
                        <Button variant="link" className="px-0" onClick={() => setShowUnsubscribeModal(subscription)}>
                          Cancel subscription
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              {!isLoading && monthlySubscriptions.length === 0 && (
                <tr>
                  <td colSpan={6}>You don't have any active subscription.</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>

        <Card>
          <Card.Body>
            <Card.Title as="h1" className="mb-0 d-flex align-items-center gap-2 text-primary">
              <ClockIcon size="22" />
              Your day passes
            </Card.Title>
          </Card.Body>

          <Table hover size="sm">
            <thead className="table-light z-2 shadow-lg">
              <tr>
                <th>Court code</th>
                <th>Case number</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={3} className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </td>
                </tr>
              )}
              {!isLoading &&
                dayPasses.map((dayPass) => {
                  const court = courts.find((court) => court.code === dayPass.court_code);

                  return (
                    <tr key={dayPass.id}>
                      <td>
                        {court ? (
                          <>
                            <span>{court.name}</span> <span>({dayPass.court_code})</span>
                          </>
                        ) : (
                          dayPass.court_code
                        )}
                      </td>
                      <td>
                        <Link to={`/cases/${dayPass.case_id}`} className="btn btn-link px-0">
                          {dayPass.case_number}
                        </Link>
                      </td>
                      <td className={dayPass.expired ? "text-danger" : "text-success"}>
                        {getDayPassStatus(dayPass.expired, dayPass.expire_at)}
                      </td>
                    </tr>
                  );
                })}
              {!isLoading && dayPasses.length === 0 && (
                <tr>
                  <td colSpan={3}>You don't have any day pass.</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>

        <Card>
          <Card.Body>
            <Card.Title as="h1" className="mb-0 d-flex align-items-center gap-2 text-primary">
              <ReceiptIcon size="22" />
              Receipts
            </Card.Title>
          </Card.Body>

          <Table hover size="sm">
            <thead className="table-light z-2 shadow-lg">
              <tr>
                <th>
                  <Button
                    variant="link"
                    type="button"
                    className="d-flex align-items-center gap-1 p-0"
                    onClick={() => onSortBy("purchase_type")}
                    title="Sort by Type"
                  >
                    Type
                    {sortBy === "purchase_type" ? (
                      sortOrder === "DESC" ? (
                        <CaretDownIcon size="14" />
                      ) : (
                        <CaretUpIcon size="14" />
                      )
                    ) : null}
                  </Button>
                </th>
                <th>
                  <Button
                    variant="link"
                    type="button"
                    className="d-flex align-items-center gap-1 p-0"
                    onClick={() => onSortBy("court_code")}
                    title="Sort by Court code"
                  >
                    Court code
                    {sortBy === "court_code" ? (
                      sortOrder === "DESC" ? (
                        <CaretDownIcon size="14" />
                      ) : (
                        <CaretUpIcon size="14" />
                      )
                    ) : null}
                  </Button>
                </th>
                <th>
                  <Button
                    variant="link"
                    type="button"
                    className="d-flex align-items-center gap-1 p-0"
                    onClick={() => onSortBy("case_number")}
                    title="Sort by Case number"
                  >
                    Case number
                    {sortBy === "case_number" ? (
                      sortOrder === "DESC" ? (
                        <CaretDownIcon size="14" />
                      ) : (
                        <CaretUpIcon size="14" />
                      )
                    ) : null}
                  </Button>
                </th>
                <th>
                  <Button
                    variant="link"
                    type="button"
                    className="d-flex align-items-center gap-1 p-0"
                    onClick={() => onSortBy("created_at")}
                    title="Sort by Purchased at"
                  >
                    Purchased at
                    {sortBy === "created_at" ? (
                      sortOrder === "DESC" ? (
                        <CaretDownIcon size="14" />
                      ) : (
                        <CaretUpIcon size="14" />
                      )
                    ) : null}
                  </Button>
                </th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {isLoadingReceipts && (
                <tr>
                  <td colSpan={5} className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </td>
                </tr>
              )}
              {!isLoadingReceipts &&
                receiptsResponse?.data.map((receipt) => {
                  const court = courts.find((court) => court.code === receipt.court_code);
                  const createdAt = new Date(receipt.created_at);
                  const PurchaseTypeIcon = getPurchaseTypeIcon(receipt.purchase_type);

                  return (
                    <tr key={receipt.id}>
                      <td>
                        <span className="d-flex align-items-center gap-2">
                          <PurchaseTypeIcon size="18" className="text-primary" />
                          {getPurchaseTypeLabel(receipt.purchase_type)}
                        </span>
                      </td>
                      <td>
                        {" "}
                        {court ? (
                          <>
                            <span>{court.name}</span> <span>({receipt.court_code})</span>
                          </>
                        ) : (
                          receipt.court_code
                        )}
                      </td>
                      <td>
                        <Link to={`/cases/${receipt.case_id}`} className="btn btn-link px-0">
                          {receipt.case_number}
                        </Link>
                      </td>
                      <td>
                        {createdAt.toLocaleDateString(undefined, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}{" "}
                        {createdAt.toLocaleTimeString()}
                      </td>
                      <td>
                        <OverlayTrigger
                          placement="top"
                          trigger={["hover", "focus"]}
                          overlay={
                            <Tooltip>
                              {receipt.purchase_type === "monthly_subscription"
                                ? "Download latest invoice"
                                : "Download invoice"}
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="outline-primary"
                            size="sm"
                            className="d-flex align-items-center"
                            onClick={() => onDownloadReceipt(receipt.id)}
                            disabled={!!isLoadingInvoice}
                          >
                            {isLoadingInvoice === receipt.id ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              <DownloadIcon size="16" />
                            )}
                          </Button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  );
                })}
              {!isLoadingReceipts && receiptsResponse?.data.length === 0 && (
                <tr>
                  <td colSpan={5}>You don't have any receipt yet.</td>
                </tr>
              )}
            </tbody>
          </Table>

          <Card.Body className="border-top">
            <Row>
              <Col xs="auto">
                <Pagination
                  currentPage={pageNumber}
                  totalPages={receiptsResponse ? receiptsResponse.page.total_pages : 0}
                  onClick={(pageNumber: number) => {
                    setPageNumber(pageNumber);
                  }}
                  disabled={isLoadingReceipts}
                />
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <Form.Select
                  value={pageSize}
                  onChange={(event) => {
                    // Reset pagination and selected rows
                    setPageNumber(1);
                    setPageSize(event.target.value as PageSize);
                  }}
                >
                  <option value="TEN">10</option>
                  <option value="TWENTY">20</option>
                  <option value="FIFTY">50</option>
                </Form.Select>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>

      <ConfirmModal
        show={!!showUnsubscribeModal}
        onClose={onCloseConfirmModal}
        title="Are you sure?"
        onConfirm={onConfirmCancelSubscription}
        cancelButtonLabel="Keep subscription"
        confirmButtonLabel="Cancel subscription"
        cancelButtonColorVariant="primary"
        confirmButtonColorVariant="danger"
        isLoading={unsubscribeModalLoading}
      >
        {showUnsubscribeModal && (
          <>
            Are you sure you want to cancel the subscription for the case{" "}
            <Link to={`/cases/${showUnsubscribeModal.case_id}`}>{showUnsubscribeModal.case_number}</Link>?
          </>
        )}
      </ConfirmModal>
    </>
  );
}
