import gql from "graphql-tag";

const getOrganizationPurchaseInvoice = gql`
  query getOrganizationPurchaseInvoice($purchase_id: ID!) {
    getOrganizationPurchaseInvoice(purchase_id: $purchase_id) {
      url
    }
  }
`;

export default getOrganizationPurchaseInvoice;
