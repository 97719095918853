const districts = [
  {
    name: "ALABAMA MIDDLE",
    code: "1127",
    formName: "Middle District of Alabama",
    court_code: "almbke",
    has_epoc: true,
    subdomain: "ecf.almb.uscourts.gov",
  },
  {
    name: "ALABAMA NORTHERN",
    code: "1126",
    formName: "Northern District of Alabama",
    court_code: "alnbke",
    has_epoc: true,
    subdomain: "ecf.alnb.uscourts.gov",
  },
  {
    name: "ALABAMA SOUTHERN",
    code: "1128",
    formName: "Southern District of Alabama",
    court_code: "alsbke",
    has_epoc: true,
    subdomain: "ecf.alsb.uscourts.gov",
  },
  {
    name: "ALASKA",
    code: "097-",
    formName: "District of Alaska",
    court_code: "akbke",
    has_epoc: false,
    subdomain: "ecf.akb.uscourts.gov",
  },
  {
    name: "ARIZONA",
    code: "0970",
    formName: "District of Arizona",
    court_code: "azbke",
    has_epoc: true,
    subdomain: "ecf.azb.uscourts.gov",
  },
  {
    name: "ARKANSAS EASTERN",
    code: "0860",
    formName: "Eastern and Western District of Arkansas",
    court_code: "arebke",
    has_epoc: true,
    subdomain: "ecf.areb.uscourts.gov",
  },
  {
    name: "ARKANSAS WESTERN",
    code: "0861",
    formName: "Eastern and Western District of Arkansas",
    court_code: "arwbke",
    has_epoc: true,
    subdomain: "ecf.arwb.uscourts.gov",
  },
  {
    name: "CALIFORNIA CENTRAL",
    code: "0973",
    formName: "Central District of California",
    court_code: "cacbke",
    has_epoc: true,
    subdomain: "ecf.cacb.uscourts.gov",
  },
  {
    name: "CALIFORNIA EASTERN",
    code: "0972",
    formName: "Eastern District of California",
    court_code: "caebke",
    has_epoc: true,
    subdomain: "ecf.caeb.uscourts.gov",
  },
  {
    name: "CALIFORNIA NORTHERN",
    code: "0971",
    formName: "Northern District of California",
    court_code: "canbke",
    has_epoc: true,
    subdomain: "ecf.canb.uscourts.gov",
  },
  {
    name: "CALIFORNIA SOUTHERN",
    code: "0974",
    formName: "Southern District of California",
    court_code: "casbke",
    has_epoc: true,
    subdomain: "ecf.casb.uscourts.gov",
  },
  {
    name: "COLORADO",
    code: "1082",
    formName: "District of Colorado",
    court_code: "cobke",
    has_epoc: true,
    subdomain: "ecf.cob.uscourts.gov",
  },
  {
    name: "CONNECTICUT",
    code: "0205",
    formName: "District of Connecticut",
    court_code: "ctbke",
    has_epoc: false,
    subdomain: "ecf.ctb.uscourts.gov",
  },
  {
    name: "DELAWARE",
    code: "0311",
    formName: "District of Delaware",
    court_code: "debke",
    has_epoc: true,
    subdomain: "ecf.deb.uscourts.gov",
  },
  {
    name: "DISTRICT OF COLUMBIA",
    code: "0090",
    formName: "District of Columbia",
    court_code: "dcbke",
    has_epoc: false,
    subdomain: "ecf.dcb.uscourts.gov",
  },
  {
    name: "FLORIDA MIDDLE",
    code: "113A",
    formName: "Middle District of Florida",
    court_code: "flmbke",
    has_epoc: true,
    subdomain: "ecf.flmb.uscourts.gov",
  },
  {
    name: "FLORIDA NORTHERN",
    code: "1129",
    formName: "Northern District of Florida",
    court_code: "flnbke",
    has_epoc: true,
    subdomain: "ecf.flnb.uscourts.gov",
  },
  {
    name: "FLORIDA SOUTHERN",
    code: "113C",
    formName: "Southern District of Florida",
    court_code: "flsbke",
    has_epoc: true,
    subdomain: "ecf.flsb.uscourts.gov",
  },
  {
    name: "GEORGIA MIDDLE",
    code: "113G",
    formName: "Middle District of Georgia",
    court_code: "gambke",
    has_epoc: true,
    subdomain: "ecf.gamb.uscourts.gov",
  },
  {
    name: "GEORGIA NORTHERN",
    code: "113E",
    formName: "Northern District of Georgia ", // keep the ending white-space! otherwise won't be able to fill the pdf field
    court_code: "ganbke",
    has_epoc: true,
    subdomain: "ecf.ganb.uscourts.gov",
  },
  {
    name: "GEORGIA SOUTHERN",
    code: "113J",
    formName: "Southern District of Georgia ", // keep the ending white-space! otherwise won't be able to fill the pdf field
    court_code: "gasbke",
    has_epoc: true,
    subdomain: "ecf.gasb.uscourts.gov",
  },
  {
    name: "GUAM",
    code: "0993",
    formName: "District of Guam",
    court_code: "gubke",
    has_epoc: false,
    subdomain: "ecf.gub.uscourts.gov",
  },
  {
    name: "HAWAII",
    code: "0975",
    formName: "District of Hawaii",
    court_code: "hibke",
    has_epoc: true,
    subdomain: "ecf.hib.uscourts.gov",
  },
  {
    name: "IDAHO",
    code: "0976",
    formName: "District of Idaho",
    court_code: "idbke",
    has_epoc: true,
    subdomain: "ecf.idb.uscourts.gov",
  },
  {
    name: "ILLINOIS CENTRAL",
    code: "0753",
    formName: "Central District of Illinois",
    court_code: "ilcbke",
    has_epoc: true,
    subdomain: "ecf.ilcb.uscourts.gov",
  },
  {
    name: "ILLINOIS NORTHERN",
    code: "0752",
    formName: "Northern District of Illinois",
    court_code: "ilnbke",
    has_epoc: true,
    subdomain: "ecf.ilnb.uscourts.gov",
  },
  {
    name: "ILLINOIS SOUTHERN",
    code: "0754",
    formName: "Southern District of Illinois",
    court_code: "ilsbke",
    has_epoc: true,
    subdomain: "ecf.ilsb.uscourts.gov",
  },
  {
    name: "INDIANA NORTHERN",
    code: "0755",
    formName: "Northern District of Indiana",
    court_code: "innbke",
    has_epoc: false,
    subdomain: "ecf.innb.uscourts.gov",
  },
  {
    name: "INDIANA SOUTHERN",
    code: "0756",
    formName: "Southern District of Indiana",
    court_code: "insbke",
    has_epoc: true,
    subdomain: "ecf.insb.uscourts.gov",
  },
  {
    name: "IOWA NORTHERN",
    code: "0762",
    formName: "Northern District of Iowa",
    court_code: "ianbke",
    has_epoc: true,
    subdomain: "ecf.ianb.uscourts.gov",
  },
  {
    name: "IOWA SOUTHERN",
    code: "0863",
    formName: "Southern District of Iowa",
    court_code: "iasbke",
    has_epoc: true,
    subdomain: "ecf.iasb.uscourts.gov",
  },
  {
    name: "KANSAS",
    code: "1083",
    formName: "District of Kansas",
    court_code: "ksbke",
    has_epoc: true,
    subdomain: "ecf.ksb.uscourts.gov",
  },
  {
    name: "KENTUCKY EASTERN",
    code: "0643",
    formName: "Eastern District of Kentucky",
    court_code: "kyebke",
    has_epoc: true,
    subdomain: "ecf.kyeb.uscourts.gov",
  },
  {
    name: "KENTUCKY WESTERN",
    code: "0644",
    formName: "Western District of Kentucky",
    court_code: "kywbke",
    has_epoc: false,
    subdomain: "ecf.kywb.uscourts.gov",
  },
  {
    name: "LOUISIANA EASTERN",
    code: "053L",
    formName: "Eastern District of Louisiana",
    court_code: "laebke",
    has_epoc: true,
    subdomain: "ecf.laeb.uscourts.gov",
  },
  {
    name: "LOUISIANA MIDDLE",
    code: "053N",
    formName: "Middle District of Louisiana",
    court_code: "lambke",
    has_epoc: true,
    subdomain: "ecf.lamb.uscourts.gov",
  },
  {
    name: "LOUISIANA WESTERN",
    code: "0536",
    formName: "Western District of Louisiana",
    court_code: "lawbke",
    has_epoc: true,
    subdomain: "ecf.lawb.uscourts.gov",
  },
  {
    name: "MAINE",
    code: "0100",
    formName: "District of Maine",
    court_code: "mebke",
    has_epoc: true,
    subdomain: "ecf.meb.uscourts.gov",
  },
  {
    name: "MARYLAND",
    code: "0416",
    formName: "District of Maryland",
    court_code: "mdbke",
    has_epoc: true,
    subdomain: "ecf.mdb.uscourts.gov",
  },
  {
    name: "MASSACHUSETTS",
    code: "0101",
    formName: "District of Massachusetts",
    court_code: "mabke",
    has_epoc: true,
    subdomain: "ecf.mab.uscourts.gov",
  },
  {
    name: "MICHIGAN EASTERN",
    code: "0645",
    formName: "Eastern District of Michigan",
    court_code: "miebke",
    has_epoc: true,
    subdomain: "ecf.mieb.uscourts.gov",
  },
  {
    name: "MICHIGAN WESTERN",
    code: "0646",
    formName: "Western District of Michigan",
    court_code: "miwbke",
    has_epoc: true,
    subdomain: "ecf.miwb.uscourts.gov",
  },
  {
    name: "MINNESOTA",
    code: "0864",
    formName: "District of Minnesota",
    court_code: "mnbke",
    has_epoc: true,
    subdomain: "ecf.mnb.uscourts.gov",
  },
  {
    name: "MISSISSIPPI NORTHERN",
    code: "0537",
    formName: "Northern District of Mississippi",
    court_code: "msnbke",
    has_epoc: true,
    subdomain: "ecf.msnb.uscourts.gov",
  },
  {
    name: "MISSISSIPPI SOUTHERN",
    code: "0538",
    formName: "Southern District of Mississippi",
    court_code: "mssbke",
    has_epoc: false,
    subdomain: "ecf.mssb.uscourts.gov",
  },
  {
    name: "MISSOURI EASTERN",
    code: "0865",
    formName: "Eastern District of Missouri",
    court_code: "moebke",
    has_epoc: true,
    subdomain: "ecf.moeb.uscourts.gov",
  },
  {
    name: "MISSOURI WESTERN",
    code: "0866",
    formName: "Western District of Missouri",
    court_code: "mowbke",
    has_epoc: true,
    subdomain: "ecf.mowb.uscourts.gov",
  },
  {
    name: "MONTANA",
    code: "0977",
    formName: "District of Montana",
    court_code: "mtbke",
    has_epoc: true,
    subdomain: "ecf.mtb.uscourts.gov",
  },
  {
    name: "NEBRASKA",
    code: "0867",
    formName: "District of Nebraska",
    court_code: "nebke",
    has_epoc: true,
    subdomain: "ecf.neb.uscourts.gov",
  },
  {
    name: "NEVADA",
    code: "0978",
    formName: "District of Nevada",
    court_code: "nvbke",
    has_epoc: true,
    subdomain: "ecf.nvb.uscourts.gov",
  },
  {
    name: "NEW HAMPSHIRE",
    code: "0102",
    formName: "District of New Hampshire",
    court_code: "nhbke",
    has_epoc: true,
    subdomain: "ecf.nhb.uscourts.gov",
  },
  {
    name: "NEW JERSEY",
    code: "0312",
    formName: "District of New Jersey",
    court_code: "njbke",
    has_epoc: true,
    subdomain: "ecf.njb.uscourts.gov",
  },
  {
    name: "NEW MEXICO",
    code: "1084",
    formName: "District of New Mexico",
    court_code: "nmbke",
    has_epoc: true,
    subdomain: "ecf.nmb.uscourts.gov",
  },
  {
    name: "NEW YORK EASTERN",
    code: "0207",
    formName: "Eastern District of New York",
    court_code: "nyebke",
    has_epoc: false,
    subdomain: "ecf.nyeb.uscourts.gov",
  },
  {
    name: "NEW YORK NORTHERN",
    code: "0206",
    formName: "Northern District of New York",
    court_code: "nynbke",
    has_epoc: true,
    subdomain: "ecf.nynb.uscourts.gov",
  },
  {
    name: "NEW YORK SOUTHERN",
    code: "0208",
    formName: "Southern District of New York",
    court_code: "nysbke",
    has_epoc: true,
    subdomain: "ecf.nysb.uscourts.gov",
  },
  {
    name: "NEW YORK WESTERN",
    code: "0209",
    formName: "Western District of New York",
    court_code: "nywbke",
    has_epoc: false,
    subdomain: "ecf.nywb.uscourts.gov",
  },
  {
    name: "NORTH CAROLINA EASTERN",
    code: "0417",
    formName: "Eastern District of North Carolina",
    court_code: "ncebke",
    has_epoc: true,
    subdomain: "ecf.nceb.uscourts.gov",
  },
  {
    name: "NORTH CAROLINA MIDDLE",
    code: "0418",
    formName: "Middle District of North Carolina",
    court_code: "ncmbke",
    has_epoc: true,
    subdomain: "ecf.ncmb.uscourts.gov",
  },
  {
    name: "NORTH CAROLINA WESTERN",
    code: "0419",
    formName: "Western District of North Carolina",
    court_code: "ncwbke",
    has_epoc: true,
    subdomain: "ecf.ncwb.uscourts.gov",
  },
  {
    name: "NORTH DAKOTA",
    code: "0868",
    formName: "District of North Dakota",
    court_code: "ndbke",
    has_epoc: true,
    subdomain: "ecf.ndb.uscourts.gov",
  },
  {
    name: "NORTHERN MARIANAS",
    code: "0994",
    formName: "District of Northern Mariana Islands",
    court_code: "nmibke",
    has_epoc: false,
    subdomain: "ecf.nmib.uscourts.gov",
  },
  {
    name: "OHIO NORTHERN",
    code: "0647",
    formName: "Northern District of Ohio",
    court_code: "ohnbke",
    has_epoc: true,
    subdomain: "ecf.ohnb.uscourts.gov",
  },
  {
    name: "OHIO SOUTHERN",
    code: "0648",
    formName: "Southern District of Ohio",
    court_code: "ohsbke",
    has_epoc: true,
    subdomain: "ecf.ohsb.uscourts.gov",
  },
  {
    name: "OKLAHOMA EASTERN",
    code: "1086",
    formName: "Eastern District of Oklahoma",
    court_code: "okebke",
    has_epoc: true,
    subdomain: "ecf.okeb.uscourts.gov",
  },
  {
    name: "OKLAHOMA NORTHERN",
    code: "1085",
    formName: "Northern District of Oklahoma",
    court_code: "oknbke",
    has_epoc: true,
    subdomain: "ecf.oknb.uscourts.gov",
  },
  {
    name: "OKLAHOMA WESTERN",
    code: "1087",
    formName: "Western District of Oklahoma",
    court_code: "okwbke",
    has_epoc: true,
    subdomain: "ecf.okwb.uscourts.gov",
  },
  {
    name: "OREGON",
    code: "0979",
    formName: "District of Oregon",
    court_code: "orbke",
    has_epoc: true,
    subdomain: "ecf.orb.uscourts.gov",
  },
  {
    name: "PENNSYLVANIA EASTERN",
    code: "0313",
    formName: "Eastern District of Pennsylvania",
    court_code: "paebke",
    has_epoc: true,
    subdomain: "ecf.paeb.uscourts.gov",
  },
  {
    name: "PENNSYLVANIA MIDDLE",
    code: "0314",
    formName: "Middle District of Pennsylvania",
    court_code: "pambke",
    has_epoc: true,
    subdomain: "ecf.pamb.uscourts.gov",
  },
  {
    name: "PENNSYLVANIA WESTERN",
    code: "0315",
    formName: "Western District of Pennsylvania",
    court_code: "pawbke",
    has_epoc: true,
    subdomain: "ecf.pawb.uscourts.gov",
  },
  {
    name: "PUERTO RICO",
    code: "0104",
    formName: "District of Puerto Rico",
    court_code: "prbke",
    has_epoc: true,
    subdomain: "ecf.prb.uscourts.gov",
  },
  {
    name: "RHODE ISLAND",
    code: "0103",
    formName: "District of Rhode Island",
    court_code: "ribke",
    has_epoc: true,
    subdomain: "ecf.rib.uscourts.gov",
  },
  {
    name: "SOUTH CAROLINA",
    code: "0420",
    formName: "District of South Carolina",
    court_code: "scbke",
    has_epoc: true,
    subdomain: "ecf.scb.uscourts.gov",
  },
  {
    name: "SOUTH CAROLINA TEST",
    code: "0420",
    formName: "District of South Carolina",
    court_code: "scbtest",
    has_epoc: true,
    subdomain: "ecf.scb.uscourts.gov",
  },
  {
    name: "SOUTH DAKOTA",
    code: "0869",
    formName: "District of South Dakota",
    court_code: "sdbke",
    has_epoc: true,
    subdomain: "ecf.sdb.uscourts.gov",
  },
  {
    name: "TENNESSEE EASTERN",
    code: "0649",
    formName: "Eastern District of Tennessee",
    court_code: "tnebke",
    has_epoc: true,
    subdomain: "ecf.tneb.uscourts.gov",
  },
  {
    name: "TENNESSEE MIDDLE",
    code: "0650",
    formName: "Middle District of Tennessee",
    court_code: "tnmbke",
    has_epoc: true,
    subdomain: "ecf.tnmb.uscourts.gov",
  },
  {
    name: "TENNESSEE WESTERN",
    code: "0651",
    formName: "Western District of Tennessee",
    court_code: "tnwbke",
    has_epoc: false,
    subdomain: "ecf.tnwb.uscourts.gov",
  },
  {
    name: "TEXAS EASTERN",
    code: "0540",
    formName: "Eastern District of Texas",
    court_code: "txebke",
    has_epoc: true,
    subdomain: "ecf.txeb.uscourts.gov",
  },
  {
    name: "TEXAS NORTHERN",
    code: "0539",
    formName: "Northern District of Texas",
    court_code: "txnbke",
    has_epoc: true,
    subdomain: "ecf.txnb.uscourts.gov",
  },
  {
    name: "TEXAS SOUTHERN",
    code: "0541",
    formName: "Southern District of Texas",
    court_code: "txsbke",
    has_epoc: false,
    subdomain: "ecf.txsb.uscourts.gov",
  },
  {
    name: "TEXAS WESTERN",
    code: "0542",
    formName: "Western District of Texas",
    court_code: "txwbke",
    has_epoc: true,
    subdomain: "ecf.txwb.uscourts.gov",
  },
  {
    name: "UTAH",
    code: "1088",
    formName: "District of Utah",
    court_code: "utbke",
    has_epoc: false,
    subdomain: "ecf.utb.uscourts.gov",
  },
  {
    name: "VERMONT",
    code: "0210",
    formName: "District of Vermont",
    court_code: "vtbke",
    has_epoc: false,
    subdomain: "ecf.vtb.uscourts.gov",
  },
  {
    name: "VIRGIN ISLANDS",
    code: "0391",
    formName: "District of Virgin Islands",
    court_code: "vibke",
    has_epoc: false,
    subdomain: "ecf.vib.uscourts.gov",
  },
  {
    name: "VIRGINIA EASTERN",
    code: "0422",
    formName: "Eastern District of Virginia",
    court_code: "vaebke",
    has_epoc: true,
    subdomain: "ecf.vaeb.uscourts.gov",
  },
  {
    name: "VIRGINIA WESTERN",
    code: "0423",
    formName: "Western District of Virginia",
    court_code: "vawbke",
    has_epoc: true,
    subdomain: "ecf.vawb.uscourts.gov",
  },
  {
    name: "WASHINGTON EASTERN",
    code: "0980",
    formName: "Eastern District of Washington",
    court_code: "waebke",
    has_epoc: true,
    subdomain: "ecf.waeb.uscourts.gov",
  },
  {
    name: "WASHINGTON WESTERN",
    code: "0981",
    formName: "Western District of Washington",
    court_code: "wawbke",
    has_epoc: true,
    subdomain: "ecf.wawb.uscourts.gov",
  },
  {
    name: "WEST VIRGINIA NORTHERN",
    code: "0424",
    formName: "Northern District of West Virginia",
    court_code: "wvnbke",
    has_epoc: true,
    subdomain: "ecf.wvnb.uscourts.gov",
  },
  {
    name: "WEST VIRGINIA SOUTHERN",
    code: "0425",
    formName: "Southern District of West Virginia",
    court_code: "wvsbke",
    has_epoc: true,
    subdomain: "ecf.wvsb.uscourts.gov",
  },
  {
    name: "WISCONSIN EASTERN",
    code: "0757",
    formName: "Eastern District of Wisconsin",
    court_code: "wiebke",
    has_epoc: true,
    subdomain: "ecf.wieb.uscourts.gov",
  },
  {
    name: "WISCONSIN WESTERN",
    code: "0758",
    formName: "Western District of Wisconsin",
    court_code: "wiwbke",
    has_epoc: true,
    subdomain: "ecf.wiwb.uscourts.gov",
  },
  {
    name: "WYOMING",
    code: "1089",
    formName: "District of Wyoming",
    court_code: "wybke",
    has_epoc: false,
    subdomain: "ecf.wyb.uscourts.gov",
  },
];

export default districts;
