import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

export default function ConfirmModal({
  show,
  onClose,
  title,
  onConfirm,
  cancelButtonLabel = "Cancel",
  confirmButtonLabel = "Confirm",
  cancelButtonColorVariant = "secondary",
  confirmButtonColorVariant = "primary",
  isLoading = false,
  children
}) {
  return (
    <Modal
      show={show}
      onHide={onClose}
      {...(isLoading ? { backdrop: "static", keyboard: false } : {})}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button
          variant={cancelButtonColorVariant}
          onClick={onClose}
          className="me-auto"
          disabled={isLoading}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          variant={confirmButtonColorVariant}
          onClick={onConfirm}
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            confirmButtonLabel
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
