const headerColumns = [
  {
    id: "title",
    label: "Case Title"
  },
  {
    id: "case_no",
    label: "Case No"
  },
  {
    id: "court",
    label: "Court"
  },
  {
    id: "chapter",
    label: "Chapter"
  },
  {
    id: "date_filed",
    label: "Date Filed"
  },
  {
    id: "date_closed",
    label: "Closed"
  },
  {
    id: "disposition",
    label: "Disposition"
  }
];

export default headerColumns;
