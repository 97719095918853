import gql from "graphql-tag";

const submitClaim = gql`
  mutation submitClaim($claim_id: ID!) {
    submitClaim(claim_id: $claim_id) {
      error
      task_id
    }
  }
`;

export default submitClaim;
