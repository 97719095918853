import { useCallback } from "react";
import classnames from "classnames";
import { Table, OverlayTrigger, Tooltip, Form, Spinner } from "react-bootstrap";
import {
  QuestionCircleFill as HelpIcon,
  PencilSquare as EditIcon,
  XLg as XIcon,
  FiletypePdf as PreviewIcon,
  FileEarmarkPlus as AttachmentsIcon,
  ExclamationTriangle as WarningIcon,
} from "react-bootstrap-icons";
import { observer } from "mobx-react-lite";

import { Claim } from "../../types";
import districts from "../districts";

import * as Validations from "./validations";
import EditTextField from "./EditTextField";
import EditOptions from "./EditOptions";
import EditAddress from "./EditAddress";

import "./ClaimsGrid.scss";

type ColumnComponent = React.FC<{
  value: any;
  claim: Claim;
  columnKey: string;
  validation?: (value: string) => boolean;
  invalidMessage?: string;
  converter?: (value: string) => unknown;
  maxLength?: number;
  updateClaim: (claim: Claim, columnKey: string, newValue: any) => void;
}>;
type Column = {
  id: string;
  label: string;
  width?: number;
  className?: string;
  key: string;
  helpText?: string;
  formatter?: (value: any) => string;
  Component?: ColumnComponent;
  validation?: (value: string) => boolean;
  invalidMessage?: string;
  converter?: (value: string) => unknown;
  maxLength?: number;
  emptyValue?: any;
};
const USDFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
const USDFormatter = (value?: null | string) => {
  if (value == null) return "";

  if (value.trim() === "") {
    return "";
  }

  const toNumber = parseFloat(value);

  if (isNaN(toNumber)) return value;

  return USDFormat.format(toNumber);
};
const PercentFormatter = (value?: null | string) => {
  if (value == null) return "";

  if (value.trim() === "") {
    return "";
  }

  const toNumber = parseFloat(value);

  if (isNaN(toNumber)) return value;

  return `${toNumber}%`;
};

type CreditorAddress = {
  name?: string | null;
  address_1?: string | null;
  address_2?: string | null;
  address_3?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  zip4?: string | null;
  phone?: string | null;
  email?: string | null;
};

const columns: Column[] = [
  {
    id: "case_no",
    label: "Case number",
    width: 140,
    key: "case_no",
    className: "case_no",
    Component: EditTextField,
    validation(value) {
      return !!Validations.isShortCaseNumber(value) || !!Validations.isLongCaseNumber(value);
    },
    invalidMessage: "Invalid case number",
  },
  {
    id: "district",
    label: "District",
    width: 200,
    key: "district",
    className: "district",
    Component: ({ ...props }) => (
      <EditOptions
        {...props}
        options={districts.map((district) => ({
          value: district.name,
          label: district.name,
        }))}
      />
    ),
  },

  { id: "case_title", label: "Debtor", width: 200, key: "case_title", Component: EditTextField },
  {
    id: "creditor",
    label: "Creditor",
    width: 500,
    key: "creditor",
    formatter: function (value: CreditorAddress): string {
      return [
        value.name,
        value.address_1,
        value.address_2,
        value.address_3,
        value.city,
        value.state,
        value.zip,
        value.zip4,
        value.phone,
        value.email,
      ]
        .filter(Boolean)
        .join(", ");
    },
    Component: EditAddress,
    emptyValue: {},
  },
  {
    id: "creditor_alias",
    label: "Creditor alias",
    width: 150,
    key: "creditor_alias",
    Component: EditTextField,
    maxLength: 200,
  },
  {
    id: "payment_address",
    label: "Payment Address",
    width: 500,
    key: "payment_address",
    formatter(value) {
      return [
        value.name,
        value.address_1,
        value.address_2,
        value.address_3,
        value.city,
        value.state,
        value.zip,
        value.zip4,
        value.phone,
        value.email,
      ]
        .filter(Boolean)
        .join(", ");
    },
    Component: EditAddress,
    emptyValue: {},
  },
  {
    id: "uniform_claim_identifier",
    label: "Uniform claim identifier",
    helpText: "Uniform claim identifier for electronic payments in chapter 13 (if you use one)",
    width: 200,
    key: "uniform_claim_identifier",
    Component: EditTextField,
    maxLength: 24,
  },
  {
    id: "filed_by",
    label: "Filed by",
    width: 150,
    key: "filed_by",
    Component: ({ ...props }) => (
      <EditOptions
        {...props}
        options={[
          { value: "Creditor", label: "Creditor" },
          { value: "Attorney", label: "Attorney" },
          { value: "Debtor", label: "Debtor" },
          { value: "Trustee", label: "Trustee" },
        ]}
      />
    ),
  },
  {
    id: "acquired_from",
    label: "Acquired from",
    helpText: "Has this claim been acquired from someone else?",
    width: 150,
    key: "acquired_from",
    Component: EditTextField,
  },
  {
    id: "earlier_filing",
    label: "Who made the earlier filing",
    helpText: "Do you know if anyone else has filed a proof of claim for this claim?",
    width: 250,
    key: "earlier_filing",
    Component: EditTextField,
    maxLength: 100,
  },
  {
    id: "debtor_number",
    label: "Debtor number",
    helpText:
      "Do you have any number you use to identify the debtor? Last 4 digits of the debtor's account or any number you use to identify the debtor",
    width: 150,
    key: "debtor_id",
    Component: EditTextField,
    maxLength: 4,
  },
  {
    id: "claim_amount",
    label: "Claim amount",
    width: 150,
    key: "claim_amount",
    formatter: USDFormatter,
    Component: EditTextField,
    validation: Validations.isValidNumber,
    invalidMessage: "Invalid number",
  },
  {
    id: "claim_amount_include_interest",
    label: "Amount include interest or other charges",
    width: 300,
    key: "amount_include_interest",
    Component: ({ ...props }) => (
      <EditOptions
        {...props}
        options={[
          { value: "Yes", label: "Yes" },
          { value: "No", label: "No" },
        ]}
      />
    ),
  },
  {
    id: "claim_basis",
    label: "Basis of the Claim",
    width: 150,
    key: "claim_basis",
    Component: EditTextField,
    maxLength: 115,
  },
  {
    id: "real_estate",
    label: "Real estate",
    key: "securities.real_estate",
    width: 140,
    Component: ({ ...props }) => (
      <EditOptions
        {...props}
        options={[
          { value: "Yes", label: "Yes" },
          { value: "No", label: "No" },
        ]}
      />
    ),
  },
  {
    id: "motor_vehicle",
    label: "Motor vehicle",
    key: "securities.motor_vehicle",
    width: 140,
    Component: ({ ...props }) => (
      <EditOptions
        {...props}
        options={[
          { value: "Yes", label: "Yes" },
          { value: "No", label: "No" },
        ]}
      />
    ),
  },
  { id: "other", label: "Other", key: "securities.other", width: 140, Component: EditTextField, maxLength: 40 },
  {
    id: "basis_for_perfection",
    label: "Basis for perfection",
    key: "securities.basis_for_perfection",
    width: 150,
    Component: EditTextField,
    maxLength: 24,
  },
  {
    id: "property_value",
    label: "Value of property",
    key: "securities.value_of_property",
    formatter: USDFormatter,
    width: 150,
    Component: EditTextField,
    validation: Validations.isValidNumber,
    invalidMessage: "Invalid number",
  },
  {
    id: "secured_amount",
    label: "Secured amount",
    key: "securities.secured_amount",
    formatter: USDFormatter,
    width: 150,
    Component: EditTextField,
    validation: Validations.isValidNumber,
    invalidMessage: "Invalid number",
  },
  {
    id: "unsecured_amount",
    label: "Unsecured amount",
    key: "securities.unsecured_amount",
    formatter: USDFormatter,
    width: 150,
    Component: EditTextField,
    validation: Validations.isValidNumber,
    invalidMessage: "Invalid number",
  },
  {
    id: "default_amount",
    label: "Default amount",
    helpText: "Amount necessary to cure any default as of the date of the petition",
    key: "securities.default_amount",
    formatter: USDFormatter,
    width: 150,
    Component: EditTextField,
    validation: Validations.isValidNumber,
    invalidMessage: "Invalid number",
  },
  {
    id: "interest_type",
    label: "Interest type",
    key: "securities.interest_type",
    width: 150,
    Component: ({ ...props }) => (
      <EditOptions
        {...props}
        options={[
          { value: "None", label: "None" },
          { value: "Fixed", label: "Fixed" },
          { value: "Variable", label: "Variable" },
        ]}
      />
    ),
  },
  {
    id: "interest_value",
    label: "Interest rate",
    helpText: "Annual Interest Rate (when case was filed)",
    key: "securities.interest_value",
    formatter: PercentFormatter,
    width: 150,
    Component: EditTextField,
    validation: Validations.isValidNumber,
    invalidMessage: "Invalid number",
  },
  {
    id: "lease_based",
    label: "Lease default amount",
    helpText: "Amount necessary to cure any default as of the date of the petition",
    width: 200,
    key: "lease_default_amount",
    formatter: USDFormatter,
    Component: EditTextField,
    validation: Validations.isValidNumber,
    invalidMessage: "Invalid number",
  },
  {
    id: "setoff",
    label: "Setoff property",
    helpText: "Is this claim subject to a right of setoff? Identify the property",
    width: 200,
    key: "setoff_property",
    Component: EditTextField,
    maxLength: 60,
  },
  {
    id: "507(a)(1)(A) or (a)(1)(B)",
    label: "507(a)(1)(A) or (a)(1)(B)",
    helpText:
      "Domestic support obligations (including alimony and child support) under 11 U.S.C. § 507(a)(1)(A) or (a)(1)(B).",
    key: "priorities.rule_507_a_1",
    formatter: USDFormatter,
    width: 200,
    Component: EditTextField,
    validation: Validations.isValidNumber,
    invalidMessage: "Invalid number",
  },
  {
    id: "507(a)(7)",
    label: "507(a)(7)",
    helpText:
      "Up to $3,350* of deposits toward purchase, lease, or rental of property or services for personal, family, or household use. 11 U.S.C. § 507(a)(7).",
    key: "priorities.rule_507_a_7",
    formatter: USDFormatter,
    width: 150,
    Component: EditTextField,
    validation: Validations.isValidNumber,
    invalidMessage: "Invalid number",
  },
  {
    id: "507(a)(4)",
    label: "507(a)(4)",
    helpText:
      "Wages, salaries, or commissions (up to $15,150*) earned within 180 days before the bankruptcy petition is filed or the debtor's business ends, whichever is earlier. 11 U.S.C. § 507(a)(4).",
    key: "priorities.rule_507_a_4",
    formatter: USDFormatter,
    width: 150,
    Component: EditTextField,
    validation: Validations.isValidNumber,
    invalidMessage: "Invalid number",
  },
  {
    id: "507(a)(8)",
    label: "507(a)(8)",
    helpText: "Taxes or penalties owed to governmental units. 11 U.S.C. § 507(a)(8).",
    key: "priorities.rule_507_a_8",
    formatter: USDFormatter,
    width: 150,
    Component: EditTextField,
    validation: Validations.isValidNumber,
    invalidMessage: "Invalid number",
  },
  {
    id: "507(a)(5)",
    label: "507(a)(5)",
    helpText: "Contributions to an employee benefit plan. 11 U.S.C. § 507(a)(5).",
    key: "priorities.rule_507_a_5",
    formatter: USDFormatter,
    width: 150,
    Component: EditTextField,
    validation: Validations.isValidNumber,
    invalidMessage: "Invalid number",
  },
  {
    id: "507(a) other",
    label: "Other",
    helpText: "Specify subsection of 11 U.S.C. § 507(a) that applies and specify amount entitled to priority",
    key: "priorities.rule_507_a_other",
    width: 150,
    Component: EditTextField,
  },
  {
    id: "507(a) other amount",
    label: "Other amount",
    key: "priorities.rule_507_a_other_amount",
    formatter: USDFormatter,
    width: 150,
    Component: EditTextField,
    validation: Validations.isValidNumber,
    invalidMessage: "Invalid number",
  },
  {
    id: "description",
    label: "Description",
    helpText: "Brief description of claim. Optional.",
    width: 150,
    key: "description",
    Component: EditTextField,
    maxLength: 255,
  },
  {
    id: "remarks",
    label: "Remarks",
    helpText: "Additional notes about the claim. Optional.",
    width: 150,
    key: "remarks",
    Component: EditTextField,
    maxLength: 255,
  },
  {
    id: "who_is_filing",
    label: "Who is filing",
    width: 150,
    key: "signee_person",
    Component: ({ ...props }) => (
      <EditOptions
        {...props}
        options={[
          { value: "Creditor.", label: "Creditor." },
          { value: "Creditor attorney or authorized agent.", label: "Creditor attorney or authorized agent." },
          {
            value: "Trustee, or the debtor, or their authorized agent. Bankruptcy Rule 3004.",
            label: "Trustee, or the debtor, or their authorized agent. Bankruptcy Rule 3004.",
          },
          {
            value: "Guarantor, surety, endorser, or other codebtor. Bankruptcy Rule 3005.",
            label: "Guarantor, surety, endorser, or other codebtor. Bankruptcy Rule 3005.",
          },
        ]}
      />
    ),
  },
  {
    id: "signee",
    label: "Signee",
    helpText: "The person completing this proof of claim",
    width: 500,
    key: "signee",
    formatter(value) {
      return [
        value.name,
        value.title,
        value.company,
        value.address_1,
        value.city,
        value.state,
        value.zip,
        value.zip4,
        value.phone,
        value.email,
      ]
        .filter(Boolean)
        .join(", ");
    },
    Component: EditAddress,
    emptyValue: {},
  },
];

export default observer(function ClaimsGrid({
  claims,
  updateClaim,
  previewClaim,
  showClaimAttachmentsModal,
  showCaseNumberResolverModal,
  showCreditorResolverModal,
}: {
  claims: Claim[];
  updateClaim: (claim: Claim, columnKey: string, newValue: any) => void;
  previewClaim: (claimId: string) => void;
  showClaimAttachmentsModal: (claim: Claim) => void;
  showCaseNumberResolverModal: (claim: Claim) => void;
  showCreditorResolverModal: (claim: Claim) => void;
}) {
  return (
    <div className="claims-table-container flex-grow-1">
      <Table hover className="claims-table mb-0">
        <thead className="table-light shadow-sm">
          <tr>
            <th className="select_claim">
              <Form.Check.Label className="inner-cell d-block text-center" htmlFor="select-all-claims">
                {/* Hacky but it works */}
                <Form.Check.Input
                  type="checkbox"
                  id="select-all-claims"
                  checked={claims.every((claim) => claim.selected)}
                  onChange={(event) => {
                    claims.forEach((claim) => (claim.selected = event.target.checked));
                  }}
                />
              </Form.Check.Label>
            </th>

            {columns.map((column) => (
              <th
                key={column.id}
                className={column.className}
                style={{
                  width: column.width,
                }}
              >
                <div className="inner-cell d-flex align-items-center gap-2 fw-bold">
                  <span>{column.label}</span>

                  {column.helpText && (
                    <OverlayTrigger
                      placement="top"
                      trigger={["hover", "focus"]}
                      overlay={<Tooltip>{column.helpText}</Tooltip>}
                    >
                      <HelpIcon size="16" className="text-primary" />
                    </OverlayTrigger>
                  )}
                </div>
              </th>
            ))}

            <th className="claim_actions">
              <div className="inner-cell">Actions</div>
            </th>
          </tr>
        </thead>

        <tbody>
          {claims.map((claim) => {
            return (
              <tr key={claim.id} className={classnames({ "table-primary": claim.selected })}>
                <td
                  className={classnames("select_claim", {
                    "table-primary": claim.selected,
                    "table-light": !claim.selected,
                  })}
                >
                  <Form.Check.Label className="inner-cell d-block text-center">
                    {/* Hacky but it works */}
                    <Form.Check.Input
                      type="checkbox"
                      id={`select-claim-${claim.id}`}
                      checked={claim.selected}
                      onChange={(event) => (claim.selected = event.target.checked)}
                    />
                  </Form.Check.Label>
                </td>

                {columns.map((column) => {
                  let columnValue;
                  if (column.key.includes(".")) {
                    const [key, child] = column.key.split(".");
                    columnValue = claim[key][child];
                  } else {
                    columnValue = claim[column.key];
                  }
                  const formattedValue = column.formatter ? column.formatter(columnValue) : columnValue;

                  return (
                    <Cell
                      key={column.id}
                      columnValue={columnValue}
                      formattedValue={formattedValue}
                      className={column.className}
                      columnKey={column.key}
                      columnComponent={column.Component}
                      columnValidation={column.validation}
                      invalidMessage={column.invalidMessage}
                      converter={column.converter}
                      maxLength={column.maxLength}
                      claim={claim}
                      updateClaim={updateClaim}
                      emptyValue={column.emptyValue}
                      showCaseNumberResolverModal={showCaseNumberResolverModal}
                      showCreditorResolverModal={showCreditorResolverModal}
                    />
                  );
                })}

                <td
                  className={classnames("claim_actions", {
                    "table-primary": claim.selected,
                    "table-light": !claim.selected,
                  })}
                >
                  <div className="inner-cell d-flex align-items-center gap-2">
                    <OverlayTrigger
                      placement="top"
                      trigger={["hover", "focus"]}
                      overlay={<Tooltip>Preview form 410</Tooltip>}
                    >
                      <PreviewIcon
                        size="18"
                        role="button"
                        className="text-primary"
                        onClick={() => previewClaim(claim.id)}
                      />
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="top"
                      trigger={["hover", "focus"]}
                      overlay={<Tooltip>Manage attachments</Tooltip>}
                    >
                      <span
                        role="button"
                        className="text-primary d-flex align-items-center gap-1"
                        onClick={() => showClaimAttachmentsModal(claim)}
                      >
                        {claim.attachments?.length > 0 ? `+${claim.attachments.length}` : null}
                        <AttachmentsIcon size="18" />
                      </span>
                    </OverlayTrigger>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
});

const Cell = observer(function Cell({
  columnValue,
  formattedValue,
  className,
  columnKey,
  columnComponent,
  columnValidation,
  invalidMessage,
  converter,
  maxLength,
  claim,
  updateClaim,
  emptyValue,
  showCaseNumberResolverModal,
  showCreditorResolverModal,
}: {
  columnValue: any;
  formattedValue: string;
  className?: string;
  columnKey: string;
  columnComponent?: ColumnComponent;
  columnValidation?: (value: string) => boolean;
  invalidMessage?: string;
  converter?: (value: string) => unknown;
  maxLength?: number;
  claim: Claim;
  updateClaim: (claim: Claim, columnKey: string, newValue: any) => void;
  emptyValue?: any;
  showCaseNumberResolverModal: (claim: Claim) => void;
  showCreditorResolverModal: (claim: Claim) => void;
}) {
  const editField = useCallback(() => {
    claim.focused_cell_element = document.activeElement as HTMLTableCellElement;
    claim.editing_field_key = columnKey;
  }, [columnKey, claim]);

  const onCellKeydown = (event: React.KeyboardEvent<HTMLTableCellElement>) => {
    if (event.key === "Enter") {
      // Must prevent default otherwise a strange bug in FF would occur
      event.preventDefault();
      // Must schedule on next tick otherwise it would propagate the enter key to the focused element input
      setTimeout(editField, 0);
    } else if (event.key === "Backspace") {
      updateClaim(claim, columnKey, emptyValue || "");
    } else if (["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"].includes(event.key)) {
      event.preventDefault();
      // Focus the next, prev, above or below cell
      switch (event.key) {
        case "ArrowRight": {
          if (document.activeElement?.nextElementSibling) {
            (document.activeElement.nextElementSibling as HTMLElement).focus();
            (document.activeElement.nextElementSibling as HTMLElement).scrollIntoView();
          }
          return;
        }
        case "ArrowLeft": {
          if (document.activeElement?.previousElementSibling) {
            (document.activeElement.previousElementSibling as HTMLElement).focus();
            (document.activeElement.previousElementSibling as HTMLElement).scrollIntoView();
          }
          return;
        }
        case "ArrowUp": {
          const currentCell = event.target as HTMLElement;
          // Get current cell index
          const currentIndex = Array.from(currentCell.parentNode?.children || []).indexOf(currentCell);
          // Get upper row: 1. get parent 2. get previous element sibling of parent
          if (currentCell.parentNode && (currentCell.parentNode as HTMLElement).previousElementSibling) {
            const child = (
              (currentCell.parentNode as HTMLElement).previousElementSibling as HTMLElement
            ).querySelector<HTMLElement>(`td:nth-child(${currentIndex + 1})`);

            if (child) {
              child.focus();
              child.scrollIntoView();
            }
          }

          return;
        }
        case "ArrowDown": {
          const currentCell = event.target as HTMLElement;
          // Get current cell index
          const currentIndex = Array.from(currentCell.parentNode?.children || []).indexOf(currentCell);
          // Get down row: 1. get parent 2. get previous element sibling of parent
          if (currentCell.parentNode && (currentCell.parentNode as HTMLElement).nextElementSibling) {
            const child = (
              (currentCell.parentNode as HTMLElement).nextElementSibling as HTMLElement
            ).querySelector<HTMLElement>(`td:nth-child(${currentIndex + 1})`);
            if (child) {
              child.focus();
              child.scrollIntoView();
            }
          }

          return;
        }
      }
    }
  };

  if (claim.editing_field_key === columnKey) {
    const Component = columnComponent ? columnComponent : null;

    return (
      <td className={className} tabIndex={0}>
        {Component && (
          <Component
            value={columnValue}
            claim={claim}
            columnKey={columnKey}
            validation={columnValidation}
            invalidMessage={invalidMessage}
            converter={converter}
            maxLength={maxLength}
            updateClaim={updateClaim}
          />
        )}
      </td>
    );
  }

  const error = claim.validation_errors.find((error) => error.key === columnKey);

  const cell = (
    <td
      title={formattedValue}
      className={classnames(className, {
        "table-danger": error,
        "table-light": !error && !claim.selected && columnKey === "case_no",
      })}
      onDoubleClick={editField}
      onKeyDown={onCellKeydown}
      tabIndex={0}
    >
      <div className="inner-cell d-flex align-items-center gap-2">
        <div className="flex-grow-1 inner-cell-value">{formattedValue}</div>

        <div className="ms-auto flex-shrink-0">
          {columnKey === "case_no" && claim.status === "NEEDS_CASE_NUMBER_RESOLUTION" && (
            <OverlayTrigger
              placement="top"
              trigger={["hover", "focus"]}
              overlay={
                <Tooltip>
                  Before submitting the batch we first need to resolve the full case number within the court. Click here
                  to pre-process case number resolution.
                </Tooltip>
              }
            >
              <WarningIcon
                size="18"
                className="text-warning me-1"
                role="button"
                onClick={() => showCaseNumberResolverModal(claim)}
              />
            </OverlayTrigger>
          )}

          {columnKey === "case_no" && claim.status === "NEEDS_CASE_NUMBER_DISAMBIGUATION" && claim.found_cases && (
            <OverlayTrigger
              placement="top"
              trigger={["hover", "focus"]}
              overlay={
                <Tooltip>
                  {claim.found_cases.length === 0
                    ? "We haven't found any matching case. Click to see details."
                    : claim.found_cases.length === 1
                    ? "We found a case matching your case number. Click to confirm that's what you've been looking for."
                    : "We found some cases matching your case number. Click to select a case."}
                </Tooltip>
              }
            >
              <WarningIcon
                size="18"
                className={classnames("me-1", {
                  "text-warning": claim.found_cases.length > 0,
                  "text-danger": claim.found_cases.length === 0,
                })}
                role="button"
                onClick={() => showCaseNumberResolverModal(claim)}
              />
            </OverlayTrigger>
          )}

          {columnKey === "case_no" && claim.status === "SEARCHING_CASE_NUMBER" && (
            <OverlayTrigger
              placement="top"
              trigger={["hover", "focus"]}
              overlay={<Tooltip>We're searching the case number within the court, click to see progress.</Tooltip>}
            >
              <Spinner
                animation="border"
                size="sm"
                variant="primary"
                role="button"
                className="me-1"
                onClick={() => showCaseNumberResolverModal(claim)}
              />
            </OverlayTrigger>
          )}

          {columnKey === "creditor" && claim.status === "NEEDS_CREDITOR_RESOLUTION" && (
            <OverlayTrigger
              placement="top"
              trigger={["hover", "focus"]}
              overlay={
                <Tooltip>
                  We must verify the presence of the specified creditor record associated with the court's case number
                  to obtain the creditor ID. If the record is not found, we will submit a new creditor record. Click
                  here to pre-process creditor resolution.
                </Tooltip>
              }
            >
              <WarningIcon
                size="18"
                className="text-warning me-1"
                role="button"
                onClick={() => showCreditorResolverModal(claim)}
              />
            </OverlayTrigger>
          )}

          {columnKey === "creditor" && claim.status === "NEEDS_CREDITOR_DISAMBIGUATION" && claim.found_creditors && (
            <OverlayTrigger
              placement="top"
              trigger={["hover", "focus"]}
              overlay={
                <Tooltip>
                  {claim.found_creditors.matching.length === 0
                    ? "We haven't found any matching creditor. Click to see details."
                    : claim.found_creditors.matching.length === 1
                    ? "We found a creditor matching. Click to confirm that's what you've been looking for."
                    : "We found some creditors matching. Click to select a creditor."}
                </Tooltip>
              }
            >
              <WarningIcon
                size="18"
                className={classnames("me-1", {
                  "text-warning": claim.found_creditors.total_count > 0,
                  "text-danger": claim.found_creditors.total_count === 0,
                })}
                role="button"
                onClick={() => showCreditorResolverModal(claim)}
              />
            </OverlayTrigger>
          )}

          {columnKey === "creditor" && claim.status === "READY" && claim.creditors_search_error && (
            <OverlayTrigger
              placement="top"
              trigger={["hover", "focus"]}
              overlay={
                <Tooltip>
                  The following error occurred while searching for the creditor: "{claim.creditors_search_error}"
                </Tooltip>
              }
            >
              <XIcon size="18" className="me-1 text-danger" />
            </OverlayTrigger>
          )}

          <EditIcon size="18" className="edit-icon text-primary" role="button" onClick={editField} />
        </div>
        {error && (
          <div>
            <XIcon size="18" className="text-danger" />
          </div>
        )}
      </div>
    </td>
  );

  if (error)
    return (
      <OverlayTrigger placement="top" trigger={["hover", "focus"]} overlay={<Tooltip>{error.error}</Tooltip>}>
        {cell}
      </OverlayTrigger>
    );

  return cell;
});
