import React, { useCallback } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import PasswordStrengthInfo from "../PasswordStrengthInfo";

const schema = yup.object().shape({
  confirmationCode: yup
    .string()
    .length(6, "Confirmation code must be exactly 6 characters")
    .matches(/\d{6}/, "Confirmation code is invalid")
    .required("Confirmation code is required"),
  newPassword: yup
    .string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
      "Password must be at least 8 characters, lowercase, uppercase, and numbers for greater security."
    )
    .required("New password is required")
});

export default function CodeAndPassword({
  isLoading,
  onCodeAndPasswordSubmit
}) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });
  const password = watch("newPassword", "");

  const onSubmit = useCallback(
    ({ confirmationCode, newPassword }) =>
      onCodeAndPasswordSubmit(confirmationCode, newPassword),
    [onCodeAndPasswordSubmit]
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="confirmationCode" className="mb-3">
        <Form.Label>Confirmation code</Form.Label>
        <Form.Control
          {...register("confirmationCode")}
          placeholder="123456"
          isInvalid={errors.confirmationCode}
          disabled={isLoading}
        />
        <Form.Control.Feedback type="invalid">
          {errors.confirmationCode && errors.confirmationCode.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="newPassword" className="mb-3">
        <Form.Label>New password</Form.Label>
        <Form.Control
          {...register("newPassword")}
          type="password"
          isInvalid={errors.newPassword}
          disabled={isLoading}
        />

        <PasswordStrengthInfo password={password} />
      </Form.Group>

      <Button type="submit" disabled={isLoading}>
        {isLoading ? (
          <Spinner animation="border" variant="light" size="sm" />
        ) : (
          "Confirm"
        )}
      </Button>
    </Form>
  );
}
