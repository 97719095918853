import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";

import { apolloClient } from "../../../../configs/apollo-client";
import getAvailablePaymentMethod from "../graphql/getAvailablePaymentMethod";
import { somethingWentWrong } from "../../../../lib/errors";

export default function BuyDocketDocumentModal({ show, onClose, onConfirm }) {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();

  useEffect(() => {
    let sub;

    if (show) {
      setIsLoading(true);

      const watchedQuery = apolloClient.watchQuery({
        query: getAvailablePaymentMethod,
        fetchPolicy: "network-only",
      });

      sub = watchedQuery.subscribe({
        next(response) {
          if (!response.partial) {
            setIsLoading(false);
            if (!response.data.getAvailablePaymentMethod.payment_method_available) {
              toast.error(
                "We haven't been able to find a valid payment method for your account. Please contact support."
              );
              onClose();
            } else {
              setResponse(response.data.getAvailablePaymentMethod);
            }
          }
        },
        error(err) {
          console.log("[watchQuery] error", err);
          setIsLoading(false);
          setResponse(undefined);
          toast.error(somethingWentWrong);
          onClose();
        },
      });
    }

    return () => {
      if (sub) {
        console.log("[watchQuery] Clean up");
        sub.unsubscribe();
      }
    };
  }, [show, onClose]);

  const onClickConfirm = useCallback(() => {
    setIsLoading(true);
    onConfirm();
  }, [onConfirm]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      {...(isLoading && {
        backdrop: "static",
        keyboard: false,
      })}
    >
      {isLoading && (
        <Modal.Body className="text-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      )}
      {!isLoading && response && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            We're going to charge <strong>$3.50</strong> on your <code>{response.payment_method.card_brand}</code> card
            that ends with <code>{response.payment_method.last_four_digits}</code>.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onClose} className="me-auto">
              Cancel
            </Button>
            <Button variant="primary" onClick={onClickConfirm}>
              Confirm
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}
