import { Modal, Button } from "react-bootstrap";

interface Props {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
export default function ConfirmModal({ show, onClose, onConfirm }: Props) {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        You haven't attached any document, are you sure you want to continue?
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <Button variant="light" onClick={onClose}>
          No, take me back
        </Button>
        <Button variant="success" onClick={onConfirm}>
          Yes, continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
