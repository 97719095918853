import { useState, useEffect } from "react";
import { Spinner, Form, Alert, Button } from "react-bootstrap";
import { FieldErrors, UseFormRegister } from "react-hook-form";

import { apolloClient } from "../../../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../../../lib/errors";

import { FormValues } from "./interfaces";
import getPreviousFiledClaims from "./graphql/getPreviousFiledClaims";

type PreviousFiledClaim = {
  number: string;
  filed_on: string;
};

type PreviousFiledClaimsResponse = {
  error: string | null;
  claims: PreviousFiledClaim[];
};

interface Props {
  creditorId: string;
  courtCode: string;
  caseNo: string;
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
}
export default function AmendClaim({ creditorId, courtCode, caseNo, register, errors }: Props) {
  const [response, setResponse] = useState<"loading" | typeof somethingWentWrong | PreviousFiledClaimsResponse>(
    "loading"
  );
  const [retry, setRetry] = useState(0);

  useEffect(() => {
    setResponse("loading");

    const watchedQuery = apolloClient.watchQuery({
      query: getPreviousFiledClaims,
      variables: {
        input: {
          creditor_id: creditorId,
          court_code: courtCode,
          case_no: caseNo,
        },
      },
      fetchPolicy: "cache-and-network",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        if (response.data) {
          setResponse(response.data.getPreviousFiledClaims as PreviousFiledClaimsResponse);
        }
      },
      error(err) {
        console.log("[watchQuery] error", err);
        setResponse(somethingWentWrong);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, [caseNo, courtCode, creditorId, retry]);

  return (
    <>
      <Form.Group controlId="previously-filed-claim" className="mb-2">
        <Form.Label>
          Previously filed claim <span className="text-danger">(required)</span>
        </Form.Label>

        {typeof response === "object" && response.claims && (
          <Form.Select
            style={{ width: "100%", maxWidth: 350 }}
            {...register("amend_claim")}
            isInvalid={!!errors.amend_claim?.message}
            disabled={!!response.error || response.claims.length === 0}
          >
            <option value="">Choose...</option>

            {response.claims.map(({ number, filed_on }, index) => (
              <option value={JSON.stringify({ number, filed_on })} key={index}>
                Claim number {number}, filed on {filed_on}
              </option>
            ))}
          </Form.Select>
        )}

        <Form.Control.Feedback type="invalid">{errors.amend_claim?.message}</Form.Control.Feedback>
      </Form.Group>

      {response === "loading" && (
        <div className="d-flex align-items-center gap-2">
          <Spinner animation="border" variant="primary" size="sm" />
          Getting list of previously filed claims
        </div>
      )}

      {response === somethingWentWrong && (
        <Alert variant="danger">
          <div className="alert-message">
            <p>{somethingWentWrong}</p>
            <Button onClick={() => setRetry(retry + 1)}>Retry</Button>
          </div>
        </Alert>
      )}

      {typeof response === "object" && response.error ? (
        <Alert variant="danger">
          <div className="alert-message">{response.error}</div>
        </Alert>
      ) : null}

      {typeof response === "object" && response.claims && response.claims.length === 0 ? (
        <Alert variant="danger">
          <div className="alert-message">Can't amend claim, no previous claims found for this creditor</div>
        </Alert>
      ) : null}
    </>
  );
}
