import { Fragment, useContext } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { FeatureFlag } from "../types";
import Roles from "../../../utils/constants/roles";
import PrivateLayout from "../../layouts/Private";
import routes from "../privateRoutes";
import { setRedirectAfterLogin } from "../../../redux/actions/authActions";

import UserContext from "./UserContext";

export default function PrivateRoutes() {
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();
  // Do not render layout if user is null
  // But render his children routes
  // This is necessary because if a user is present
  // we don't want to re render common layout parts on each route change
  const WrapperComponent = user ? PrivateLayout : Fragment;

  return (
    <WrapperComponent>
      <Switch>
        {routes.map(
          ({ id, path, component: Component, accessibleBy, requiredFeatureFlag, disableContainerPadding }) => (
            <Route
              key={id}
              path={path}
              exact
              render={function (routeProps) {
                const currentPath = path;

                if (!user) {
                  console.log(`Saving redirect to ${routeProps.location.pathname}${routeProps.location.search}`);
                  // save desired path to redirect to after login
                  setTimeout(
                    () =>
                      dispatch(setRedirectAfterLogin(`${routeProps.location.pathname}${routeProps.location.search}`)),
                    0
                  );

                  return <Redirect to={"/login"} />;
                }

                const jwtData = user.signInUserSession.idToken.payload;
                const { active, role } = jwtData;
                const activeFeatures = JSON.parse(jwtData.features);

                // for testing purposes
                // let role = Roles.SubUser;
                // Todo Mirko: pre token generation inject active, but claims to override has only k-v pairs of string->string
                // let active = "false";

                const [canAccess, redirectTo, error] = userCanAccess({
                  active,
                  role,
                  currentPath,
                  accessibleBy,
                  activeFeatures,
                  requiredFeatureFlag,
                });
                if (!canAccess) {
                  if (error) {
                    toast.error(error);
                  }

                  return <Redirect to={redirectTo} />;
                }

                // @ts-ignore
                const componentElement = <Component {...routeProps} user={user} userRole={role} />;

                return disableContainerPadding ? (
                  componentElement
                ) : (
                  <div className="p-2 p-sm-4">{componentElement}</div>
                );
              }}
            />
          )
        )}
      </Switch>
    </WrapperComponent>
  );
}

function userCanAccess({
  active,
  role,
  currentPath,
  accessibleBy,
  activeFeatures,
  requiredFeatureFlag,
}: {
  active: string;
  role: Roles;
  currentPath: string;
  accessibleBy?: Roles[];
  activeFeatures: FeatureFlag[];
  requiredFeatureFlag?: FeatureFlag;
}): [false, string, string?] | [true] {
  // Todo Mirko: pre token generation inject active, but claims to override has only k-v pairs of string->string
  if (active === "false") {
    // TODO
    // if (role === Roles.SubUser && currentPath !== "/contact-admin") {
    //   return [false, "/contact-admin", "User not active"];
    // }

    // TODO
    if ([Roles.User, Roles.Admin].includes(role) && currentPath !== "/subscriptions") {
      return [false, "/subscriptions", "Error message"];
    }
  }

  if (requiredFeatureFlag) {
    if (activeFeatures.includes(requiredFeatureFlag)) {
      return [true];
    }

    // Still accessible by role?
    if (accessibleBy && accessibleBy.includes(role)) {
      return [true];
    }

    console.log(`Route requires feature flag "${requiredFeatureFlag}"`);
    return [false, "/dashboard"];
  }

  if (accessibleBy && !accessibleBy.includes(role)) {
    console.log("Route not accessible by role", role);
    return [false, "/dashboard"];
  }

  return [true];
}
