import { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import { Form, Button, Row, Col, Alert, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { usePlacesWidget } from "react-google-autocomplete";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ExclamationTriangle as WarningIcon } from "react-bootstrap-icons";

import { Signee, SigneeRoles } from "../interfaces";
import states from "../lib/states.json";
import UserContext from "../../../../../../components/Routing/components/UserContext";

import ConfirmModal from "./ConfirmModal";

interface GoogleMapsAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

const addressSchema = yup.object({
  address1: yup.string().required("Insert the address"),
  city: yup.string().required("Insert the City"),
  state: yup.string().required("Choose a State"),
  zip: yup.string().required("Insert the ZIP code").matches(/\d{5}/, "Insert a valid ZIP code"),
  zip4: yup.string().matches(/\d{4}/, {
    message: "Insert a valid ZIP code",
    excludeEmptyString: true,
  }),
});

const schema = yup.object({
  role: yup
    .string()
    .nullable()
    .oneOf(
      [SigneeRoles.Creditor, SigneeRoles.CreditorAttorney, SigneeRoles.Rule3004, SigneeRoles.Rule3005],
      "Select an option"
    ),
  signature: yup.string().required("Insert signature"),
  title: yup.string(),
  company: yup.string(),
  address: addressSchema,
  phone: yup.string(),
  email: yup.string().nullable().email("Insert a valid email address"),
  save_filing_profile: yup.boolean(),
  filing_profile_name: yup.string().when("save_filing_profile", {
    is: true,
    then: (schema) => schema.nullable().required("Specify a name for the filing profile"),
    otherwise: (schema) => schema.nullable(),
  }),
});

enum ConfirmModalState {
  Show,
  Hide,
  Confirm,
}

interface Props {
  signee?: Signee;
  filedBy: string;
  onChange: (signee: Signee) => void;
  onNext: (signee: Signee) => void;
}
export default function Sign({ signee, filedBy, onChange, onNext }: Props) {
  const { user } = useContext(UserContext);
  const { role } = user.signInUserSession.idToken.payload;

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm<Signee>({
    mode: "onChange",
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      ...signee,
    },
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "save_filing_profile") {
        if (!value.save_filing_profile) {
          setValue("filing_profile_name", null, {
            shouldValidate: true,
          });
        } else {
          setTimeout(() => setFocus("filing_profile_name"));
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, setFocus]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log("Signee onChange");
      console.log(value, name, type);
      onChange(getValues());
    });
    return () => subscription.unsubscribe();
  }, [getValues, onChange, watch]);

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      const addressComponents: GoogleMapsAddressComponent[] = place.address_components;
      console.log("addressComponents", addressComponents);
      if (!addressComponents) {
        return;
      }

      // Reference
      // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
      const address1 = [
        addressComponents.find(({ types }) => types.includes("street_number"))?.long_name || "",
        addressComponents.find(({ types }) => types.includes("route"))?.long_name || "",
      ]
        .filter(Boolean)
        .join(" ");
      const city =
        addressComponents.find(({ types }) => types.includes("locality"))?.long_name ||
        addressComponents.find(({ types }) => types.includes("neighborhood") && types.includes("political"))
          ?.long_name ||
        "";
      const state =
        addressComponents.find(({ types }) => types.includes("administrative_area_level_1"))?.short_name || "";
      const zip = addressComponents.find(({ types }) => types.includes("postal_code"))?.long_name || "";
      const zip4 = addressComponents.find(({ types }) => types.includes("postal_code_suffix"))?.long_name || "";

      // according to docs more verbose but more performant than overwriting entire object field
      setValue("address.address1", address1, {
        shouldValidate: true,
      });
      setValue("address.city", city, {
        shouldValidate: true,
      });
      setValue("address.state", state, {
        shouldValidate: true,
      });
      setValue("address.zip", zip, {
        shouldValidate: true,
      });
      setValue("address.zip4", zip4, {
        shouldValidate: true,
      });
    },
    options: {
      componentRestrictions: { country: "us" },
      types: ["address"],
    },
  });

  const [confirmModal, setConfirmModal] = useState<ConfirmModalState>(ConfirmModalState.Hide);
  const [showShareModal, setShowShareModal] = useState(false);

  const onSubmit = (data: Signee) => {
    console.log("data", data);
    setConfirmModal(ConfirmModalState.Show);
  };

  const filedByCreditor = filedBy === "CR";
  const filedByCreditorAttorney = filedByCreditor || filedBy === "CAT";
  const filedByTrustee = ["DE", "DAT", "TR"].includes(filedBy);
  const filedByGuarantor = ["CR", "CAT", "DE", "DAT"].includes(filedBy);

  return (
    <>
      <Alert variant="warning" className="shadow">
        <div className="border-end border-white p-3 d-flex align-items-center">
          <WarningIcon size="24" className="text-warning flex-shrink-0" />
        </div>
        <div className="p-3 d-flex align-items-center">
          <strong className="text-warning">
            A person who files a fraudulent claim could be fined up to $500,000, imprisoned for up to 5 years, or both.
            18 U.S.C. §§ 152, 157 and 3571.
          </strong>
        </div>
      </Alert>

      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Form.Label>
          Check the appropriate box: <span className="text-danger">(required)</span>
        </Form.Label>

        <div
          className={classNames({
            "is-invalid": !!errors.role?.message,
          })}
        >
          {filedByCreditor && (
            <Form.Check
              type="radio"
              id="signee-creditor"
              label="I am the creditor."
              {...register("role")}
              value={SigneeRoles.Creditor}
            />
          )}

          {filedByCreditorAttorney && (
            <Form.Check
              type="radio"
              id="signee-creditor-attorney"
              label="I am the creditor's attorney or authorized agent."
              {...register("role")}
              value={SigneeRoles.CreditorAttorney}
            />
          )}

          {filedByTrustee && (
            <Form.Check
              type="radio"
              id="signee-trustee"
              label="I am the trustee, or the debtor, or their authorized agent. Bankruptcy Rule 3004."
              {...register("role")}
              value={SigneeRoles.Rule3004}
            />
          )}

          {filedByGuarantor && (
            <Form.Check
              type="radio"
              id="signee-guarantor"
              label="I am a guarantor, surety, endorser, or other codebtor. Bankruptcy Rule 3005."
              {...register("role")}
              value={SigneeRoles.Rule3005}
            />
          )}
        </div>

        <Form.Control.Feedback type="invalid">{errors.role?.message}</Form.Control.Feedback>

        <hr />

        <Form.Group className="mb-3" controlId="signee-signature">
          <Form.Label>
            Signature <span className="text-danger">(required)</span>
          </Form.Label>

          <Form.Control type="text" maxLength={50} isInvalid={!!errors.signature?.message} {...register("signature")} />

          <Form.Control.Feedback type="invalid">{errors.signature?.message}</Form.Control.Feedback>

          <Form.Text>Type full name of the person who is completing and signing this claim</Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="signee-title">
          <Form.Label>Title</Form.Label>

          <Form.Control type="text" maxLength={50} isInvalid={!!errors.title?.message} {...register("title")} />

          <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="signee-company">
          <Form.Label>Company</Form.Label>

          <Form.Control type="text" maxLength={50} isInvalid={!!errors.company?.message} {...register("company")} />

          <Form.Control.Feedback type="invalid">{errors.company?.message}</Form.Control.Feedback>

          <Form.Text>Identify the corporate servicer as the company if the authorized agent is a servicer</Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="signee-address-1">
            Address <span className="text-danger">(required)</span>
          </Form.Label>

          <Controller
            name="address.address1"
            control={control}
            render={({ field }) => (
              <Form.Control
                id="signee-address-1"
                type="text"
                placeholder="123 Main St"
                isInvalid={!!errors.address?.address1?.message}
                {...field}
                className="mw-100"
                htmlSize={40}
                // @ts-ignore
                ref={ref}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                  }
                }}
              />
            )}
          />

          <Form.Control.Feedback type="invalid">{errors.address?.address1?.message}</Form.Control.Feedback>
        </Form.Group>

        <Row className="mb-3 gx-0 gap-3">
          <Form.Group as={Col} sm="auto" controlId="signee-address-city">
            <Form.Label>City</Form.Label>

            <Form.Control maxLength={30} isInvalid={!!errors.address?.city?.message} {...register("address.city")} />

            <Form.Control.Feedback type="invalid">{errors.address?.city?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} sm="auto" controlId="signee-address-state">
            <Form.Label>State</Form.Label>

            <Form.Select isInvalid={!!errors.address?.state?.message} {...register("address.state")}>
              <option value="">Choose...</option>
              {states.map(function ({ name, abbreviation }) {
                return (
                  <option key={abbreviation} value={abbreviation}>
                    {name} ({abbreviation})
                  </option>
                );
              })}
            </Form.Select>

            <Form.Control.Feedback type="invalid">{errors.address?.state?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} sm="auto">
            <Form.Label htmlFor="signee-address-zip-5">Zip</Form.Label>

            <div
              className={classNames("d-flex gap-1 align-items-center", {
                "is-invalid": !!errors.address?.zip?.message || !!errors.address?.zip4?.message,
              })}
            >
              <Form.Control
                placeholder="12345"
                id="signee-address-zip-5"
                maxLength={5}
                htmlSize={5}
                inputMode="numeric"
                isInvalid={!!errors.address?.zip?.message}
                {...register("address.zip")}
              />
              -
              <Form.Control
                placeholder="1234"
                maxLength={4}
                htmlSize={4}
                inputMode="numeric"
                isInvalid={!!errors.address?.zip4?.message}
                {...register("address.zip4")}
              />
            </div>

            <Form.Control.Feedback type="invalid">
              {errors.address?.zip?.message || errors.address?.zip4?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Form.Group className="mb-3" controlId="signee-phone-number">
          <Form.Label>Telephone number</Form.Label>

          <Form.Control type="text" placeholder="818-252-9950" maxLength={20} inputMode="tel" {...register("phone")} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="signee-email">
          <Form.Label>Email</Form.Label>

          <Form.Control
            type="email"
            placeholder="johndoe@company.com"
            isInvalid={!!errors.email?.message}
            maxLength={40}
            inputMode="email"
            {...register("email")}
          />

          <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
        </Form.Group>

        <div className="sticky-bottom bg-body border-top py-3">
          {role === "super_admin" && (
            <div className="mb-3">
              <Form.Group className="mb-2">
                <Form.Check
                  type="checkbox"
                  id="save-filing-profile"
                  label="Save filing information for future use"
                  {...register("save_filing_profile")}
                />
              </Form.Group>

              {watch("save_filing_profile") && (
                <Form.Group>
                  <Form.Label htmlFor="filing-profile-name">Name</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={50}
                    id="filing-profile-name"
                    isInvalid={!!errors.filing_profile_name?.message}
                    {...register("filing_profile_name")}
                  />
                  <Form.Control.Feedback type="invalid">{errors.filing_profile_name?.message}</Form.Control.Feedback>
                </Form.Group>
              )}
            </div>
          )}

          <div className="d-flex align-items-center gap-3">
            <Button type="submit" variant="primary" size="lg">
              Submit Claim
            </Button>

            {role === "super_admin" && (
              <>
                <span>Or</span>
                <Button type="button" variant="warning" size="lg" onClick={() => setShowShareModal(true)}>
                  Share for review
                </Button>
              </>
            )}
          </div>
        </div>
      </Form>

      <ConfirmModal
        show={confirmModal === ConfirmModalState.Show}
        handleClose={() => setConfirmModal(ConfirmModalState.Hide)}
        onConfirm={() => onNext(getValues())}
      />

      <Modal show={showShareModal} onHide={() => setShowShareModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="lead">Before sending, share this claim with a member of team for review</p>

          <Form.Select>
            <option value="">Select a team member</option>
            <option value="johndoe">John Doe</option>
            <option value="janedoe">Jane Doe</option>
          </Form.Select>
        </Modal.Body>

        <Modal.Footer className="justify-content-between">
          <Button variant="light" onClick={() => setShowShareModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => setShowShareModal(false)}>
            Share
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
