export default function createCsvFile(csvData: string, filename: string): Promise<File> {
  const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvData}`);
  return urltoFile(encodedUri, filename, "text/csv");
}

// https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
async function urltoFile(url: string, filename: string, mimeType: string): Promise<File> {
  const res = await fetch(url);
  const blob = await res.blob();
  return new File([blob], filename, { type: mimeType });
}
