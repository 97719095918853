export default function CourtIcon() {
  const successCircleCircumference = 16 * 2 * Math.PI;

  return (
    <svg
      className="d-block court-icon"
      width="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="CourtIcon">
        <g id="CourtCircle">
          <circle
            cx="60"
            cy="60"
            r="58.5"
            stroke="#4E4544"
            strokeOpacity="0.3"
            strokeWidth="3"
          />
        </g>
        <g id="Court" fill="#293042">
          <rect id="Rectangle 1" x="22" y="90" width="76" height="5" />
          <rect
            id="Rectangle 2"
            x="25.8"
            y="83.3333"
            width="68.4"
            height="3.33333"
          />
          <rect
            id="Rectangle 3"
            x="31.12"
            y="46.6667"
            width="3.8"
            height="38.3333"
          />
          <rect
            id="Rectangle 4"
            x="85.08"
            y="46.6667"
            width="3.8"
            height="38.3333"
          />
          <rect
            id="Rectangle 5"
            x="44.8"
            y="46.6667"
            width="7.6"
            height="38.3333"
          />
          <rect
            id="Rectangle 6"
            x="67.6"
            y="46.6667"
            width="7.6"
            height="38.3333"
          />
          <rect
            id="Rectangle 7"
            x="25.8"
            y="43.3333"
            width="68.4"
            height="3.33333"
          />
          <path id="Vector 5" d="M22 35.7143V40H98V35.7143L60 25L22 35.7143Z" />
        </g>

        <g className="success-circle">
          <circle
            strokeWidth="2"
            strokeDasharray={`${successCircleCircumference} ${successCircleCircumference}`}
            strokeDashoffset={
              successCircleCircumference -
              (0 / 100) * successCircleCircumference
            }
            fill="white"
            r={16}
            cx={102}
            cy={18}
          />

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            x={92}
            y={8}
            viewBox="0 0 16 16"
          >
            <path
              d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
              fill="var(--bs-success)"
            />
          </svg>
        </g>
      </g>
    </svg>
  );
}
