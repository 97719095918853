import gql from "graphql-tag";

const getClaimsRegisterScreenshot = gql`
  query getClaimsRegisterScreenshot($claim_id: ID!) {
    getClaimsRegisterScreenshot(claim_id: $claim_id) {
      error
      url
    }
  }
`;

export default getClaimsRegisterScreenshot;
