import gql from "graphql-tag";

const getClaimBatch = gql`
  query getClaimBatch($batch_id: ID!) {
    getClaimBatch(batch_id: $batch_id) {
      error
      id
      name
      claims {
        id
        district
        case_no
        case_title
        creditor {
          name
          address_1
          address_2
          address_3
          city
          state
          zip
          zip4
          phone
          email
        }
        creditor_alias
        payment_address {
          name
          address_1
          address_2
          address_3
          city
          state
          zip
          zip4
          phone
          email
        }
        uniform_claim_identifier
        filed_by
        acquired_from
        earlier_filing
        debtor_id
        claim_amount
        amount_include_interest
        claim_basis
        securities {
          real_estate
          motor_vehicle
          other
          basis_for_perfection
          value_of_property
          secured_amount
          unsecured_amount
          default_amount
          interest_type
          interest_value
        }
        lease_default_amount
        setoff_property
        priorities {
          rule_507_a_1
          rule_507_a_7
          rule_507_a_4
          rule_507_a_8
          rule_507_a_5
          rule_507_a_other
          rule_507_a_other_amount
        }
        description
        remarks
        signee_person
        signee {
          name
          title
          company
          address_1
          city
          state
          zip
          zip4
          phone
          email
        }
        attachments {
          filename
          s3_key
          description
        }
        validation_errors {
          key
          error
        }
        status
        found_cases {
          case_no
          case_title
          court_code
          chapter
          date_filed
          date_closed
          match_pct
        }
        cases_search_error
        found_creditors {
          total_count
          matching {
            id
            name
            address_1
            address_2
            address_3
            address_4
            address_5
            match_pct
          }
          not_matching {
            id
            name
            address_1
            address_2
            address_3
            address_4
            address_5
            match_pct
          }
        }
        creditors_search_error
        created_at
        updated_at
      }
      status
      pending_import_jobs {
        id
        filename
      }
      invalid_records_count
      valid_records_count
      created_at
      updated_at
    }
  }
`;

export default getClaimBatch;
