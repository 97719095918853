import { useState, useCallback } from "react";
import classnames from "classnames";
import { Link, withRouter } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CornerUpLeft as CornerUpLeftIcon } from "react-feather";
import { toast } from "react-toastify";

import { somethingWentWrong } from "../../../lib/errors";

import Email from "./Email";
import CodeAndPassword from "./CodeAndPassword";
import AuthService from "../../../services/AuthService";

function ResetPassword({ history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [secondStep, setSecondStep] = useState({
    show: false,
  });

  const onEmailSubmit = useCallback(async (email) => {
    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/can-reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          email,
        }),
      });
      if (!response.ok) {
        throw new Error("Response not ok");
      }

      const json = await response.json();

      const canReset = json.can_reset;
      if (!canReset) {
        toast.success("Please check your email, we sent you a login link");
        setIsLoading(false);
        return;
      }

      await AuthService.forgotPassword(email);

      setSecondStep({
        show: true,
        email,
      });
      setIsLoading(false);

      toast.success("Please check your email, we sent you a confirmation code");
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);

      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error(somethingWentWrong);
      }
    }
  }, []);

  const onCodeReceived = useCallback(({ email }) => {
    setSecondStep({
      show: true,
      email,
    });
  }, []);

  const onCodeAndPasswordSubmit = useCallback(
    async (code, newPassword) => {
      setIsLoading(true);

      try {
        await AuthService.forgotPasswordSubmit(secondStep.email, code, newPassword);

        history.push("/auth/login");
        toast.success("Password changed successfully");

        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);

        if (error.message) {
          toast.error(error.message);
        } else {
          toast.error(somethingWentWrong);
        }
      }
    },
    [history, secondStep.email]
  );

  return (
    <Row className="mt-4">
      <Col xs={12} sm={8} lg={5} className="mx-auto">
        <Button as={Link} to="/auth/login" variant="link" className={classnames({ disabled: isLoading })}>
          <CornerUpLeftIcon size="16" /> go back to login
        </Button>

        <Card className="mt-2">
          <Card.Body>
            <Card.Title>
              <h1 className="mt-0">Reset password</h1>
            </Card.Title>

            {!secondStep.show && (
              <Email isLoading={isLoading} onEmailSubmit={onEmailSubmit} onCodeReceived={onCodeReceived} />
            )}

            {secondStep.show && (
              <CodeAndPassword isLoading={isLoading} onCodeAndPasswordSubmit={onCodeAndPasswordSubmit} />
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default withRouter(ResetPassword);
