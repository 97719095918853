import gql from "graphql-tag";

const getCurrentUserInfo = gql`
  query getCurrentUserInfo {
    getCurrentUserInfo {
      name
      email
      organization_id
    }
  }
`;

export default getCurrentUserInfo;
