import { useContext } from "react";
import { Offcanvas, Nav } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";

import privateRoutes, { getSidebarRoutes } from "../../Routing/privateRoutes";
import Roles from "../../../utils/constants/roles";
import UserContext from "../../Routing/components/UserContext";
import Logo from "../../atoms/Logo";

export default function OffcanvasSidebar({ show, onHide }) {
  const { user } = useContext(UserContext);
  const jwtData = user.signInUserSession.idToken.payload;
  const { role, active } = jwtData;
  const activeFeatures = JSON.parse(jwtData.features);
  // for testing purposes
  // const active = "false";
  const caseRoles = [Roles.User, Roles.Admin, "none"];

  let sidebarRoutes = getSidebarRoutes({
    currentUser: { role, activeFeatures },
  });

  // Todo Mirko: pre token generation inject active, but claims to override has only k-v pairs of string->string
  if (active === "false" && caseRoles.includes(role)) {
    sidebarRoutes = privateRoutes.find((route) => route.label === "Subscriptions");
  }

  return (
    <Offcanvas
      show={show}
      onHide={onHide}
      className="app-offcanvas-sidebar"
      style={{
        "--bs-offcanvas-width": "200px",
        "--bs-offcanvas-bg": "#293042",
        "--bs-offcanvas-padding-y": 0,
        "--bs-offcanvas-padding-x": 0,
      }}
    >
      <Offcanvas.Body>
        <div className="p-3 d-flex align-items-center" style={{ height: 58 }}>
          <Link to="/">
            <Logo inverse height={22} />
          </Link>
        </div>

        <Nav variant="pills" className="flex-column">
          {sidebarRoutes.map((route) => {
            if (typeof route === "string") {
              return (
                <Nav.Item key={route} className="sidebar-category-label">
                  {route}
                </Nav.Item>
              );
            }

            const { id, path, icon: Icon, label } = route;
            return (
              <Nav.Item key={id}>
                <NavLink to={path} className="sidebar-link" exact>
                  <Icon size={20} />
                  <span>{label}</span>
                </NavLink>
              </Nav.Item>
            );
          })}
        </Nav>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
