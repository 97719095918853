import gql from "graphql-tag";

const deleteClaimBatch = gql`
  mutation deleteClaimBatch($batch_id: ID!) {
    deleteClaimBatch(batch_id: $batch_id) {
      error
      error_code
    }
  }
`;

export default deleteClaimBatch;
