import gql from "graphql-tag";

const listUserFeatures = gql`
  query listUserFeatures($user_id: ID!) {
    listUserFeatures(user_id: $user_id) {
      id
      enabled_feature
      created_at
    }
  }
`;

export default listUserFeatures;
