import {
  CHECK_IMPERSONATION,
  SET_IMPERSONATED_USER,
  END_IMPERSONATING,
  CHANGE_IMPERSONATED_USER_NAME,
  RESET_SUPER_ADMIN_DATA
} from "../constants";

const impersonatedUser = localStorage.getItem("impersonatedUserToken");
const superAdmin = localStorage.getItem("superAdminEmail");

const initialState = {
  isImpersonating: !!impersonatedUser,
  impersonatedUser: impersonatedUser && JSON.parse(impersonatedUser).name,
  impersonatedUserEmail: impersonatedUser && JSON.parse(impersonatedUser).email,
  role: impersonatedUser && JSON.parse(impersonatedUser).role,
  superAdmin: superAdmin && JSON.parse(superAdmin)
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case CHECK_IMPERSONATION:
      return {
        ...state,
        isImpersonating: actions.payload.isImpersonating
      };

    case SET_IMPERSONATED_USER:
      return {
        ...state,
        impersonatedUser: actions.payload.impersonatedUser,
        impersonatedUserEmail: actions.payload.impersonatedUserEmail,
        role: actions.payload.role
      };

    case END_IMPERSONATING:
      return {
        ...state,
        isImpersonating: false,
        impersonatedUser: null,
        fullImpersonatedUser: null,
        role: false
      };

    case RESET_SUPER_ADMIN_DATA:
      return {
        ...initialState
      };

    case CHANGE_IMPERSONATED_USER_NAME:
      return {
        ...state,
        impersonatedUser: actions.payload.name
      };

    default:
      return state;
  }
}
