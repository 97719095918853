import React, { useState, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";

import { apolloClient } from "../../../../configs/apollo-client";
import purchaseCase from "../graphql/purchaseCase";
import { somethingWentWrong } from "../../../../lib/errors";

const dayPassPrice = 10;
const courtDocumentsPrice = 3500;
const casePlanPrice = 15;

export default function ModalPaywall({ show, onCancel, caseId }) {
  const [isLoading, setIsLoading] = useState(false);

  const onClickBuy24hPass = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await apolloClient.mutate({
        mutation: purchaseCase,
        variables: {
          input: {
            case_id: caseId,
            type: "TWENTYFOURHOURSPASS",
          },
        },
      });

      setIsLoading(false);

      const { error, stripe_redirect_url } = response.data.purchaseCase;
      if (error) {
        toast.error(error);
      } else {
        window.location = stripe_redirect_url;
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(somethingWentWrong);
    }
  }, [caseId]);

  const onClickBuySubscription = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await apolloClient.mutate({
        mutation: purchaseCase,
        variables: {
          input: {
            case_id: caseId,
            type: "MONTHLYSUBSCRIPTION",
          },
        },
      });

      setIsLoading(false);

      const { error, stripe_redirect_url } = response.data.purchaseCase;
      if (error) {
        toast.error(error);
      } else {
        window.location = stripe_redirect_url;
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(somethingWentWrong);
    }
  }, [caseId]);

  return (
    <Modal show={show} onHide={onCancel} size="lg" {...(isLoading ? { backdrop: "static", keyboard: false } : {})}>
      {!isLoading && (
        <Modal.Header closeButton>
          <Modal.Title>Choose the plan that's right for you</Modal.Title>
        </Modal.Header>
      )}
      {!isLoading && (
        <Modal.Body>
          <h4 className="text-center mb-4">Bankruptcy Case Search and Monitoring</h4>
          <Row>
            <Col sm={6} className="d-flex">
              <div className="flex-fill d-flex flex-column text-center">
                <h2>Day Pass</h2>
                <h2>${dayPassPrice} / case</h2>
                <h4>Activate When Ready</h4>
                <ul className="list-unstyled">
                  {[
                    "Access court interface for 24 hours",
                    `Download court documents for $${(courtDocumentsPrice / 1000).toFixed(2)} each`,
                    "Use when needed",
                    "Email support",
                  ].map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
                <p>
                  <strong>No subscription required.</strong>
                </p>
                <div className="d-flex flex-fill align-items-end justify-content-center">
                  <Button variant="primary" onClick={onClickBuy24hPass}>
                    Buy now
                  </Button>
                </div>
              </div>
            </Col>
            <Col sm={6} className="d-flex">
              <div className="flex-fill d-flex flex-column text-center">
                <h2>Case Plan</h2>
                <h2>${casePlanPrice} / mo / case</h2>
                <h4>Daily Tracking of One Case</h4>
                <ul className="list-unstyled">
                  {[
                    "Best for following a single case",
                    `Download court documents for $${(courtDocumentsPrice / 1000).toFixed(2)} each`,
                    "Subscription cancelable anytime",
                    "Email support",
                  ].map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
                <p>
                  <strong>
                    Subscription auto-renews monthly until canceled. Cancel anytime by clicking "Cancel" in your account
                    settings or email us at support@bkalerts.com.
                  </strong>
                </p>
                <div className="d-flex flex-fill align-items-end justify-content-center">
                  <Button variant="primary" onClick={onClickBuySubscription}>
                    Buy now
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      )}
      {isLoading && (
        <Modal.Body className="text-center">
          <h2 className="mb-3">Redirecting to Stripe</h2>
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      )}
    </Modal>
  );
}
