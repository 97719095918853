import gql from "graphql-tag";

const getImportJobProgress = gql`
  query getImportJobProgress($job_id: ID!) {
    getImportJobProgress(job_id: $job_id) {
      done
      error
    }
  }
`;

export default getImportJobProgress;
