import gql from "graphql-tag";

const listOrganizationSubscriptions = gql`
  query listOrganizationSubscriptions {
    listOrganizationMonthlySubscriptions {
      id
      case_id
      court_code
      case_number
      cost
      auto_renews_on
      created_at
    }

    listOrganizationDayPasses {
      id
      case_id
      court_code
      case_number
      expire_at
      expired
      created_at
    }
  }
`;

export default listOrganizationSubscriptions;
