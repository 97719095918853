import gql from "graphql-tag";

const resolveClaimCaseNumber = gql`
  query resolveClaimCaseNumber($batch_id: ID!, $claim_id: ID!) {
    resolveClaimCaseNumber(batch_id: $batch_id, claim_id: $claim_id) {
      accepted
      error
      error_code
    }
  }
`;

export default resolveClaimCaseNumber;
