import React from "react";

import "./style.css";

function makeArrayOfLength(length) {
  return Array.from({ length }).map((_, i) => i);
}

export default function TableContentLoader({ columns, rows = 4 }) {
  return (
    <>
      {makeArrayOfLength(rows).map((_, rowIndex) => (
        <tr key={rowIndex}>
          {makeArrayOfLength(columns).map((_, columnIndex) => (
            <td key={columnIndex}>
              <span className="loading-cell" />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}
