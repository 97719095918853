import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().email("Email address is invalid").required("Email address is required"),
  password: yup.string().required("Password is required"),
});

export default function LoginForm({ isLoading, onSubmit }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Form.Group controlId="email" className="mb-3">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          {...register("email")}
          type="email"
          placeholder="johndoe@foobar.org"
          isInvalid={errors.email}
          disabled={isLoading}
          autoFocus
        />
        <Form.Control.Feedback type="invalid">{errors.email && errors.email.message}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="password" className="mb-3">
        <Form.Label>Password</Form.Label>
        <Form.Control {...register("password")} type="password" isInvalid={errors.password} disabled={isLoading} />
        <Form.Control.Feedback type="invalid">{errors.password && errors.password.message}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Button type="submit" disabled={isLoading}>
          {isLoading ? <Spinner animation="border" variant="light" size="sm" /> : "Login"}
        </Button>
      </Form.Group>

      {!process.env.REACT_APP_SELF_HOSTED && (
        <>
          <Form.Group className="mb-1 mt-3">
            <Link to="/auth/register">Create an account</Link>
          </Form.Group>

          <Link to="/auth/reset-password">Forgot password?</Link>
        </>
      )}
    </Form>
  );
}
