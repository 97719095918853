import { useState, useEffect } from "react";
import { Hub } from "@aws-amplify/core";
import { BrowserRouter as Router, Switch, Redirect, useLocation } from "react-router-dom";
import PostHog from "posthog-js";

import AuthService from "../../services/AuthService";

// Public routes
import Login from "../../scenes/Auth/Login";
import Register from "../../scenes/Auth/Register";
import ResetPassword from "../../scenes/Auth/ResetPassword";
import SignupChoosePassword from "../../scenes/Auth/SignupChoosePassword";

// Route components
import PublicRoute from "./components/PublicRoute";
import UserContext from "./components/UserContext";
import PrivateRoutes from "./components/PrivateRoutes";

export default function Routes() {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    async function handleAuthentication() {
      Hub.listen("auth", ({ payload: { event, data } }) => {
        if (event === "signIn") {
          setUser(data);
        } else if (event === "signOut") {
          setUser(null);
        }
      });

      const user = await AuthService.checkAuthentication();

      setUser(user);
    }

    handleAuthentication();
  }, []);

  // Component not mount yet
  if (user === undefined) {
    return null;
  }

  return (
    <Router>
      <AppSwitch user={user} />
    </Router>
  );
}

function AppSwitch({ user }) {
  const location = useLocation();

  useEffect(() => {
    PostHog.__loaded && PostHog.capture("$pageview");
  }, [location]);

  return (
    <Switch>
      <Redirect from="/login" to="/auth/login" />
      <Redirect from="/register" to="/auth/register" />
      <Redirect from="/reset-password" to="/auth/reset-password" />

      <UserContext.Provider value={{ user }}>
        <PublicRoute path="/auth/login" component={Login} />
        <PublicRoute path="/auth/register" component={Register} />
        <PublicRoute path="/auth/reset-password" component={ResetPassword} />
        <PublicRoute
          path="/auth/signup-choose-password/:token"
          component={SignupChoosePassword}
          renderAuthLayout={false}
        />

        <PrivateRoutes />
      </UserContext.Provider>
    </Switch>
  );
}
