import gql from "graphql-tag";

const getUserNotifications = gql`
  query {
    getUserNotifications {
      id
      content
      notification_type
      is_new
      created_at
    }
  }
`;

export default getUserNotifications;
