import React from 'react';
import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
} from 'react-feather';
import classNames from 'classnames';

const SortIcon = ({ direction }) => {
  return (
    <div className="sort-icons">
      <ChevronUpIcon
        className={classNames('sort-icon', {
          'sort-icon--disabled': direction === 'asc',
        })}
        size={15}
      />
      <ChevronDownIcon
        className={classNames('sort-icon', {
          'sort-icon--disabled': direction === 'desc',
        })}
        size={15}
      />
    </div>
  );
};

export default SortIcon;
