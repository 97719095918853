import gql from "graphql-tag";

const listUserApps = gql`
  query listUserApps {
    listUserApps {
      id
      is_active
      created_at
      updated_at
    }
  }
`;

export default listUserApps;
