import gql from "graphql-tag";

const listDocketDocuments = gql`
  query listDocketDocuments($input: DocketDocumentInput!) {
    listDocketDocuments(input: $input) {
      documents {
        description_html
        docket_seq
        number
        pages
        can_download
      }
    }
  }
`;

export default listDocketDocuments;
