import classnames from "classnames";
import { Form } from "react-bootstrap";

import "./CustomCheckbox.scss";

interface Props {
  className?: string;
  as: React.ElementType;
  onClick: () => void;
  checked: boolean;
}
export default function CustomCheckbox({ className, as: Component = "div", onClick, checked }: Props) {
  return (
    <Component className={classnames("custom-checkbox", className, { checked })} role="button" onClick={onClick}>
      <Form.Check type="checkbox" checked={checked} readOnly className={"actual-checkbox"} />
      <Form.Check type="checkbox" checked disabled className="hover-checkbox" />
    </Component>
  );
}
