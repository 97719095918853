import gql from "graphql-tag";

const getPacerUpdateProgress = gql`
  query getPacerUpdateProgress($task_id: ID!) {
    getPacerUpdateProgress(task_id: $task_id) {
      pending
      entries {
        content {
          date_filed
          docket_no
          docket_display_number
          docket_seq
          docket_text
          has_pdf_link_on_pacer
          timestamp
          purchased
        }
        total_items
        total_pages
        page_size
      }
    }
  }
`;

export default getPacerUpdateProgress;
