import { useEffect, useState } from "react";
import { Container, Card, Spinner, Table, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ArrowRightShort as ArrowRightIcon } from "react-bootstrap-icons";

import { apolloClient } from "../../configs/apollo-client";
import Roles from "../../utils/constants/roles";

import courts from "./Epoc/New/steps/lib/courts.json";
import getDashboard from "./graphql/getDashboard";

type GetDashboardResponse = {
  listTrackedDebtors: {
    pagination: {
      total_records_count: number;
    };
  };
  listTrackedDebtorsMatches: {
    length: number;
  };
  listClaims: {
    id: string;
    court_code: string;
    case_no: string;
    case_title: string;
    updated_at: string;
  }[];
};

export default function Dashboard({ user }: { user: any }) {
  const [getDashboardResponse, setGetDashboardResponse] = useState<GetDashboardResponse | undefined>();
  const jwtData = user.signInUserSession.idToken.payload;
  const { role } = jwtData;

  const canSeeMonitoring = [Roles.SuperAdmin].includes(role);

  useEffect(() => {
    const watchedQuery = apolloClient.watchQuery({
      query: getDashboard,
      variables: {
        input: {
          page_size: 10,
          page_number: 0,
          sort_by: "CREATEDAT",
          sort_order: "DESC",
          filter_by: "",
        },
      },
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        const getDashboardResponse: GetDashboardResponse = response.data;
        setGetDashboardResponse(getDashboardResponse);
      },
      error(err) {
        console.log("[watchQuery] error", err);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, []);

  const isLoading = getDashboardResponse === undefined;

  return (
    <>
      <h1 className="mb-3">Dashboard</h1>

      <Container fluid="xl" className="dashboard-page px-0">
        <Row>
          {canSeeMonitoring && (
            <Col md="12" xl="4">
              <Row className="h-100">
                <Col xs="12" md="6" xl="12">
                  <Card className="tracked-customers-count mb-3">
                    <Card.Body>
                      <Card.Title className="mb-3">Your Tracked Customers</Card.Title>
                      {isLoading ? (
                        <Spinner variant="primary" animation="border" />
                      ) : (
                        <h2 className="display-5 mb-0 text-primary">
                          {(
                            getDashboardResponse?.listTrackedDebtors.pagination.total_records_count || 0
                          ).toLocaleString("en-US")}
                        </h2>
                      )}
                    </Card.Body>
                    <Card.Footer>
                      <Link to="/tracking" className="btn btn-link px-0 d-flex align-items-center gap-1 text-nowrap">
                        Go to your Tracked Customers
                        <ArrowRightIcon size="24" />
                      </Link>
                    </Card.Footer>
                  </Card>
                </Col>

                <Col xs="12" md="6" xl="12" className="d-flex align-items-end">
                  <Card className="matches-count mb-3 mb-xl-0 flex-grow-1">
                    <Card.Body>
                      <Card.Title className="mb-3">New Matches</Card.Title>
                      {isLoading ? (
                        <Spinner variant="primary" animation="border" />
                      ) : (
                        <h2 className="display-5 mb-0 text-success">
                          {(getDashboardResponse?.listTrackedDebtorsMatches.length).toLocaleString("en-US")}
                        </h2>
                      )}
                    </Card.Body>
                    <Card.Footer>
                      <Link to="/matches" className="btn btn-link px-0 d-flex align-items-center gap-1 text-nowrap">
                        Go to your Matches
                        <ArrowRightIcon size="24" />
                      </Link>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </Col>
          )}

          <Col md="12" xl={{ span: "8", offset: canSeeMonitoring ? 0 : 2 }} className="d-flex">
            <Card className="recent-claims mb-0 flex-grow-1">
              <Card.Body>
                <Card.Title className="mb-3">Recent Claims</Card.Title>
                {isLoading ? (
                  <Spinner variant="primary" animation="border" />
                ) : (
                  <Table size="sm" className="mb-0">
                    <thead className="table-light">
                      <tr>
                        <td>Court</td>
                        <td>Case</td>
                        <td>Updated at</td>
                      </tr>
                    </thead>
                    <tbody>
                      {getDashboardResponse?.listClaims.slice(0, 5).map((claim) => (
                        <tr key={claim.id}>
                          <td>{courts.find((court) => court.court_code === claim.court_code)?.court_name}</td>
                          <td>
                            <strong>{claim.case_no}</strong>
                            <br />
                            {claim.case_title}
                          </td>
                          <td>
                            {new Date(claim.updated_at).toLocaleDateString()}
                            <br />
                            {new Date(claim.updated_at).toLocaleTimeString()}
                          </td>
                        </tr>
                      ))}
                      {getDashboardResponse?.listClaims.length === 0 && (
                        <tr>
                          <td colSpan={3}>
                            No records yet, <Link to="/epoc/new">click here to file a new proof of Claim</Link>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                )}
              </Card.Body>
              <Card.Footer>
                <Link to="/claims" className="btn btn-link px-0 d-flex align-items-center gap-1 text-nowrap">
                  Go to your Claims
                  <ArrowRightIcon size="24" />
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
