import { useState, useCallback } from "react";
import classNames from "classnames";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import OffcanvasSidebar from "../components/OffcanvasSidebar";

import "./PrivateLayout.scss";

export default function PrivateLayout({ children }) {
  const settings = JSON.parse(localStorage.getItem("bkalerts_sidebar_settings")) || {
    initialCollapsed: false,
  };

  const [collapsed, setCollapsed] = useState(settings.initialCollapsed);
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);

  const onSidebarToggle = useCallback(() => {
    const newCollapsed = !collapsed;

    setCollapsed(newCollapsed);

    localStorage.setItem(
      "bkalerts_sidebar_settings",
      JSON.stringify({
        initialCollapsed: newCollapsed,
      })
    );
  }, [collapsed]);

  const onMobileSidebarShow = useCallback(() => setShowMobileSidebar(true), []);

  const onHideOffcanvasSidebar = useCallback(() => setShowMobileSidebar(false), []);

  return (
    <>
      <div className={classNames("app-container", { collapsed })}>
        <Navbar onSidebarToggle={onSidebarToggle} onMobileSidebarShow={onMobileSidebarShow} />

        <Sidebar collapsed={collapsed} />

        <main className="app-main bg-light">{children}</main>
      </div>

      <OffcanvasSidebar show={showMobileSidebar} onHide={onHideOffcanvasSidebar} />
    </>
  );
}
