import { useEffect } from "react";
import classNames from "classnames";
import { Form, Row, Col, InputGroup, Alert } from "react-bootstrap";
import {
  UseFormRegister,
  UseFormWatch,
  UseFormSetValue,
  UseFormSetFocus,
  FieldErrors,
} from "react-hook-form";

import { FormValues } from "./interfaces";
import AmountInput from "./AmountInput";

interface Props {
  register: UseFormRegister<FormValues>;
  watch: UseFormWatch<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  setFocus: UseFormSetFocus<FormValues>;
  errors: FieldErrors<FormValues>;
}
export default function Securities({
  register,
  watch,
  setValue,
  setFocus,
  errors,
}: Props) {
  const claimSecured = watch("claim_secured");
  const interestRates = watch("securities.interest_rates");

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (!name?.startsWith("securities") || type !== "change") {
        return;
      }

      if (name === "securities.other") {
        if (!value.securities?.other) {
          // reset field value
          setValue("securities.other_desc", "", {
            shouldValidate: true,
          });
        } else {
          setTimeout(() => setFocus("securities.other_desc"));
        }
      } else if (name === "securities.interest_rates") {
        if (
          ["fixed", "variable"].includes(value.securities?.interest_rates || "")
        ) {
          setTimeout(() => setFocus("securities.annual_interest_rate"));
        } else {
          setValue("securities.annual_interest_rate", null, {
            shouldValidate: true,
          });
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [setFocus, setValue, watch]);

  if (!claimSecured || claimSecured === "no") {
    return null;
  }

  return (
    <div id="securities-container">
      <Form.Label>
        Nature of property <span className="text-danger">(required)</span>:
      </Form.Label>

      <div
        className={classNames("mb-2", {
          "is-invalid": !!errors.securities?.message,
        })}
      >
        <Form.Group className="mb-2">
          <Form.Check
            type="checkbox"
            id="securities-real-estate"
            label="Real estate"
            {...register("securities.real_estate")}
          />
        </Form.Group>

        {watch("securities.real_estate") && (
          <Alert variant="info">
            <div className="alert-message">
              If the claim is secured by the debtor's principal residence, file
              a Mortgage Proof of Claim Attachment (Official Form 410-A) with
              this Proof of Claim.
            </div>
          </Alert>
        )}

        <Form.Group className="mb-2">
          <Form.Check
            type="checkbox"
            id="securities-vehicle"
            label="Motor vehicle"
            {...register("securities.vehicle")}
          />
        </Form.Group>

        <Form.Group as={Row}>
          <Col sm="auto">
            <Form.Check
              type="checkbox"
              id="securities-other"
              label="Other. Describe:"
              className="col-form-label"
              {...register("securities.other")}
            />
          </Col>

          <Col sm>
            <Form.Control
              type="text"
              maxLength={40}
              disabled={!watch("securities.other")}
              id="securities-other-desc"
              isInvalid={!!errors.securities?.other_desc?.message}
              {...register("securities.other_desc")}
            />

            <Form.Control.Feedback type="invalid">
              {errors.securities?.other_desc?.message}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </div>

      <Form.Control.Feedback type="invalid" className="mb-2">
        <>{errors.securities?.message}</>
      </Form.Control.Feedback>

      <Form.Group as={Row} className="mb-3" controlId="securities-basis">
        <Form.Label column sm="auto">
          Basis for perfection:
        </Form.Label>

        <Col>
          <Form.Control
            type="text"
            maxLength={24}
            {...register("securities.basis")}
          />
        </Col>
      </Form.Group>

      <Alert variant="info">
        <div className="alert-message">
          Attach redacted copies of documents, if any, that show evidence of
          perfection of a security interest (for example, a mortgage, lien,
          certificate of title, financing statement, or other document that
          shows the lien has been filed or recorded.)
        </div>
      </Alert>

      <Form.Group as={Row} controlId="securities-value" className="mb-3">
        <Form.Label column sm={4}>
          Value of property <span className="text-danger">(required)</span>:
        </Form.Label>

        <Col sm>
          <InputGroup hasValidation>
            <InputGroup.Text>$</InputGroup.Text>

            <AmountInput
              isInvalid={!!errors.securities?.value?.message}
              className="flex-grow-0 w-auto"
              register={register}
              name="securities.value"
              setValue={setValue}
            />

            <Form.Control.Feedback type="invalid">
              {errors.securities?.value?.message}
            </Form.Control.Feedback>
          </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group
        as={Row}
        controlId="securities-amount-secured"
        className="mb-3"
      >
        <Form.Label column sm={4}>
          Amount of the claim that is secured{" "}
          <span className="text-danger">(required)</span>:
        </Form.Label>

        <Col sm>
          <InputGroup hasValidation>
            <InputGroup.Text>$</InputGroup.Text>

            <AmountInput
              isInvalid={!!errors.securities?.amount_secured?.message}
              className="flex-grow-0 w-auto"
              register={register}
              name="securities.amount_secured"
              setValue={setValue}
            />

            <Form.Control.Feedback type="invalid">
              {errors.securities?.amount_secured?.message}
            </Form.Control.Feedback>
          </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group
        as={Row}
        controlId="securities-amount-unsecured"
        className="mb-1"
      >
        <Form.Label column sm={4}>
          Amount of the claim that is unsecured{" "}
          <span className="text-danger">(required)</span>:
        </Form.Label>

        <Col sm>
          <InputGroup hasValidation>
            <InputGroup.Text>$</InputGroup.Text>

            <AmountInput
              isInvalid={!!errors.securities?.amount_unsecured?.message}
              className="flex-grow-0 w-auto"
              register={register}
              name="securities.amount_unsecured"
              setValue={setValue}
            />

            <Form.Control.Feedback type="invalid">
              {errors.securities?.amount_unsecured?.message}
            </Form.Control.Feedback>
          </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Text>
          The sum of the secured and unsecured amounts should match the claim
          amount above
        </Form.Text>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="securities-cure-default">
        <Form.Label column sm={4}>
          Amount necessary to cure any default as of the date of the petition:
        </Form.Label>

        <Col sm>
          <InputGroup hasValidation>
            <InputGroup.Text>$</InputGroup.Text>

            <AmountInput
              isInvalid={!!errors.securities?.cure_default?.message}
              className="flex-grow-0 w-auto"
              register={register}
              name="securities.cure_default"
              setValue={setValue}
            />

            <Form.Control.Feedback type="invalid">
              {errors.securities?.cure_default?.message}
            </Form.Control.Feedback>
          </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          Annual Interest Rate (when case was filed)
        </Form.Label>

        <Col sm="auto" className="d-flex flex-wrap col-form-label gap-3">
          <Form.Check
            type="radio"
            id="securities-interest-rates-none"
            label="None"
            className="m-0"
            {...register("securities.interest_rates")}
            value=""
          />

          <Form.Check
            type="radio"
            id="securities-interest-rates-fixed"
            label="Fixed"
            className="m-0"
            {...register("securities.interest_rates")}
            value="fixed"
          />

          <Form.Check
            type="radio"
            id="securities-interest-rates-variable"
            label="Variable"
            className="m-0"
            {...register("securities.interest_rates")}
            value="variable"
          />
        </Col>

        <Col>
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              className="text-end flex-grow-0 w-auto"
              isInvalid={!!errors.securities?.annual_interest_rate?.message}
              disabled={!interestRates}
              {...register("securities.annual_interest_rate")}
            />
            <InputGroup.Text>%</InputGroup.Text>

            <Form.Control.Feedback type="invalid">
              {errors.securities?.annual_interest_rate?.message}
            </Form.Control.Feedback>
          </InputGroup>
        </Col>
      </Form.Group>
    </div>
  );
}
