import gql from "graphql-tag";

const discardFilingProfile = gql`
  mutation discardFilingProfile($id: ID!) {
    discardFilingProfile(id: $id) {
      error
    }
  }
`;

export default discardFilingProfile;
