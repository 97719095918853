import { useEffect, useState } from "react";
import { Card, Spinner, Button } from "react-bootstrap";
import { FiletypeCsv as FiletypeCsvIcon, CheckLg as CheckLgIcon } from "react-bootstrap-icons";

import { apolloClient } from "../../configs/apollo-client";

import getJobStatus from "./graphql/getJobStatus";

type JobStatusResponse = {
  error?: string;
  status?: "pending" | "finished";
};

interface Props {
  filename: string;
  jobId: string;
  onDismiss: () => void;
  onFinished: () => void;
}
export default function ImportProgress({ filename, jobId, onDismiss, onFinished }: Props) {
  const [taskFinished, setTaskFinished] = useState(false);
  const [retry, setRetry] = useState(0);

  useEffect(() => {
    const watchedQuery = apolloClient.watchQuery({
      query: getJobStatus,
      variables: {
        job_id: jobId,
      },
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        const jobStatusResponse: JobStatusResponse = response.data.getJobStatus;

        if (jobStatusResponse.status === "finished") {
          setTaskFinished(true);
        } else {
          setTimeout(() => {
            setRetry((retry) => retry + 1);
          }, 3000);
        }
      },
      error(err) {
        console.log("[watchQuery] error", err);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, [retry, jobId]);

  useEffect(() => {
    if (taskFinished) {
      onFinished();
    }
    // onFinished is wrapped by useCallback, so it's safe to ignore it here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskFinished]);

  return (
    <Card>
      <Card.Body className="d-flex flex-column">
        <div className="d-flex flex-row align-items-center gap-3">
          <FiletypeCsvIcon size="32" className={taskFinished ? "text-success" : "text-primary"} />

          <div className="me-auto">
            <Card.Title>{filename}</Card.Title>
            <Card.Text className={taskFinished ? "text-success" : "text-primary"}>
              {taskFinished ? "Import complete" : "We're importing your CSV file..."}
            </Card.Text>
          </div>

          {taskFinished ? (
            <CheckLgIcon size="32" className="text-success" />
          ) : (
            <Spinner animation="border" variant="primary" />
          )}
        </div>

        {taskFinished && (
          <>
            <hr />
            <Button type="button" variant="outline-success" className="ms-auto" onClick={onDismiss}>
              Dismiss
            </Button>
          </>
        )}
      </Card.Body>
    </Card>
  );
}
