import { Address } from "../interfaces";

export enum FiledBy {
  Creditor = "CR",
  CreditorAttorney = "CAT",
  Debtor = "DE",
  DebtorAttorney = "DAT",
  Trustee = "TR",
}

export interface FormValues {
  creditor_phone: string;
  creditor_email: string;
  creditor_alias: string;
  filed_by: FiledBy | "";
  creditors_attorney: {
    id: string | null;
    name: string;
    address: Address;
  } | null;
  amending_claim: "yes" | "no" | "";
  amend_claim: string | null;
  acquired_from: "yes" | "no" | "";
  acquired_from_whom: string | null;
  payment_address_differ: boolean;
  payment_address: {
    id: string | null;
    name: string;
    address: Address;
    phone: string;
    email: string;
  } | null;
  uniform_identifier: string | null;
  anyone_else_filed: "yes" | "no" | "";
  other_proof_name: string | null;
  identify_debtor: "yes" | "no" | "";
  debtor_number: string | null;
  claim_amount?: number;
  include_interest: "yes" | "no" | "";
  claim_comment: string;
  claim_basis: string;
  claim_secured: "yes" | "no" | "";
  securities: {
    real_estate: boolean;
    vehicle: boolean;
    other: boolean;
    other_desc: string;
    basis: string;
    value: number | null;
    amount_secured: number | null;
    amount_unsecured: number | null;
    cure_default: number | null;
    interest_rates: string;
    annual_interest_rate: number | null;
  } | null;
  claim_lease_based: "yes" | "no" | "";
  lease_default: number | null;
  right_of_set_off: "yes" | "no" | "";
  set_off: string | null;
  priority_entitled: "yes" | "no" | "";
  priorities: {
    amount: number | null;
    code: "507_1" | "507_7" | "507_4" | "507_8" | "507_5" | "507_other" | null;
    code_507_other_sub?: string;
  } | null;
  attachments: PDFAttachment[];
  [key: string]: any;
}

export const defaultFormValues: FormValues = {
  creditor_phone: "",
  creditor_email: "",
  creditor_alias: "",
  filed_by: "",
  creditors_attorney: null,
  amending_claim: "",
  amend_claim: null,
  acquired_from: "",
  acquired_from_whom: null,
  payment_address_differ: false,
  payment_address: null,
  uniform_identifier: null,
  anyone_else_filed: "",
  other_proof_name: null,
  identify_debtor: "",
  debtor_number: null,
  claim_amount: undefined,
  include_interest: "",
  claim_comment: "",
  claim_basis: "",
  claim_secured: "",
  securities: null,
  claim_lease_based: "",
  lease_default: null,
  right_of_set_off: "",
  set_off: null,
  priority_entitled: "",
  priorities: null,
  attachments: [],
};

export type PDFAttachment = {
  id: string;
  filename: string;
  short_filename: string;
  size: number;
  s3_key: string;
};
