import classnames from "classnames";
import { Row, Col, Button, Alert } from "react-bootstrap";

import POCIcon from "./POCIcon";
import BKalertsIcon from "./BKalertsIcon";
import CourtIcon from "./CourtIcon";

import "./SubmitProgress.scss";

interface ClaimData {
  court_api_response_status_code?: number;
  court_api_response_status_text?: string;
  court_api_response_body?: any;
}

interface Props {
  finished: boolean;
  claim_data?: ClaimData;
  onShowPreview: () => void;
}
export default function SubmitProgress({
  finished,
  claim_data,
  onShowPreview,
}: Props) {
  console.log(claim_data);
  const successfull = claim_data?.court_api_response_status_code === 200;

  return (
    <Row
      className={classnames("submit-progress-wrapper", {
        finished,
        succeeded: successfull,
        failed: !successfull,
      })}
    >
      <Col
        sm={8}
        xl={8}
        xxl={5}
        className="mx-auto d-flex flex-column align-items-center"
      >
        <h1 className="mb-3 please-wait-title">Please wait</h1>

        <div className="submit-progress-container">
          <POCIcon />

          <div className="submit-progress-bar">
            <div />
            <div />
            <div />
          </div>

          <BKalertsIcon />

          <div className="submit-progress-bar">
            <div />
            <div />
            <div />
          </div>

          <CourtIcon />
        </div>

        <p className="text-center progress-title">
          We're sending the claim to your Court.
          <br />
          It might take a while, if you want, you can close this page and wait
          for an email with results
        </p>

        <h1
          className={classnames(
            "done-title",
            finished ? (successfull ? "text-success" : "text-danger") : ""
          )}
        >
          {finished ? (successfull ? "Done!" : "Something went wrong") : null}
        </h1>

        <div className="w-100 progress-actions">
          {finished ? (
            successfull ? (
              <div
                className="d-flex flex-column gap-2 mx-auto"
                style={{ maxWidth: 300 }}
              >
                <div className="align-self-center">
                  Your Claim has been accepted by the Court
                </div>

                <Button
                  as="a"
                  variant="outline-success"
                  className="d-block text-uppercase"
                  download
                  href={
                    claim_data?.court_api_response_body.documents[0]
                      .download_link
                  }
                >
                  Download
                </Button>

                <Button
                  type="button"
                  variant="outline-primary"
                  className="d-block text-uppercase"
                  onClick={onShowPreview}
                >
                  Preview
                </Button>
              </div>
            ) : (
              <Alert variant="danger">
                <div className="alert-message">
                  <p>
                    Oops!{" "}
                    {claim_data?.court_api_response_body.error
                      ? "Something went wrong, error message from your Court:"
                      : "Some validation errors occurred:"}
                  </p>
                  <p className="font-weight-bold">
                    {claim_data?.court_api_response_body.error &&
                      claim_data.court_api_response_body.error.detail}
                    {claim_data?.court_api_response_body.errors && (
                      <ul>
                        {(
                          claim_data?.court_api_response_body.errors as Record<
                            string,
                            string
                          >[]
                        ).map((error, index) => (
                          <li key={index}>
                            {error.message}
                            <br />
                            Path: {error.path}
                          </li>
                        ))}
                      </ul>
                    )}
                  </p>
                  Should you have any questions, please do not hesitate to
                  contact us at support@bkalerts.com
                </div>
              </Alert>
            )
          ) : null}
        </div>
      </Col>
    </Row>
  );
}
