import gql from "graphql-tag";

const listTrackedDebtorsMatches = gql`
  query {
    listTrackedDebtorsMatches {
      id
      customer_id
      customer_ssn
      customer_taxid
      customer_first_name
      customer_middle_name
      customer_last_name
      customer_generation
      customer_address_line_1
      customer_address_line_2
      customer_address_line_3
      customer_city
      customer_state
      customer_zip_code
      customer_country
      customer_phone
      court_id
      court_ssn
      court_taxid
      court_first_name
      court_middle_name
      court_last_name
      court_generation
      court_address_line_1
      court_address_line_2
      court_address_line_3
      court_city
      court_state
      court_zip_code
      court_country
      court_phone
      match_data
      created_at
    }
  }
`;

export default listTrackedDebtorsMatches;
