import { Auth } from "@aws-amplify/auth";
import { CurrentUserOpts } from "@aws-amplify/auth/lib-esm/types";
import { Hub } from "@aws-amplify/core";
import PostHog from "posthog-js";

import { logUser, signOut } from "../redux/actions/authActions";
import store from "../redux/store/index";
import { apolloClient } from "../configs/apollo-client";
import getCurrentUserInfo from "../scenes/Settings/Profile/Account/Info/graphql/getCurrentUserInfo";

import attachIOTPolicy from "./graphql/attachIOTPolicy.mutation";

export default abstract class AuthService {
  static startAuthListener() {
    Hub.listen("auth", (data) => {
      const { payload } = data;

      switch (payload.event) {
        case "signIn":
          this.onUserSignedIn(payload.data);
          break;
        case "signOut":
          this.onUserSignedOut();
          break;
        case "signIn_failure":
          console.error("[AuthService] User sign in failed");
          break;
        case "signUp":
          break;
        default:
          break;
      }
    });
  }

  static async onUserSignedIn(data: any) {
    console.log("[AuthService] onUserSignedIn", data);

    if (data && data.attributes) {
      try {
        console.log("[AuthService] getting current user info");
        const response = await apolloClient.query({
          query: getCurrentUserInfo,
          fetchPolicy: "no-cache",
        });

        store.dispatch(
          logUser(
            data.attributes.email,
            response.data.getCurrentUserInfo.name,
            response.data.getCurrentUserInfo.organization_id
          )
        );

        PostHog.__loaded &&
          PostHog.identify(data.attributes.email, {
            name: response.data.getCurrentUserInfo.name,
            email: data.attributes.email,
          });
      } catch (error) {
        console.log("[AuthService] onUserSignedIn error");
        console.log(error);
      }
    }

    if (!process.env.REACT_APP_SELF_HOSTED) {
      const credentials = await Auth.currentCredentials();
      const cognitoIdentityId = credentials && credentials.identityId;

      if (cognitoIdentityId) {
        await apolloClient.mutate({
          mutation: attachIOTPolicy,
          variables: {
            identity_id: cognitoIdentityId,
          },
        });
      }
    }
  }

  static onUserSignedOut() {
    console.log("[AuthService] onUserSignedOut");
    store.dispatch(signOut());
    PostHog.__loaded && PostHog.reset();
  }

  static signUp(attributes: { email: string; password: string; fullName: string }) {
    return Auth.signUp({
      username: attributes.email,
      password: attributes.password,
      attributes: {
        email: attributes.email,
        name: attributes.fullName,
        "custom:admin_signup": "true",
      },
    });
  }

  static async signIn(email: string, password: string) {
    if (process.env.REACT_APP_SELF_HOSTED) {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/signIn`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          email,
          password,
        }),
      });

      if (!response.ok) {
        const error = new Error();

        if (response.status === 401) {
          const json = await response.json();
          error.message = json.error;
          throw error;
        } else {
          error.message = "Something went wrong";
          throw error;
        }
      }

      const { token } = await response.json();
      // 1. Save token to local storage
      localStorage.setItem("bkalerts_self_hosted_auth_token", token);

      // 2. Notify Hub
      const payload = JSON.parse(atob(token.split(".")[1]));
      Hub.dispatch("auth", {
        event: "signIn",
        data: {
          attributes: {
            email: payload.email,
            email_verified: true,
            name: payload.name,
          },
          signInUserSession: {
            idToken: {
              payload,
            },
          },
        },
      });

      return;
    }

    return Auth.signIn(email, password);
  }

  static completeNewPassword(user: any, password: string) {
    return Auth.completeNewPassword(user, password);
  }

  static forgotPassword(email: string) {
    return Auth.forgotPassword(email);
  }

  static forgotPasswordSubmit(username: string, code: string, password: string) {
    return Auth.forgotPasswordSubmit(username, code, password);
  }

  static changePassword(user: any, currentPassword: string, newPassword: string) {
    return Auth.changePassword(user, currentPassword, newPassword);
  }

  static async currentAuthenticatedUser(params?: CurrentUserOpts) {
    if (process.env.REACT_APP_SELF_HOSTED) {
      const currentToken = localStorage.getItem("bkalerts_self_hosted_auth_token");
      if (!currentToken) return null;

      const response = await fetch(`${process.env.REACT_APP_API_URL}/currentSession`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: currentToken,
        },
      });

      if (!response.ok) {
        localStorage.removeItem("bkalerts_self_hosted_auth_token");
        throw new Error("Invalid token");
      }

      const { token } = await response.json();
      localStorage.setItem("bkalerts_self_hosted_auth_token", token);

      const payload = JSON.parse(atob(token.split(".")[1]));

      return {
        attributes: {
          email: payload.email,
          email_verified: true,
          name: payload.name,
        },
        signInUserSession: {
          idToken: {
            payload,
          },
        },
      };
    }

    return Auth.currentAuthenticatedUser(params);
  }

  static async checkAuthentication() {
    try {
      console.log("[AuthService] checkAuthentication");
      const user = await this.currentAuthenticatedUser();
      this.onUserSignedIn(user);
      return user;
    } catch (error) {
      console.log("[AuthService] checkAuthentication error");
      console.log(error);
      this.onUserSignedOut();
      return null;
    }
  }

  static currentSession() {
    return Auth.currentSession();
  }

  static async getJwtToken() {
    if (process.env.REACT_APP_SELF_HOSTED) return localStorage.getItem("bkalerts_self_hosted_auth_token");

    try {
      const currentSession = await this.currentSession();

      return currentSession.getIdToken().getJwtToken();
    } catch (error) {
      return null;
    }
  }

  static async getInstalledApps(): Promise<string[]> {
    try {
      const currentAuthenticatedUser = await this.currentAuthenticatedUser({ bypassCache: true });

      return JSON.parse(currentAuthenticatedUser.signInUserSession.idToken.payload.installedApps) as string[];
    } catch (error) {
      return [];
    }
  }

  static logout() {
    console.log("[AuthService] logout");
    if (process.env.REACT_APP_SELF_HOSTED) {
      localStorage.removeItem("bkalerts_self_hosted_auth_token");

      Hub.dispatch("auth", {
        event: "signOut",
      });

      return;
    }

    return Auth.signOut();
  }
}

// @ts-ignore
window.AuthService = AuthService;
