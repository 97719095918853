import {
  House as HomeIcon,
  Activity as ActivityIcon,
  Upload as UploadIcon,
  Archive as ArchiveIcon,
  FileEarmarkPlus as FileNewIcon,
  Folder2Open as Folder2OpenIcon,
  Person as UserIcon,
  CreditCard as CreditCardIcon,
  Controller as SuperAdminIcon,
  PeopleFill as PeopleIcon,
  JournalText as AddressesIcon,
  WindowStack as AppStoreIcon,
  Files as FilesIcon,
} from "react-bootstrap-icons";

import Dashboard from "../../scenes/Dashboard";
import Import from "../../scenes/Import";
import TrackedDebtors from "../../scenes/TrackedDebtors";
import Matches from "../../scenes/Matches";
import EpocNew from "../../scenes/Dashboard/Epoc/New";
import BulkPOC from "../../scenes/Dashboard/Epoc/Bulk";
import EditBulkPOC from "../../scenes/Dashboard/Epoc/Bulk/Edit/Edit";
import SubmitBulkPOC from "../../scenes/Dashboard/Epoc/Bulk/Submit/Submit";
import Claim from "../../scenes/Dashboard/Epoc/Claim";
import FilingProfiles from "../../scenes/Dashboard/Epoc/FilingProfiles/FilingProfiles";
import Claims from "../../scenes/Dashboard/Epoc/Claims/Claims";
import SuperAdmin from "../../scenes/SuperAdmin/SuperAdmin";
import Account from "../../scenes/Settings/Profile/Account";
import Subscriptions from "../../scenes/Settings/Profile/Subscriptions";
import Cases from "../../scenes/Dashboard/Cases";
import SearchCases from "../../scenes/Dashboard/SearchCases";
import Case from "../../scenes/Dashboard/Case";
import AppStore from "../../scenes/Settings/Profile/AppStore";
import Connection from "../../scenes/Settings/Profile/AppStore/Connection";

import Roles from "../../utils/constants/roles";
import { Route, FeatureFlag } from "./types";

const privateRoutes: Route[] = [
  {
    id: "dashboard",
    path: "/",
    label: "Dashboard",
    component: Dashboard,
  },
  {
    id: "dashboard-alias",
    path: "/dashboard",
    label: "Dashboard",
    component: Dashboard,
    icon: HomeIcon,
    sidebar: true,
  },
  {
    id: "import",
    path: "/import",
    label: "Import",
    component: Import,
    icon: UploadIcon,
    sidebar: true,
    category: "Monitoring",
    accessibleBy: [Roles.SuperAdmin],
  },
  {
    id: "import-type",
    path: "/import/:type",
    label: "Import",
    component: Import,
    category: "Monitoring",
    accessibleBy: [Roles.SuperAdmin],
  },
  {
    id: "debtors",
    path: "/tracking",
    label: "Tracking",
    component: TrackedDebtors,
    icon: PeopleIcon,
    sidebar: true,
    category: "Monitoring",
    accessibleBy: [Roles.SuperAdmin],
  },
  {
    id: "matches",
    path: "/matches",
    label: "Matches",
    component: Matches,
    icon: ActivityIcon,
    sidebar: true,
    category: "Monitoring",
    disableContainerPadding: true,
    accessibleBy: [Roles.SuperAdmin],
  },
  {
    id: "search-cases",
    path: "/search-cases",
    label: "Search Cases",
    component: SearchCases,
  },
  {
    id: "cases",
    path: "/cases",
    label: "Cases",
    component: Cases,
    icon: ArchiveIcon,
    sidebar: true,
    category: "Case Lookup",
  },
  {
    id: "case",
    path: "/cases/:case_id",
    label: "Case",
    component: Case,
  },
  {
    id: "new-claim",
    path: "/epoc/new",
    label: "File New",
    component: EpocNew,
    icon: FileNewIcon,
    sidebar: true,
    disableContainerPadding: true,
    category: "Proof of Claim",
  },
  {
    id: "edit-claim",
    path: "/epoc/edit/:claim_id",
    label: "Edit Proof of Claim",
    component: EpocNew,
    disableContainerPadding: true,
    category: "Proof of Claim",
  },
  {
    id: "bulk-poc",
    path: "/epoc/bulk",
    label: "File in Bulk",
    component: BulkPOC,
    icon: FilesIcon,
    sidebar: true,
    category: "Proof of Claim",
    accessibleBy: [Roles.SuperAdmin],
  },
  {
    id: "edit-bulk-poc",
    path: "/epoc/bulk/:batch_id",
    label: "Edit Bulk POC",
    component: EditBulkPOC,
    disableContainerPadding: true,
    category: "Proof of Claim",
    accessibleBy: [Roles.SuperAdmin],
  },
  {
    id: "submit-bulk-poc",
    path: "/epoc/bulk/:batch_id/submit",
    label: "Submit Batch",
    component: SubmitBulkPOC,
    disableContainerPadding: true,
    category: "Proof of Claim",
    accessibleBy: [Roles.SuperAdmin],
  },
  {
    id: "claims",
    path: "/claims",
    label: "Your Claims",
    component: Claims,
    icon: Folder2OpenIcon,
    sidebar: true,
    category: "Proof of Claim",
  },
  {
    id: "address-book",
    path: "/epoc/filing-profiles",
    label: "Filing Profiles",
    component: FilingProfiles,
    icon: AddressesIcon,
    sidebar: true,
    category: "Proof of Claim",
    accessibleBy: [Roles.SuperAdmin],
  },
  {
    id: "claim",
    path: "/claims/:claim_id",
    label: "Proof of Claim",
    component: Claim,
    category: "Proof of Claim",
  },
  {
    id: "profile",
    path: "/profile",
    label: "Profile",
    component: Account,
    icon: UserIcon,
    sidebar: true,
    userMenu: true,
    category: "Settings",
  },
  {
    id: "subscriptions",
    path: "/subscriptions",
    label: "Subscriptions",
    component: Subscriptions,
    icon: CreditCardIcon,
    sidebar: true,
    userMenu: true,
    accessibleBy: [Roles.User, Roles.Admin, Roles.SuperAdmin],
    category: "Settings",
  },
  {
    id: "app-store",
    path: "/app-store",
    label: "App Store",
    component: AppStore,
    icon: AppStoreIcon,
    sidebar: true,
    category: "Settings",
  },
  {
    id: "app-store-connection",
    path: "/app-store/:app_id",
    label: "App Store",
    component: Connection,
    accessibleBy: [Roles.SuperAdmin],
    category: "Settings",
  },
  {
    id: "super-admin",
    path: "/super-admin",
    label: "Super Admin",
    component: SuperAdmin,
    icon: SuperAdminIcon,
    sidebar: true,
    accessibleBy: [Roles.SuperAdmin],
    category: "Settings",
  },
];

export default privateRoutes;

export function getSidebarRoutes({
  currentUser: { role, activeFeatures },
}: {
  currentUser: {
    role: Roles;
    activeFeatures: FeatureFlag[];
  };
}): (string | Route)[] {
  const filtered = privateRoutes.filter(function ({ sidebar = false, accessibleBy, requiredFeatureFlag }) {
    // not a sidebar route ignore
    if (!sidebar) return false;

    // if a feature flag is required, current user active features must include the feature flag or,
    // accessible by must be defined and current user role is included or,
    // if a feature flag is NOT required, check if accessible by is NOT defined or the current user role is included
    const accessible = requiredFeatureFlag
      ? activeFeatures.includes(requiredFeatureFlag) || (accessibleBy && accessibleBy.includes(role))
      : !accessibleBy || accessibleBy.includes(role);

    if (!accessible) {
      return false;
    }

    return true;
  });

  const result = [];
  let prevCategory: string | null = null;
  for (let route of filtered) {
    if (!route.category) {
      result.push(route);
      prevCategory = null;
      continue;
    }

    if (route.category !== prevCategory) {
      result.push(route.category);
      result.push(route);
      prevCategory = route.category;
    } else {
      result.push(route);
    }
  }

  return result;
}
