import { SelectedCase, Creditor, Signee } from "./steps/interfaces";
import { FormValues as Documentation } from "./steps/Documentation/interfaces";

export const WizardSteps = {
  SelectCase: "SelectCase",
  SelectCreditor: "SelectCreditor",
  Documentation: "Documentation",
  Sign: "Sign",
  SaveClaim: "SaveClaim",
} as const;

export interface FormState {
  loading: boolean;
  claimId?: string;
  claimUpdatedAt?: string;
  selectedFilingProfileId: string;
  filingProfiles: FilingProfile[];
  currentStep: keyof typeof WizardSteps;
  selectedCase?: SelectedCase;
  selectedCreditor?: Creditor;
  documentation?: Documentation;
  signee?: Signee;
  savingDraft: boolean;
  showStartOverModal: boolean;
  showPreviewModal: boolean;
}

export interface FilingProfile {
  id: string;
  name: string;
  raw_form_data: string;
  created_at: string;
}

/**
 * @deprecated
 */
export type ClaimAddress = {
  id: string;
  name: string;
  title: string | null;
  company: string | null;
  address_1: string;
  address_2: string | null;
  address_3: string | null;
  city: string;
  state: string;
  zip: string;
  zip4: string | null;
  phone: string | null;
  email: string | null;
  address_type: "creditor_address" | "payment_address" | "creditors_attorney_address" | "signee_address";
  created_at: string;
};
