export interface Address {
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  zip: string;
  zip4: string;
}

export interface Creditor {
  id: string | null;
  name: string;
  address: string[] | Address;
  phone: string;
  email: string;
}

export interface SelectedCase {
  court_code: string;
  case_no: string;
  case_title: string;
  creditor_name?: string;
  creditors: Creditor[];
  attachment_size_limit: number;
}

export enum SigneeRoles {
  Creditor = "creditor",
  CreditorAttorney = "creditor_attorney",
  Rule3004 = "rule_3004",
  Rule3005 = "rule_3005",
}

export interface Signee {
  id: string | null;
  role: SigneeRoles;
  signature: string;
  title: string;
  company: string;
  address: Address;
  phone: string;
  email: string;
  save_filing_profile: boolean;
  filing_profile_name: string | null;
}
