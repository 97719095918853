import { useCallback } from "react";
import { Pagination as BootStrapPagination, Form } from "react-bootstrap";

// Taken from https://gist.github.com/kottenator/9d936eb3e4e3c3e02598
export const calculatePagination = (currentPage, nrOfPages) => {
  let delta = 2;
  let range = [];
  let rangeWithDots = [];
  let l;
  range.push(1);
  if (nrOfPages <= 1) {
    return range;
  }

  for (let i = currentPage - delta; i <= currentPage + delta; i++) {
    if (i < nrOfPages && i > 1) {
      range.push(i);
    }
  }

  range.push(nrOfPages);
  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push("...");
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
};

export default function Pagination({ currentPage, totalPages, disabled, onClick }) {
  const onClickOnFirstPage = useCallback(() => onClick(1), [onClick]);
  const onClickOnPreviousPage = useCallback(() => onClick(currentPage - 1), [onClick, currentPage]);
  const onClickOnNextPage = useCallback(() => onClick(currentPage + 1), [onClick, currentPage]);
  const onClickOnLastPage = useCallback(() => onClick(totalPages), [onClick, totalPages]);

  if (!totalPages) {
    return null;
  }

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  return (
    <>
      <BootStrapPagination className="mb-0 d-none d-lg-flex">
        <BootStrapPagination.First disabled={isFirstPage || disabled} onClick={onClickOnFirstPage} />
        <BootStrapPagination.Prev disabled={isFirstPage || disabled} onClick={onClickOnPreviousPage} />

        {calculatePagination(currentPage, totalPages).map(function (page, index) {
          if (page === "...") {
            return <BootStrapPagination.Ellipsis key={`ellipsis-${index}`} />;
          }

          const isCurrentPage = page === currentPage;
          return (
            <BootStrapPagination.Item
              key={page}
              active={isCurrentPage}
              disabled={disabled}
              onClick={() => onClick(page)}
            >
              {page}
            </BootStrapPagination.Item>
          );
        })}

        <BootStrapPagination.Next disabled={isLastPage || disabled} onClick={onClickOnNextPage} />
        <BootStrapPagination.Last disabled={isLastPage || disabled} onClick={onClickOnLastPage} />
      </BootStrapPagination>

      <Form.Select
        className="d-block d-lg-none"
        id="pagination-mobile"
        onChange={(ev) => onClick(ev.target.value)}
        value={currentPage}
        style={{ paddingTop: "0.3rem", paddingBottom: "0.3rem" }}
      >
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page, index) => (
          <option key={index} value={page}>
            Page: {page}
          </option>
        ))}
      </Form.Select>
    </>
  );
}
