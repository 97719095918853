import gql from "graphql-tag";

const deleteTrackedCases = gql`
  mutation deleteTrackedCases($ids: [ID!]!) {
    deleteTrackedCases(ids: $ids) {
      ids
    }
  }
`;

export default deleteTrackedCases;
