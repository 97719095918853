import { Form, FormControlProps, Button, Row, Col, InputGroup } from "react-bootstrap";
import InputMask from "react-input-mask";
import { usePlacesWidget } from "react-google-autocomplete";

import { Debtor } from "../types";

interface Props {
  id: string;
  index: number;
  data: Debtor;
  errors: { [key in keyof Debtor]?: string };
  onChange: (id: string, key: keyof Debtor, value: string) => void;
  onRemove: (id: string) => void;
}

export default function InputRow({ id, index, data, errors, onChange, onRemove }: Props) {
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      console.log(place);

      if (place) {
        onChange(id, "address", place);
      }
    },
    options: {
      componentRestrictions: { country: "us" },
      types: ["address"],
    },
  });

  const addressParsedValue = typeof data.address === "string" ? data.address : data.address.formatted_address;

  return (
    <div className="mb-2 p-2 border-bottom bg-light bg-gradient">
      <Row>
        <Form.Group as={Col} sm={3} controlId={`${id}-ssn`} className="mb-2">
          <Form.Label className="mb-1">SSN</Form.Label>
          <InputMask
            mask="xxx-xx-xxxx"
            formatChars={{ x: "[x0-9]" }}
            value={data.ssn}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onChange(id, "ssn", ev.target.value)}
          >
            {(inputProps: FormControlProps) => (
              <Form.Control
                {...inputProps}
                placeholder="xxx-xx-1234"
                size="sm"
                htmlSize={"xxx-xx-1234".length + 1}
                isInvalid={!!errors.ssn}
              />
            )}
          </InputMask>
          <Form.Control.Feedback type="invalid">{errors.ssn}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} sm={3} controlId={`${id}-taxid`} className="mb-2">
          <Form.Label className="mb-1">Tax ID</Form.Label>
          <InputMask
            mask="99-9999999"
            value={data.taxid}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onChange(id, "taxid", ev.target.value)}
          >
            {(inputProps: FormControlProps) => (
              <Form.Control
                {...inputProps}
                placeholder="12-3456789"
                size="sm"
                htmlSize={"12-3456789".length + 1}
                isInvalid={!!errors.taxid}
              />
            )}
          </InputMask>
          <Form.Control.Feedback type="invalid">{errors.taxid}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} sm={3} controlId={`${id}-lastName`} className="mb-2">
          <Form.Label className="mb-1">Last / Company name</Form.Label>
          <Form.Control
            type="text"
            size="sm"
            placeholder="Doe / Acme Inc."
            value={data.lastName}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onChange(id, "lastName", ev.target.value)}
            isInvalid={!!errors.lastName}
          />
          <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} sm={3} controlId={`${id}-address`} className="mb-2">
          <Form.Label className="mb-1">Address</Form.Label>
          <Form.Control
            ref={ref}
            type="text"
            size="sm"
            htmlSize={40}
            value={addressParsedValue}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onChange(id, "address", ev.target.value)}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} sm={3} controlId={`${id}-firstName`} className="mb-2">
          <Form.Label className="mb-1">First name</Form.Label>
          <Form.Control
            type="text"
            size="sm"
            placeholder="John"
            value={data.firstName}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onChange(id, "firstName", ev.target.value)}
          />
        </Form.Group>

        <Form.Group as={Col} sm={3} controlId={`${id}-middleName`} className="mb-2">
          <Form.Label className="mb-1">Middle name</Form.Label>
          <Form.Control
            type="text"
            size="sm"
            placeholder="Fitzgerald"
            value={data.middleName}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onChange(id, "middleName", ev.target.value)}
          />
        </Form.Group>

        <Form.Group as={Col} sm={3} controlId={`${id}-generation`} className="mb-2">
          <Form.Label className="mb-1">Generation</Form.Label>
          <Form.Control
            type="text"
            size="sm"
            htmlSize={5}
            placeholder="Jr."
            value={data.generation}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onChange(id, "generation", ev.target.value)}
          />
        </Form.Group>

        <Form.Group as={Col} sm={3} controlId={`${id}-phone`} className="mb-2">
          <Form.Label className="mb-1">Phone</Form.Label>
          <InputGroup size="sm">
            <InputGroup.Text>+1</InputGroup.Text>
            <InputMask
              mask="(999) 999-9999"
              value={data.phone}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onChange(id, "phone", ev.target.value)}
            >
              {(inputProps: FormControlProps) => (
                <Form.Control
                  {...inputProps}
                  placeholder="(555) 123-4567"
                  size="sm"
                  htmlSize={"(555) 123-4567".length + 1}
                />
              )}
            </InputMask>
          </InputGroup>
        </Form.Group>
      </Row>

      {index > 0 && (
        <Form.Group>
          <Button type="button" variant="link" className="ms-auto px-0" onClick={() => onRemove(id)}>
            Remove row
          </Button>
        </Form.Group>
      )}
    </div>
  );
}
