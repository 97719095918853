import gql from "graphql-tag";

const listClaimBatches = gql`
  query listClaimBatches($page_size: Int!, $page_number: Int!) {
    listClaimBatches(page_size: $page_size, page_number: $page_number) {
      batches {
        id
        name
        no_of_claims
        status
        created_at
        updated_at
      }
      pagination {
        total_records_count
        total_pages
      }
    }
  }
`;

export default listClaimBatches;
