import { useState, useCallback } from "react";
import classNames from "classnames";
import { Form, Button } from "react-bootstrap";
import {
  Upload as UploadIcon,
  Download as DownloadIcon,
  X as XIcon,
  FileEarmarkCheck as FileEarmarkCheckIcon,
  FileEarmarkPlus as SelectFileIcon,
} from "react-bootstrap-icons";

import "./FileDropArea.scss";

type AcceptedFileExtensions = ".csv" | ".xlsx" | ".pdf";
type AcceptedMimeTypes =
  | "text/csv"
  | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  | "application/pdf";
type FileTypeLabel = "CSV" | "Excel" | "PDF";

export default function FileDropArea({
  id,
  sampleFileURL,
  accept,
  mimeType,
  fileTypeLabel,
  onFilesSelected,
  showDownloadSampleButton = true,
  multiple = false,
}: {
  id: string;
  sampleFileURL?: string;
  accept: AcceptedFileExtensions;
  mimeType: AcceptedMimeTypes;
  fileTypeLabel: FileTypeLabel;
  onFilesSelected: (files: File[]) => void;
  showDownloadSampleButton?: boolean;
  multiple?: boolean;
}) {
  const [dragOver, setDragOver] = useState(false);
  const [error, showError] = useState<"invalid-file-type" | "multiple-files" | null>(null);
  const errorsMapping = {
    "invalid-file-type": `Only ${fileTypeLabel} files are allowed`,
    "multiple-files": `You can upload only one ${fileTypeLabel} file at a time`,
  };

  const onDrop = useCallback(
    (ev: React.DragEvent<HTMLDivElement>) => {
      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();
      setDragOver(false);

      let files: File[] = [];

      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        Array.from(ev.dataTransfer.items).forEach((item) => {
          // If dropped items aren't files, reject them
          if (item.kind !== "file") {
            return;
          }

          const file = item.getAsFile();
          if (file) {
            files.push(file);
          }
        });
      } else {
        // Use DataTransfer interface to access the file(s)
        Array.from(ev.dataTransfer.files).forEach((file) => {
          files.push(file);
        });
      }

      if (!multiple && files.length > 1) {
        showError("multiple-files");
        return;
      }

      if (multiple && files.some((selectedFile) => selectedFile.type !== mimeType)) {
        showError("invalid-file-type");
        return;
      }

      if (!multiple && files[0].type !== mimeType) {
        showError("invalid-file-type");
        return;
      }

      showError(null);
      onFilesSelected(files);
    },
    [onFilesSelected, mimeType, multiple]
  );

  const onDragOver = useCallback((ev: React.DragEvent<HTMLDivElement>) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    setDragOver(true);
  }, []);

  const onChangeFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const files = Array.from(event.target.files);

      if (!multiple && files.length > 1) {
        showError("multiple-files");
        return;
      }

      if (multiple && files.some((selectedFile) => selectedFile.type !== mimeType)) {
        showError("invalid-file-type");
        return;
      }

      if (!multiple && files[0].type !== mimeType) {
        showError("invalid-file-type");
        return;
      }

      showError(null);

      onFilesSelected(files);
    },
    [multiple, mimeType, onFilesSelected]
  );

  const onDragLeave = useCallback(() => {
    setDragOver(false);
  }, []);

  return (
    <div
      className={classNames(
        "file-drop-area p-3 d-flex flex-column align-items-center justify-content-center gap-3 flex-grow-1",
        {
          over: dragOver,
          error: error && !dragOver,
        }
      )}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
    >
      {dragOver ? (
        <FileEarmarkCheckIcon size={40} className="text-primary" />
      ) : error ? (
        <XIcon size={40} className="text-danger" />
      ) : (
        <UploadIcon size={40} />
      )}

      <p
        className={classNames("lead mb-0", {
          "text-danger": error && !dragOver,
          "text-primary": dragOver,
        })}
      >
        {dragOver
          ? "You almost made it, release the file here"
          : error
          ? errorsMapping[error]
          : `Drag ${fileTypeLabel === "Excel" ? "an" : "a"} ${fileTypeLabel} file here or upload via the button below.`}
      </p>

      <Form.Control
        id={`file-input-for-${id}`}
        type="file"
        className="d-none"
        onChange={onChangeFile}
        accept={accept}
        multiple={multiple}
      />
      <Form.Label
        htmlFor={`file-input-for-${id}`}
        className="btn btn-primary btn-lg d-flex align-items-center gap-2 mb-0"
      >
        <SelectFileIcon size={20} />
        Select file
      </Form.Label>

      {showDownloadSampleButton && sampleFileURL && (
        <Button as="a" href={sampleFileURL} variant="success" className="d-flex align-items-center gap-2">
          <DownloadIcon size={16} />
          Download Sample {fileTypeLabel}
        </Button>
      )}
    </div>
  );
}
