import * as types from "../constants";

const initialState = {
  name: null,
  companyName: null,
  email: null,
  isAuthenticated: false,
  organizationId: null,
  redirectAfterLogin: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SIGNED_IN:
      return {
        ...state,
        name: action.name,
        companyName: action.companyName,
        email: action.email,
        isAuthenticated: action.isAuthenticated,
        organizationId: action.organizationId,
      };

    case types.SIGNED_OUT:
      return {
        ...state,
        name: action.name,
        companyName: action.companyName,
        email: action.email,
        isAuthenticated: action.isAuthenticated,
      };

    case types.CHANGE_NAME:
      return {
        ...state,
        name: action.name,
      };

    case types.SET_REDIRECT_AFTER_LOGIN:
      return {
        ...state,
        redirectAfterLogin: action.path,
      };

    default:
      return state;
  }
}
