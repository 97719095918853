import { useContext } from "react";
import classnames from "classnames";
import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import UserContext from "../../Routing/components/UserContext";
import Roles from "../../../utils/constants/roles";
import privateRoutes, { getSidebarRoutes } from "../../Routing/privateRoutes";

import "./Sidebar.scss";

export default function Sidebar({ collapsed }: { collapsed: boolean }) {
  const { user } = useContext(UserContext);
  const jwtData = user.signInUserSession.idToken.payload;
  const { role, active } = jwtData;
  const activeFeatures = JSON.parse(jwtData.features);
  // for testing purposes
  // const active = "false";
  const caseRoles = [Roles.User, Roles.Admin, "none"];

  let sidebarRoutes = getSidebarRoutes({
    currentUser: { role, activeFeatures },
  });

  // Todo Mirko: pre token generation inject active, but claims to override has only k-v pairs of string->string
  if (active === "false" && caseRoles.includes(role)) {
    sidebarRoutes = [privateRoutes.find((route) => route.id === "subscriptions")!];
  }

  return (
    <div
      className={classnames("app-sidebar sidebar shadow-sm", {
        "sidebar-collapsed": collapsed,
      })}
    >
      <Nav variant="pills" className="flex-column">
        {sidebarRoutes.map((route) => {
          if (typeof route === "string") {
            if (collapsed) {
              return <hr key={route} className="my-0" />;
            }

            return (
              <Nav.Item key={route} className="sidebar-category-label">
                {route}
              </Nav.Item>
            );
          }

          const { id, path, icon: Icon, label } = route;

          const navItem = (
            <Nav.Item key={id}>
              <NavLink to={path} className="sidebar-link">
                {Icon && <Icon size={20} />}
                {!collapsed && <span>{label}</span>}
              </NavLink>
            </Nav.Item>
          );

          if (collapsed) {
            return (
              <OverlayTrigger
                key={id}
                placement="right"
                overlay={(props) => (
                  <Tooltip id={`sidebar-tooltip-${label}`} {...props}>
                    {label}
                  </Tooltip>
                )}
              >
                {navItem}
              </OverlayTrigger>
            );
          }

          return navItem;
        })}
      </Nav>
    </div>
  );
}
