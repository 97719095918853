import { useEffect } from "react";
import { Modal, Button, Form as BSForm, OverlayTrigger, Tooltip, Spinner, Table, Alert } from "react-bootstrap";
import { useLocalObservable, Observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import { QuestionCircleFill as HelpIcon } from "react-bootstrap-icons";
import { Form, Field } from "react-final-form";
import gql from "graphql-tag";
import { toast } from "react-toastify";

import districts from "../Epoc/Bulk/Edit/districts";
import { apolloClient } from "../../../configs/apollo-client";
import { somethingWentWrong } from "../../../lib/errors";

import searchByCaseNumber from "./graphql/searchByCaseNumber";

const caseNumberHelpText = (
  <Tooltip>
    <div className="text-start">
      <p className="mb-2">Case numbers can be entered in any of the following formats:</p>
      <ul className="ms-4 p-0 mb-2">
        <li>yy-nnnnn</li>
        <li>yy-tp-nnnnn</li>
        <li>yy tp nnnnn</li>
        <li>yytpnnnnn</li>
        <li>o:yy-nnnnn</li>
        <li>o:yy-tp-nnnnn</li>
        <li>o:yy tp nnnnn</li>
        <li>o:yytpnnnnn</li>
      </ul>
      <table>
        <tbody>
          <tr>
            <td>o</td>
            <td>office where the case was filed (1 digit)</td>
          </tr>
          <tr>
            <td>yy</td>
            <td>case year (2 or 4 digits)</td>
          </tr>
          <tr>
            <td>tp</td>
            <td>case type (up to 2 characters)</td>
          </tr>
          <tr>
            <td>nnnnn</td>
            <td>case number (up to 5 digits)</td>
          </tr>
        </tbody>
      </table>
    </div>
  </Tooltip>
);

interface IProps {
  show: boolean;
  onClose: () => void;
  onAddCase: (caseId: string) => void;
}
export default function AddCaseModal({ show, onClose, onAddCase }: IProps) {
  const state = useLocalObservable(() => ({
    isSearching: false,
    searchResults: undefined as
      | {
          case_no: string;
          case_title: string;
          chapter: string;
          court_code: string;
          date_filed: string;
          date_closed: string;
        }[]
      | undefined,
    selectedCase: undefined as { court_code: string; case_no: string } | undefined,
    isAddingCase: false,
    searchByCaseNumber: async function (court_code: string, case_no: string) {
      try {
        this.isSearching = true;
        this.searchResults = undefined;
        this.selectedCase = undefined;

        const { data } = await apolloClient.query({
          query: searchByCaseNumber,
          variables: {
            court_code,
            case_no,
          },
          fetchPolicy: "network-only",
        });

        this.searchResults = data.searchByCaseNumber;
      } catch (error) {
        console.log(error);
        toast.error(somethingWentWrong);
      } finally {
        this.isSearching = false;
      }
    },
    addCase: async function (court_code: string, case_number: string) {
      try {
        this.isAddingCase = true;

        const {
          data: {
            addCase: { id: newCaseId },
          },
        } = await apolloClient.mutate({
          mutation: gql`
            mutation addCase($input: AddCaseInput!) {
              addCase(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: {
              court_code,
              case_number,
            },
          },
        });

        onAddCase(newCaseId);
      } catch (error) {
        console.log(error);
        this.isAddingCase = false;
        toast.error(somethingWentWrong);
      }
    },
  }));

  useEffect(() => {
    // Reset state when modal is closed
    if (!show) {
      runInAction(() => {
        state.isSearching = false;
        state.searchResults = undefined;
        state.selectedCase = undefined;
        state.isAddingCase = false;
      });
    }
  });

  const required = (value: string) => (value ? undefined : "Required");
  // Check a minimum length of 4 characters for the case no format "yy-n"
  const requiredAndShouldHaveAMinLength = (value: string) =>
    required(value) === undefined && value.length >= 4 ? undefined : "Required";

  function onSubmit(values: { court_code: string; case_no: string }) {
    state.searchByCaseNumber(values.court_code, values.case_no);
  }

  return (
    <Observer>
      {() => (
        <Modal
          show={show}
          onHide={onClose}
          size="lg"
          {...(state.isSearching || state.isAddingCase ? { backdrop: "static", keyboard: false } : {})}
        >
          {state.isAddingCase ? (
            <Modal.Body className="text-center">
              <h2 className="mb-3">Downloading case docket</h2>
              <Spinner animation="border" variant="primary" />
            </Modal.Body>
          ) : (
            <>
              <Modal.Header closeButton={!state.isSearching}>
                <Modal.Title>Search Case to track</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit, form }) => (
                    <BSForm onSubmit={handleSubmit}>
                      <Field name="court_code" validate={required}>
                        {({ input, meta }) => (
                          <BSForm.Group controlId="add-case-select-jurisdiction" className="mb-3">
                            <BSForm.Label>Jurisdiction</BSForm.Label>

                            <BSForm.Select
                              disabled={state.isSearching}
                              isInvalid={meta.error && meta.touched}
                              {...input}
                            >
                              <option value="">Choose...</option>

                              {districts.map((district) => {
                                const districtName = district.name
                                  .toLowerCase()
                                  .split(" ")
                                  .map((fragment) => `${fragment.slice(0, 1).toUpperCase()}${fragment.slice(1)}`)
                                  .join(" ");

                                return (
                                  <option key={district.court_code} value={district.court_code}>
                                    {districtName}
                                  </option>
                                );
                              })}
                            </BSForm.Select>

                            <BSForm.Control.Feedback type="invalid">
                              {meta.error && meta.touched && "Please select a jurisdiction"}
                            </BSForm.Control.Feedback>
                          </BSForm.Group>
                        )}
                      </Field>

                      <Field name="case_no" validate={requiredAndShouldHaveAMinLength}>
                        {({ input, meta }) => (
                          <BSForm.Group controlId="add-case-case-number" className="mb-3">
                            <BSForm.Label className="d-flex align-items-center gap-1">
                              Case Number
                              <OverlayTrigger
                                placement="right"
                                trigger={["hover", "focus"]}
                                overlay={caseNumberHelpText}
                              >
                                <HelpIcon size="16" className="text-primary" />
                              </OverlayTrigger>
                            </BSForm.Label>

                            <BSForm.Control
                              type="text"
                              disabled={state.isSearching}
                              isInvalid={meta.error && meta.touched}
                              {...input}
                            />

                            <BSForm.Control.Feedback type="invalid">
                              {meta.error && meta.touched && "Please enter a case number"}
                            </BSForm.Control.Feedback>
                          </BSForm.Group>
                        )}
                      </Field>

                      <BSForm.Group className="d-flex align-items-center gap-2">
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={state.isSearching}
                          className="d-flex align-items-center gap-1"
                        >
                          {state.isSearching ? (
                            <>
                              <Spinner animation="border" size="sm" />
                              <span>Searching...</span>
                            </>
                          ) : (
                            "Search"
                          )}
                        </Button>

                        <Button
                          type="button"
                          variant="light"
                          onClick={() => {
                            state.searchResults = undefined;
                            form.reset();
                          }}
                          disabled={state.isSearching}
                        >
                          Reset
                        </Button>
                      </BSForm.Group>
                    </BSForm>
                  )}
                />

                {state.searchResults && state.searchResults.length > 0 && (
                  <>
                    <Table hover size="sm" responsive className="mt-3">
                      <thead className="table-light text-nowrap">
                        <tr>
                          <th className="min-content-width"></th>
                          <th>Case no</th>
                          <th>Case title</th>
                          <th>Chapter</th>
                          <th>Date filed</th>
                          <th>Date closed</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.searchResults.map((result, index) => {
                          // Use explicit check with ternary, need a real boolean value not true | undefined
                          const selected = state.selectedCase ? state.selectedCase.case_no === result.case_no : false;

                          return (
                            <tr
                              key={index}
                              role="button"
                              onClick={() => {
                                state.selectedCase = {
                                  court_code: result.court_code,
                                  case_no: result.case_no,
                                };
                              }}
                              className={selected ? "table-primary" : ""}
                            >
                              <td>
                                <BSForm.Check
                                  type="radio"
                                  className="pe-none"
                                  name="add-case-select-record"
                                  checked={selected}
                                  readOnly
                                />
                              </td>
                              <td>{result.case_no}</td>
                              <td>{result.case_title}</td>
                              <td>{result.chapter}</td>
                              <td>{result.date_filed}</td>
                              <td>{result.date_closed}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>

                    <Button
                      type="button"
                      variant="primary"
                      disabled={state.selectedCase === undefined}
                      onClick={() => {
                        state.addCase(state.selectedCase!.court_code, state.selectedCase!.case_no);
                      }}
                    >
                      Add to your cases
                    </Button>
                  </>
                )}

                {state.searchResults && state.searchResults.length === 0 && (
                  <Alert variant="danger" className="mt-3 mb-0">
                    <div className="alert-message">No cases found. Please refine your search.</div>
                  </Alert>
                )}
              </Modal.Body>
            </>
          )}
        </Modal>
      )}
    </Observer>
  );
}
