import classNames from "classnames";
import { Form, Row, Col } from "react-bootstrap";
import { UseFormRegister, UseFormSetValue, Controller, Control, FieldErrors } from "react-hook-form";
import { usePlacesWidget } from "react-google-autocomplete";

import { FormValues } from "../interfaces";
import states from "../../lib/states.json";

interface GoogleMapsAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface Props {
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  control: Control<FormValues>;
}
export default function PaymentAddress({ register, errors, setValue, control }: Props) {
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      const addressComponents: GoogleMapsAddressComponent[] = place.address_components;
      console.log("addressComponents", addressComponents);
      if (!addressComponents) {
        return;
      }

      // Reference
      // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
      const address1 = [
        addressComponents.find(({ types }) => types.includes("street_number"))?.long_name || "",
        addressComponents.find(({ types }) => types.includes("route"))?.long_name || "",
      ]
        .filter(Boolean)
        .join(" ");
      const city =
        addressComponents.find(({ types }) => types.includes("locality"))?.long_name ||
        addressComponents.find(({ types }) => types.includes("neighborhood") && types.includes("political"))
          ?.long_name ||
        "";
      const state =
        addressComponents.find(({ types }) => types.includes("administrative_area_level_1"))?.short_name || "";
      const zip = addressComponents.find(({ types }) => types.includes("postal_code"))?.long_name || "";
      const zip4 = addressComponents.find(({ types }) => types.includes("postal_code_suffix"))?.long_name || "";

      // according to docs more verbose but more performant than overwriting entire object field
      setValue("payment_address.address.address1", address1, {
        shouldValidate: true,
      });
      setValue("payment_address.address.city", city, {
        shouldValidate: true,
      });
      setValue("payment_address.address.state", state, {
        shouldValidate: true,
      });
      setValue("payment_address.address.zip", zip, {
        shouldValidate: true,
      });
      setValue("payment_address.address.zip4", zip4, {
        shouldValidate: true,
      });
    },
    options: {
      componentRestrictions: { country: "us" },
      types: ["address"],
    },
  });

  return (
    <div id="payment_address-container">
      <Form.Group className="mb-3" controlId="payment-address-name">
        <Form.Label>
          Name <span className="text-danger">(required)</span>
        </Form.Label>

        <Form.Control
          type="text"
          maxLength={50}
          isInvalid={!!errors.payment_address?.name?.message}
          {...register("payment_address.name")}
        />

        <Form.Control.Feedback type="invalid">{errors.payment_address?.name?.message}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor="payment-address-address-1">
          Address where payments should be sent <span className="text-danger">(required)</span>
        </Form.Label>

        <Controller
          name="payment_address.address.address1"
          control={control}
          render={({ field }) => (
            <Form.Control
              id="payment-address-address-1"
              type="text"
              placeholder="123 Main St"
              className="mb-1 mw-100"
              isInvalid={!!errors.payment_address?.address?.address1?.message}
              htmlSize={40}
              {...field}
              // @ts-ignore
              ref={ref}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
            />
          )}
        />

        <Form.Control
          type="text"
          placeholder="Apartment, studio, or floor"
          className="mb-1 mw-100"
          htmlSize={40}
          {...register("payment_address.address.address2")}
        />

        <Form.Control
          type="text"
          placeholder="c/o"
          className="mw-100"
          htmlSize={40}
          {...register("payment_address.address.address3")}
        />

        <Form.Control.Feedback type="invalid">
          {errors.payment_address?.address?.address1?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Row className="mb-3 gx-0 gap-3">
        <Form.Group as={Col} sm="auto" controlId="payment-address-address-city">
          <Form.Label>City</Form.Label>

          <Form.Control
            maxLength={30}
            isInvalid={!!errors.payment_address?.address?.city?.message}
            {...register("payment_address.address.city")}
          />

          <Form.Control.Feedback type="invalid">{errors.payment_address?.address?.city?.message}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} sm="auto" controlId="payment-address-address-state">
          <Form.Label>State</Form.Label>

          <Form.Select
            isInvalid={!!errors.payment_address?.address?.state?.message}
            {...register("payment_address.address.state")}
          >
            <option value="">Choose...</option>
            {states.map(function ({ name, abbreviation }) {
              return (
                <option key={abbreviation} value={abbreviation}>
                  {name} ({abbreviation})
                </option>
              );
            })}
          </Form.Select>

          <Form.Control.Feedback type="invalid">
            {errors.payment_address?.address?.state?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} sm="auto">
          <Form.Label htmlFor="payment-address-address-zip-5">Zip</Form.Label>

          <div
            className={classNames("d-flex gap-1 align-items-center", {
              "is-invalid":
                !!errors.payment_address?.address?.zip?.message || !!errors.payment_address?.address?.zip4?.message,
            })}
          >
            <Form.Control
              placeholder="12345"
              id="payment-address-address-zip-5"
              maxLength={5}
              htmlSize={5}
              inputMode="numeric"
              isInvalid={!!errors.payment_address?.address?.zip?.message}
              {...register("payment_address.address.zip")}
            />
            -
            <Form.Control
              placeholder="1234"
              maxLength={4}
              htmlSize={4}
              inputMode="numeric"
              isInvalid={!!errors.payment_address?.address?.zip4?.message}
              {...register("payment_address.address.zip4")}
            />
          </div>

          <Form.Control.Feedback type="invalid">
            {errors.payment_address?.address?.zip?.message || errors.payment_address?.address?.zip4?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" controlId="payment-address-phone-number">
        <Form.Label>Telephone number</Form.Label>

        <Form.Control
          type="text"
          placeholder="818-252-9950"
          maxLength={20}
          inputMode="tel"
          {...register("payment_address.phone")}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="payment-address-email">
        <Form.Label>Email</Form.Label>

        <Form.Control
          type="email"
          placeholder="johndoe@company.com"
          isInvalid={!!errors.payment_address?.email?.message}
          maxLength={40}
          inputMode="email"
          {...register("payment_address.email")}
        />

        <Form.Control.Feedback type="invalid">{errors.payment_address?.email?.message}</Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}
