import { GoogleMapsAddressComponent } from "./types";

export function validateTaxId(taxid: string) {
  const re = /\d{2}-\d{7}/gi;
  return taxid.length === 10 && re.test(taxid);
}

export function validateSSN(ssn: string) {
  return ssn.match(/[0-9x]{3}-[0-9x]{2}-[0-9]{4}/g);
}

interface SerializedAddress {
  "Address line 1": string;
  "Address line 2": string;
  "Address line 3": string;
  City: string;
  State: string;
  "Zip code": string;
  Country: string;
}
export function serializeAddressField(addressComponents: string | GoogleMapsAddressComponent[]): SerializedAddress {
  if (typeof addressComponents === "string") {
    return {
      "Address line 1": addressComponents,
      "Address line 2": "",
      "Address line 3": "",
      City: "",
      State: "",
      "Zip code": "",
      Country: "",
    };
  }

  return addressComponents.reduce(
    (acc, { long_name, short_name, types }) => {
      if (types.includes("street_number")) {
        acc["Address line 1"] = [long_name, acc["Address line 1"]].filter(Boolean).join(" ");
      } else if (types.includes("route")) {
        acc["Address line 1"] = [acc["Address line 1"], short_name].filter(Boolean).join(" ");
      } else if (types.includes("locality")) {
        acc["Address line 2"] = long_name;
      } else if (types.includes("administrative_area_level_2")) {
        acc["City"] = long_name;
      } else if (types.includes("administrative_area_level_1")) {
        acc["State"] = short_name;
      } else if (types.includes("postal_code")) {
        acc["Zip code"] = long_name;
      } else if (types.includes("country")) {
        acc["Country"] = short_name;
      }

      return acc;
    },
    {
      "Address line 1": "",
      "Address line 2": "",
      "Address line 3": "",
      City: "",
      State: "",
      "Zip code": "",
      Country: "",
    } as SerializedAddress
  );
}
