import gql from "graphql-tag";

const listOrganizationPurchases = gql`
  query listOrganizationPurchases($input: OrganizationPurchasesInput!) {
    listOrganizationPurchases(input: $input) {
      page {
        total_records_count
        total_pages
      }
      data {
        id
        purchase_type
        case_id
        expire_at
        docket_sequence_number
        docket_document_number
        created_at
        deleted_at
        court_code
        case_number
      }
    }
  }
`;

export default listOrganizationPurchases;
