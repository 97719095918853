import { useState, useEffect } from "react";
import { Card, Spinner, Table, Badge } from "react-bootstrap";
import { toast } from "react-toastify";
import { Download as DownloadIcon } from "react-bootstrap-icons";

import { apolloClient } from "../../configs/apollo-client";
import { somethingWentWrong } from "../../lib/errors";

import listImportJobs from "./graphql/listImportJobs";

type ImportJob = {
  id: string;
  filename: string;
  csv_file_download_link: string;
  records_count: number;
  invalid_records_count: number;
  csv_errors_file_download_link: string;
  job_status: "pending" | "finished";
  created_at: string;
  updated_at: string;
};

export default function ImportJobs() {
  const [isLoading, setIsLoading] = useState(true);
  const [importJobs, setImportJobs] = useState<ImportJob[]>([]);

  useEffect(() => {
    const watchedQuery = apolloClient.watchQuery({
      query: listImportJobs,
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        const listImportJobsResponse: ImportJob[] = response.data.listImportJobs;

        setImportJobs(listImportJobsResponse);
        setIsLoading(false);
      },
      error(err) {
        console.log("[watchQuery] error", err);
        toast.error(somethingWentWrong);
        setIsLoading(false);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, []);

  if (isLoading) {
    return (
      <Card.Body className="d-flex justify-content-center">
        <Spinner animation="border" variant="primary" />
      </Card.Body>
    );
  }

  return (
    <Table hover responsive size="sm">
      <thead className="table-light shadow-lg text-nowrap">
        <tr>
          <th>Filename</th>
          <th>Status</th>
          <th>Imported records</th>
          <th>Invalid records</th>
          <th>Total records count</th>
          <th>Created at</th>
        </tr>
      </thead>
      <tbody>
        {importJobs &&
          importJobs.map((job) => (
            <tr key={job.id}>
              <td>
                <div className="d-flex align-items-center gap-2">
                  {job.filename}
                  <a
                    href={job.csv_file_download_link}
                    download
                    title="Download"
                    className="btn btn-outline-primary btn-sm ms-auto d-flex align-items-center"
                  >
                    <DownloadIcon size="16" />
                  </a>
                </div>
              </td>
              <td>
                <Badge bg={job.job_status === "finished" ? "success" : "primary"} className="text-capitalize">
                  {job.job_status}
                </Badge>
              </td>
              <td>
                {job.records_count !== null &&
                  (job.records_count - (job.invalid_records_count || 0)).toLocaleString("en-US")}
              </td>
              <td>
                <div className="d-flex align-items-center">
                  {(job.invalid_records_count || 0).toLocaleString("en-US")}
                  {job.csv_errors_file_download_link && (
                    <a
                      href={job.csv_errors_file_download_link}
                      download
                      title="Download"
                      className="btn btn-outline-primary btn-sm ms-auto d-flex align-items-center"
                    >
                      <DownloadIcon size="16" />
                    </a>
                  )}
                </div>
              </td>
              <td>{job.records_count !== null && job.records_count.toLocaleString("en-US")}</td>
              <td>
                {new Date(job.created_at).toLocaleDateString()}
                <br />
                {new Date(job.created_at).toLocaleTimeString()}
              </td>
            </tr>
          ))}
        {importJobs && importJobs.length === 0 && (
          <tr>
            <td colSpan={6}>Looks like you haven't imported any data yet.</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
