import gql from "graphql-tag";

const deleteBatchClaims = gql`
  mutation deleteBatchClaims($batch_id: ID!, $claim_ids: [ID]!) {
    deleteBatchClaims(batch_id: $batch_id, claim_ids: $claim_ids) {
      error
      error_code
    }
  }
`;

export default deleteBatchClaims;
