import gql from "graphql-tag";

const attachIOTPolicy = gql`
  mutation attachIOTPolicy($identity_id: ID!) {
    attachIOTPolicy(identity_id: $identity_id) {
      result
    }
  }
`;

export default attachIOTPolicy;
