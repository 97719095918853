import gql from "graphql-tag";

const createBatchPackages = gql`
  mutation createBatchPackages($batch_id: ID!) {
    createBatchPackages(batch_id: $batch_id) {
      error
      error_code
      zip_files {
        filename
        s3_key
        url
      }
    }
  }
`;

export default createBatchPackages;
