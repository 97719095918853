import { useEffect } from "react";
import { Row, Col, Form, Button, Alert, InputGroup, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { Eye as ShowPasswordIcon, EyeSlash as HidePasswordIcon } from "react-bootstrap-icons";
import { useLocalObservable, Observer } from "mobx-react-lite";
import { toast } from "react-toastify";

import { apolloClient } from "../../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../../lib/errors";
import getPACERCredentials from "./getPACERCredentials";
import savePACERCredentials from "./savePACERCredentials";

export default function PACERCredentials() {
  const state = useLocalObservable(() => ({
    isLoading: false,
    isSaving: false,
    showPassword: false,
    username: "",
    password: "",
    getPACERCredentials: function* () {
      try {
        this.isLoading = true;

        const {
          data: { getPACERCredentials: result },
        }: {
          data: { getPACERCredentials: { error: string | null; error_code: string | null; username: string | null } };
        } = yield apolloClient.query({
          query: getPACERCredentials,
          fetchPolicy: "network-only",
        });

        if (result.error_code === "SOMETHING_WENT_WRONG") {
          toast.error(somethingWentWrong);
        } else if (result.error_code === "CREDENTIALS_NOT_FOUND") {
          this.username = "";
          this.password = "";
        } else if (result.username) {
          this.username = result.username;
        }
      } catch (error) {
        console.log("[query] error");
        console.log(error);

        toast.error(somethingWentWrong);
      } finally {
        this.isLoading = false;
      }
    },
    savePACERCredentials: function* () {
      try {
        const password = this.password;
        this.isSaving = true;
        this.password = "";

        const {
          data: { savePACERCredentials: result },
        }: {
          data: { savePACERCredentials: { error: string | null; error_code: string | null; username: string | null } };
        } = yield apolloClient.mutate({
          mutation: savePACERCredentials,
          variables: {
            username: this.username,
            password: password,
          },
          fetchPolicy: "no-cache",
        });

        if (result.error_code === "SOMETHING_WENT_WRONG") {
          toast.error(somethingWentWrong);
        } else if (result.error_code === "INVALID_CREDENTIALS") {
          toast.error(result.error);
        } else if (result.username) {
          this.username = result.username;
          toast.success("PACER credentials saved successfully!");
        }
      } catch (error) {
        console.log("[mutation] error");
        console.log(error);

        toast.error(somethingWentWrong);
      } finally {
        this.isSaving = false;
      }
    },
  }));

  useEffect(() => {
    const promise = state.getPACERCredentials();

    // @ts-ignore
    promise.catch(() => {}); // Ignore cancellation error

    return () => {
      // @ts-ignore
      promise.cancel();
    };
  });

  return (
    <Row>
      <Col sm={12} md={6} lg={5} xl={4}>
        <h3 className="mb-3">PACER credentials</h3>

        <Observer>
          {() => {
            if (state.isLoading) {
              return <Spinner animation="border" variant="primary" />;
            }

            return (
              <Form
                onSubmit={(event) => {
                  event.preventDefault();
                  state.savePACERCredentials();
                }}
              >
                <Alert variant="warning">
                  <div className="alert-message">
                    PACER credentials are required for Bulk Claim filing. Please note you must use a CM/ECF Filer
                    account.
                  </div>
                </Alert>

                <Form.Group controlId="pacer_username" className="mb-3">
                  <Form.Label>PACER username</Form.Label>
                  <Form.Control
                    type="text"
                    htmlSize={30}
                    value={state.username}
                    onChange={(event) => (state.username = event.target.value)}
                    disabled={state.isSaving}
                  />
                </Form.Group>

                <Form.Group controlId="pacer_password" className="mb-3">
                  <Form.Label>PACER password</Form.Label>

                  <InputGroup>
                    <Form.Control
                      type={state.showPassword ? "text" : "password"}
                      htmlSize={30}
                      value={state.password}
                      onChange={(event) => (state.password = event.target.value)}
                      disabled={state.isSaving}
                    />

                    <OverlayTrigger
                      placement="top"
                      trigger={["hover", "focus"]}
                      overlay={<Tooltip>{state.showPassword ? "Hide password" : "Show password"}</Tooltip>}
                    >
                      <Button variant="secondary" onClick={() => (state.showPassword = !state.showPassword)}>
                        {state.showPassword ? <HidePasswordIcon size="16" /> : <ShowPasswordIcon size="16" />}
                      </Button>
                    </OverlayTrigger>
                  </InputGroup>
                </Form.Group>

                <Button type="submit" disabled={state.isSaving} className="d-flex align-items-center gap-1">
                  {state.isSaving ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />
                      <span>Saving</span>
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Form>
            );
          }}
        </Observer>
      </Col>
    </Row>
  );
}
