import devConfig from "./dev.json";
import prodConfig from "./prod.json";

const configFile = process.env.NODE_ENV === "development" ? devConfig : prodConfig;

export const config = {
  cognito: {
    REGION: process.env.REACT_APP_AWS_REGION,
    USER_POOL_ID: configFile.cognito_user_pool_id.value,
    APP_CLIENT_ID: configFile.aws_cognito_user_pool_app_client_id.value,
    IDENTITY_POOL_ID: configFile.aws_cognito_identity_pool.value,
  },
};

export const cognitoConfig = configFile.cognito;
