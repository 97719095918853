import { useReducer, useEffect, useRef } from "react";
import classNames from "classnames";
import { Row, Col, Form, Table, Alert, Button, Card } from "react-bootstrap";
import { X as XIcon } from "react-bootstrap-icons";
import Fuse, { FuseResult, FuseResultMatch } from "fuse.js";

import { SelectedCase, Creditor } from "../interfaces";

import NewCreditor from "./NewCreditor";

enum ActionTypes {
  ChangeView,
  SelectCreditor,
  FilterBy,
}
interface Action {
  type: ActionTypes;
  data?: any;
}

enum CurrentCreditorView {
  Listed = 0,
  NotListed,
}
interface State {
  currentView: CurrentCreditorView;
  selectedCreditor: Creditor | undefined;
  filterBy: string;
}
function reducer(state: State, action: Action) {
  switch (action.type) {
    case ActionTypes.ChangeView:
      return {
        ...state,
        currentView: action.data,
        selectedCreditor: undefined,
        filterBy: "",
      };
    case ActionTypes.SelectCreditor:
      return {
        ...state,
        selectedCreditor: action.data,
      };
    case ActionTypes.FilterBy:
      return {
        ...state,
        filterBy: action.data,
        selectedCreditor: undefined,
      };
  }
}

interface Props {
  selectedCase: SelectedCase;
  selectedCreditor?: Creditor;
  onChange: (creditor: Creditor) => void;
  onNext: (creditor: Creditor) => void;
}
export default function SelectCreditor({ selectedCase, selectedCreditor, onChange, onNext }: Props) {
  // const creditorAddresses = claimAddresses.filter((claimAddress) => claimAddress.address_type === "creditor_address");
  // const hasCreditorClaimsAddresses = creditorAddresses.length > 0;

  const [state, dispatch] = useReducer(
    reducer,
    {
      // there is a selected creditor?
      // if so, current view depends on if new creditor or existing
      // otherwise depends on if selected case has a list of existing creditors or not
      currentView: selectedCreditor
        ? selectedCreditor.id
          ? CurrentCreditorView.Listed
          : CurrentCreditorView.NotListed
        : selectedCase.creditors.length > 0
        ? CurrentCreditorView.Listed
        : CurrentCreditorView.NotListed,
      // default the value if there is a selected creditor(editing or reviewing form)
      selectedCreditor,
      filterBy: "",
    },
    function initState(initialState) {
      // if (!initialState.selectedCreditor && hasCreditorClaimsAddresses) {
      //   const availableCreditorNames = creditorAddresses.map((claimAddress) =>
      //     claimAddress.name.trim().toLocaleLowerCase()
      //   );
      //   const matchedCourtCreditor = selectedCase.creditors.find((creditor) =>
      //     availableCreditorNames.includes(creditor.name.trim().toLocaleLowerCase())
      //   );
      //   if (matchedCourtCreditor) {
      //     initialState.selectedCreditor = matchedCourtCreditor;
      //   }
      // }

      return initialState;
    }
  );

  const creditors = selectedCase.creditors.map((creditor) => {
    return {
      id: creditor.id,
      name: creditor.name,
      address: (creditor.address as string[]).join(" "),
    };
  });
  const fuzzySearch = useRef<Fuse<{ id: string | null; name: string; address: string }>>(
    new Fuse(creditors, {
      /**
       * When true, the matching function will continue to the end of a search pattern
       * even if a perfect match has already been located in the string.
       */
      findAllMatches: true,
      /**
       * Whether the matches should be included in the result set.
       * When true, each record in the result set will include the indices
       * of the matched characters.
       * These can consequently be used for highlighting purposes.
       */
      includeMatches: true,
      // Only the matches whose length exceeds this value will be returned.
      minMatchCharLength: 2,
      // Whether to sort the result list, by score.
      shouldSort: true,
      keys: [{ name: "name", weight: 2 }, "address"],
    })
  );

  useEffect(() => {
    if (state.selectedCreditor && state.selectedCreditor.id) {
      document
        .getElementById(`creditor-id-${state.selectedCreditor.id}`)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    if (state.selectedCreditor) {
      onChange(state.selectedCreditor);
    }
  }, [state.selectedCreditor, onChange]);

  let searchResults: FuseResult<{ id: string | null; name: string; address: string }>[];
  if (state.filterBy.trim() !== "") {
    searchResults = fuzzySearch.current.search(state.filterBy);
  } else {
    // Return all of them, but in the search result form
    searchResults = creditors.map((creditor, index) => ({ item: creditor, refIndex: index }));
  }

  const existingCreditorCheckDisabled = selectedCase.creditors.length === 0;
  return (
    <>
      <Row className="gx-0 gap-2 mb-3">
        <Col xs={12} sm="auto">
          <label
            role="button"
            className={classNames("d-flex align-items-center gap-2 p-2 border rounded bg-white shadow-lg", {
              "border-primary text-primary fw-bold": state.currentView === CurrentCreditorView.Listed,
              "fw-normal": state.currentView !== CurrentCreditorView.Listed,
              "text-muted": existingCreditorCheckDisabled,
            })}
            htmlFor="select-a-creditor"
            style={{
              opacity: existingCreditorCheckDisabled ? 0.5 : 1,
              cursor: existingCreditorCheckDisabled ? "not-allowed" : "hand",
            }}
          >
            <Form.Check
              type="radio"
              id="select-a-creditor"
              name="creditor-selection"
              checked={state.currentView === CurrentCreditorView.Listed}
              onChange={() =>
                dispatch({
                  type: ActionTypes.ChangeView,
                  data: CurrentCreditorView.Listed,
                })
              }
              disabled={existingCreditorCheckDisabled}
              className="pe-none"
            />
            Select an existing Creditor
          </label>
        </Col>

        <Col xs={12} sm="auto">
          <label
            role="button"
            className={classNames("d-flex align-items-center gap-2 p-2 border rounded bg-white shadow-lg", {
              "border-primary text-primary fw-bold": state.currentView === CurrentCreditorView.NotListed,
              "fw-normal": state.currentView !== CurrentCreditorView.NotListed,
            })}
            htmlFor="creditor-not-listed"
          >
            <Form.Check
              type="radio"
              id="creditor-not-listed"
              name="creditor-selection"
              checked={state.currentView === CurrentCreditorView.NotListed}
              onChange={() =>
                dispatch({
                  type: ActionTypes.ChangeView,
                  data: CurrentCreditorView.NotListed,
                })
              }
              className="pe-none"
            />
            Insert a new Creditor
          </label>
        </Col>
      </Row>

      {state.currentView === CurrentCreditorView.Listed && (
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            onNext(state.selectedCreditor);
          }}
        >
          <Card>
            <Card.Body className="d-flex align-items-center flex-wrap gap-2">
              <Form.Group className="position-relative">
                <Form.Control
                  type="text"
                  placeholder="Filter results"
                  style={{ height: 32, paddingRight: 32 }}
                  value={state.filterBy}
                  onChange={(event) =>
                    dispatch({
                      type: ActionTypes.FilterBy,
                      data: event.target.value,
                    })
                  }
                />

                {state.filterBy.trim() !== "" && (
                  <Button
                    type="button"
                    variant="link"
                    className="p-0 position-absolute"
                    style={{ width: 32, height: 32, top: 0, right: 0 }}
                    onClick={() => dispatch({ type: ActionTypes.FilterBy, data: "" })}
                  >
                    <XIcon size="24" />
                  </Button>
                )}
              </Form.Group>

              <span>
                Showing {searchResults.length} result
                {searchResults.length > 1 ? "s" : ""} out of {selectedCase.creditors.length}
              </span>
            </Card.Body>

            {searchResults.length > 0 ? (
              <Table hover size="sm" className="mb-0">
                <thead className="table-light position-sticky" id="creditors-table-header" style={{ top: 60 }}>
                  <tr>
                    <th className="min-content-width"></th>
                    <th>Creditor</th>
                  </tr>
                </thead>

                <tbody>
                  {searchResults.map(function (record) {
                    const creditor = record.item;

                    return (
                      <tr
                        key={creditor.id}
                        id={`creditor-id-${creditor.id!}`}
                        role="button"
                        className={classNames({
                          "table-primary": creditor.id === state.selectedCreditor?.id,
                        })}
                        onClick={() =>
                          dispatch({
                            type: ActionTypes.SelectCreditor,
                            data: selectedCase.creditors.find(({ id }) => creditor.id === id),
                          })
                        }
                      >
                        <td className="align-top">
                          <Form.Check
                            type="radio"
                            checked={state.selectedCreditor?.id === creditor.id}
                            readOnly
                            className="pe-none"
                          />
                        </td>
                        <td>
                          <strong className="d-block mb-2">
                            <Highlighter
                              value={creditor.name}
                              match={record.matches?.find(({ key }) => key === "name")}
                            />
                          </strong>
                          <strong>Address:</strong>{" "}
                          <Highlighter
                            value={creditor.address}
                            match={record.matches?.find(({ key }) => key === "address")}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <Card.Body className="pt-0">No results found for "{state.filterBy}"</Card.Body>
            )}

            <Card.Body className="border-top bg-white sticky-bottom">
              {state.selectedCreditor && state.selectedCreditor.id !== null && (
                <Alert variant="warning" className="mb-3">
                  <div className="alert-message">
                    NOTE: The creditor's name and address must be an exact match. If it is not, please create a new
                    creditor record.
                  </div>
                </Alert>
              )}

              <Form.Group>
                <Button type="submit" variant="primary" size="lg" disabled={!state.selectedCreditor}>
                  Continue
                </Button>
              </Form.Group>
            </Card.Body>
          </Card>
        </Form>
      )}

      {selectedCase.creditors.length === 0 && (
        <Alert variant="danger">
          <div className="alert-message">No creditors found, please create a new creditor record</div>
        </Alert>
      )}

      {state.currentView === CurrentCreditorView.NotListed && (
        <NewCreditor
          selectedCreditor={state.selectedCreditor}
          onChange={(creditor: Creditor) => onChange(creditor)}
          onSubmit={(creditor: Creditor) => {
            onNext(creditor);
          }}
        />
      )}
    </>
  );
}

function Highlighter({ value, match }: { value: string; match?: FuseResultMatch }) {
  if (!match) {
    return <span>{value}</span>;
  }

  const indices = match.indices;
  const result = [];
  let index = 0;
  while (index < value.length) {
    // eslint-disable-next-line no-loop-func
    const nextIndex = indices.find(([start]) => start === index);

    if (nextIndex) {
      let fragment = "";
      while (index <= nextIndex[1]) {
        fragment += value.charAt(index);
        index++;
      }

      result.push(
        <mark key={`fragment-${nextIndex[0]}-${nextIndex[1]}`} className="bg-warning text-white">
          {fragment}
        </mark>
      );
    } else {
      result.push(<span key={index}>{value.charAt(index)}</span>);
      index++;
    }
  }

  return <span>{result}</span>;
}
