import { useCallback, useState } from "react";
import classnames from "classnames";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { CornerUpLeft as CornerUpLeftIcon } from "react-feather";

import AuthService from "../../services/AuthService";
import { somethingWentWrong } from "../../lib/errors";

import PasswordStrengthInfo from "./PasswordStrengthInfo";

const schema = yup.object().shape({
  fullName: yup.string().required("Full name is required"),
  email: yup.string().email("Email address is invalid").required("Email address is required"),
  password: yup
    .string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
      "Password must be at least 8 characters, lowercase, uppercase, and numbers for greater security."
    )
    .required("New password is required"),
});

function getQueryParams(search) {
  const result = {};
  new URLSearchParams(search).forEach(function (value, key) {
    result[key] = value;
  });
  return result;
}

function getName(first, last) {
  return [first, last].filter(Boolean).join(" ");
}

export default function Register({ location: { search } }) {
  const { email, first_name, last_name, case_number, case_title } = getQueryParams(search);
  const name = getName(first_name, last_name);
  const isACreditor = email || name;
  const bkCase = [case_title, case_number].filter(Boolean);

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({
    email,
    name,
  });
  const [showConfirmStep, setShowConfirmStep] = useState(isACreditor);
  const [showConfirmRegistration, setShowConfirmRegistration] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const password = watch("password", "");

  const onSubmit = useCallback(async ({ fullName, email, password }) => {
    setIsLoading(true);
    try {
      await AuthService.signUp({
        fullName,
        email,
        password,
      });
      setIsLoading(false);
      setShowConfirmRegistration(true);
    } catch (error) {
      setIsLoading(false);

      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error(somethingWentWrong);
      }
    }
  }, []);

  const onClickContinue = useCallback(() => setShowConfirmStep(false), []);

  const onClickReset = useCallback(() => {
    setUser({
      email: "",
      name: "",
    });
    setShowConfirmStep(false);
  }, []);

  if (showConfirmRegistration) {
    return (
      <Row className="mt-4">
        <Col xs={12} sm={8} lg={5} className="mx-auto">
          <h1 className="display-5 text-success text-center">Please check your email to complete your registration.</h1>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="mt-4">
      <Col xs={12} sm={8} lg={5} className="mx-auto">
        {!showConfirmStep && (
          <Button as={Link} to="/auth/login" variant="link" className={classnames({ disabled: isLoading })}>
            <CornerUpLeftIcon size="16" /> or login with your account
          </Button>
        )}

        {bkCase.length > 0 && (
          <>
            <h1 className="display-5 text-center mt-0">
              Case number: {bkCase[1]} - {bkCase[0]}
            </h1>
            <h4 className="text-center">
              Register to view the bankruptcy case docket from PACER for $10 / day or $15 / month!
            </h4>
          </>
        )}

        <Card className="mt-2">
          <Card.Body>
            <Card.Title>
              <h1 className="mt-0">Register</h1>
            </Card.Title>

            {showConfirmStep && (
              <>
                <Form.Group className="mb-3">
                  Welcome {name || email}. Please{" "}
                  <Button type="button" variant="link" className="p-0" onClick={onClickContinue}>
                    click here to continue
                  </Button>
                </Form.Group>

                <Form.Group>
                  If this isn't you, please{" "}
                  <Button type="button" variant="link" className="p-0" onClick={onClickReset}>
                    click here
                  </Button>
                </Form.Group>
              </>
            )}

            {!showConfirmStep && (
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="fullName" className="mb-3">
                  <Form.Label>Full name</Form.Label>
                  <Form.Control
                    {...register("fullName")}
                    defaultValue={user.name}
                    placeholder="John Doe"
                    isInvalid={errors.fullName}
                    disabled={isLoading}
                    autoFocus
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.fullName && errors.fullName.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="email" className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    {...register("email")}
                    type="email"
                    defaultValue={user.email}
                    placeholder="johndoe@foobar.org"
                    isInvalid={errors.email}
                    disabled={isLoading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email && errors.email.message}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="password" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    {...register("password")}
                    type="password"
                    isInvalid={errors.password}
                    disabled={isLoading}
                  />

                  <PasswordStrengthInfo password={password} />
                </Form.Group>

                <Button type="submit" disabled={isLoading}>
                  {isLoading ? <Spinner animation="border" variant="light" size="sm" /> : "Register"}
                </Button>
              </Form>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
