import {
  CHECK_IMPERSONATION,
  SET_IMPERSONATED_USER,
  END_IMPERSONATING,
  CHANGE_IMPERSONATED_USER_NAME,
  RESET_SUPER_ADMIN_DATA,
} from "../constants";

export const checkImpersonation = (isImpersonating) => ({
  type: CHECK_IMPERSONATION,
  payload: {
    isImpersonating,
  },
});

export const setImpersonatedUser = (name, role, email) => ({
  type: SET_IMPERSONATED_USER,
  payload: {
    impersonatedUser: name,
    impersonatedUserEmail: email,
    role,
  },
});

export const endImpersonating = () => ({
  type: END_IMPERSONATING,
});

export const changeImpersonatedUserName = (name) => ({
  type: CHANGE_IMPERSONATED_USER_NAME,
  payload: {
    name,
  },
});

export const resetSuperAdminData = () => ({
  type: RESET_SUPER_ADMIN_DATA,
});
