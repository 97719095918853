import { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";

import { Claim } from "../../types";

export default observer(function EditOptions({
  value,
  claim,
  columnKey,
  options,
  updateClaim,
}: {
  value: any;
  claim: Claim;
  columnKey: string;
  options: { value: string; label: string }[];
  updateClaim: (claim: Claim, columnKey: string, newValue: any) => void;
}) {
  const [newValue, setNewValue] = useState(options.find((option) => option.value === value) ? value : "");
  const shouldConfirmChange = useRef(true);

  function confirmChange() {
    if (!shouldConfirmChange.current) return;

    updateClaim(claim, columnKey, newValue);
  }

  return (
    <Form.Select
      autoFocus
      value={newValue}
      onChange={(event) => {
        setNewValue(event.target.value);
      }}
      onBlur={() => confirmChange()}
      onKeyDown={(event) => {
        // Discard changes on ESC
        if (event.key === "Escape") {
          shouldConfirmChange.current = false;
          claim.editing_field_key = null;
          claim.focused_cell_element?.focus();
          claim.focused_cell_element = null;
        } else if (event.key === "Enter") {
          confirmChange();
        }
      }}
      size="sm"
    >
      <option value=""></option>

      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Select>
  );
});
