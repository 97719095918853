import { useState, useEffect, useCallback } from "react";
import { Card, Spinner, Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { apolloClient } from "../../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../../lib/errors";
import { changeName } from "../../../../../redux/actions/authActions";

import getCurrentUserInfo from "./graphql/getCurrentUserInfo";
import updateUserName from "./graphql/updateUserName";

export default function Info() {
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [name, setName] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const watchedQuery = apolloClient.watchQuery({
      query: getCurrentUserInfo,
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        if (!response.partial) {
          setCurrentUser(response.data.getCurrentUserInfo);
          setName(response.data.getCurrentUserInfo.name);
        }
      },
      error(err) {
        console.log("[watchQuery] error", err);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, []);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      try {
        setIsLoading(true);

        await apolloClient.mutate({
          mutation: updateUserName,
          variables: {
            name,
          },
        });

        toast.success("Name changed successfully");

        setIsLoading(false);

        dispatch(changeName(name));
      } catch (error) {
        console.log(error);

        toast.error(somethingWentWrong);

        setIsLoading(false);
      }
    },
    [dispatch, name]
  );

  const onChangeName = useCallback((event) => {
    setName(event.target.value);
  }, []);

  return (
    <>
      <Card.Title>
        <h3>Account info</h3>
      </Card.Title>

      {!currentUser && <Spinner animation="border" variant="primary" />}

      {currentUser && (
        <Row>
          <Col sm={12} md={6} lg={5} xl={4}>
            <Form onSubmit={onSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <pre>{currentUser.email}</pre>
              </Form.Group>

              <Form.Group controlId="name" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={onChangeName}
                  htmlSize={Math.max(currentUser.name.length, 30)}
                  disabled={isLoading}
                />
              </Form.Group>

              <Button type="submit" disabled={isLoading}>
                {isLoading ? <Spinner animation="border" variant="light" size="sm" /> : "Save"}
              </Button>
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
}
