import { Form } from "react-bootstrap";
import classnames from "classnames";

import { ParsedCSVResult } from "../types";

import "./PasteCSVTextarea.scss";

interface Props {
  value: string;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  isInvalid: boolean;
  errors: ParsedCSVResult["errors"];
}
export default function PasteCSVTextarea({ value, onChange, isInvalid, errors }: Props) {
  return (
    <div className={classnames("paste-csv-textarea border rounded", { "is-invalid": isInvalid })}>
      <ul className="line-numbers border-end font-monospace">
        {value.split("\n").map((_line, index) => (
          <li
            key={index}
            className={classnames({ "bg-danger text-white": errors.find(({ row }) => index + 1 === row + 1) })}
          >
            {index + 1}.
          </li>
        ))}
      </ul>

      <Form.Control
        as="textarea"
        rows={10}
        placeholder={
          "SSN,TAXID,First name,Middle name,Last name,Generation,Address line 1,Address line 2,Address line 3,City,State,Zip code,Country,Phone"
        }
        value={value}
        onChange={onChange}
        autoFocus
        className="font-monospace"
        wrap="off"
      />
    </div>
  );
}
