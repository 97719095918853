import cdLogoUrl from "../../../../assets/images/cd-logo.png";

const availableApps = [
  {
    id: "courtdrive",
    name: "CourtDrive",
    logoUrl: cdLogoUrl,
    features: [
      {
        title: "Save PACER expenditures with largest dataset of PACER filings",
        showTitle: true,
        description:
          "Our community based platform used by bankruptcy attorneys helps minimize PACER costs with the largest database of PDFs.",
      },
      {
        title: "Find bankruptcy cases with our real time PACER interface",
        showTitle: true,
        description:
          "Ensures fastest and most cost effective solution to find any case, docket, claims register, mailing matrix and creditors.",
      },
      {
        title: "Monitor docket events with AI powered workflow tools",

        showTitle: true,
        description: "Our AI Bankruptcy Monitoring Platform monitors docket events with over 2,000 events.",
      },
      {
        title: "Calendar key dates to never miss a deadline",
        showTitle: true,
        description:
          "Our AI power docket calendaring solution helps identify key dates like deadline to file proof of claim.",
      },
      {
        title: "CourtDrive Pricing",
        showTitle: false,
        description:
          "You will be charged for your CourtDrive subscription separately. There is no charge from BKalerts for this integration.",
      },
    ],
  },
];

export default availableApps;
