import gql from "graphql-tag";

const getDocketHeader = gql`
  query getDocketHeader($case_id: ID!) {
    getDocketHeader(case_id: $case_id) {
      header {
        html
      }
    }
  }
`;

export default getDocketHeader;
