import { useState, useEffect } from "react";
import { PubSub } from "@aws-amplify/pubsub";

export default function useTopicSubscription<T>(topics: string | string[] | null, initialValue: T): T {
  const [value, setValue] = useState<T>(initialValue);

  useEffect(() => {
    let currentSubscription: any = null;

    if (!topics) {
      console.log("[PubSub] No topics to subscribe to");
    } else {
      console.log("[PubSub] Subscribing to", topics);

      currentSubscription = PubSub.subscribe(topics).subscribe({
        next: ({ value }) => {
          setValue(value);
        },
        error: (error) => {
          console.error(`[PubSub] Error`, error);
        },
      });
    }

    return () => {
      if (currentSubscription) {
        console.log(`[PubSub] Cleaning up subscriptions`);
        currentSubscription.unsubscribe();
      }
    };
  }, [topics]);

  return value;
}
