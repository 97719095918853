import React, { useState, useCallback, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";

import { apolloClient } from "../../configs/apollo-client";
import resetUserPassword from "./graphql/resetUserPassword";

export default function ModalResetPassword({ show, email, onCancel }) {
  const [isLoading, setIsLoading] = useState(false);
  const [temporary, setTemporary] = useState(true);
  const [emailLoginLink, setEmailLoginLink] = useState(false);
  const [generatedPassword, showGeneratedPassword] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  // reset form on hide
  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setTemporary(true);
        setEmailLoginLink(false);
        showGeneratedPassword(false);
        setCopiedToClipboard(false);
      }, 500);
    }
  }, [show]);

  const onChangePasswordResetType = useCallback(() => {
    const newValue = !temporary;
    setTemporary(newValue);
    if (!newValue) {
      setEmailLoginLink(false);
    }
  }, [temporary]);

  const onChangeEmailLoginLink = useCallback(() => setEmailLoginLink(!emailLoginLink), [emailLoginLink]);

  const onClickConfirm = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await apolloClient.mutate({
        mutation: resetUserPassword,
        variables: {
          input: {
            email,
            temporary: temporary,
            email_login_link: emailLoginLink,
          },
        },
      });

      showGeneratedPassword(response.data.resetUserPassword.password);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  }, [emailLoginLink, email, temporary]);

  const onClickCopyToClipboard = useCallback(() => {
    setCopiedToClipboard(true);
    copyTextToClipboard(generatedPassword);
  }, [generatedPassword]);

  const disableClose = isLoading || generatedPassword;

  return (
    <Modal show={show} onHide={onCancel} {...(disableClose ? { backdrop: "static", keyboard: false } : {})}>
      <Modal.Header closeButton={!disableClose}>
        <Modal.Title>Reset password for {email}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!generatedPassword && (
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Password type</Form.Label>

              <ul className="list-inline">
                <li className="list-inline-item">
                  <Form.Check
                    custom
                    id="temporary-password-reset"
                    type="radio"
                    label="Temporary"
                    checked={temporary}
                    onChange={onChangePasswordResetType}
                    disabled={isLoading}
                  />
                </li>
                <li className="list-inline-item">
                  <Form.Check
                    custom
                    id="permanent-password-reset"
                    type="radio"
                    label="Permanent"
                    checked={!temporary}
                    onChange={onChangePasswordResetType}
                    disabled={isLoading}
                  />
                </li>
              </ul>
            </Form.Group>

            {temporary && (
              <Form.Group className="mb-3">
                <Form.Check
                  type="switch"
                  id="email-login-link-switch"
                  label="Email login link"
                  checked={emailLoginLink}
                  onChange={onChangeEmailLoginLink}
                  disabled={isLoading}
                />
              </Form.Group>
            )}

            <Button type="button" onClick={onClickConfirm} disabled={isLoading}>
              {isLoading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                <span>
                  Get {temporary ? "temporary" : "permanent"} password
                  {emailLoginLink ? " and send login link" : ""}
                </span>
              )}
            </Button>
          </Form>
        )}

        {generatedPassword && (
          <Form>
            <OverlayTrigger
              placement="top"
              shouldUpdatePosition
              overlay={
                <UpdatingTooltip id="copy-password-to-clipboard-tooltip">
                  {copiedToClipboard ? "Copied!" : "Click to copy to clipboard"}
                </UpdatingTooltip>
              }
            >
              <Form.Group controlId="newPassword" onClick={onClickCopyToClipboard} className="mb-3">
                <Form.Control
                  type="text"
                  value={generatedPassword}
                  readOnly
                  onFocus={(event) => event.target.select()}
                  style={{ cursor: "pointer" }}
                />
              </Form.Group>
            </OverlayTrigger>

            <Button type="button" onClick={onCancel}>
              Done
            </Button>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
}

const UpdatingTooltip = React.forwardRef(({ popper, children, show: _, ...props }, ref) => {
  useEffect(() => {
    popper.scheduleUpdate();
  }, [children, popper]);

  return (
    <Tooltip id="copy-password-to-clipboard-tooltip" ref={ref} {...props}>
      {children}
    </Tooltip>
  );
});
// function fallbackCopyTextToClipboard(text) {
//   const textArea = document.createElement("textarea");
//   textArea.value = text;

//   // Avoid scrolling to bottom
//   textArea.style.top = "0";
//   textArea.style.left = "0";
//   textArea.style.position = "fixed";

//   document.body.appendChild(textArea);
//   textArea.focus();
//   textArea.select();

//   try {
//     return document.execCommand("copy");
//   } catch (error) {
//     console.error("Fallback: Oops, unable to copy", error);
//     throw error
//   }

//   document.body.removeChild(textArea);
// }

function copyTextToClipboard(text) {
  console.log("navigator.clipboard?", navigator.clipboard);

  if (!navigator.clipboard) {
    console.log("Using fallback copy");
    // fallbackCopyTextToClipboard(text);
    // return;
  }

  return navigator.clipboard.writeText(text);
}
