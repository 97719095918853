import { useEffect } from "react";
import classnames from "classnames";
import { observer } from "mobx-react-lite";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { usePlacesWidget } from "react-google-autocomplete";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Claim } from "../../types";
import states from "../../../New/steps/lib/states.json";

interface IAddressForm {
  name: string | null;
  title: string | null;
  company: string | null;
  address_1: string | null;
  address_2: string | null;
  address_3: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  zip4: string | null;
  phone: string | null;
  email: string | null;
}

interface IGoogleMapsAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

const schema = yup.object({
  name: yup.string().nullable().required("Insert name"),
  title: yup.string().nullable(),
  company: yup.string().nullable(),
  address_1: yup.string().nullable().required("Insert the address"),
  city: yup.string().nullable().required("Insert the City"),
  state: yup.string().nullable().required("Choose a State"),
  zip: yup
    .string()
    .nullable()
    .required("Insert the ZIP code")
    .matches(/^\d{5}$/, "Insert a valid ZIP code"),
  zip4: yup
    .string()
    .nullable()
    .matches(/^\d{4}$/, {
      message: "Insert a valid ZIP code",
      excludeEmptyString: true,
    }),
  phone: yup.string().nullable(),
  email: yup.string().nullable().email("Insert a valid email address"),
});

export default observer(function EditAddress({
  value,
  claim,
  columnKey,
  updateClaim,
}: {
  value: any;
  claim: Claim;
  columnKey: string;
  updateClaim: (claim: Claim, columnKey: string, newValue: any) => void;
}) {
  const cancelChange = () => (claim.editing_field_key = null);
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm<IAddressForm>({
    mode: "onChange",
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      name: value.name,
      title: value.title,
      company: value.company,
      address_1: value.address_1,
      address_2: value.address_2,
      address_3: value.address_3,
      city: value.city,
      state: states.find((state) => state.name === value.state) ? value.state : null,
      zip: value.zip,
      zip4: value.zip4,
      phone: value.phone,
      email: value.email,
    },
  });

  const onSubmit = function (data: IAddressForm) {
    updateClaim(claim, columnKey, {
      ...claim[columnKey],
      ...data,
    });
  };

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      const addressComponents: IGoogleMapsAddressComponent[] = place.address_components;
      console.log("addressComponents", addressComponents);
      if (!addressComponents) {
        return;
      }

      // Reference
      // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
      const address1 = [
        addressComponents.find(({ types }) => types.includes("street_number"))?.long_name || "",
        addressComponents.find(({ types }) => types.includes("route"))?.long_name || "",
      ]
        .filter(Boolean)
        .join(" ");
      const city =
        addressComponents.find(({ types }) => types.includes("locality"))?.long_name ||
        addressComponents.find(({ types }) => types.includes("neighborhood") && types.includes("political"))
          ?.long_name ||
        "";
      const state =
        addressComponents.find(({ types }) => types.includes("administrative_area_level_1"))?.long_name || "";
      const zip = addressComponents.find(({ types }) => types.includes("postal_code"))?.long_name || "";
      const zip4 = addressComponents.find(({ types }) => types.includes("postal_code_suffix"))?.long_name || "";

      // according to docs more verbose but more performant than overwriting entire object field
      setValue("address_1", address1, {
        shouldValidate: true,
      });
      setValue("city", city, {
        shouldValidate: true,
      });
      setValue("state", state, {
        shouldValidate: true,
      });
      setValue("zip", zip, {
        shouldValidate: true,
      });
      setValue("zip4", zip4, {
        shouldValidate: true,
      });
    },
    options: {
      componentRestrictions: { country: "us" },
      types: ["address"],
    },
  });

  useEffect(() => {
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addressTypeLabel = (function () {
    switch (columnKey) {
      case "creditor":
        return "Creditor";
      case "payment_address":
        return "Payment";
      case "signee":
        return "Signee";
    }

    return "";
  })();

  return (
    <Modal show={true} onHide={cancelChange} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit {addressTypeLabel} address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(claim.district || claim.case_no) && (
          <div className="mb-3">
            Editing {addressTypeLabel} address of {[claim.district, claim.case_no].filter(Boolean).join("/")}
          </div>
        )}

        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate id="edit-batch-claim-address">
          <Form.Group className="mb-3" controlId="edit-address-form-name">
            <Form.Label>
              Name <span className="text-danger">(required)</span>
            </Form.Label>

            <Form.Control type="text" maxLength={50} isInvalid={!!errors.name?.message} {...register("name")} />

            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
          </Form.Group>

          {columnKey === "signee" && (
            <>
              <Form.Group className="mb-3" controlId="edit-address-form-title">
                <Form.Label>Title</Form.Label>

                <Form.Control type="text" maxLength={50} {...register("title")} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="edit-address-form-company">
                <Form.Label>Company</Form.Label>

                <Form.Control type="text" maxLength={50} {...register("company")} />

                <Form.Text>
                  Identify the corporate servicer as the company if the authorized agent is a servicer
                </Form.Text>
              </Form.Group>
            </>
          )}

          <Form.Group className="mb-3">
            <Form.Label htmlFor="edit-address-form-address-1">
              Address <span className="text-danger">(required)</span>
            </Form.Label>

            <Controller
              name="address_1"
              control={control}
              render={({ field }) => (
                <Form.Control
                  id="edit-address-form-address-1"
                  type="text"
                  placeholder="123 Main St"
                  className="mb-1"
                  isInvalid={!!errors.address_1?.message}
                  style={{ minWidth: 300 }}
                  {...field}
                  // @ts-ignore
                  ref={ref}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                    }
                  }}
                />
              )}
            />

            {columnKey !== "signee" && (
              <>
                <Form.Control
                  type="text"
                  className="mb-1"
                  placeholder="Apartment, studio, or floor"
                  style={{ minWidth: 300 }}
                  {...register("address_2")}
                />

                <Form.Control type="text" placeholder="c/o" style={{ minWidth: 300 }} {...register("address_3")} />
              </>
            )}

            <Form.Control.Feedback type="invalid">{errors.address_1?.message}</Form.Control.Feedback>
          </Form.Group>

          <Row className="mb-3">
            <Form.Group as={Col} sm="auto" controlId="edit-address-form-address-city">
              <Form.Label>City</Form.Label>

              <Form.Control maxLength={30} isInvalid={!!errors.city?.message} {...register("city")} />

              <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm="auto" controlId="edit-address-form-address-state">
              <Form.Label>State</Form.Label>

              <Form.Select isInvalid={!!errors.state?.message} {...register("state")}>
                <option value="">Choose...</option>

                {states.map(function ({ name, abbreviation }) {
                  return (
                    <option key={name} value={name}>
                      {name} ({abbreviation})
                    </option>
                  );
                })}
              </Form.Select>

              <Form.Control.Feedback type="invalid">{errors.state?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm="auto">
              <Form.Label htmlFor="edit-address-form-address-zip-5">Zip</Form.Label>

              <div
                className={classnames("d-flex gap-1 align-items-center", {
                  "is-invalid": !!errors.zip?.message || !!errors.zip4?.message,
                })}
              >
                <Form.Control
                  placeholder="12345"
                  id="edit-address-form-address-zip-5"
                  maxLength={5}
                  htmlSize={5}
                  inputMode="numeric"
                  isInvalid={!!errors.zip?.message}
                  {...register("zip")}
                />
                -
                <Form.Control
                  placeholder="1234"
                  maxLength={4}
                  htmlSize={4}
                  inputMode="numeric"
                  isInvalid={!!errors.zip4?.message}
                  {...register("zip4")}
                />
              </div>

              <Form.Control.Feedback type="invalid">
                {errors.zip?.message || errors.zip4?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="edit-address-form-phone-number">
            <Form.Label>Telephone number</Form.Label>

            <Form.Control
              type="text"
              placeholder="818-252-9950"
              maxLength={20}
              inputMode="tel"
              {...register("phone")}
            />
          </Form.Group>

          <Form.Group controlId="edit-address-form-email" className="mb-3">
            <Form.Label>Email</Form.Label>

            <Form.Control
              type="email"
              placeholder="johndoe@company.com"
              isInvalid={!!errors.email?.message}
              maxLength={40}
              inputMode="email"
              {...register("email")}
            />

            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
          </Form.Group>

          <Button
            type="button"
            variant="danger"
            onClick={() =>
              onSubmit({
                name: null,
                title: null,
                company: null,
                address_1: null,
                address_2: null,
                address_3: null,
                city: null,
                state: null,
                zip: null,
                zip4: null,
                phone: null,
                email: null,
              })
            }
          >
            Delete address
          </Button>
        </Form>
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <Button type="button" variant="secondary" onClick={cancelChange}>
          Cancel
        </Button>

        <div className="d-flex align-items-center gap-2">
          <Button type="submit" form="edit-batch-claim-address" variant="primary">
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
});
