import { List as ListIcon } from "react-bootstrap-icons";

import "./SidebarToggle.scss";

type Props = {
  onToggle: () => void;
  onMobileSidebarShow: () => void;
};
export default function SidebarToggle({
  onToggle,
  onMobileSidebarShow,
}: Props) {
  return (
    <>
      <div className="app-sidebar-toggle d-none d-lg-block">
        <div
          className="hamburger-button text-light"
          role="button"
          onClick={onToggle}
        >
          <ListIcon size={20} />
        </div>
      </div>

      <div className="app-sidebar-toggle d-block d-lg-none">
        <div
          className="hamburger-button text-light"
          role="button"
          onClick={onMobileSidebarShow}
        >
          <ListIcon size={20} />
        </div>
      </div>
    </>
  );
}
