import React, { useState, useCallback } from "react";
import { Dropdown, Button, Form, InputGroup } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Person as UserIcon, Search as SearchIcon, BoxArrowUpRight as ExternalLinkIcon } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";

import Logo from "../../atoms/Logo";
import privateRoutes from "../../Routing/privateRoutes";
import AuthService from "../../../services/AuthService";
import { endImpersonating } from "../../../redux/actions/superAdminActions";
import { refreshSession } from "../../../configs/functions/refreshSession";
import { apolloClient } from "../../../configs/apollo-client";
import { RootState } from "../../../redux/store";

import SidebarToggle from "./SidebarToggle";
import Notifications from "./Notifications";

import "./Navbar.scss";

function Navbar({
  onSidebarToggle,
  onMobileSidebarShow,
}: {
  onSidebarToggle: () => void;
  onMobileSidebarShow: () => void;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const { name, organizationId, isImpersonating, impersonatedUser } = useSelector<
    RootState,
    {
      name: string;
      organizationId: string;
      isImpersonating: boolean;
      impersonatedUser: string;
    }
  >((state) => ({
    name: state.authentication.name,
    organizationId: state.authentication.organizationId,
    isImpersonating: state.superAdmin.isImpersonating,
    impersonatedUser: state.superAdmin.impersonatedUser,
  }));
  const query = Object.fromEntries(new URLSearchParams(search));
  const [searchTerm, setSearchTerm] = useState(query.searchTerm || "");

  const navbarRoutes = privateRoutes.filter(({ userMenu = false }) => userMenu);

  const signOut = useCallback(async () => {
    await AuthService.logout();
    history.push("/auth/login");
    // TODO Still needed?
    apolloClient.clearStore();
  }, [history]);

  const goBackToSuperAdmin = useCallback(async () => {
    dispatch(endImpersonating());

    const superAdminEmailFromStorage = localStorage.getItem("superAdminEmail");
    const superAdminUserToken = superAdminEmailFromStorage && JSON.parse(superAdminEmailFromStorage);

    await refreshSession(superAdminUserToken.email, superAdminUserToken.refresh_token);

    // TODO Still needed?
    apolloClient.clearStore();

    localStorage.removeItem("impersonatedUserToken");
    localStorage.removeItem("isImpersonating");

    window.location.href = "/super-admin";
  }, [dispatch]);

  const superAdminEmailFromStorage = localStorage.getItem("superAdminEmail");
  const superAdmin = superAdminEmailFromStorage && JSON.parse(superAdminEmailFromStorage);

  const onChangeSearchTerm = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(ev.target.value);
  }, []);

  const onSubmitSearch = useCallback(
    (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (searchTerm?.trim() !== "") {
        history.push(
          `/search-cases?${new URLSearchParams({
            searchTerm,
          }).toString()}`
        );
      }
    },
    [history, searchTerm]
  );

  return (
    <header className="app-navbar bg-dark text-light">
      <div className="d-flex align-items-center">
        <SidebarToggle onToggle={onSidebarToggle} onMobileSidebarShow={onMobileSidebarShow} />

        <Link to="/" className="d-none d-sm-block">
          <Logo inverse height={22} />
        </Link>

        <Link to="/" className="d-block d-sm-none">
          <Logo mobile height={22} />
        </Link>
      </div>

      <Form onSubmit={onSubmitSearch} className="search-cases-form d-flex align-items-center gap-2">
        <Form.Label className="d-none d-md-inline mb-0 text-nowrap" htmlFor="search-bk-cases">
          Search Cases
        </Form.Label>
        <InputGroup className="w-auto">
          <Form.Control
            id="search-bk-cases"
            type="search"
            placeholder="Case Number or Case Title"
            value={searchTerm}
            onChange={onChangeSearchTerm}
            htmlSize={25}
          />

          <Button variant="primary" type="submit" className="d-flex align-items-center">
            <SearchIcon size={16} />
          </Button>
        </InputGroup>
      </Form>

      <div className="d-flex align-items-center gap-2 me-2">
        <Notifications organizationId={organizationId} />

        {isImpersonating ? (
          <div className="d-flex align-items-center">
            <span className="text-white me-2">{`${
              superAdmin && superAdmin.name
            } masquerading as ${impersonatedUser}`}</span>
            <Button onClick={goBackToSuperAdmin} variant="primary">
              Go back
            </Button>
          </div>
        ) : (
          <Dropdown align="end" className="d-flex align-items-center">
            <Dropdown.Toggle as={UserDropdownCustomToggle} name={name} />

            <Dropdown.Menu style={{ "--bs-dropdown-zindex": 1100 } as React.CSSProperties} className="p-2">
              {navbarRoutes.map(({ id, label, icon: Icon, path }) => (
                <Dropdown.Item key={id} as={Link} to={path} className="d-flex align-items-center gap-2 px-2 rounded">
                  {Icon && <Icon size={16} />}
                  {label}
                </Dropdown.Item>
              ))}

              <Dropdown.Divider />

              <Dropdown.Item
                href="https://www.bkalerts.com/legal/privacy"
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex align-items-center gap-2 px-2 rounded"
              >
                <ExternalLinkIcon size={16} />
                Privacy
              </Dropdown.Item>

              <Dropdown.Item
                href="https://www.bkalerts.com/legal/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex align-items-center gap-2 px-2 rounded"
              >
                <ExternalLinkIcon size={16} />
                Terms of Service
              </Dropdown.Item>

              <Dropdown.Divider />

              <Dropdown.Item onClick={signOut} className="px-2 rounded">
                Sign out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </header>
  );
}

export default Navbar;

const UserDropdownCustomToggle = React.forwardRef<HTMLButtonElement, { name: string; onClick: () => {} }>(
  ({ name, onClick }, ref) => (
    <Button type="button" variant="secondary" ref={ref} onClick={onClick}>
      <UserIcon size={18} />
      <span className="d-none d-md-inline ms-1">{name}</span>
    </Button>
  )
);
