import { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  InputGroup,
  Spinner,
  Badge,
  Button,
  Card,
  ProgressBar,
  Table,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  QuestionCircle as QuestionCircleIcon,
  Search as SearchIcon,
  Key as KeyIcon,
  Eye as EyeIcon,
  MenuButton as MenuButtonIcon,
  CaretUpFill as CaretUpIcon,
  CaretDownFill as CaretDownIcon,
  ArrowUpCircleFill as BackToTopIcon,
} from "react-bootstrap-icons";

import { apolloClient } from "../../configs/apollo-client";
import Pagination from "../../components/atoms/Pagination/Pagination";
import PageSize from "../../components/PageSize";
import { checkImpersonation, setImpersonatedUser } from "../../redux/actions/superAdminActions";
import { changeName } from "../../redux/actions/authActions";
import { refreshSession } from "../../configs/functions/refreshSession";

import ModalResetPassword from "./ModalResetPassword";
import UserFeaturesModal from "./UserFeaturesModal";
import adminListUsers from "./graphql/adminListUsers";
import getImpersonateAuthToken from "./graphql/getImpersonateAuthToken";

import "./index.scss";

const PAGE_SIZES = [
  { label: "20", value: "TWENTY" },
  { label: "50", value: "FIFTY" },
  { label: "100", value: "ONEHUNDRED" },
] as const;

type SortBy = "EMAIL" | "NAME" | "ACTIVE" | "CREATEDAT";

type AdminListUsersResponse = {
  users: {
    id: string;
    email: string;
    name: string;
    active: boolean;
    cognito_user_status: string;
    cognito_user_status_description: string;
    created_at: string;
  }[];
  pagination: {
    total_records_count: number;
    total_pages: number;
  };
};

function getJWTTokenPayload<T>(token: string): T | null {
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    return payload;
  } catch (error) {
    return null;
  }
}

export default function SuperAdmin({ user: currentUser }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<AdminListUsersResponse | undefined>(undefined);
  const [sortBy, setSortBy] = useState<SortBy>("CREATEDAT");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [pageSize, setPageSize] = useState<(typeof PAGE_SIZES)[number]["value"]>(PAGE_SIZES[0].value);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterBy, setFilterBy] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [impersonating, setImpersonating] = useState<boolean | string>(false);
  const [showModalResetPassword, setShowModalResetPassword] = useState<false | string>(false);
  const [showUserFeaturesModal, setShowUserFeaturesModal] = useState<false | string>(false);
  const dispatch = useDispatch();
  const currentUserEmail = currentUser.attributes.email;

  useEffect(() => {
    async function loadSuperAdminData() {
      const {
        data: { getImpersonateAuthToken: tokens },
      } = await apolloClient.query({
        query: getImpersonateAuthToken,
        variables: {
          email: currentUserEmail,
        },
      });

      const payload = getJWTTokenPayload<{ name: string }>(tokens.id_token);
      if (!payload) {
        toast.error("Cannot parse JWT token");
        return;
      }
      const { name } = payload;

      localStorage.setItem(
        "superAdminEmail",
        JSON.stringify({
          token: tokens.id_token,
          access_token: tokens.access_token,
          name,
          email: currentUserEmail,
          refresh_token: tokens.refresh_token,
        })
      );

      localStorage.setItem("errors", "false");
    }

    loadSuperAdminData();
  }, [currentUserEmail]);

  useEffect(() => {
    setIsLoading(true);

    const watchedQuery = apolloClient.watchQuery({
      query: adminListUsers,
      variables: {
        input: {
          filter_by: filterBy,
          sort_by: {
            column: sortBy,
            order: sortOrder,
          },
          pagination: {
            page_number: pageNumber - 1,
            page_size: pageSize,
          },
        },
      },
      fetchPolicy: "cache-and-network",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        if (response.data) {
          setResponse(response.data.adminListUsers as AdminListUsersResponse);
          setIsLoading(false);
        }
      },
      error(err) {
        console.log("[watchQuery] error", err);
        setIsLoading(false);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, [filterBy, sortBy, sortOrder, pageNumber, pageSize]);

  const impersonate = useCallback(
    async (email: string, name: string) => {
      try {
        setImpersonating(email);

        const {
          data: { getImpersonateAuthToken: tokens },
        } = await apolloClient.query({
          query: getImpersonateAuthToken,
          variables: {
            email,
          },
        });

        const payload = getJWTTokenPayload<{ role: string }>(tokens.id_token);
        if (!payload) {
          toast.error("Cannot parse JWT token");
          return;
        }
        const { role } = payload;

        localStorage.setItem(
          "impersonatedUserToken",
          JSON.stringify({
            token: tokens.id_token,
            name,
            email,
            role,
            access_token: tokens.access_token,
            refresh_token: tokens.refresh_token,
          })
        );

        await refreshSession(email, tokens.refresh_token);

        dispatch(changeName(name));

        localStorage.setItem("isImpersonating", "true");

        dispatch(checkImpersonation(true));
        dispatch(setImpersonatedUser(name, role, email));

        setImpersonating(false);

        window.location.href = "/dashboard";
      } catch (error) {
        console.log(error);
        setImpersonating(false);
        toast.error(`Something went wrong, unable to masquerade ${email}`);
      }
    },
    [dispatch]
  );

  const onSortBy = useCallback(
    (columnId: SortBy) => {
      setSortBy(columnId);
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
      setPageNumber(1);
    },
    [sortOrder]
  );

  const onClearFilterBy = useCallback(() => {
    setFilterBy("");
    setSearchTerm("");
    setPageNumber(1);
    setSortBy("CREATEDAT");
    setSortOrder("DESC");
  }, []);

  const onChangeSearchTerm = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = ev.target.value;

      if (filterBy !== "" && newValue.trim() === "") {
        //reset filter
        onClearFilterBy();
      } else {
        setSearchTerm(newValue);
      }
    },
    [filterBy, onClearFilterBy]
  );

  const onFilterBy = useCallback(
    (ev: React.FormEvent) => {
      ev.preventDefault();

      if (searchTerm.trim() !== "") {
        setFilterBy(searchTerm.trim());
        setPageNumber(1);
        setSortBy("CREATEDAT");
        setSortOrder("DESC");
      }
    },
    [searchTerm]
  );

  const data = response && response.users ? response.users : [];

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xs={12} sm="auto" className="d-flex align-items-center mb-3 mb-sm-0">
            <Card.Title as="h1" className="mb-0">
              Super Admin
              {response && <small> - {response.pagination.total_records_count} existing users</small>}
            </Card.Title>
          </Col>
          <Col xs={12} sm="auto" className="ms-auto">
            <Form onSubmit={onFilterBy}>
              <InputGroup size="sm">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={onChangeSearchTerm}
                  disabled={isLoading}
                />

                <Button variant="primary" type="submit" className="d-flex align-items-center">
                  <SearchIcon size={14} />
                </Button>
              </InputGroup>
            </Form>
          </Col>
        </Row>
      </Card.Body>

      <Table size="sm" hover className="super-admin-table">
        <thead className="sticky-top z-2 shadow-lg">
          <tr className="table-light">
            <th>
              <Button variant="link" className="d-flex align-items-center gap-1 p-0" onClick={() => onSortBy("EMAIL")}>
                <span>Email</span>
                {sortBy === "EMAIL" ? (
                  sortOrder === "DESC" ? (
                    <CaretDownIcon size="14" />
                  ) : (
                    <CaretUpIcon size="14" />
                  )
                ) : null}
              </Button>
            </th>
            <th>
              <Button variant="link" className="d-flex align-items-center gap-1 p-0" onClick={() => onSortBy("NAME")}>
                <span>Name</span>
                {sortBy === "NAME" ? (
                  sortOrder === "DESC" ? (
                    <CaretDownIcon size="14" />
                  ) : (
                    <CaretUpIcon size="14" />
                  )
                ) : null}
              </Button>
            </th>
            <th>
              <Button variant="link" className="d-flex align-items-center gap-1 p-0" onClick={() => onSortBy("ACTIVE")}>
                <span>Active</span>
                {sortBy === "ACTIVE" ? (
                  sortOrder === "DESC" ? (
                    <CaretDownIcon size="14" />
                  ) : (
                    <CaretUpIcon size="14" />
                  )
                ) : null}
              </Button>
            </th>
            <th>Cognito status</th>
            <th>
              <Button
                variant="link"
                className="d-flex align-items-center gap-1 p-0"
                onClick={() => onSortBy("CREATEDAT")}
              >
                <span>Created at</span>
                {sortBy === "CREATEDAT" ? (
                  sortOrder === "DESC" ? (
                    <CaretDownIcon size="14" />
                  ) : (
                    <CaretUpIcon size="14" />
                  )
                ) : null}
              </Button>
            </th>
            <th>Actions</th>
          </tr>

          {isLoading && (
            <tr>
              <th colSpan={6} className="p-0">
                <ProgressBar animated now={100} style={{ borderRadius: 0, height: 10 }} />
              </th>
            </tr>
          )}
        </thead>

        <tbody>
          {data.map(({ id, email, name, active, cognito_user_status, cognito_user_status_description, created_at }) => (
            <tr key={id}>
              <td className="email-cell">
                <span className="user-select-all">{email}</span>{" "}
                {currentUserEmail === email && <Badge bg="success">YOU</Badge>}
              </td>
              <td className="name-cell">{name}</td>
              <td>{active ? "Yes" : "No"}</td>
              <td>
                <div className="d-flex align-items-center gap-1">
                  <span>{cognito_user_status}</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`cognito-user-status-${email}`}>{cognito_user_status_description}</Tooltip>}
                  >
                    <QuestionCircleIcon size="16" className="text-primary" />
                  </OverlayTrigger>
                </div>
              </td>
              <td>
                <div className="d-flex gap-1 flex-wrap">
                  <span>{new Date(created_at).toLocaleDateString()}</span>
                  <span>{new Date(created_at).toLocaleTimeString()}</span>
                </div>
              </td>
              <td>
                {currentUserEmail !== email && (
                  <div className="d-flex flex-wrap align-items-center gap-2">
                    {cognito_user_status === "Confirmed" && (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`impersonate-${email}`}>Impersonate</Tooltip>}
                      >
                        <Button
                          variant="outline-primary"
                          className="d-flex align-items-center"
                          size="sm"
                          onClick={() => {
                            impersonate(email, name);
                          }}
                          disabled={!!impersonating}
                        >
                          {impersonating && impersonating === email ? (
                            <Spinner animation="border" variant="primary" size="sm" />
                          ) : (
                            <EyeIcon size="16" />
                          )}
                        </Button>
                      </OverlayTrigger>
                    )}

                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`reset-password-${email}`}>Reset password</Tooltip>}
                    >
                      <Button
                        variant="outline-danger"
                        className="d-flex align-items-center"
                        size="sm"
                        onClick={() => setShowModalResetPassword(email)}
                        disabled={!!impersonating}
                      >
                        <KeyIcon size="16" />
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`enable-disable-features-${email}`}>Enable/Disable features</Tooltip>}
                    >
                      <Button
                        variant="outline-success"
                        className="d-flex align-items-center"
                        size="sm"
                        onClick={() => setShowUserFeaturesModal(id)}
                        disabled={!!impersonating}
                      >
                        <MenuButtonIcon size="16" />
                      </Button>
                    </OverlayTrigger>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Card.Body className="border-top bg-white sticky-bottom">
        <Row className="gx-3">
          <Col xs="auto">
            <Pagination
              currentPage={pageNumber}
              totalPages={response && response.pagination.total_pages}
              disabled={isLoading}
              onClick={setPageNumber}
            />
          </Col>
          <Col xs="auto" className="me-auto">
            <PageSize
              options={PAGE_SIZES.map((size) => Number(size.label))}
              value={Number(PAGE_SIZES.find((size) => size.value === pageSize)?.label)}
              onChange={(value) => {
                setPageSize(PAGE_SIZES.find(({ label }) => Number(label) === value)!.value);
                setPageNumber(1);
              }}
            />
          </Col>
          <Col xs="auto" className="d-flex align-items-center">
            <Button
              variant="link"
              className="p-0 d-flex align-items-center gap-2"
              onClick={() => {
                (document.querySelector("main.app-main") as HTMLElement).scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Back to Top
              <BackToTopIcon size="18" />
            </Button>
          </Col>
        </Row>
      </Card.Body>

      <ModalResetPassword
        show={!!showModalResetPassword}
        email={showModalResetPassword}
        onCancel={() => setShowModalResetPassword(false)}
      />

      <UserFeaturesModal
        show={!!showUserFeaturesModal}
        userId={showUserFeaturesModal ? showUserFeaturesModal : ""}
        onClose={() => setShowUserFeaturesModal(false)}
      />
    </Card>
  );
}
