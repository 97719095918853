import { useCallback, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";

import { somethingWentWrong } from "../../../lib/errors";
import AuthService from "../../../services/AuthService";

import LoginForm from "./LoginForm";
import NewPasswordRequiredForm from "./NewPasswordRequiredForm";

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [newPasswordRequiredForUser, setNewPasswordRequiredForUser] = useState(false);

  const onSubmitLoginForm = useCallback(async ({ email, password }) => {
    try {
      setIsLoading(true);

      const user = await AuthService.signIn(email, password);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setIsLoading(false);
        setNewPasswordRequiredForUser(user);
      }
    } catch (error) {
      setIsLoading(false);

      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error(somethingWentWrong);
      }
    }
  }, []);

  const onSubmitNewPasswordRequiredForm = useCallback(
    async ({ newPassword }) => {
      try {
        setIsLoading(true);

        await AuthService.completeNewPassword(newPasswordRequiredForUser, newPassword);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        if (error.message) {
          toast.error(error.message);
        } else {
          toast.error(somethingWentWrong);
        }
      }
    },
    [newPasswordRequiredForUser]
  );

  return (
    <Row className="mt-4">
      <Col xs={12} sm={8} lg={5} className="mx-auto">
        <Card>
          <Card.Body>
            <Card.Title>
              <h1 className="mt-0">Login</h1>
            </Card.Title>

            {!newPasswordRequiredForUser && <LoginForm isLoading={isLoading} onSubmit={onSubmitLoginForm} />}

            {newPasswordRequiredForUser && (
              <NewPasswordRequiredForm isLoading={isLoading} onSubmit={onSubmitNewPasswordRequiredForm} />
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
