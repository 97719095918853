import gql from "graphql-tag";

const getClaimSearchCreditorProgress = gql`
  query getClaimSearchCreditorProgress($batch_id: ID!, $claim_id: ID!) {
    getClaimSearchCreditorProgress(batch_id: $batch_id, claim_id: $claim_id) {
      done
      error
      error_code
      claim {
        status
        found_creditors {
          total_count
          matching {
            id
            name
            address_1
            address_2
            address_3
            address_4
            address_5
            match_pct
          }
          not_matching {
            id
            name
            address_1
            address_2
            address_3
            address_4
            address_5
            match_pct
          }
        }
        creditors_search_error
      }
    }
  }
`;

export default getClaimSearchCreditorProgress;
