import { useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import Papa from "papaparse";

import createCsvFile from "../createCsv";
import csvHeader from "../csvHeader";
import ImportHelp from "../ImportHelp";
import { validateSSN, validateTaxId } from "../lib";
import { ParsedCSVResult } from "../types";

import PasteCSVTextarea from "./PasteCSVTextarea";

export default function PasteCSV({ onFileSelected }: { onFileSelected: (file: File) => void }) {
  const [textareaContent, setTextareaContent] = useState("");
  const [errors, setErrors] = useState<ParsedCSVResult["errors"]>([]);
  const [loading, setLoading] = useState(false);

  const onTextareaChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setTextareaContent(ev.target.value);
    setErrors([]);
  };

  const onSubmit = async () => {
    try {
      const results = await parseTextareaCsv([csvHeader.join(","), textareaContent].join("\n"));
      const validationErrors: ParsedCSVResult["errors"] = [];
      results.forEach((row, index) => {
        if (row["SSN"] && !validateSSN(row["SSN"])) {
          validationErrors.push({
            type: "InvalidSSN",
            code: "InvalidSSN",
            message: "Invalid SSN",
            row: index,
          });
        }

        if (row["TAXID"] && !validateTaxId(row["TAXID"])) {
          validationErrors.push({
            type: "InvalidTAXID",
            code: "InvalidTAXID",
            message: "Invalid TAXID",
            row: index,
          });
        }
      });

      if (validationErrors.length > 0) {
        setErrors(validationErrors);
        return;
      }

      setErrors([]);
      setLoading(true);
      const today = new Date();
      const date = [
        today.toLocaleDateString("en-US", { year: "numeric" }),
        today.toLocaleDateString("en-US", { month: "numeric" }),
        today.toLocaleDateString("en-US", { day: "numeric" }),
      ].join("-");
      const file = await createCsvFile(Papa.unparse(results), `paste-csv-${date}.csv`);
      onFileSelected(file);
    } catch (errors) {
      setErrors(errors as ParsedCSVResult["errors"]);
      return;
    }
  };

  const isInvalid = errors.length > 0;

  return (
    <Card className="import-card">
      <Card.Body>
        <fieldset className="p-0" disabled={loading}>
          <ImportHelp />

          <p className="fw-semibold mb-1">CSV columns:</p>

          <div className="mb-3">
            <code>{csvHeader.join(",")}</code>
          </div>

          <Form.Group className="mb-3">
            <PasteCSVTextarea
              value={textareaContent}
              onChange={onTextareaChange}
              isInvalid={isInvalid}
              errors={errors}
            />
            <Form.Control.Feedback type="invalid">
              {isInvalid && (
                <>
                  One or more rows are invalid, please fix them:
                  <ul className="ps-3">
                    {errors.map(({ message, row }) => (
                      <li key={row}>
                        {row + 1}. {message}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Form.Control.Feedback>
          </Form.Group>

          <Button variant="primary" type="button" onClick={onSubmit}>
            Import
          </Button>
        </fieldset>
      </Card.Body>
    </Card>
  );
}

function parseTextareaCsv(data: string): Promise<ParsedCSVResult["data"]> {
  return new Promise(function (resolve, reject) {
    Papa.parse(data, {
      header: true,
      complete: function (results: ParsedCSVResult) {
        console.log(results.errors);
        if (results.errors.length > 0) {
          reject(results.errors);
        } else {
          resolve(results.data);
        }
      },
    });
  });
}
