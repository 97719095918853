import gql from "graphql-tag";

const getClaimFormPreviewLink = gql`
  query getClaimFormPreviewLink($batch_id: ID!, $claim_id: ID!) {
    getClaimFormPreviewLink(batch_id: $batch_id, claim_id: $claim_id) {
      error
      url
    }
  }
`;

export default getClaimFormPreviewLink;
