import gql from "graphql-tag";

const getEPOCAttachmentUploadUrl = gql`
  query getEPOCAttachmentUploadUrl($claim_id: ID!, $filename: String!) {
    getEPOCAttachmentUploadUrl(claim_id: $claim_id, filename: $filename) {
      error
      url
      s3_key
    }
  }
`;

export default getEPOCAttachmentUploadUrl;
