import { useState, useEffect, useCallback } from "react";
import { Row, Col, Spinner, Card, Button, Container } from "react-bootstrap";
import { toast } from "react-toastify";

import { apolloClient } from "../../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../../lib/errors";

import getAvailablePaymentMethod from "./graphql/getAvailablePaymentMethod";
import checkoutClaim from "./graphql/checkoutClaim";
import purchaseClaim from "./graphql/purchaseClaim";

interface AvailablePaymentMethod {
  payment_method_available: boolean;
  payment_method: {
    id: string;
    card_brand: string;
    last_four_digits: string;
  };
}

interface Props {
  claimId: string;
  onPurchaseConfirmed: () => void;
}
export default function Payment({ claimId, onPurchaseConfirmed }: Props) {
  const [response, setResponse] = useState<AvailablePaymentMethod | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<"checkout" | "purchase" | false>(false);

  useEffect(() => {
    const watchedQuery = apolloClient.watchQuery({
      query: getAvailablePaymentMethod,
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        console.log(response);
        setResponse(response.data.getAvailablePaymentMethod as AvailablePaymentMethod);
      },
      error(err) {
        console.log("[watchQuery] error", err);
        toast.error(somethingWentWrong);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, []);

  const onCheckoutClaim = useCallback(async () => {
    try {
      setIsLoading("checkout");

      const response = await apolloClient.mutate({
        mutation: checkoutClaim,
        variables: {
          claim_id: claimId,
        },
      });

      setIsLoading(false);

      if (response.data.checkoutClaim.error) {
        toast.error(somethingWentWrong);
      } else {
        window.location = response.data.checkoutClaim.stripe_redirect_url;
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(somethingWentWrong);
    }
  }, [claimId]);

  const onPurchaseClaim = useCallback(async () => {
    try {
      setIsLoading("purchase");

      const response = await apolloClient.mutate({
        mutation: purchaseClaim,
        variables: {
          claim_id: claimId,
        },
      });

      setIsLoading(false);

      if (response.data.purchaseClaim.error) {
        toast.error(somethingWentWrong);
      } else {
        // callback
        onPurchaseConfirmed();
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(somethingWentWrong);
    }
  }, [claimId, onPurchaseConfirmed]);

  if (!response) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (response.payment_method_available) {
    return (
      <Container>
        <Card>
          <Card.Body>
            <Card.Title>Congratulations, you are almost done!</Card.Title>
            <Card.Text className="mb-1">
              We're going to pre authorize <strong>$10</strong> on your{" "}
              <code>{response.payment_method.card_brand}</code> card that ends with{" "}
              <code>{response.payment_method.last_four_digits}</code>.
            </Card.Text>

            <Card.Text>
              <strong>
                Your card will NOT be charged until your Proof of Claim is successfully submitted to the court.
              </strong>
            </Card.Text>

            <Row>
              <Col sm="auto" className="me-auto mb-3 mb-sm-0">
                <Button type="button" variant="primary" disabled={!!isLoading} onClick={onPurchaseClaim}>
                  {isLoading === "purchase" ? <Spinner animation="border" size="sm" /> : "Confirm payment"}
                </Button>
              </Col>

              <Col sm="auto">
                <Button type="button" variant="light" disabled={!!isLoading} onClick={onCheckoutClaim}>
                  {isLoading === "checkout" ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Use a different payment method"
                  )}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  return (
    <Container>
      <Card>
        <Card.Body>
          <Card.Title>Congratulations, you are almost done!</Card.Title>
          <Card.Text className="mb-1">
            To proceed with filing your Proof of Claim, we now need to authorize your card to validate source of
            payment.
          </Card.Text>

          <Card.Text className="mb-1">
            <strong>
              Your card will NOT be charged until your Proof of Claim is successfully submitted to the court.
            </strong>
          </Card.Text>

          <Card.Text>
            After it is successfully submitted, you will be charged <strong>$10</strong> and emailed a receipt along
            with a separate email of the court filed Proof of Claim.
          </Card.Text>

          <Button type="button" variant="primary" onClick={onCheckoutClaim} disabled={!!isLoading}>
            {isLoading === "checkout" ? <Spinner animation="border" size="sm" /> : "Continue"}
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
}
