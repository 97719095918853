import { useState, useCallback } from "react";
import { Modal, Form, Alert, Button } from "react-bootstrap";

interface Props {
  show: boolean;
  handleClose: () => void;
  onConfirm: () => void;
}
export default function ConfirmModal({ show, handleClose, onConfirm }: Props) {
  const [ack1, setAck1] = useState(false);
  const [ack2, setAck2] = useState(false);
  const [ack3, setAck3] = useState(false);

  const onExited = useCallback(() => {
    setAck1(false);
    setAck2(false);
    setAck3(false);
  }, []);

  return (
    <Modal show={show} onHide={handleClose} onExited={onExited}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm & Submit</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Check
          type="checkbox"
          id="acknowledgement-check-1"
          className="mb-2"
          label="I understand that an authorized signature on this Proof of Claim serves as an acknowledgment that when calculating the amount of the claim, the creditor gave the debtor credit for any payments received toward the debt."
          checked={ack1}
          onChange={(event) => setAck1(event.target.checked)}
          isValid={ack1}
        />

        <Form.Check
          type="checkbox"
          id="acknowledgement-check-2"
          className="mb-2"
          label="I have examined the information in this Proof of Claim and have a reasonable belief that the information is true and correct."
          checked={ack2}
          onChange={(event) => setAck2(event.target.checked)}
          isValid={ack2}
        />

        <Form.Check
          type="checkbox"
          id="acknowledgement-check-3"
          className="mb-0"
          label="I declare under penalty of perjury that the foregoing is true and correct."
          checked={ack3}
          onChange={(event) => setAck3(event.target.checked)}
          isValid={ack3}
        />

        {ack1 && ack2 && ack3 && (
          <Alert variant="danger" className="mt-3 mb-0 shadow">
            <strong className="alert-message text-danger">
              A person who files a fraudulent claim could be fined up to $500,000, imprisoned for up to 5 years, or
              both. 18 U.S.C. §§ 152, 157 and 3571.
            </strong>
          </Alert>
        )}
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <Button variant="light" onClick={handleClose}>
          Cancel
        </Button>

        <Button variant="success" onClick={onConfirm} disabled={!(ack1 && ack2 && ack3)}>
          I understand, checkout & submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
