import moment from "moment";
import momentTz from "moment-timezone";

export default function getPurchaseDetails({ type, expireAt }) {
  if (type === "monthly_subscription") {
    return "You're currently subscribed to a Monthly Plan for this case.";
  }

  return [
    "You currently have a Day Pass for this case.",
    `Access to Docket will expire on ${moment(
      momentTz(new Date(+expireAt * 1000)).tz("America/New_York")
    ).format("MMMM Do YYYY, h:mm a")} New York time.`
  ].join(" ");
}
