import { useEffect, useState, useCallback } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";

import Logo from "../../../components/atoms/Logo";
import { loginLinkExpired, somethingWentWrong } from "../../../lib/errors";
import PasswordStrengthInfo from "../PasswordStrengthInfo";
import AuthService from "../../../services/AuthService";

const schema = yup.object().shape({
  password: yup.string().matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, "Invalid password"),
  confirmPassword: yup.string().test("passwords-match", "Passwords must match", function (value) {
    return this.parent.password === value;
  }),
});

export default function SignupChoosePassword({
  match: {
    params: { token },
  },
  history,
}) {
  const { email, temporaryPassword } = JSON.parse(atob(token));
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const password = watch("password", "");

  useEffect(() => {
    async function attemptSignIn() {
      try {
        const user = await AuthService.signIn(email, temporaryPassword);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          //login link still valid
          setIsAuthenticating(false);
        }
      } catch (error) {
        console.log(error);
        toast.error(loginLinkExpired);
        //login link not valid anymore, redirect to login
        history.push(`/auth/login?email=${email}`);
      }
    }

    attemptSignIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    async ({ password }) => {
      try {
        setIsLoading(true);

        const user = await AuthService.signIn(email, temporaryPassword);

        await AuthService.completeNewPassword(user, password);

        //hotfix
        window.location.href = "/cases";
        return false;
      } catch (error) {
        toast.error(somethingWentWrong);
        console.log("onSubmit error", error);
        setIsLoading(false);
      }
    },
    [email, temporaryPassword]
  );

  return (
    <Container fluid>
      <Row>
        <Col md="7" lg="5" className="mx-auto">
          <h1 className="d-flex justify-content-center mt-5 mb-4">
            <Logo height={40} />
          </h1>
          <Card>
            <Card.Body>
              {!isAuthenticating && (
                <>
                  <Card.Title>
                    <h3>Choose a password for your account to continue</h3>
                  </Card.Title>

                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId="email" className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="text" value={email} readOnly />
                    </Form.Group>

                    <Form.Group controlId="password" className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        {...register("password")}
                        type="password"
                        isInvalid={errors.password}
                        disabled={isLoading}
                      />

                      <PasswordStrengthInfo password={password} />
                    </Form.Group>

                    <Form.Group controlId="confirmPassword" className="mb-3">
                      <Form.Label>Confirm password</Form.Label>
                      <Form.Control
                        {...register("confirmPassword")}
                        type="password"
                        isInvalid={errors.confirmPassword}
                        disabled={isLoading}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword && errors.confirmPassword.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Button type="submit" disabled={isLoading}>
                      {isLoading ? <Spinner animation="border" variant="light" size="sm" /> : "Confirm"}
                    </Button>
                  </Form>
                </>
              )}
              {isAuthenticating && (
                <div className="text-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
