import React from "react";
import classnames from "classnames";

import BKalertsLogo from "../../assets/images/logo.png";
import BKalertsLogoInverse from "../../assets/images/logo-inverse.png";
import BKalertsLogoMobile from "../../assets/images/logo-mobile.png";

export default function Logo({
  className = "",
  height = 30,
  inverse = false,
  mobile = false,
}) {
  return (
    <img
      src={
        mobile
          ? BKalertsLogoMobile
          : inverse
          ? BKalertsLogoInverse
          : BKalertsLogo
      }
      alt="BKalerts"
      className={classnames(className)}
      height={height}
    />
  );
}
