import { useEffect } from "react";
import classnames from "classnames";
import { Modal, Spinner, Button, Table, Form } from "react-bootstrap";
import { Observer, useLocalObservable, observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import { toast } from "react-toastify";

import { Claim, ClaimCreditors } from "../types";
import { apolloClient } from "../../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../../lib/errors";

import districts from "./districts";
import resolveClaimCreditor from "../graphql/resolveClaimCreditor";
import getClaimSearchCreditorProgress from "../graphql/getClaimSearchCreditorProgress";

interface IProps {
  show: boolean;
  onClose: () => void;
  claim: Claim | null;
  batchId: string | null;
  saveClaimCreditor: (
    claim: Claim,
    {
      creditor_id,
      creditor_name,
      is_new_creditor,
    }: { creditor_id?: string; creditor_name?: string; is_new_creditor?: boolean }
  ) => void;
}
export default function ClaimCreditorResolverModal({ show, onClose, claim, batchId, saveClaimCreditor }: IProps) {
  return (
    <Modal show={show} onHide={onClose} size="lg">
      {claim && batchId && (
        <ModalContent onClose={onClose} claim={claim} batchId={batchId} saveClaimCreditor={saveClaimCreditor} />
      )}
    </Modal>
  );
}

function ModalContent({
  onClose,
  claim,
  batchId,
  saveClaimCreditor,
}: {
  onClose: () => void;
  claim: Claim;
  batchId: string;
  saveClaimCreditor: (
    claim: Claim,
    {
      creditor_id,
      creditor_name,
      is_new_creditor,
    }: { creditor_id?: string; creditor_name?: string; is_new_creditor?: boolean }
  ) => void;
}) {
  console.log("[ModalContent] rendering");

  const state = useLocalObservable(() => ({
    searchingClaimCreditors: !claim.found_creditors,
    selectedCreditor:
      claim.found_creditors && claim.found_creditors.matching.length === 1
        ? claim.found_creditors.matching[0].id
        : claim.found_creditors && claim.found_creditors.matching.length > 1
        ? undefined
        : null,
    searchClaimCreditors: function* searchClaimCreditors() {
      this.searchingClaimCreditors = true;

      try {
        console.log("[ModalContent] Running resolveClaimCaseNumber query");

        const resolveClaimCreditorResponse: {
          data: {
            resolveClaimCreditor: { error?: string };
          };
        } = yield apolloClient.query({
          query: resolveClaimCreditor,
          variables: {
            batch_id: batchId,
            claim_id: claim.id,
          },
          fetchPolicy: "network-only",
        });
        console.log("[ModalContent] response");
        console.log(resolveClaimCreditorResponse);

        if (resolveClaimCreditorResponse.data.resolveClaimCreditor.error) {
          toast.error(resolveClaimCreditorResponse.data.resolveClaimCreditor.error);
          onClose();
          return;
        }

        // Loop to check progress
        let taskIsPending = true;
        const sleepingTime = 2000;

        let response: {
          done?: boolean;
          error?: string;
          error_code?: string;
          claim?: Claim;
        } = {};

        while (taskIsPending) {
          console.log("[ModalContent] Polling getClaimSearchCaseProgress");

          const progressResponse: {
            data: {
              getClaimSearchCreditorProgress: {
                done?: boolean;
                error?: string;
                error_code?: string;
                claim?: Claim;
              };
            };
          } = yield apolloClient.query({
            query: getClaimSearchCreditorProgress,
            variables: {
              batch_id: batchId,
              claim_id: claim.id,
            },
            fetchPolicy: "network-only",
          });

          response = progressResponse.data.getClaimSearchCreditorProgress;

          if (response.error) {
            toast.error(response.error, {
              autoClose: false,
            });

            onClose();
            return;
          }

          if (!response.done) {
            console.log(`[ModalContent] Not done, sleeping ${sleepingTime}s`);
            yield sleep(sleepingTime);
            continue;
          } else {
            console.log("[ModalContent] done, found creditor");

            taskIsPending = false;
          }
        }

        runInAction(() => {
          this.searchingClaimCreditors = false;

          // Update claim locally
          if (response.claim?.status) claim.status = response.claim.status;

          claim.found_creditors = response.claim?.found_creditors;
          claim.creditors_search_error = response.claim?.creditors_search_error;

          this.selectedCreditor =
            claim.found_creditors && claim.found_creditors.matching.length === 1
              ? claim.found_creditors.matching[0].id
              : claim.found_creditors && claim.found_creditors.matching.length > 1
              ? undefined
              : null;
        });
      } catch (error) {
        console.log(error);
        toast.error(somethingWentWrong);
        onClose();
      }
    },
  }));

  useEffect(() => {
    let promise: any;

    if (state.searchingClaimCreditors) {
      console.log("[ModalContent] useEffect searchingClaimCreditors");
      promise = state.searchClaimCreditors();
    }

    return () => {
      if (promise) {
        console.log("[ModalContent] canceling promise");
        promise.cancel();
      }
    };
  });

  return (
    <Observer>
      {() => {
        console.log("[ModalContent] observer rendering");

        const districtName =
          districts.find((district) => district.name.toLowerCase() === claim.district?.toLowerCase())?.formName || "";

        if (state.searchingClaimCreditors)
          return (
            <Modal.Body>
              <h2 className="text-center fw-normal">
                Getting creditors list for case number <strong>{claim.case_no}</strong> on {districtName}.
              </h2>

              <p className="lead text-center">Please wait, this request may take a little while.</p>

              <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="primary" />
              </div>
            </Modal.Body>
          );

        if (claim.creditors_search_error)
          return (
            <>
              <Modal.Body>
                <h2 className="text-danger fw-normal mb-3">
                  The following error occurred while searching for the creditor: "{claim.creditors_search_error}"
                </h2>
                <p className="lead">Please try again or contact support if problem persist</p>
              </Modal.Body>

              <Modal.Footer className="justify-content-between">
                <Button type="button" variant="secondary" onClick={onClose}>
                  Cancel
                </Button>

                <Button type="button" variant="primary" onClick={() => state.searchClaimCreditors()}>
                  Retry
                </Button>
              </Modal.Footer>
            </>
          );

        if (claim.found_creditors) {
          return (
            <>
              <Modal.Body>
                {claim.found_creditors.matching.length === 0 ? (
                  <>
                    <h2 className="fw-normal mb-3">
                      We haven't found any creditor matching the name "{claim.creditor?.name}" for the case number "
                      {claim.case_no}" in the {districtName}.
                    </h2>
                  </>
                ) : claim.found_creditors.matching.length === 1 ? (
                  <>
                    <h2 className="fw-normal mb-3">
                      We found a creditor matching the name "{claim.creditor?.name}" for the case number "
                      {claim.case_no}" on {districtName}.
                    </h2>
                    <p className="lead fw-bold">Please confirm that's what you've been looking for:</p>
                  </>
                ) : (
                  <>
                    <h2 className="fw-normal mb-3">
                      We found some creditors matching the name "{claim.creditor?.name}" for the case number "
                      {claim.case_no}" on {districtName}.
                    </h2>
                    <p className="lead fw-bold">Please select a creditor:</p>
                  </>
                )}

                <CreditorsSearchResults
                  creditors={claim.found_creditors}
                  selectedCreditor={state.selectedCreditor}
                  onSelectCreditor={(creditorId) => (state.selectedCreditor = creditorId)}
                  districtName={districtName}
                />
              </Modal.Body>

              <Modal.Footer className="justify-content-between">
                <Button type="button" variant="secondary" onClick={onClose}>
                  Cancel
                </Button>

                <Button
                  type="button"
                  variant="success"
                  disabled={state.selectedCreditor === undefined}
                  onClick={() => {
                    saveClaimCreditor(claim, {
                      ...(state.selectedCreditor
                        ? {
                            creditor_id: state.selectedCreditor,
                            creditor_name: [
                              ...(claim.found_creditors?.matching || []),
                              ...(claim.found_creditors?.not_matching || []),
                            ].find((creditor) => creditor.id === state.selectedCreditor)?.name,
                          }
                        : {}),
                      ...(!state.selectedCreditor ? { is_new_creditor: true } : {}),
                    });
                    onClose();
                  }}
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </>
          );
        }

        return null;
      }}
    </Observer>
  );
}

export const CreditorsSearchResults = observer(function CreditorsSearchResults({
  creditors,
  selectedCreditor,
  onSelectCreditor,
  districtName,
  selectInputName = "select-claim-creditor",
}: {
  creditors: ClaimCreditors;
  selectedCreditor?: string | null;
  onSelectCreditor: (creditorId: string | null) => void;
  districtName: string;
  selectInputName?: string;
}) {
  return (
    <>
      {creditors.total_count > 0 && (
        <>
          {creditors.matching.length > 0 && (
            <Table hover size="sm">
              <thead className="table-light">
                <tr>
                  <th></th>
                  <th>Match</th>
                  <th>Name</th>
                  <th>Address</th>
                </tr>
              </thead>
              <tbody>
                {creditors.matching.map((creditor) => {
                  const selected = selectedCreditor === creditor.id;

                  return (
                    <tr
                      key={creditor.id}
                      role="button"
                      className={classnames({
                        "table-primary": selected,
                      })}
                      onClick={() => onSelectCreditor(creditor.id)}
                    >
                      <td>
                        <Form.Check
                          type="radio"
                          name={selectInputName}
                          className="pe-none"
                          checked={selected}
                          readOnly
                        />
                      </td>
                      <td>
                        {typeof creditor.match_pct === "number" && creditor.match_pct > 0.6 ? (
                          <strong className="text-success">{(creditor.match_pct * 100).toFixed(0)}%</strong>
                        ) : null}
                      </td>
                      <td>{creditor.name}</td>
                      <td>
                        {[
                          creditor.address_1,
                          creditor.address_2,
                          creditor.address_3,
                          creditor.address_4,
                          creditor.address_5,
                        ]
                          .filter(Boolean)
                          .join(", ")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}

          {creditors.not_matching.length > 0 && (
            <>
              <p className="lead fw-bold">
                {creditors.matching.length > 0
                  ? "Other non matching creditors found on case:"
                  : "Low matching creditors found on case"}
              </p>

              <div className="mb-3" style={{ height: 200, overflowY: "scroll" }}>
                <Table hover size="sm" className="mb-0">
                  <thead className="table-light position-sticky sticky-top">
                    <tr>
                      <th></th>
                      <th>Match</th>
                      <th>Name</th>
                      <th>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {creditors.not_matching.map((creditor) => {
                      const selected = selectedCreditor === creditor.id;

                      return (
                        <tr
                          key={creditor.id}
                          role="button"
                          className={classnames({
                            "table-primary": selected,
                          })}
                          onClick={() => onSelectCreditor(creditor.id)}
                        >
                          <td>
                            <Form.Check
                              type="radio"
                              name="select-claim-creditor"
                              className="pe-none"
                              checked={selected}
                              readOnly
                            />
                          </td>
                          <td>
                            {typeof creditor.match_pct === "number" ? (
                              <strong className="text-success">{(creditor.match_pct * 100).toFixed(0)}%</strong>
                            ) : null}
                          </td>
                          <td>{creditor.name}</td>
                          <td>
                            {[
                              creditor.address_1,
                              creditor.address_2,
                              creditor.address_3,
                              creditor.address_4,
                              creditor.address_5,
                            ]
                              .filter(Boolean)
                              .join(", ")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </>
      )}

      <div className="d-flex align-items-center gap-2" role="button" onClick={() => onSelectCreditor(null)}>
        <Form.Check.Input
          type="radio"
          name="select-claim-creditor"
          className="pe-none m-0"
          checked={selectedCreditor === null}
          readOnly
        />
        <Form.Check.Label className="fw-bolder pe-none">Add a new creditor on {districtName}</Form.Check.Label>
      </div>
    </>
  );
});

const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));
