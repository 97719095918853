import React from "react";
import classnames from "classnames";
import Card from "react-bootstrap/Card";
import BoostrapTable from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

import SortIcon from "../atoms/SortIcon";
import TableContentLoader from "../atoms/TableContentLoader";

export default function DataGridTable({
  className,
  tableClassName,
  highlightOnHover = true,
  isRowClickable = () => false,
  onRowClick,
  headerColumns,
  selectableRows,
  sortableRows,
  allSelected,
  onChangeSelectAll,
  sortBy,
  sortOrder,
  onSortBy,
  isLoading,
  data,
  selectedRecords = [],
  onSelectRecord,
  noRecordsFoundLabel,
  renderRow,
  keyExtractor = (row) => row.id,
  loadingPlaceholderRowsCount = 4,
  children,
}) {
  const noRecordsFound = !isLoading && data.length === 0;
  const isTableSortable = !noRecordsFound && sortableRows;

  return (
    <Card className={className}>
      <BoostrapTable
        hover={highlightOnHover && !noRecordsFound}
        size="sm"
        className={tableClassName}
      >
        <thead className="table-light shadow-lg">
          <tr>
            {selectableRows && (
              <th className="select_all">
                <Form.Check
                  type="checkbox"
                  checked={!noRecordsFound && allSelected}
                  onChange={onChangeSelectAll}
                  disabled={noRecordsFound || isLoading}
                />
              </th>
            )}
            {headerColumns.map(
              ({
                id,
                label,
                columnWidth,
                sortable: isColumnSortable = true,
              }) => (
                <th
                  key={id}
                  {...(columnWidth && { width: columnWidth })}
                  {...(isTableSortable &&
                    isColumnSortable && {
                      onClick: () => {
                        if (isTableSortable) {
                          onSortBy(id);
                        }
                      },
                    })}
                  className={id}
                >
                  <div
                    className={classnames("d-flex align-items-center", {
                      "cursor-pointer": isTableSortable && isColumnSortable,
                    })}
                  >
                    <span className="me-3">{label}</span>
                    {isTableSortable && isColumnSortable && (
                      <SortIcon direction={sortBy === id ? sortOrder : null} />
                    )}
                  </div>
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          <TableBody
            headerColumns={headerColumns}
            isLoading={isLoading}
            data={data}
            selectedRecords={selectedRecords}
            onSelectRecord={onSelectRecord}
            noRecordsFound={noRecordsFound}
            noRecordsFoundLabel={noRecordsFoundLabel}
            selectableRows={selectableRows}
            renderRow={renderRow}
            keyExtractor={keyExtractor}
            isRowClickable={isRowClickable}
            onRowClick={onRowClick}
            loadingPlaceholderRowsCount={loadingPlaceholderRowsCount}
          >
            {children}
          </TableBody>
        </tbody>
      </BoostrapTable>
    </Card>
  );
}

function TableBody({
  headerColumns,
  isLoading,
  data,
  selectedRecords,
  onSelectRecord,
  noRecordsFound,
  noRecordsFoundLabel,
  selectableRows,
  renderRow,
  keyExtractor,
  isRowClickable,
  onRowClick,
  loadingPlaceholderRowsCount,
  children,
}) {
  const columnsCount = headerColumns.length + (selectableRows ? 1 : 0);

  if (isLoading) {
    return (
      <TableContentLoader
        rows={loadingPlaceholderRowsCount}
        columns={columnsCount}
      />
    );
  }

  if (noRecordsFound) {
    return (
      <tr className="no-records-found">
        <td className="text-center" colSpan={columnsCount}>
          {noRecordsFoundLabel}
        </td>
      </tr>
    );
  }

  return (
    <>
      {data.map((row, index) => (
        <tr
          key={keyExtractor(row, index)}
          {...(isRowClickable(row) && {
            role: "button",
            onClick: () => onRowClick(row),
          })}
          className={classnames({
            "no-hover": !isRowClickable(row),
            "table-primary": selectedRecords.includes(row.id),
          })}
        >
          {selectableRows && (
            <td className="select_row">
              <Form.Check
                type="checkbox"
                checked={selectedRecords.includes(row.id)}
                onClick={(ev) => {
                  //to avoid click propagation
                  ev.stopPropagation();
                }}
                onChange={() => onSelectRecord(row.id)}
              />
            </td>
          )}
          {renderRow(row)}
        </tr>
      ))}
      {children}
    </>
  );
}
