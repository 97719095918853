import gql from "graphql-tag";

const checkoutClaim = gql`
  mutation checkoutClaim($claim_id: ID!) {
    checkoutClaim(claim_id: $claim_id) {
      error
      stripe_redirect_url
    }
  }
`;

export default checkoutClaim;
