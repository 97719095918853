export function isLongCaseNumber(caseNumber: string) {
  return caseNumber.match(
    /(?<office>\d|\w)[:](?<year>\d{1,4})(?:[\s-])?(?<type>[a-zA-Z0-9]{1,5})(?:[\s-])?(?<case_number>\d{1,5})/
  );
}

export function isShortCaseNumber(caseNumber: string) {
  return caseNumber.match(/^\d{2}-\d{1,5}$/);
}

export function isValidNumber(value?: null | string | number) {
  // Don't validate null and undefined
  if (value == null) return true;

  // If number pass-through
  if (typeof value === "number") return true;

  // Permit empty string values(not trimmed) for resetting fields
  if (typeof value === "string" && value === "") return true;

  return !isNaN(parseFloat(value));
}
