import gql from "graphql-tag";

const getClaimSearchCaseProgress = gql`
  query getClaimSearchCaseProgress($batch_id: ID!, $claim_id: ID!) {
    getClaimSearchCaseProgress(batch_id: $batch_id, claim_id: $claim_id) {
      done
      error
      error_code
      claim {
        status
        found_cases {
          case_no
          case_title
          court_code
          chapter
          date_filed
          date_closed
          match_pct
        }
        cases_search_error
      }
    }
  }
`;

export default getClaimSearchCaseProgress;
