import { useEffect } from "react";
import { Spinner, Container, Card } from "react-bootstrap";
import { toast } from "react-toastify";

import { SelectedCase, Creditor, Signee } from "../interfaces";
import { FormValues as DocumentationData } from "../Documentation/interfaces";
import { apolloClient } from "../../../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../../../lib/errors";

import saveClaim, { buildMutationInput } from "../../graphql/saveClaim";

interface Props {
  claimId?: string;
  selectedCase: SelectedCase;
  selectedCreditor: Creditor;
  documentation: DocumentationData;
  signee: Signee;
  onClaimSaved: (claimId: string) => void;
}
export default function Submit({
  claimId,
  selectedCase,
  selectedCreditor,
  documentation,
  signee,
  onClaimSaved,
}: Props) {
  useEffect(() => {
    async function save() {
      console.log("Saving Claim");

      try {
        const input = buildMutationInput({
          claimId,
          selectedCase,
          selectedCreditor,
          documentation,
          signee,
        });

        console.log(input);

        const response = await apolloClient.mutate({
          mutation: saveClaim,
          variables: {
            input,
          },
        });

        console.log(response);
        toast.success("Claim successfully saved");
        onClaimSaved(response.data.saveClaim.claim_id);
      } catch (error) {
        console.log(error);
        toast.error(somethingWentWrong);
      }
    }

    save();
  }, [claimId, documentation, onClaimSaved, selectedCase, selectedCreditor, signee]);

  return (
    <Container fluid="lg" className="py-3">
      <Card>
        <Card.Body>
          <Card.Title className="mb-3 d-flex justify-content-center">
            <h1 className="text-center">Saving Claim, don't close this page</h1>
          </Card.Title>

          <div className="d-flex justify-content-center">
            <Spinner variant="primary" animation="border" />
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}
