import { Row, Col } from "react-bootstrap";

const faqs = [
  [
    {
      title: "Identify new bankruptcies from your portfolio",
      description:
        "Our AI powered solution will match results with corresponding probabilities with smarts to identify common names such as Robert vs Bob and transposed numbers.",
    },
    {
      title: "Find bankruptcy cases with our real time PACER interface",
      description:
        "Ensures fastest and most cost effective solution to find any case, docket, claims register, mailing matrix and creditors.",
    },
  ],
  [
    {
      title: "Save PACER expenditures with largest dataset of PACER filings",
      description:
        "Our community based platform used by bankruptcy attorneys helps minimize PACER costs with the largest database of PDFs.",
    },
    {
      title: "Monitor docket events with AI powered workflow tools",
      description: "Our AI Bankruptcy Monitoring Platform monitors docket events with over 2,000 events.",
    },
  ],
  [
    {
      title: "Calendar key dates to never miss a deadline",
      description:
        "Our AI power docket calendaring solution helps identify key dates like deadline to file proof of claim.",
    },
    { title: "File Proof of Claims in bulk", description: "File your Proof of Claims online seamlessly in bulk." },
  ],
];

export default function Faqs() {
  return (
    <>
      {faqs.map(([first, second]) => (
        <Row className="mb-2" key={`${first.title}_${second.title}`}>
          <Col sm={12} md={6}>
            <p className="fw-bold mb-1">{first.title}</p>
            {first.description}
          </Col>
          <Col sm={12} md={6}>
            <p className="fw-bold mb-1">{second.title}</p>
            {second.description}
          </Col>
        </Row>
      ))}
    </>
  );
}
