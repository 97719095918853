import gql from "graphql-tag";

const searchCasesOnPacer = gql`
  query searchCasesOnPacer($input: String!) {
    searchCasesOnPacer(input: $input) {
      id
      title
      court_code
      court_name
      case_no
      chapter
      date_filed
      date_closed
      disposition
    }
  }
`;

export default searchCasesOnPacer;
