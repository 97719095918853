import { useState, useCallback, useEffect } from "react";
import classnames from "classnames";
import {
  Card,
  Row,
  Col,
  Table,
  Button,
  Spinner,
  Dropdown,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  CaretUpFill as CaretUpIcon,
  CaretDownFill as CaretDownIcon,
  ArrowClockwise as ArrowClockwiseIcon,
  PencilSquare as PencilSquareIcon,
  Trash as TrashIcon,
  ArrowUpCircleFill as BackToTopIcon,
  Upload as UploadIcon,
} from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import Pagination from "../../components/atoms/Pagination/Pagination";
import PageSize from "../../components/PageSize";
import ConfirmModal from "../../components/ConfirmModal";
import IndeterminateCheckbox from "../../components/IndeterminateCheckbox";
import SearchInput from "../../components/SearchInput/SearchInput";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import { apolloClient } from "../../configs/apollo-client";
import { somethingWentWrong } from "../../lib/errors";

import ModifyRecordModal from "./ModifyRecordModal";
import listTrackedDebtors from "./graphql/listTrackedDebtors";
import deleteTrackedDebtors from "./graphql/deleteTrackedDebtors";
import deleteAllTrackedDebtors from "./graphql/deleteAllTrackedDebtors";

type TrackedDebtorsResponse = {
  tracked_debtors: TrackedDebtor[];
  pagination: {
    partial_records_count: number;
    total_records_count: number;
    total_pages: number;
  };
};

type TrackedDebtor = {
  id: string;
  ssn: string;
  taxid: string;
  full_name: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  generation: string;
  full_address: string;
  address_line_1: string;
  address_line_2: string;
  address_line_3: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  phone: string;
  last_check: string;
  created_at: string;
  updated_at: string;
};

type SortBy = "SSN" | "TAXID" | "NAME" | "ADDRESS" | "PHONE" | "CREATEDAT";

const header: {
  id: SortBy | "SELECTALL";
  label: string;
  sortable: boolean;
}[] = [
  { id: "SELECTALL", label: "", sortable: false },
  { id: "SSN", label: "SSN", sortable: true },
  { id: "TAXID", label: "TAXID", sortable: true },
  { id: "NAME", label: "Full name / Company name", sortable: true },
  { id: "ADDRESS", label: "Address", sortable: true },
  { id: "PHONE", label: "Phone", sortable: true },
  { id: "CREATEDAT", label: "Created at", sortable: true },
];

export default function TrackedDebtors() {
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState<SortBy>(() => {
    const bkAlertsTrackedDebtorsSortBy = localStorage.getItem("bkalerts_tracked_debtors_sort_by");
    switch (bkAlertsTrackedDebtorsSortBy) {
      case "SSN":
      case "TAXID":
      case "NAME":
      case "ADDRESS":
      case "PHONE":
      case "CREATEDAT":
        return bkAlertsTrackedDebtorsSortBy;
      default:
        return "CREATEDAT";
    }
  });
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">(() => {
    switch (localStorage.getItem("bkalerts_tracked_debtors_sort_order")) {
      case "ASC":
        return "ASC";
      case "DESC":
      default:
        return "DESC";
    }
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState<50 | 100 | 500>(() => {
    switch (localStorage.getItem("bkalerts_tracked_debtors_page_size")) {
      case "50":
        return 50;
      case "100":
        return 100;
      case "500":
        return 500;
      default:
        return 100;
    }
  });
  const [trackedDebtorsResponse, setTrackedDebtorsResponse] = useState<TrackedDebtorsResponse | null>(null);
  const [selectedRows, setSelectedRows] = useState<TrackedDebtor["id"][] | number>([]);
  const [reload, setReload] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState<null | TrackedDebtor>(null);

  useEffect(() => {
    setLoading(true);

    const watchedQuery = apolloClient.watchQuery({
      query: listTrackedDebtors,
      variables: {
        input: {
          page_size: pageSize,
          page_number: pageNumber - 1,
          sort_by: sortBy,
          sort_order: sortOrder,
          filter_by: searchTerm,
        },
      },
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        const listTrackedDebtorsResponse: TrackedDebtorsResponse = response.data.listTrackedDebtors;

        setTrackedDebtorsResponse(listTrackedDebtorsResponse);
        setLoading(false);
        setTimeout(
          () =>
            (document.querySelector("main.app-main") as HTMLElement).scrollTo({
              top: 0,
              behavior: "smooth",
            }),
          0
        );
      },
      error(err) {
        console.log("[watchQuery] error", err);
        toast.error(somethingWentWrong);
        setLoading(false);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, [pageNumber, pageSize, searchTerm, sortBy, sortOrder, reload]);

  const onSortBy = useCallback(
    (columnId: SortBy) => {
      setSortBy(columnId);
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
      localStorage.setItem("bkalerts_tracked_debtors_sort_by", columnId);
      localStorage.setItem("bkalerts_tracked_debtors_sort_order", sortOrder === "DESC" ? "ASC" : "DESC");
      setPageNumber(1);
      setSelectedRows([]);
    },
    [sortOrder]
  );

  const onConfirmDeletion = useCallback(async () => {
    try {
      setShowConfirmModal(false);
      setLoading(true);

      let affectedRows: number;
      if (typeof selectedRows === "number") {
        const {
          data: { deleteAllTrackedDebtors: _affectedRows },
        } = await apolloClient.mutate({
          mutation: deleteAllTrackedDebtors,
          fetchPolicy: "no-cache",
        });

        affectedRows = _affectedRows;
      } else {
        const {
          data: { deleteTrackedDebtors: _affectedRows },
        } = await apolloClient.mutate({
          mutation: deleteTrackedDebtors,
          variables: {
            ids: selectedRows,
          },
          fetchPolicy: "no-cache",
        });

        affectedRows = _affectedRows;
      }

      toast.success(
        `${affectedRows} ${affectedRows > 1 ? "records have been" : "record has been"} successfully deleted`,
        {
          position: "top-right",
          hideProgressBar: true,
          progress: undefined,
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(somethingWentWrong);
    } finally {
      setSelectedRows([]);
      setReload((prev) => prev + 1);
    }
  }, [selectedRows]);

  return (
    <>
      <Card.Body className="pb-0">
        <Row>
          <Col className="d-flex align-items-center">
            <Card.Title as="h1" className="mb-0">
              Your tracked customers
              {trackedDebtorsResponse &&
                ` (${trackedDebtorsResponse.pagination.total_records_count.toLocaleString("en-US")})`}
            </Card.Title>
          </Col>

          <Col className="d-flex">
            <Link
              className="ms-auto btn btn-primary btn-sm d-flex align-items-center gap-1"
              to="/import"
              title="Import customers"
            >
              <UploadIcon size={16} />
              Import customers
            </Link>
          </Col>
        </Row>
      </Card.Body>

      <Table hover className="tracked-debtors-table" size="sm">
        <thead className="z-2 shadow-lg">
          <tr>
            <th colSpan={header.length} className="bg-white fw-normal py-3">
              <Row className="flex-grow-1">
                <Col
                  xs={{ order: 2, span: 12 }}
                  sm={{ order: 1, span: "auto" }}
                  className="d-flex flex-wrap gap-2 me-auto"
                >
                  <OverlayTrigger placement="top" trigger={["focus", "hover"]} overlay={<Tooltip>Refresh</Tooltip>}>
                    <Button
                      type="button"
                      variant="outline-primary"
                      onClick={() => {
                        setSelectedRows([]);
                        setReload((prev) => prev + 1);
                      }}
                      size="sm"
                    >
                      <ArrowClockwiseIcon size="16" />
                    </Button>
                  </OverlayTrigger>

                  {loading && (
                    <div className="d-flex align-items-center gap-2">
                      <Spinner animation="border" size="sm" variant="primary" />
                      Loading...
                    </div>
                  )}

                  {typeof selectedRows !== "number" && selectedRows.length > 0 && (
                    <OverlayTrigger placement="top" trigger={["focus", "hover"]} overlay={<Tooltip>Edit</Tooltip>}>
                      <Button
                        type="button"
                        variant="outline-primary"
                        disabled={selectedRows.length > 1}
                        onClick={() =>
                          setRecordToEdit({
                            ...trackedDebtorsResponse?.tracked_debtors.find(
                              (debtor) => debtor.id === (selectedRows as string[])[0]
                            )!,
                          })
                        }
                        size="sm"
                      >
                        <PencilSquareIcon size="16" />
                      </Button>
                    </OverlayTrigger>
                  )}

                  {(typeof selectedRows === "number" || selectedRows.length > 0) && (
                    <OverlayTrigger placement="top" trigger={["focus", "hover"]} overlay={<Tooltip>Delete</Tooltip>}>
                      <Button
                        type="button"
                        variant="outline-danger"
                        onClick={() => setShowConfirmModal(true)}
                        size="sm"
                      >
                        <TrashIcon size="16" />
                      </Button>
                    </OverlayTrigger>
                  )}
                </Col>

                <Col xs={{ order: 1, span: 12 }} sm={{ order: 2, span: "auto" }} className="mb-3 mb-sm-0">
                  <SearchInput
                    onSubmit={(value) => {
                      // reset pagination
                      setPageNumber(1);
                      setSelectedRows([]);
                      setSearchTerm(value);
                    }}
                  />
                </Col>
              </Row>
            </th>
          </tr>

          <tr className="table-header-row table-light">
            {header.map(({ id, label, sortable }) => {
              if (id === "SELECTALL") {
                return (
                  <th key={id} className={id.toLowerCase()}>
                    <Dropdown as={ButtonGroup}>
                      <Button
                        variant="light"
                        onClick={() => {
                          if (typeof selectedRows !== "number" && selectedRows.length === 0) {
                            setSelectedRows(trackedDebtorsResponse?.tracked_debtors.map((debtor) => debtor.id) || []);
                          } else {
                            setSelectedRows([]);
                          }
                        }}
                      >
                        <IndeterminateCheckbox
                          indeterminate={
                            typeof selectedRows !== "number" &&
                            selectedRows.length > 0 &&
                            selectedRows.length !== trackedDebtorsResponse?.tracked_debtors.length
                          }
                          readOnly
                          checked={
                            typeof selectedRows !== "number"
                              ? selectedRows.length === trackedDebtorsResponse?.tracked_debtors.length &&
                                trackedDebtorsResponse?.tracked_debtors.length > 0
                              : true
                          }
                          style={{ pointerEvents: "none" }}
                        />
                      </Button>

                      <Dropdown.Toggle split variant="light" />

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            setSelectedRows(trackedDebtorsResponse?.tracked_debtors.map((debtor) => debtor.id) || [])
                          }
                        >
                          All
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedRows([])}>None</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </th>
                );
              }

              return (
                <th key={id} className={id.toLowerCase()}>
                  {sortable ? (
                    <Button
                      type="button"
                      variant="link"
                      className="d-flex align-items-center gap-1 p-0"
                      onClick={() => onSortBy(id as SortBy)}
                      disabled={trackedDebtorsResponse === null}
                      title={`Sort by ${label}`}
                    >
                      {label}
                      {sortBy === id ? (
                        sortOrder === "DESC" ? (
                          <CaretDownIcon size="14" />
                        ) : (
                          <CaretUpIcon size="14" />
                        )
                      ) : null}
                    </Button>
                  ) : (
                    label
                  )}
                </th>
              );
            })}
          </tr>

          {trackedDebtorsResponse &&
            (typeof selectedRows === "number" ||
              (selectedRows.length > 0 && selectedRows.length === trackedDebtorsResponse.tracked_debtors.length)) && (
              <tr>
                <th colSpan={header.length} className="fw-normal table-light">
                  <div className="d-flex align-items-center gap-1">
                    <span>
                      All <strong>{typeof selectedRows === "number" ? selectedRows : selectedRows.length}</strong>{" "}
                      record
                      {typeof selectedRows === "number" || selectedRows.length > 1 ? "s" : ""}
                      {typeof selectedRows !== "number" && " on this page"} are selected.
                    </span>

                    {typeof selectedRows !== "number" &&
                      selectedRows.length < trackedDebtorsResponse.pagination.total_records_count && (
                        <Button
                          type="button"
                          variant="link"
                          className="px-0"
                          onClick={() => setSelectedRows(trackedDebtorsResponse.pagination.total_records_count)}
                        >
                          Select all {trackedDebtorsResponse.pagination.total_records_count.toLocaleString("en-US")}{" "}
                          records.
                        </Button>
                      )}
                  </div>
                </th>
              </tr>
            )}
        </thead>

        <tbody>
          {trackedDebtorsResponse &&
            trackedDebtorsResponse.tracked_debtors.length > 0 &&
            trackedDebtorsResponse.tracked_debtors.map((debtor) => (
              <tr
                key={debtor.id}
                className={classnames("tracked-debtor-row", {
                  "table-primary": typeof selectedRows === "number" || selectedRows.includes(debtor.id),
                })}
              >
                <CustomCheckbox
                  className="selectrecord"
                  as="td"
                  onClick={() => {
                    if (typeof selectedRows === "number") {
                      setSelectedRows(
                        trackedDebtorsResponse?.tracked_debtors
                          .map((debtor) => debtor.id)
                          .filter((id) => id !== debtor.id)
                      );
                      return;
                    }

                    const checked = selectedRows.includes(debtor.id);
                    if (!checked) {
                      setSelectedRows([...selectedRows, debtor.id]);
                    } else {
                      setSelectedRows(selectedRows.filter((id) => id !== debtor.id));
                    }
                  }}
                  checked={typeof selectedRows === "number" || selectedRows.includes(debtor.id)}
                />
                <td className="ssn">
                  <span className="user-select-all text-nowrap">{debtor.ssn}</span>
                </td>
                <td className="taxid">
                  <span className="user-select-all text-nowrap">{debtor.taxid}</span>
                </td>
                <td className="name">{debtor.full_name}</td>
                <td className="address">
                  <div className="overflow-hidden">
                    {[
                      debtor.address_line_1,
                      debtor.address_line_2,
                      debtor.address_line_3,
                      debtor.city,
                      [debtor.state, debtor.zip_code].join(" "),
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </div>
                </td>
                <td className="phone">{debtor.phone}</td>
                <td className="createdat">{new Date(debtor.created_at).toLocaleDateString()}</td>
              </tr>
            ))}
          {trackedDebtorsResponse && trackedDebtorsResponse.tracked_debtors.length === 0 && !loading && (
            <tr>
              <td colSpan={header.length}>
                {searchTerm === ""
                  ? "Looks like you have no tracked records yet."
                  : `No results found for "${searchTerm}". Try to refine your search.`}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {trackedDebtorsResponse !== null && trackedDebtorsResponse.pagination.total_pages > 0 && (
        <Card.Body className="table-footer border-top">
          <Row className="gx-3">
            <Col xs="auto">
              <Pagination
                currentPage={pageNumber}
                totalPages={trackedDebtorsResponse.pagination.total_pages}
                disabled={false}
                onClick={(pageNumber: number) => {
                  setSelectedRows([]);
                  setPageNumber(pageNumber);
                }}
              />
            </Col>
            <Col xs="auto" className="me-auto">
              <PageSize
                options={[50, 100, 500]}
                value={pageSize}
                onChange={(value) => {
                  // Reset pagination and selected rows
                  setPageNumber(1);
                  setSelectedRows([]);
                  setPageSize(value as 50 | 100 | 500);
                  localStorage.setItem("bkalerts_tracked_debtors_page_size", value + "");
                }}
              />
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              <Button
                variant="link"
                className="p-0 d-flex align-items-center gap-2"
                onClick={() => {
                  (document.querySelector("main.app-main") as HTMLElement).scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                Back to Top
                <BackToTopIcon size="18" />
              </Button>
            </Col>
            <Col xs={12} sm="auto" className="d-flex align-items-center mt-2 mt-md-0">
              Results: {(pageSize * (pageNumber - 1) + 1).toLocaleString("en-US")} -{" "}
              {(trackedDebtorsResponse.tracked_debtors.length + pageSize * (pageNumber - 1)).toLocaleString("en-US")} of{" "}
              {trackedDebtorsResponse.pagination.partial_records_count.toLocaleString("en-US")}
            </Col>
          </Row>
        </Card.Body>
      )}

      <ConfirmModal
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        title="Are you sure?"
        onConfirm={onConfirmDeletion}
      >
        Are you sure you want to delete {typeof selectedRows === "number" ? selectedRows : selectedRows.length} record
        {typeof selectedRows === "number" || selectedRows.length > 1 ? "s" : ""}?
      </ConfirmModal>

      <ModifyRecordModal
        key={recordToEdit?.id}
        show={!!recordToEdit}
        onClose={(trackedDebtor) => {
          setRecordToEdit(null);
          if (trackedDebtor) {
            const index = trackedDebtorsResponse?.tracked_debtors.findIndex((debtor) => debtor.id === trackedDebtor.id);
            if (index !== undefined && index !== -1) {
              trackedDebtorsResponse!.tracked_debtors[index] = trackedDebtor;

              setTrackedDebtorsResponse({
                tracked_debtors: trackedDebtorsResponse!.tracked_debtors,
                pagination: trackedDebtorsResponse!.pagination,
              });
            }
          }
        }}
        data={recordToEdit}
      />
    </>
  );
}
