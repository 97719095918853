import { Form } from "react-bootstrap";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";

import { FormValues } from "./interfaces";

interface Props {
  isInvalid: boolean;
  className?: string;
  register: UseFormRegister<FormValues>;
  name: string;
  registerOptions?: any;
  setValue: UseFormSetValue<FormValues>;
  [prop: string]: any;
}
export default function AmountInput({
  isInvalid,
  className,
  register,
  name,
  registerOptions,
  setValue,
  ...otherProps
}: Props) {
  const {
    onChange,
    onBlur,
    ref,
    name: registeredName,
  } = register(name, registerOptions);

  return (
    <Form.Control
      type="text"
      className={className}
      isInvalid={isInvalid}
      onChange={onChange}
      onBlur={(event) => {
        const parsedNumber = Number(event.target.value);

        // Number("") results in 0, check against empty string we don't want this behaviour
        if (!isNaN(parsedNumber) && event.target.value.trim() !== "") {
          setValue(name, parsedNumber.toFixed(2));
        }

        onBlur(event);
      }}
      name={registeredName}
      ref={ref}
      {...otherProps}
    />
  );
}
