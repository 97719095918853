import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

import Logo from "../atoms/Logo";

import Footer from "./components/Footer";

export default function Auth({ children }) {
  return (
    <div className="d-flex flex-column h-100">
      <Navbar bg="dark" variant="dark" expand="lg">
        <Link to="/">
          <Logo height={30} inverse />
        </Link>

        {!process.env.REACT_APP_SELF_HOSTED && (
          <>
            <Navbar.Toggle />

            <Navbar.Collapse className="justify-content-end">
              <Nav>
                <Nav.Link href="https://www.bkalerts.com/pricing">Pricing</Nav.Link>
                <Nav.Link href="https://www.bkalerts.com/#contact-form">Ask a question</Nav.Link>
                <Link to="/auth/login" className="nav-link">
                  Login
                </Link>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Navbar>

      <Container>{children}</Container>

      <Footer />
    </div>
  );
}
