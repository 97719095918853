import gql from "graphql-tag";

const listPendingJobs = gql`
  query {
    listPendingJobs {
      id
      filename
      records_count
      job_status
      created_at
      updated_at
    }
  }
`;

export default listPendingJobs;
