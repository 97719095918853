import { useState, useEffect } from "react";
import { Container, Card, OverlayTrigger, Tooltip, Button, Form, Spinner, Row, Col, Alert } from "react-bootstrap";
import {
  PencilSquare as EditIcon,
  Trash as TrashIcon,
  Upload as UploadIcon,
  Send as SendIcon,
  CheckCircleFill as CheckIcon,
  XCircleFill as ErrorIcon,
  CloudCheck as CloudIcon,
  ArrowClockwise as ReloadIcon,
  ExclamationTriangle as WarningIcon,
} from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { runInAction } from "mobx";
import { observer, useLocalObservable, Observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import { apolloClient } from "../../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../../lib/errors";
import { Claim, Batch } from "../types";
import getClaimBatch from "../graphql/getClaimBatch";
import updateBatchClaim from "../graphql/updateBatchClaim";
import AuthService from "../../../../../services/AuthService";

import updateClaimBatchName from "./graphql/updateClaimBatchName";
import getClaimFormPreviewLink from "./graphql/getClaimFormPreviewLink";
import deleteBatchClaims from "./graphql/deleteBatchClaims";
import getImportJobProgress from "./graphql/getImportJobProgress";
import ImportClaimsModal from "./ImportClaimsModal";
import ClaimAttachmentsModal from "./ClaimAttachmentsModal";
import ClaimsGrid from "./ClaimsGrid/ClaimsGrid";
import ClaimCaseNumberResolverModal from "./ClaimCaseNumberResolverModal";
import ClaimCreditorResolverModal from "./ClaimCreditorResolverModal";
import ClaimFormPreviewModal from "./ClaimFormPreviewModal";

type State = {
  isLoading: boolean;
  isSyncingChanges: boolean;
  isEditingBatchName: boolean;
  batch: Batch | null;
  showImportClaimsModal: boolean;
  claimAttachmentsModal: { show: false } | { show: true; claim: Claim };
  caseNumberResolverModal: { show: false } | { show: true; claim: Claim };
  creditorResolverModal: { show: false } | { show: true; claim: Claim };
  formPreviewModal: { show: false } | { show: true; documentUrl: string };
  showClaimAttachmentsModal: (claim: Claim) => void;
  showCaseNumberResolverModal: (claim: Claim) => void;
  showCreditorResolverModal: (claim: Claim) => void;
  showFormPreviewModal: (documentUrl: string) => void;
  updateBatchName: (name: string) => void;
  deleteClaims: () => void;
  updateClaim: (claim: Claim, columnKey: string, newValue: any) => void;
  saveClaimCase: (claim: Claim, { case_no, case_title }: { case_no: string; case_title: string }) => void;
  saveClaimCreditor: (
    claim: Claim,
    {
      creditor_id,
      creditor_name,
      is_new_creditor,
    }: { creditor_id?: string; creditor_name?: string; is_new_creditor?: boolean }
  ) => void;
  previewClaim: (claimId: string) => void;
};

interface Props {
  match: {
    params: {
      batch_id: string;
    };
  };
  location: {
    search: string;
  };
}
export default function EditBatch({ match, location }: Props) {
  const {
    params: { batch_id },
  } = match;
  const queryParams = new URLSearchParams(location.search);
  const history = useHistory();
  const state = useLocalObservable<State>(() => ({
    isLoading: true,
    isSyncingChanges: false,
    isEditingBatchName: queryParams.has("editBatchName"),
    batch: null,
    showImportClaimsModal: false,
    claimAttachmentsModal: { show: false },
    caseNumberResolverModal: { show: false },
    creditorResolverModal: { show: false },
    formPreviewModal: { show: false },
    showClaimAttachmentsModal: function (claim: Claim) {
      this.claimAttachmentsModal = { show: true, claim };
    },
    showCaseNumberResolverModal: function (claim: Claim) {
      this.caseNumberResolverModal = { show: true, claim };
    },
    showCreditorResolverModal: function (claim: Claim) {
      this.creditorResolverModal = { show: true, claim };
    },
    showFormPreviewModal: function (documentUrl: string) {
      this.formPreviewModal = { show: true, documentUrl };
    },
    updateBatchName: async function (name: string) {
      try {
        runInAction(() => {
          this.isEditingBatchName = false;
          this.isSyncingChanges = true;
          // Optimistic update
          this.batch!.name = name;
          // Clear history search params
          history.replace({
            search: new URLSearchParams().toString(),
          });
        });

        await apolloClient.mutate({
          mutation: updateClaimBatchName,
          variables: {
            batch_id: this.batch!.id,
            name,
          },
        });
      } catch (error) {
        console.log("[mutation] error");
        console.log(error);

        toast.error(somethingWentWrong);
      } finally {
        this.isSyncingChanges = false;
      }
    },
    deleteClaims: async function () {
      try {
        this.isSyncingChanges = true;
        const claimIds = this.batch!.claims.filter((claim) => claim.selected).map((claim) => claim.id);
        this.batch!.claims = this.batch!.claims.filter((claim) => !claim.selected);

        const response = await apolloClient.mutate({
          mutation: deleteBatchClaims,
          variables: {
            batch_id: this.batch!.id,
            claim_ids: claimIds,
          },
        });

        if (response.data.deleteBatchClaims && response.data.deleteBatchClaims.error) {
          toast.error(response.data.updateBatchClaim.error);

          return;
        }
      } catch (error) {
        console.log("[mutation] error");
        console.log(error);

        toast.error(somethingWentWrong);
      } finally {
        this.isSyncingChanges = false;
      }
    },
    updateClaim: async function (claim: Claim, columnKey: string, newValue: any) {
      const claimId = claim.id;
      const isNestedValue = columnKey.includes(".");
      let data;
      if (isNestedValue) {
        const [column, child] = columnKey.split(".");
        claim[column][child] = newValue;

        data = {
          [column]: {
            ...claim[column],
            [child]: newValue,
          },
        };
      } else {
        claim[columnKey] = newValue;
        data = {
          [columnKey]: newValue,
        };
      }

      claim.editing_field_key = null;
      claim.focused_cell_element?.focus();
      claim.focused_cell_element = null;

      this.isSyncingChanges = true;

      const hadValidationErrors = claim.validation_errors.length > 0;

      try {
        const response = await apolloClient.mutate({
          mutation: updateBatchClaim,
          variables: {
            batch_id: this.batch!.id,
            claim_id: claimId,
            data,
          },
        });

        if (response.data.updateBatchClaim.error) {
          toast.error(response.data.updateBatchClaim.error);

          return;
        }

        this.batch!.claims = this.batch!.claims.map((claim) => {
          if (claim.id === claimId) {
            return {
              ...claim,
              ...response.data.updateBatchClaim.claim,
            };
          }

          return claim;
        });

        if (hadValidationErrors && response.data.updateBatchClaim.claim.validation_errors.length === 0) {
          this.batch!.invalid_records_count--;
          this.batch!.valid_records_count++;
        } else if (!hadValidationErrors && response.data.updateBatchClaim.claim.validation_errors.length > 0) {
          this.batch!.invalid_records_count++;
          this.batch!.valid_records_count--;
        }
      } catch (error) {
        console.log("[mutation] error");
        console.log(error);

        toast.error(somethingWentWrong);
      } finally {
        this.isSyncingChanges = false;
      }
    },
    saveClaimCase: async function (claim: Claim, { case_no, case_title }: { case_no: string; case_title: string }) {
      runInAction(() => {
        this.isSyncingChanges = true;
        // Update Fields
        claim.case_no = case_no;
        claim.case_title = case_title;
        // Temporary show claim status as ready
        claim.status = "READY";
      });

      // Save changes
      try {
        const claimId = claim.id;
        const response = await apolloClient.mutate({
          mutation: updateBatchClaim,
          variables: {
            batch_id: this.batch!.id,
            claim_id: claimId,
            data: {
              case_title,
              case_no,
            },
          },
        });

        if (response.data.updateBatchClaim.error) {
          toast.error(response.data.updateBatchClaim.error);

          return;
        }

        this.batch!.claims = this.batch!.claims.map((claim) => {
          if (claim.id === claimId) {
            return {
              ...claim,
              ...response.data.updateBatchClaim.claim,
            };
          }

          return claim;
        });
      } catch (error) {
        console.log("[mutation] error");
        console.log(error);

        toast.error(somethingWentWrong);
      } finally {
        this.isSyncingChanges = false;
      }
    },
    saveClaimCreditor: async function (
      claim: Claim,
      {
        creditor_id,
        creditor_name,
        is_new_creditor,
      }: { creditor_id?: string; creditor_name?: string; is_new_creditor?: boolean }
    ) {
      claim.status = "READY";

      try {
        const claimId = claim.id;
        const data = {
          ...(creditor_id ? { creditor_id } : {}),
          ...(creditor_name ? { creditor: { ...claim.creditor, name: creditor_name } } : {}),
          ...(is_new_creditor ? { is_new_creditor } : {}),
        };

        const response = await apolloClient.mutate({
          mutation: updateBatchClaim,
          variables: {
            batch_id: this.batch!.id,
            claim_id: claimId,
            data,
          },
        });

        if (response.data.updateBatchClaim.error) {
          toast.error(response.data.updateBatchClaim.error);

          return;
        }

        this.batch!.claims = this.batch!.claims.map((claim) => {
          if (claim.id === claimId) {
            return {
              ...claim,
              ...response.data.updateBatchClaim.claim,
            };
          }

          return claim;
        });
      } catch (error) {
        console.log("[mutation] error");
        console.log(error);

        toast.error(somethingWentWrong);
      } finally {
        this.isSyncingChanges = false;
      }
    },
    previewClaim: async function (claimId: string) {
      const toastId = toast.info("Generating Form 410", {
        autoClose: false,
        closeButton: false,
        draggable: false,
        isLoading: true,
        position: "top-right",
        hideProgressBar: true,
      });

      try {
        const response = await apolloClient.query({
          query: getClaimFormPreviewLink,
          variables: {
            batch_id: this.batch!.id,
            claim_id: claimId,
          },
          fetchPolicy: "network-only",
        });

        if (response.data.getClaimFormPreviewLink.error) {
          toast.update(toastId, {
            render: () => response.data.getClaimFormPreviewLink.error,
            type: "error",
            isLoading: false,
            closeButton: true,
          });

          return;
        }

        toast.done(toastId);

        this.showFormPreviewModal(response.data.getClaimFormPreviewLink.url);
      } catch (error) {
        console.log("[query] error");
        console.log(error);

        toast.update(toastId, {
          render: () => somethingWentWrong,
          type: "error",
          isLoading: false,
          closeButton: true,
        });
      }
    },
  }));
  const [reloadClaims, setReloadClaims] = useState(0);

  useEffect(() => {
    state.isLoading = true;

    const watchedQuery = apolloClient.watchQuery({
      query: getClaimBatch,
      variables: {
        batch_id,
      },
      fetchPolicy: "network-only",
    });

    let sub: ReturnType<typeof watchedQuery.subscribe> | null = watchedQuery.subscribe({
      next(response) {
        sub && sub.unsubscribe();
        sub = null;

        if (response.data.getClaimBatch.error) {
          toast.error(response.data.getClaimBatch.error);
          history.push("/epoc/bulk");
          return;
        }

        state.batch = response.data.getClaimBatch;
        state.isLoading = false;
      },
      error(err) {
        console.log("[watchQuery] error", err);
        toast.error(somethingWentWrong);
        state.isLoading = false;
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub && sub.unsubscribe();
    };
  }, [batch_id, history, reloadClaims, state]);

  const onFileSelected = async (selectedFile: File) => {
    state.showImportClaimsModal = false;

    const toastId = toast.info("Uploading file, please wait...", {
      autoClose: false,
      closeButton: false,
      draggable: false,
      isLoading: true,
      position: "top-right",
      hideProgressBar: true,
    });

    const formData = new FormData();
    formData.append("file", selectedFile);

    const Authorization = await AuthService.getJwtToken();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/import/xlsx/${batch_id}`, {
      method: "POST",
      headers: {
        Authorization: Authorization || "",
      },
      body: formData,
    });
    toast.done(toastId);

    if (!response.ok) {
      toast.error("Failed to upload the CSV file, please try again");
      return;
    }

    const json = await response.json();
    if (json.error) {
      toast.error(json.error);
      return;
    }

    const importJobId = json.import_job_id;

    state.batch?.pending_import_jobs.push({
      id: importJobId,
      filename: selectedFile.name,
    });
  };

  return (
    <Container fluid className="bulk-poc pt-2 h-100">
      <Card className="mb-0 h-100">
        <Observer>
          {() =>
            state.isLoading && !state.batch ? (
              <Card.Body className="d-flex justify-content-center">
                <Spinner animation="border" variant="primary" />
              </Card.Body>
            ) : null
          }
        </Observer>

        <Observer>
          {() => {
            if (!state.batch) return null;

            return (
              <>
                <Card.Body className="flex-grow-0 pb-3">
                  <Row>
                    <Col xs={12} sm="auto" className="mb-2 mb-md-0 d-flex align-items-center gap-2">
                      <BatchName
                        batch={state.batch}
                        isEditingBatchName={state.isEditingBatchName}
                        onEditBatchName={() => (state.isEditingBatchName = true)}
                        onUpdateBatchName={state.updateBatchName}
                      />
                    </Col>

                    <Col xs={6} sm="auto" className="d-flex align-items-center gap-2">
                      {state.isSyncingChanges ? (
                        <>
                          <Spinner animation="border" variant="primary" size="sm" />
                          <span>Saving...</span>
                        </>
                      ) : (
                        <>
                          <CloudIcon size="22" className="text-success" />
                          <span>All changes saved</span>
                        </>
                      )}
                    </Col>

                    <Col xs={6} sm className="d-flex align-items-center">
                      <Button
                        type="button"
                        variant="primary"
                        size="sm"
                        className="ms-auto d-flex align-items-center gap-1"
                        onClick={() => (state.showImportClaimsModal = true)}
                      >
                        <UploadIcon size="16" />
                        Import claims
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>

                <PendingImportJobs batch={state.batch} reloadClaims={() => setReloadClaims((prev) => prev + 1)} />

                {state.batch.claims.length > 0 ? (
                  <>
                    <Card.Body className="pt-0 pb-2 flex-grow-0">
                      <RecordsValidation batch={state.batch} />
                    </Card.Body>

                    <Card.Body className="pt-0 pb-3 flex-grow-0">
                      <Actions
                        claims={state.batch.claims}
                        deleteClaims={state.deleteClaims}
                        reloadClaims={() => setReloadClaims((prev) => prev + 1)}
                        isRefreshingView={state.isLoading && !!state.batch}
                      />
                    </Card.Body>

                    <ClaimsGrid
                      claims={state.batch.claims}
                      updateClaim={state.updateClaim}
                      previewClaim={state.previewClaim}
                      showClaimAttachmentsModal={state.showClaimAttachmentsModal}
                      showCaseNumberResolverModal={state.showCaseNumberResolverModal}
                      showCreditorResolverModal={state.showCreditorResolverModal}
                    />

                    <Card.Body className="flex-grow-0 sticky-bottom border-top">
                      Click on a cell to focus it. Use <kbd>&uArr;</kbd> <kbd>&rArr;</kbd> <kbd>&dArr;</kbd>{" "}
                      <kbd>&lArr;</kbd> to move focus between cells. Press <kbd>Enter</kbd> to edit the cell. Press{" "}
                      <kbd>Esc</kbd> to cancel editing. Press <kbd>&lArr; Backspace</kbd> to clear a cell.
                    </Card.Body>
                  </>
                ) : (
                  <Card.Body className="pt-0 flex-grow-0">
                    This batch doesn't contain any claim yet. Click on the button above to import claims.
                  </Card.Body>
                )}

                <ClaimAttachmentsModal
                  show={state.claimAttachmentsModal.show}
                  onClose={() => (state.claimAttachmentsModal = { show: false })}
                  claim={state.claimAttachmentsModal.show ? state.claimAttachmentsModal.claim : null}
                  batch={state.batch}
                  updateClaim={state.updateClaim}
                />

                <ClaimCaseNumberResolverModal
                  show={state.caseNumberResolverModal.show}
                  onClose={() => (state.caseNumberResolverModal = { show: false })}
                  claim={state.caseNumberResolverModal.show ? state.caseNumberResolverModal.claim : null}
                  batchId={state.batch.id}
                  saveClaimCase={state.saveClaimCase}
                />

                <ClaimCreditorResolverModal
                  show={state.creditorResolverModal.show}
                  onClose={() => (state.creditorResolverModal = { show: false })}
                  claim={state.creditorResolverModal.show ? state.creditorResolverModal.claim : null}
                  batchId={state.batch.id}
                  saveClaimCreditor={state.saveClaimCreditor}
                />

                <ClaimFormPreviewModal
                  show={state.formPreviewModal.show}
                  onClose={() => (state.formPreviewModal = { show: false })}
                  documentUrl={state.formPreviewModal.show ? state.formPreviewModal.documentUrl : null}
                />
              </>
            );
          }}
        </Observer>
      </Card>

      <Observer>
        {() => (
          <ImportClaimsModal
            show={state.showImportClaimsModal}
            onClose={() => (state.showImportClaimsModal = false)}
            onFileSelected={onFileSelected}
          />
        )}
      </Observer>
    </Container>
  );
}

const PendingImportJobs = observer(function PendingImportJobs({
  batch,
  reloadClaims,
}: {
  batch: Batch;
  reloadClaims: () => void;
}) {
  return (
    <>
      {batch.pending_import_jobs.map((importJob) => (
        <PendingImportJob
          key={importJob.id}
          jobId={importJob.id}
          filename={importJob.filename}
          onDone={(importJobId) => {
            batch.pending_import_jobs = batch.pending_import_jobs.filter((importJob) => importJob.id !== importJobId);
            reloadClaims();
          }}
        />
      ))}
    </>
  );
});

const PendingImportJob = function PendingImportJob({
  jobId,
  filename,
  onDone,
}: {
  jobId: string;
  filename: string;
  onDone: (importJobId: string) => void;
}) {
  useEffect(() => {
    const interval = setInterval(async function getProgress() {
      console.log(`[getImportJobProgress] job_id = "${jobId}"`);

      const response: {
        data: {
          getImportJobProgress: { done?: boolean; error?: string };
        };
      } = await apolloClient.query({
        query: getImportJobProgress,
        variables: {
          job_id: jobId,
        },
        fetchPolicy: "network-only",
      });

      console.log("[getImportJobProgress] response", response.data.getImportJobProgress);

      if (response.data.getImportJobProgress.error) {
        toast.error(response.data.getImportJobProgress.error);
        clearInterval(interval);
        onDone(jobId);
      } else if (response.data.getImportJobProgress.done) {
        clearInterval(interval);
        onDone(jobId);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [jobId, onDone]);

  return (
    <Card.Body className="flex-grow-0 pt-0 pb-3 d-flex align-items-center gap-2">
      <Spinner animation="border" size="sm" variant="primary" />
      <span>
        Please wait, importing <strong>"{filename}"</strong>
      </span>
    </Card.Body>
  );
};

const Actions = observer(function Actions({
  claims,
  deleteClaims,
  reloadClaims,
  isRefreshingView,
}: {
  claims: Claim[];
  deleteClaims: () => void;
  reloadClaims: () => void;
  isRefreshingView: boolean;
}) {
  const selectedRecords = claims.filter((claim) => claim.selected);

  return (
    <div className="d-flex align-items-center gap-2">
      <OverlayTrigger placement="top" trigger={["hover", "focus"]} overlay={<Tooltip>Refresh</Tooltip>}>
        <Button type="button" size="sm" variant="outline-primary" onClick={reloadClaims} title="Reload view">
          <ReloadIcon size="16" />
        </Button>
      </OverlayTrigger>

      {selectedRecords.length > 0 && (
        <OverlayTrigger
          placement="top"
          trigger={["hover", "focus"]}
          overlay={
            <Tooltip>
              Delete
              {selectedRecords.length > 0
                ? ` ${selectedRecords.length} claim${selectedRecords.length > 1 ? "s" : ""}`
                : ""}
            </Tooltip>
          }
        >
          <Button
            type="button"
            size="sm"
            variant="outline-danger"
            disabled={selectedRecords.length === 0}
            onClick={deleteClaims}
          >
            <TrashIcon size="16" />
          </Button>
        </OverlayTrigger>
      )}

      {isRefreshingView && (
        <>
          <Spinner animation="border" variant="primary" size="sm" />
          <span>Loading...</span>
        </>
      )}
    </div>
  );
});

const RecordsValidation = observer(function RecordsValidation({ batch }: { batch: Batch }) {
  const someRecordsRequirePreProcessing = batch.claims.some((claim) =>
    [
      "NEEDS_CASE_NUMBER_RESOLUTION",
      "NEEDS_CASE_NUMBER_DISAMBIGUATION",
      "SEARCHING_CASE_NUMBER",
      "CASE_NUMBER_SEARCH_ERROR",
      "NEEDS_CREDITOR_RESOLUTION",
      "NEEDS_CREDITOR_DISAMBIGUATION",
      "SEARCHING_CREDITOR",
      "CREDITOR_SEARCH_ERROR",
    ].includes(claim.status)
  );

  return (
    <Row className="gx-0 gap-2">
      <Col xs={12} sm="auto" className="d-flex align-items-center">
        {batch.claims.length} claims total
      </Col>

      {batch.invalid_records_count === 0 && batch.valid_records_count > 0 && (
        <>
          <Col xs="auto" sm="auto" className="flex-shrink-1">
            <Alert variant="success" className="mb-0 d-flex align-items-center gap-1 px-2 py-1">
              <CheckIcon size="18" className="text-success" />
              All records are valid, ready to send
            </Alert>
          </Col>

          <Col xs="auto" sm="auto" className="me-auto">
            <Link
              to={`/epoc/bulk/${batch.id}/submit`}
              className="btn btn-success btn-sm d-flex align-items-center gap-1 text-nowrap px-2 py-1"
            >
              <SendIcon size="16" />
              Send batch
            </Link>
          </Col>
        </>
      )}

      {batch.invalid_records_count > 0 && (
        <Col xs={12} sm="auto" className="me-auto">
          <Alert variant="danger" className="mb-0 d-flex align-items-center gap-1 px-2 py-1">
            <ErrorIcon size="18" className="text-danger flex-shrink-0" />
            {batch.invalid_records_count} invalid record{batch.invalid_records_count > 1 ? "s" : ""}
          </Alert>
        </Col>
      )}

      {someRecordsRequirePreProcessing && (
        <Col xs={12} sm="auto">
          <Alert variant="warning" className="mb-0 d-flex align-items-center gap-1 px-2 py-1">
            <WarningIcon size="18" className="text-warning flex-shrink-0" />
            Some records will require pre-processing before sending to the court.
          </Alert>
        </Col>
      )}
    </Row>
  );
});

const BatchName = observer(function BatchName({
  batch,
  isEditingBatchName,
  onEditBatchName,
  onUpdateBatchName,
}: {
  batch: Batch;
  isEditingBatchName: boolean;
  onEditBatchName: () => void;
  onUpdateBatchName: (name: string) => void;
}) {
  if (isEditingBatchName)
    return (
      <EditBatchName
        name={batch.name}
        onSubmit={(name: string) => {
          onUpdateBatchName(name);
        }}
      />
    );

  return (
    <>
      <h4 className="mb-0">{batch.name}</h4>

      <OverlayTrigger placement="top" trigger={["hover", "focus"]} overlay={<Tooltip>Edit batch name</Tooltip>}>
        <EditIcon size="18" role="button" className="text-primary" title="Edit batch name" onClick={onEditBatchName} />
      </OverlayTrigger>
    </>
  );
});

function EditBatchName({ name, onSubmit }: { name: string; onSubmit: (name: string) => void }) {
  const [batchName, setBatchName] = useState(name);

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit(batchName);
      }}
      className="d-inline-flex"
    >
      <Form.Control
        type="text"
        value={batchName}
        onChange={(event) => setBatchName(event.target.value)}
        onBlur={() => onSubmit(batchName)}
        autoFocus
        onFocus={(event) => event.target.select()}
        size="sm"
      />
    </Form>
  );
}
