import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { Row, Col, Spinner, Card, Table, Button, Container, Modal } from "react-bootstrap";
import { FileEarmarkPlus as FileEarmarkPlusIcon, Eye as EyeIcon, Trash as TrashIcon } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

import { apolloClient } from "../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../lib/errors";
import PreviewModal from "../New/PreviewModal";

import getFilingProfiles from "./graphql/getFilingProfiles";
import discardFilingProfile from "./graphql/discardFilingProfile";

interface FilingProfile {
  id: string;
  name: string;
  raw_form_data: string;
  created_at: string;
}

type FilingProfileID = string;

export default function FilingProfiles() {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<FilingProfile[] | undefined>(undefined);
  const [showPreviewModal, setShowPreviewModal] = useState<false | FilingProfileID>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<false | FilingProfileID>(false);

  useEffect(() => {
    const watchedQuery = apolloClient.watchQuery({
      query: getFilingProfiles,
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        if (response.data) {
          setResponse(response.data.getFilingProfiles as FilingProfile[]);
        }

        setIsLoading(false);
      },
      error(err) {
        console.log("[watchQuery] error", err);
        toast.error(somethingWentWrong);
        setIsLoading(false);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, []);

  const onDiscardConfirm = useCallback(async () => {
    if (response && response.length > 0) {
      // Optmistic update
      setResponse(response.filter((record) => record.id !== showConfirmModal));
      setShowConfirmModal(false);

      // Fire mutation and forget
      const saving = apolloClient.mutate({
        mutation: discardFilingProfile,
        variables: {
          id: showConfirmModal,
        },
        fetchPolicy: "no-cache",
      });

      toast.promise(saving, {
        pending: "Discarding filing profile...",
        success: "Done!",
        error: somethingWentWrong,
      });
    }
  }, [response, showConfirmModal]);

  const closeConfirmationModal = useCallback(() => setShowConfirmModal(false), []);

  const selectedFilingProfile = showPreviewModal ? response?.find((record) => record.id === showPreviewModal) : null;
  const formData = selectedFilingProfile?.raw_form_data ? JSON.parse(selectedFilingProfile.raw_form_data) : {};

  return (
    <Container fluid="xl" className="px-0">
      <Card>
        <Card.Body>
          <Row className="d-flex align-items-center">
            <Col xs="auto" sm className="me-auto">
              <Card.Title as="h1" className="mb-0">
                Your Filing Profiles
              </Card.Title>
            </Col>
            <Col xs="auto">
              <Link className="btn btn-primary btn-sm d-flex align-items-center gap-1" to="/epoc/new">
                <FileEarmarkPlusIcon size={16} />
                New Proof of Claim
              </Link>
            </Col>
          </Row>
        </Card.Body>

        {isLoading && (
          <Card.Body className="d-flex align-items-center justify-content-center">
            <Spinner animation="border" variant="primary" />
          </Card.Body>
        )}

        {response && (
          <Table hover size="sm" className="filing-profiles-table">
            <thead className="table-light position-sticky sticky-top z-2 shadow-lg">
              <tr>
                <th className="name">Name</th>
                <th className="created_at">Created at</th>
                <th className="actions">Actions</th>
              </tr>
            </thead>

            <tbody>
              {response.length > 0 ? (
                response.map(({ id, name, created_at }) => {
                  return (
                    <tr key={id}>
                      <td className="name">{name}</td>
                      <td className="created_at">{new Date(created_at).toLocaleString("en-US")}</td>
                      <td className="action">
                        <div className="d-flex align-items-center gap-2">
                          <Button
                            type="button"
                            size="sm"
                            variant="outline-primary"
                            className="d-flex align-items-center gap-1"
                            onClick={() => setShowPreviewModal(id)}
                          >
                            <EyeIcon size={16} />
                            Preview
                          </Button>
                          <Button
                            type="button"
                            size="sm"
                            variant="outline-danger"
                            className="d-flex align-items-center gap-1"
                            onClick={() => setShowConfirmModal(id)}
                          >
                            <TrashIcon size={16} />
                            Discard
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="py-3" colSpan={5}>
                    No records yet, <Link to="/epoc/new">click here to file a new proof of Claim</Link>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </Card>

      <PreviewModal
        formState={
          selectedFilingProfile
            ? {
                documentation: formData.documentation,
                signee: formData.signee,
              }
            : {}
        }
        show={!!showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
      />

      <Modal show={!!showConfirmModal} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>

        <Modal.Body>All data inserted will be lost</Modal.Body>

        <Modal.Footer className="justify-content-between">
          <Button variant="light" onClick={closeConfirmationModal}>
            Cancel
          </Button>

          <Button variant="success" onClick={onDiscardConfirm}>
            Discard
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
