import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import AuthService from "../services/AuthService";

const authLink = setContext(async (_, { headers }) => {
  const authorization = await AuthService.getJwtToken();

  return {
    headers: {
      ...headers,
      authorization,
    },
  };
});

const httpLink = createHttpLink({ uri: `${process.env.REACT_APP_API_URL}/graphql` });

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: authLink.concat(httpLink),
});
