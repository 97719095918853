import { Container, Card } from "react-bootstrap";

import Info from "./Info";
import ChangePassword from "./ChangePassword";
import PACERCredentials from "./PACERCredentials/PACERCredentials";

export default function Account() {
  return (
    <Container className="px-0">
      <Card>
        <Card.Body>
          <Info />
          <hr />
          <ChangePassword />
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <PACERCredentials />
        </Card.Body>
      </Card>
    </Container>
  );
}
