import gql from "graphql-tag";

const getAvailablePaymentMethod = gql`
  query getAvailablePaymentMethod {
    getAvailablePaymentMethod {
      payment_method_available
      payment_method {
        id
        card_brand
        last_four_digits
      }
    }
  }
`;

export default getAvailablePaymentMethod;
