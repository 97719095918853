import { useState } from "react";
import { Modal, Button, Breadcrumb, OverlayTrigger, Tooltip, Table, Form } from "react-bootstrap";
import { toast, Id } from "react-toastify";
import { XLg as XIcon, PencilSquare as EditIcon } from "react-bootstrap-icons";
import { Observer, observer, useLocalObservable } from "mobx-react-lite";

import FileDropArea from "../../../../../components/FileDropArea/FileDropArea";
import { Batch, PDFAttachment, Claim } from "../types";
import { somethingWentWrong } from "../../../../../lib/errors";
import { apolloClient } from "../../../../../configs/apollo-client";
import getClaimAttachmentUploadUrl from "./graphql/getClaimAttachmentUploadUrl";

export default function ClaimAttachmentsModal({
  show,
  onClose,
  claim,
  batch,
  updateClaim,
}: {
  show: boolean;
  onClose: () => void;
  claim: Claim | null;
  batch: Batch;
  updateClaim: (claim: Claim, columnKey: string, newValue: any) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);

  async function onFilesSelected(selectedFiles: File[]) {
    // Let's make TypeScript happy
    if (!claim) return;

    setIsLoading(true);
    const toastIds: Id[] = [];
    const uploads: Promise<Response>[] = [];
    const newAttachments: PDFAttachment[] = [];

    try {
      for (const [index, selectedFile] of Object.entries(selectedFiles)) {
        const toastId = toast.info(`Uploading "${selectedFile.name}"`, {
          autoClose: false,
          closeButton: false,
          draggable: false,
          isLoading: true,
          position: "top-right",
          hideProgressBar: true,
        });

        toastIds.push(toastId);

        const response = await apolloClient.query({
          query: getClaimAttachmentUploadUrl,
          variables: {
            batch_id: batch.id,
            claim_id: claim.id,
            filename: selectedFile.name,
          },
        });
        const { error, s3_key, url } = response.data.getClaimAttachmentUploadUrl;

        if (error) {
          toast.error(error);
          return;
        }

        uploads.push(
          fetch(url, {
            method: "PUT",
            body: selectedFile,
            headers: {
              "Content-Type": "application/pdf",
            },
          })
        );
        newAttachments.push({
          filename: selectedFile.name,
          s3_key,
          description: `Document attachment ${claim.attachments.length + 1 + Number(index)}`,
        });
      }

      // Wait for all uploads to finish
      await Promise.all(uploads);
      // // Update claim attachments
      claim.attachments.push(...newAttachments);
      updateClaim(claim, "attachments", claim.attachments);
    } catch (error) {
      console.log(error);
      toast.error(somethingWentWrong);
    } finally {
      toastIds.forEach((toastId) => toast.done(toastId));
      setIsLoading(false);
    }
  }

  function removeAttachment(s3_key: string) {
    // Let's make TypeScript happy
    if (!claim) return;

    // Update claim attachments
    claim.attachments = claim.attachments.filter((attachment) => attachment.s3_key !== s3_key);
    updateClaim(claim, "attachments", claim.attachments);
  }

  return (
    <Modal show={show} onHide={onClose} size="lg" {...(isLoading ? { backdrop: "static", keyboard: false } : {})}>
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>Manage claim attachments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {claim && (
          <div className="mb-3">
            <h3>Claim</h3>

            <Breadcrumb>
              {[claim.district, claim.case_no].filter(Boolean).map((item) => (
                <Breadcrumb.Item key={item} active>
                  {item}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>

            <h3>Attachments{claim.attachments.length > 0 ? ` (${claim.attachments.length})` : null}</h3>

            <Observer>
              {() => {
                return claim.attachments.length > 0 ? (
                  <Table striped hover size="sm">
                    <tbody>
                      {claim.attachments.map((attachment, index) => (
                        <tr key={attachment.s3_key}>
                          <td className="min-content-width align-top">
                            <strong>{index + 1}.</strong>
                          </td>
                          <td>
                            <div className="mb-1">
                              <strong>Filename:</strong> {attachment.filename}
                            </div>

                            <AttachmentDescription claim={claim} updateClaim={updateClaim} attachment={attachment} />
                          </td>
                          <td className="text-center">
                            <OverlayTrigger
                              placement="top"
                              trigger={["hover", "focus"]}
                              overlay={<Tooltip>Remove attachment</Tooltip>}
                            >
                              <XIcon
                                size="18"
                                role="button"
                                className="text-primary"
                                onClick={() => removeAttachment(attachment.s3_key)}
                              />
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>No attachments yet.</p>
                );
              }}
            </Observer>

            <p className="lead mb-1 fw-bold">
              Attach redacted copies of any documents that show that the debt exists, a lien secures the debt, or both.
            </p>
            <p>
              Also attach redacted copies of any documents that show perfection of any security interest or any
              assignments or transfers of the debt. In addition to the documents, a summary may be added. Federal Rule
              of Bankruptcy Procedure (called "Bankruptcy Rule") 3001(c) and (d).
            </p>
          </div>
        )}

        <div className="d-flex" style={{ height: 300 }}>
          <FileDropArea
            id="claim-attachments-drop-area"
            accept=".pdf"
            mimeType="application/pdf"
            fileTypeLabel="PDF"
            showDownloadSampleButton={false}
            multiple={true}
            onFilesSelected={onFilesSelected}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button type="button" variant="primary" disabled={isLoading} onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const AttachmentDescription = observer(function AttachmentDescription({
  claim,
  updateClaim,
  attachment,
}: {
  claim: Claim;
  updateClaim: (claim: Claim, columnKey: string, newValue: any) => void;
  attachment: PDFAttachment;
}) {
  const state = useLocalObservable(() => ({
    isEditing: false,
    description: "",
    saveDescription() {
      attachment.description = state.description;
      updateClaim(claim, "attachments", claim.attachments);
      state.isEditing = false;
      state.description = "";
    },
  }));

  return (
    <div>
      <Form.Label as="strong">Description:</Form.Label>

      {state.isEditing ? (
        <Form.Control
          as="textarea"
          autoFocus
          rows={3}
          value={state.description}
          onChange={(event) => (state.description = event.target.value)}
          onBlur={() => state.saveDescription()}
          // Trick for puttin cursor at the end of the text
          onFocus={() => (state.description = attachment.description)}
        />
      ) : (
        <div className="d-flex align-items-center gap-2">
          {attachment.description}
          <OverlayTrigger
            placement="top"
            trigger={["hover", "focus"]}
            overlay={<Tooltip>Edit attachment description</Tooltip>}
          >
            <EditIcon size="18" role="button" className="text-primary" onClick={() => (state.isEditing = true)} />
          </OverlayTrigger>
        </div>
      )}
    </div>
  );
});
