import { useState, useEffect, Fragment } from "react";
import classnames from "classnames";
import { Container, Card, Row, Col, Spinner, Button, Table, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import {
  ArrowClockwise as ArrowClockwiseIcon,
  Search as VerifyOnPACERIcon,
  XLg as NotAMatchIcon,
  ArrowUpCircleFill as BackToTopIcon,
  Upload as UploadIcon,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";

import { apolloClient } from "../../configs/apollo-client";
import ConfirmModal from "../../components/ConfirmModal";

import HarveyBall from "./HarveyBall";
import listTrackedDebtorsMatches from "./graphql/listTrackedDebtorsMatches";

import "./index.scss";

const header = [
  { id: "SOURCE", label: "Source", className: "source", width: 110 },
  { id: "SSN", label: "SSN", className: "ssn", width: 110 },
  { id: "TAXID", label: "TAXID", className: "taxid", width: 110 },
  { id: "FIRSTNAME", label: "First name", className: "first_name", width: 150 },
  { id: "MIDDLENAME", label: "Middle name", className: "middle_name", width: 150 },
  { id: "LASTNAME", label: "Last / Company name", className: "last_name", width: 200 },
  { id: "GENERATION", label: "Generation", className: "generation", width: 90 },
  { id: "ADDRESS1", label: "Address line 1", className: "address_line_1", width: 200 },
  { id: "ADDRESS2", label: "Address line 2", className: "address_line_2", width: 150 },
  { id: "ADDRESS3", label: "Address line 3", className: "address_line_3", width: 150 },
  { id: "CITY", label: "City", className: "city", width: 110 },
  { id: "STATE", label: "State", className: "state", width: 80 },
  { id: "ZIPCODE", label: "Zip code", className: "zip_code", width: 80 },
  { id: "COUNTRY", label: "Country", className: "country", width: 80 },
  { id: "PHONE", label: "Phone", className: "phone", width: 200 },
  { id: "MATCH", label: "Overall Match", className: "match", width: 120 },
];

const columns = [
  "ssn",
  "taxid",
  "first_name",
  "middle_name",
  "last_name",
  "generation",
  "address_line_1",
  "address_line_2",
  "address_line_3",
  "city",
  "state",
  "zip_code",
  "country",
  "phone",
];

interface TrackedDebtorMatch {
  id: string;
  customer_id: string;
  customer_ssn: string | null;
  customer_taxid: string | null;
  customer_first_name: string | null;
  customer_middle_name: string | null;
  customer_last_name: string | null;
  customer_generation: string | null;
  customer_address_line_1: string | null;
  customer_address_line_2: string | null;
  customer_address_line_3: string | null;
  customer_city: string | null;
  customer_state: string | null;
  customer_zip_code: string | null;
  customer_country: string | null;
  customer_phone: string | null;
  court_id: string;
  court_ssn: string | null;
  court_taxid: string | null;
  court_first_name: string | null;
  court_middle_name: string | null;
  court_last_name: string | null;
  court_generation: string | null;
  court_address_line_1: string | null;
  court_address_line_2: string | null;
  court_address_line_3: string | null;
  court_city: string | null;
  court_state: string | null;
  court_zip_code: string | null;
  court_country: string | null;
  court_phone: string | null;
  match_data: string;
  created_at: string;
  [k: `${"customer" | "court"}_${string}`]: string | null;
}

type TrackedDebtorMatches = TrackedDebtorMatch[];
export default function MatchesPage() {
  const [matches, setMatches] = useState<TrackedDebtorMatches | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    const watchedQuery = apolloClient.watchQuery({
      query: listTrackedDebtorsMatches,
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        const listTrackedDebtorsMatchesResponse: TrackedDebtorMatches = response.data.listTrackedDebtorsMatches;
        setMatches(listTrackedDebtorsMatchesResponse);
        setLoading(false);
      },
      error(err) {
        console.log("[watchQuery] error", err);
        setLoading(false);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, [reload]);

  function getCellColorClassName(match: TrackedDebtorMatch, column: string, matchData: any) {
    if (!(match[`customer_${column}`] && match[`court_${column}`])) {
      return null;
    }

    const gamma = matchData[`gamma_${column}`];
    if (gamma === undefined || gamma < 0) {
      return null;
    }

    if (gamma === 0) {
      return "bg-danger bg-opacity-25 text-dark";
    }

    if (gamma > 0) {
      return "bg-success bg-opacity-25 text-dark";
    }

    return null;
  }

  return (
    <>
      <Container fluid className="h-100 pt-2 matches-page">
        <Card className="h-100 mb-0">
          <Card.Body className="flex-grow-0 pb-0">
            <Row>
              <Col className="d-flex align-items-center">
                <Card.Title as="h1" className="mb-0">
                  Matches {matches && `(${matches.length})`}
                </Card.Title>
              </Col>

              <Col className="d-flex">
                <Link
                  className="ms-auto btn btn-primary btn-sm d-flex align-items-center gap-1"
                  to="/import"
                  title="Import customers"
                >
                  <UploadIcon size={16} />
                  Import customers
                </Link>
              </Col>
            </Row>
          </Card.Body>

          <Card.Body className="flex-grow-0 py-3 d-flex align-items-center gap-2">
            <OverlayTrigger placement="top" trigger={["focus", "hover"]} overlay={<Tooltip>Refresh</Tooltip>}>
              <Button
                type="button"
                variant="outline-primary"
                onClick={() => {
                  setReload((prev) => prev + 1);
                }}
                size="sm"
              >
                <ArrowClockwiseIcon size={16} />
              </Button>
            </OverlayTrigger>

            {loading && (
              <>
                <Spinner animation="border" size="sm" variant="primary" />
                <span>Loading...</span>
              </>
            )}
          </Card.Body>

          <div className="matches-table-container flex-grow-1">
            <Table hover size="sm" className="matches-table mb-0">
              <thead className="table-light z-2 shadow-lg sticky-top">
                <tr>
                  {header.map((item) => (
                    <th
                      key={item.id}
                      className={`matches-table-header-column ${item.className}`}
                      style={{ width: item.width }}
                    >
                      <div className="inner-cell">{item.label}</div>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {matches &&
                  matches.length > 0 &&
                  matches.map((match, index) => {
                    const matchData = JSON.parse(match.match_data);
                    const matchValue = Math.floor(matchData.match_probability * 100);

                    return (
                      <Fragment key={match.id}>
                        <tr className="matches-table-row">
                          <td className="customer_source table-light">
                            <Badge pill bg="success">
                              Customer
                            </Badge>
                          </td>

                          {columns.map((column) => {
                            const cellColorClassName = getCellColorClassName(match, column, matchData);

                            return (
                              <td
                                key={`customer_${column}`}
                                className={classnames(`customer_${column}`, cellColorClassName)}
                                title={match[`customer_${column}`] || undefined}
                              >
                                <div className="inner-cell">{match[`customer_${column}`]}</div>
                              </td>
                            );
                          })}

                          <td className="match table-light" rowSpan={2}>
                            <div className="inner-cell">
                              <div className="d-flex justify-content-center mb-2">
                                <HarveyBall value={matchValue} size={40} />
                              </div>

                              <div className="d-flex align-items-center justify-content-center gap-2">
                                <OverlayTrigger
                                  placement="top"
                                  trigger={["focus", "hover"]}
                                  overlay={<Tooltip>Verify match on PACER</Tooltip>}
                                >
                                  <Button type="button" variant="outline-primary" size="sm" className="d-flex">
                                    <VerifyOnPACERIcon size={16} />
                                  </Button>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="top"
                                  trigger={["focus", "hover"]}
                                  overlay={<Tooltip>Not a match</Tooltip>}
                                >
                                  <Button
                                    type="button"
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={() => setShowConfirmModal(true)}
                                    className="d-flex"
                                  >
                                    <NotAMatchIcon size={16} />
                                  </Button>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className="court_source table-light">
                            <Badge pill bg="primary">
                              Court
                            </Badge>
                          </td>

                          {columns.map((column) => {
                            const cellColorClassName = getCellColorClassName(match, column, matchData);

                            return (
                              <td
                                key={`court_${column}`}
                                className={classnames(`court_${column}`, cellColorClassName)}
                                title={match[`court_${column}`] || undefined}
                              >
                                <div className="inner-cell">{match[`court_${column}`]}</div>
                              </td>
                            );
                          })}
                        </tr>

                        {index < matches.length - 1 && (
                          <tr className="table-secondary">
                            <td colSpan={columns.length + 2}></td>
                          </tr>
                        )}
                      </Fragment>
                    );
                  })}

                {matches && matches.length === 0 && (
                  <tr>
                    <td colSpan={columns.length + 2}>Looks like there are no matching records yet.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          {matches && (
            <Card.Body className="bg-white border-top flex-grow-0">
              <Row className="gx-3">
                <Col xs="auto" className="ms-auto d-flex align-items-center">
                  <Button
                    variant="link"
                    className="p-0 d-flex align-items-center gap-2"
                    onClick={() => {
                      (document.querySelector(".matches-table-container") as HTMLElement).scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    Back to Top
                    <BackToTopIcon size="18" />
                  </Button>
                </Col>
                <Col xs={12} sm="auto" className="d-flex align-items-center mt-2 mt-md-0">
                  <span className="ms-auto">
                    Results: {matches.length > 0 ? `1 - ${matches.length} of ${matches.length}` : "0"}
                  </span>
                </Col>
              </Row>
            </Card.Body>
          )}
        </Card>
      </Container>

      <ConfirmModal
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        title="Are you sure?"
        onConfirm={() => console.log("Confirm")}
      >
        Are you sure you want to mark this record as a non match?
      </ConfirmModal>
    </>
  );
}
