import { Card, Button } from "react-bootstrap";
import { Download as DownloadIcon } from "react-bootstrap-icons";

import FileDropArea from "../../../components/FileDropArea/FileDropArea";

// @ts-ignore
import sampleCSVFileURL from "../sample-csv.csv";

import "./index.scss";

export default function UploadCSV({ onFileSelected }: { onFileSelected: (file: File) => void }) {
  return (
    <Card className="import-card upload-form">
      <Card.Body>
        <div className="flex-grow-1 p-3 d-flex flex-column gap-3 bg-white">
          <Button
            as="a"
            href={sampleCSVFileURL}
            variant="success"
            className="align-self-center d-flex align-items-center gap-2"
          >
            <DownloadIcon size={16} />
            Download Sample CSV
          </Button>

          <FileDropArea
            id="csv-file-drop-area"
            accept=".csv"
            mimeType="text/csv"
            fileTypeLabel="CSV"
            onFilesSelected={(selectedFiles) => onFileSelected(selectedFiles[0])}
            showDownloadSampleButton={false}
          />
        </div>
      </Card.Body>
    </Card>
  );
}
