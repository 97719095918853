import gql from "graphql-tag";

const getCourtSubmissionScreenshot = gql`
  query getCourtSubmissionScreenshot($claim_id: ID!) {
    getCourtSubmissionScreenshot(claim_id: $claim_id) {
      error
      url
    }
  }
`;

export default getCourtSubmissionScreenshot;
