import { Modal, Button } from "react-bootstrap";

interface Props {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
export default function StartOverModal({ show, onClose, onConfirm }: Props) {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>

      <Modal.Body>All data inserted will be lost</Modal.Body>

      <Modal.Footer className="justify-content-between">
        <Button variant="light" onClick={onClose}>
          Cancel
        </Button>

        <Button variant="success" onClick={onConfirm}>
          Start over
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
