import { useState, useEffect } from "react";
import { Modal, Card, Button } from "react-bootstrap";
import { ArrowLeft as ArrowLeftIcon, Download as DownloadIcon } from "react-bootstrap-icons";

import FileDropArea from "../../../../../components/FileDropArea/FileDropArea";

// @ts-ignore
import bulkFilingXlsxTemplate from "../Bulk filing template.xlsx";

export default function ImportClaimsModal({
  show,
  onClose,
  onFileSelected,
}: {
  show: boolean;
  onClose: () => void;
  onFileSelected: (selectedFile: File) => void;
}) {
  const [currentStep, setCurrentStep] = useState<
    "select-import-type" | "import-excel-template" | "import-custom-template"
  >("import-excel-template");

  useEffect(() => {
    if (!show) {
      setCurrentStep("import-excel-template");
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Import Claims</Modal.Title>
      </Modal.Header>

      {currentStep === "select-import-type" ? (
        <Modal.Body className="d-flex gap-3">
          <Card className="mb-0 border" style={{ flex: "1 1 0" }}>
            <Card.Body>
              <Card.Title className="text-center">Use our predefined Excel template</Card.Title>
              <Card.Subtitle className="text-center">
                Download our template, enter all the information and upload it here
              </Card.Subtitle>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-center">
              <Button type="button" variant="success" onClick={() => setCurrentStep("import-excel-template")}>
                Go
              </Button>
            </Card.Footer>
          </Card>
          <Card className="mb-0 border" style={{ flex: "1 1 0" }}>
            <Card.Body>
              <Card.Title className="text-center">Use your own template</Card.Title>
              <Card.Subtitle className="text-center">
                Upload your own Excel/CSV file, use our UI to map columns to required fields
              </Card.Subtitle>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-center">
              <Button type="button" variant="success" onClick={() => setCurrentStep("import-custom-template")}>
                Go
              </Button>
            </Card.Footer>
          </Card>
        </Modal.Body>
      ) : currentStep === "import-excel-template" ? (
        <>
          <Modal.Body className="pb-0 d-flex justify-content-center">
            <Button as="a" href={bulkFilingXlsxTemplate} variant="success" className="d-flex align-items-center gap-2">
              <DownloadIcon size="18" />
              Download Bulk Filing Excel Template
            </Button>
          </Modal.Body>

          <Modal.Body className="d-flex" style={{ height: 300 }}>
            <FileDropArea
              id="claim-batch-import-excel-template"
              sampleFileURL={bulkFilingXlsxTemplate}
              accept=".xlsx"
              mimeType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              fileTypeLabel="Excel"
              onFilesSelected={(selectedFiles) => onFileSelected(selectedFiles[0])}
              showDownloadSampleButton={false}
            />
          </Modal.Body>

          {/* <Modal.Footer className="justify-content-start">
            <Button
              type="button"
              variant="outline-primary"
              className="d-flex align-items-center gap-1"
              onClick={() => setCurrentStep("select-import-type")}
            >
              <ArrowLeftIcon size="18" />
              Go back
            </Button>
          </Modal.Footer> */}
        </>
      ) : (
        <>
          <Modal.Body className="d-flex" style={{ height: 300 }}>
            Import custom template
          </Modal.Body>

          <Modal.Footer className="justify-content-start">
            <Button
              type="button"
              variant="outline-primary"
              className="d-flex align-items-center gap-1"
              onClick={() => setCurrentStep("select-import-type")}
            >
              <ArrowLeftIcon size="18" />
              Go back
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}
