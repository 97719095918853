import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";

import { apolloClient } from "../../../../configs/apollo-client";
import getDocketHeader from "../graphql/getDocketHeader";

export default function DocketHeader({ caseId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState();

  useEffect(() => {
    const watchedQuery = apolloClient.watchQuery({
      query: getDocketHeader,
      variables: {
        case_id: caseId,
      },
      fetchPolicy: "cache-first",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        if (!response.partial) {
          setResponse(response.data.getDocketHeader);
          setIsLoading(false);
        }
      },
      error(err) {
        console.log("[watchQuery] error", err);
        setIsLoading(false);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, [caseId]);

  return (
    <Card>
      <Card.Body>
        {isLoading || !response ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <Card.Text
            dangerouslySetInnerHTML={{
              __html: response.header.html || "Docket header not present",
            }}
          />
        )}
      </Card.Body>
    </Card>
  );
}
