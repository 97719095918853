import gql from "graphql-tag";

const discardClaimDraft = gql`
  mutation discardClaimDraft($claim_id: ID!) {
    discardClaimDraft(claim_id: $claim_id) {
      error
    }
  }
`;

export default discardClaimDraft;
