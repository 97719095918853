import gql from "graphql-tag";

const getPreviousFiledClaims = gql`
  query getPreviousFiledClaims($input: EPOCGetPreviousFiledClaimsInput!) {
    getPreviousFiledClaims(input: $input) {
      error
      claims {
        number
        filed_on
      }
    }
  }
`;

export default getPreviousFiledClaims;
