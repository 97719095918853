import gql from "graphql-tag";

const listDockets = gql`
  query listDockets($input: DocketSearchInput!) {
    listDockets(input: $input) {
      updating
      task_id
      entries {
        content {
          date_filed
          docket_no
          docket_display_number
          docket_seq
          docket_text
          has_pdf_link_on_pacer
          timestamp
          purchased
        }
        total_items
        total_pages
        page_size
      }
    }
  }
`;

export default listDockets;
