import gql from "graphql-tag";

const listUserTrackedCases = gql`
  query listUserTrackedCases($input: FiltersInput!) {
    listUserTrackedCases(input: $input) {
      page {
        total_records_count
        total_pages
      }
      data {
        id
        company_id
        ein
        company_name
        court_name
        case_title
        case_number
        date_filed
        chapter
        updated
        claim_deadline
        jurisdiction
        purchase_details {
          id
          purchase_type
          payment_status
          expire_at
        }
      }
    }
  }
`;

export default listUserTrackedCases;
