import gql from "graphql-tag";

const cancelOrganizationSubscription = gql`
  mutation cancelOrganizationSubscription($purchase_id: ID!) {
    cancelOrganizationSubscription(purchase_id: $purchase_id) {
      error
    }
  }
`;

export default cancelOrganizationSubscription;
