import { Modal, Button } from "react-bootstrap";

interface IProps {
  show: boolean;
  onClose: () => void;
  documentUrl: string | null;
}

export default function ClaimFormPreviewModal({ show, onClose, documentUrl }: IProps) {
  return (
    <Modal show={show} onHide={onClose} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>Form 410 Preview</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {documentUrl && <iframe title="Form 410 Preview" src={documentUrl} width="100%" height="100%" />}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={onClose} className="ms-auto">
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
