import { useState, useEffect } from "react";
import { Container, Card, Row, Col, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { apolloClient } from "../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../lib/errors";

import listUserApps from "./graphql/listUserApps";
import connectApp from "./graphql/connectApp";
import availableApps from "./availableApps";

type UserApp = {
  id: string;
  is_active: boolean;
  created_at?: string;
  updated_at?: string;
};

export default function AppStore() {
  const [isLoading, setIsLoading] = useState(true);
  const [userApps, setUserApps] = useState<UserApp[]>([]);
  const [connectingApp, setConnectingApp] = useState<string | null>(null);

  useEffect(() => {
    const watchedQuery = apolloClient.watchQuery({
      query: listUserApps,
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        setUserApps(response.data.listUserApps);
        setIsLoading(false);
        sub.unsubscribe();
      },
      error(err) {
        console.log("[watchQuery] error", err);
        setIsLoading(false);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, []);

  const onConnectApp = async (appId: string) => {
    setConnectingApp(appId);

    try {
      const response = await apolloClient.mutate({
        mutation: connectApp,
        variables: {
          id: appId,
        },
      });

      const userApp = response.data.connectApp;
      setUserApps((prevUserApps) => {
        const index = prevUserApps.findIndex((userApp) => userApp.id === appId);
        const newUserApps = [...prevUserApps];
        newUserApps[index] = userApp;
        return newUserApps;
      });
    } catch (error) {
      console.log(error);
      toast.error(somethingWentWrong);
    } finally {
      setConnectingApp(null);
    }
  };

  return (
    <Container fluid="xl" className="px-0">
      <h1 className="mb-3">App Store</h1>

      {isLoading && <Spinner animation="border" variant="primary" />}

      <Row>
        {!isLoading &&
          userApps.length > 0 &&
          availableApps.map((app) => {
            const userApp = userApps.find((userApp) => userApp.id === app.id);

            return (
              <Col sm="12" md="6" key={app.id}>
                <Card>
                  <Card.Body>
                    <Card.Title className="mb-3">
                      <img src={app.logoUrl} alt={app.name} width="200" />
                    </Card.Title>

                    {userApp && userApp.is_active ? (
                      <>
                        <Card.Text>Connected on {new Date(userApp.created_at!).toLocaleString("en-US")}</Card.Text>

                        <Link to={`/app-store/${userApp.id}`} className="btn btn-primary">
                          Go to settings
                        </Link>
                      </>
                    ) : (
                      <>
                        <ul className="ps-3">
                          {app.features
                            .filter((feature) => feature.showTitle)
                            .map((feature) => (
                              <li key={feature.title}>{feature.title}</li>
                            ))}
                        </ul>

                        <Button
                          variant="success"
                          type="button"
                          onClick={() => onConnectApp(app.id)}
                          disabled={connectingApp === app.id}
                        >
                          {connectingApp === app.id ? <Spinner animation="border" size="sm" /> : "Connect"}
                        </Button>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
      </Row>
    </Container>
  );
}
