import { combineReducers } from "redux";

import authentication from "./authentication";
import superAdmin from "./superAdmin";

const appReducer = combineReducers({
  authentication,
  superAdmin,
});

export default function rootReducer(state, action) {
  if (action.type === "SIGNED_OUT") {
    console.log("Resetting the state after the user signed out");
    state = undefined;
  }

  return appReducer(state, action);
}
