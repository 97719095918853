import React, { useState, useEffect, useCallback } from "react";
import classnames from "classnames";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import {
  Eye as EyeIcon,
  Download as DownloadIcon,
  FolderPlus as FolderPlusIcon,
  FolderMinus as FolderMinusIcon,
} from "react-feather";

import TableContentLoader from "../../../../components/atoms/TableContentLoader";
import { apolloClient } from "../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../lib/errors";

import listDocketDocuments from "../graphql/listDocketDocuments";
import downloadDocketDocument from "../graphql/downloadDocketDocument";
import previewDocketDocument from "../graphql/previewDocketDocument";

const columns = ["Part", "Description", "Pages", "Actions"];

export default function DocketRow({ caseId, row, open, reload, onClickBuyDocketDocument }) {
  const [expandRow, setExpandRow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();
  const [downloadDocumentUrl, setDownloadDocumentUrl] = useState({
    loading: false,
  });
  const [previewDocumentUrl, setPreviewDocumentUrl] = useState({
    loading: false,
  });

  const cellClassName = classnames({ "bg-light": expandRow });

  const hasDocuments = row.has_pdf_link_on_pacer;
  const expandRowIcon = hasDocuments ? expandRow ? <FolderMinusIcon size={20} /> : <FolderPlusIcon size={20} /> : null;

  useEffect(() => {
    setExpandRow(open);

    let sub;

    if (open || reload) {
      setIsLoading(true);
      const watchedQuery = apolloClient.watchQuery({
        query: listDocketDocuments,
        variables: {
          input: {
            case_id: caseId,
            docket_seq: row.docket_seq,
          },
        },
        fetchPolicy: "network-only",
      });

      sub = watchedQuery.subscribe({
        next(response) {
          if (!response.partial) {
            setResponse(response.data.listDocketDocuments);
            setIsLoading(false);
          }
        },
        error(err) {
          console.log("[watchQuery] error", err);
          setIsLoading(false);
          setResponse(undefined);
          toast.error(somethingWentWrong);
        },
      });
    }

    return () => {
      if (sub) {
        console.log("[watchQuery] Clean up");
        sub.unsubscribe();
      }
    };
  }, [caseId, row, open, reload]);

  useEffect(() => {
    let sub;

    if (downloadDocumentUrl.loading) {
      const watchedQuery = apolloClient.watchQuery({
        query: downloadDocketDocument,
        variables: {
          input: {
            case_id: downloadDocumentUrl.caseId,
            docket_seq: downloadDocumentUrl.docketSeq,
            document_number: `${downloadDocumentUrl.documentNumber}`,
          },
        },
        fetchPolicy: "network-only",
      });

      sub = watchedQuery.subscribe({
        next(response) {
          if (!response.partial) {
            setDownloadDocumentUrl({
              loading: false,
              download_url: response.data.downloadDocketDocument.download_url,
            });
            window.open(response.data.downloadDocketDocument.download_url, "_blank");
          }
        },
        error(err) {
          console.log("[watchQuery] error", err);
          setDownloadDocumentUrl({
            loading: false,
          });
          toast.error(somethingWentWrong);
        },
      });
    }

    return () => {
      if (sub) {
        console.log("[watchQuery] Clean up");
        sub.unsubscribe();
      }
    };
  }, [downloadDocumentUrl, row]);

  useEffect(() => {
    let sub;

    if (previewDocumentUrl.loading) {
      const watchedQuery = apolloClient.watchQuery({
        query: previewDocketDocument,
        variables: {
          input: {
            case_id: previewDocumentUrl.caseId,
            docket_seq: previewDocumentUrl.docketSeq,
            document_number: `${previewDocumentUrl.documentNumber}`,
          },
        },
        fetchPolicy: "network-only",
      });

      sub = watchedQuery.subscribe({
        next(response) {
          if (!response.partial) {
            setPreviewDocumentUrl({
              loading: false,
              preview_url: response.data.previewDocketDocument.preview_url,
            });
            window.open(response.data.previewDocketDocument.preview_url, "_blank");
          }
        },
        error(err) {
          console.log("[watchQuery] error", err);
          setPreviewDocumentUrl({
            loading: false,
          });
          toast.error(somethingWentWrong);
        },
      });
    }

    return () => {
      if (sub) {
        console.log("[watchQuery] Clean up");
        sub.unsubscribe();
      }
    };
  }, [previewDocumentUrl]);

  const onDownload = useCallback(
    ({ documentNumber }) => {
      if (downloadDocumentUrl.download_url) {
        window.open(downloadDocumentUrl.download_url, "_blank");
      } else {
        setDownloadDocumentUrl({
          loading: true,
          caseId,
          docketSeq: row.docket_seq,
          documentNumber,
        });
      }
    },
    [downloadDocumentUrl, caseId, row.docket_seq]
  );

  const onPreview = useCallback(
    ({ documentNumber }) => {
      if (previewDocumentUrl.preview_url) {
        window.open(previewDocumentUrl.preview_url, "_blank");
      } else {
        setPreviewDocumentUrl({
          loading: true,
          caseId,
          docketSeq: row.docket_seq,
          documentNumber,
        });
      }
    },
    [previewDocumentUrl, caseId, row.docket_seq]
  );

  return (
    <>
      <td className={classnames("data_filed", cellClassName)}>{row.date_filed}</td>
      <td className={classnames("docket_seq", cellClassName)}>
        <strong>
          {row.purchased ? <span className="text-success me-2">$</span> : null}
          {row.docket_display_number}
        </strong>
      </td>
      <td className={classnames("docket_text", cellClassName)}>
        <div className={classnames({ "mb-3": expandRow })} dangerouslySetInnerHTML={{ __html: row.docket_text }} />
        {expandRow && (
          <Table size="small" className="docket-documents">
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <TableBody
                isLoading={isLoading}
                response={response}
                onClickBuyDocketDocument={onClickBuyDocketDocument}
                onDownload={onDownload}
                onPreview={onPreview}
                downloadingDocument={downloadDocumentUrl.loading}
                previewingDocument={previewDocumentUrl.loading}
              />
            </tbody>
          </Table>
        )}
      </td>
      <td className={classnames("documents", cellClassName)}>{expandRowIcon}</td>
    </>
  );
}

function TableBody({
  isLoading,
  response,
  onClickBuyDocketDocument,
  onDownload,
  onPreview,
  downloadingDocument,
  previewingDocument,
}) {
  if (isLoading || !response) {
    return <TableContentLoader columns={columns.length} rows={2} />;
  }

  const documents = response.documents;

  if (documents.length === 0) {
    return (
      <tr>
        <td colSpan={columns.length}>Documents not found for this docket.</td>
      </tr>
    );
  }

  return documents.map(({ docket_no, number, description_html, pages, can_download }, index) => (
    <tr key={index}>
      <td>{number}</td>
      <td>{description_html || "n/a"}</td>
      <td>{pages}</td>
      <td>
        {!can_download && (
          <Button
            variant="outline-primary"
            size="sm"
            type="button"
            onClick={() => {
              onClickBuyDocketDocument({
                document_number: number,
              });
            }}
          >
            Buy for $3.50
          </Button>
        )}
        {can_download && (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id={`tooltip-download-${docket_no}-${number}`}>Download</Tooltip>}
            >
              <Button
                variant="link"
                className="px-0 me-3"
                disabled={downloadingDocument}
                onClick={() => {
                  onDownload({
                    documentNumber: number,
                  });
                }}
              >
                {downloadingDocument ? <Spinner animation="border" size="sm" /> : <DownloadIcon size="20" />}
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id={`tooltip-preview-${docket_no}-${number}`}>Preview</Tooltip>}
            >
              <Button
                variant="link"
                className="px-0"
                disabled={previewingDocument}
                onClick={() => {
                  onPreview({
                    documentNumber: number,
                  });
                }}
              >
                {previewingDocument ? <Spinner animation="border" size="sm" /> : <EyeIcon size="20" />}
              </Button>
            </OverlayTrigger>
          </>
        )}
      </td>
    </tr>
  ));
}
