import styles from "./HarveyBall.module.css";

function clamp(num: number, min: number, max: number) {
  return Math.min(Math.max(num, min), max);
}

function interpolateRGBColor(startColor: number[], endColor: number[], percent: number): number[] {
  const color = startColor.map((start, i) => {
    const end = endColor[i];
    return start + percent * (end - start);
  });
  return color;
}

const HarveyBall = ({ value, size = 100 }: { value: number; size: number }) => {
  const percentage = clamp(value, 0, 100);
  const radius = (size - 5) / 2;
  const circumference = 2 * Math.PI * radius;
  const fillLength = (circumference * percentage) / 100;

  const greenRGB = [75, 191, 115];
  const yellowRGB = [229, 165, 75];
  const redRGB = [217, 83, 79];

  const fillColor = (function () {
    if (percentage < 50) {
      return interpolateRGBColor(redRGB, yellowRGB, percentage / 49);
    } else if (percentage < 75) {
      return interpolateRGBColor(yellowRGB, greenRGB, (percentage - 50) / 25);
    } else {
      return greenRGB;
    }
  })();

  return (
    <svg className={styles.svgWrapper} width={size} height={size}>
      <circle className={`${styles.circle} ${styles["bg-circle"]}`} cx={size / 2} cy={size / 2} r={radius} />
      <circle
        className={`${styles.circle} ${styles["fill-circle"]}`}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        style={{
          strokeDasharray: `${fillLength} ${circumference}`,
          stroke: `rgb(${fillColor.join(", ")})`,
        }}
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={`${size * 0.25}px`}
        fontWeight="bold"
        fill={`rgb(${fillColor.join(", ")})`}
      >
        {percentage}%
      </text>
    </svg>
  );
};

export default HarveyBall;
