import gql from "graphql-tag";

const markAllNotificationsAsRead = gql`
  mutation {
    markAllNotificationsAsRead {
      id
      content
      notification_type
      is_new
      created_at
    }
  }
`;

export default markAllNotificationsAsRead;
