import gql from "graphql-tag";

const getClaimForm410 = gql`
  query getClaimForm410($batch_id: ID!, $claim_id: ID!) {
    getClaimForm410(batch_id: $batch_id, claim_id: $claim_id) {
      error
      error_code
      url
    }
  }
`;

export default getClaimForm410;
