import gql from "graphql-tag";

const downloadDocketDocument = gql`
  query downloadDocketDocument($input: DocketDocumentActionInput!) {
    downloadDocketDocument(input: $input) {
      download_url
    }
  }
`;

export default downloadDocketDocument;
