import gql from "graphql-tag";

const createClaimBatch = gql`
  mutation createClaimBatch {
    createClaimBatch {
      id
    }
  }
`;

export default createClaimBatch;
