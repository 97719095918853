import { useRef, useLayoutEffect } from "react";
import { Form } from "react-bootstrap";

interface Props {
  indeterminate: boolean;
  [key: string]: any;
}
export default function IndeterminateCheckbox({ indeterminate, ...rest }: Props) {
  const ref = useRef<HTMLInputElement>(null);

  // there is no browser support for setting `indeterminate` via dom strings
  // we can only set it via JS once it's rendered
  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = indeterminate;
    }
  }, [ref, indeterminate]);

  return <Form.Check type="checkbox" ref={ref} {...rest} />;
}
