import React from "react";
import classnames from "classnames";
import { Check as CheckIcon, X as XIcon } from "react-feather";

import isGoodPassword from "./isGoodPassword";
import inspectPasswordStrength from "./inspectPasswordStrength";

export default function PasswordStrengthInfo({ password }) {
  const isValid = isGoodPassword(password);
  const validations = inspectPasswordStrength(password);

  return (
    <div className="bg-light text-dark p-2 mt-2 rounded">
      <p className="mb-1">
        {isValid ? (
          <span>
            You picked a{" "}
            <strong className="text-success">great password</strong>, it has it
            all!
          </span>
        ) : (
          "Password needs to contain:"
        )}
      </p>
      <ul className="list-unstyled mb-0">
        <PasswordStrengthOption isMet={validations.hasLowerCase}>
          a lowercase character,
        </PasswordStrengthOption>
        <PasswordStrengthOption isMet={validations.hasUpperCase}>
          an UPPERCASE character,
        </PasswordStrengthOption>
        <PasswordStrengthOption isMet={validations.hasNumber}>
          a number,
        </PasswordStrengthOption>
        <PasswordStrengthOption isMet={validations.hasGoodSize}>
          is 8 or more characters long.
        </PasswordStrengthOption>
      </ul>
    </div>
  );
}

function PasswordStrengthOption({ isMet, children }) {
  return (
    <li
      className={classnames({ "text-success": isMet, "text-danger": !isMet })}
      style={{ transition: "color .5s" }}
    >
      {isMet ? <CheckIcon size={16} /> : <XIcon size={16} />} {children}
    </li>
  );
}
