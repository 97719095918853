export default function BKalertsIcon() {
  return (
    <svg
      className="d-block bkalerts-icon"
      width="120"
      viewBox="0 0 145 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="BKalertsIcon">
        <g id="CloudPath">
          <path
            d="M58.5043 0C47.8093 0 37.1792 4.39481 29.6166 11.9419C24.8519 16.6967 21.3447 22.6665 19.3996 29.1017C14.7246 29.9824 10.3093 32.2595 6.94261 35.6192C2.55449 39.9983 0 46.1514 0 52.3444C0 58.5375 2.55449 64.6925 6.94261 69.0716C11.3308 73.4507 17.4986 76 23.7043 76H129.87C133.831 76 137.768 74.3735 140.569 71.5785C143.37 68.7833 145 64.8537 145 60.9007C145 56.9476 143.37 53.0202 140.569 50.2251C137.785 47.447 133.879 45.8252 129.942 45.8052C129.174 38.6431 125.923 31.7494 120.815 26.652C114.933 20.7821 106.666 17.3642 98.3478 17.3642C96.3282 17.3642 94.3131 17.5709 92.333 17.96C90.8841 15.8021 89.234 13.7799 87.392 11.9419C79.8295 4.39481 69.1994 0 58.5043 0V0Z"
            fill="#EFF2F6"
          />
          <path
            d="M30.6762 13.0036L30.6762 13.0036C37.9573 5.73747 48.2062 1.5 58.5043 1.5C68.8025 1.5 79.0514 5.73747 86.3324 13.0036L87.3403 11.9937L86.3325 13.0036C88.1022 14.7696 89.6907 16.7157 91.0877 18.7962L91.6437 19.6241L92.6222 19.4318C94.5122 19.0605 96.4304 18.8642 98.3478 18.8642C106.269 18.8642 114.155 22.1248 119.755 27.7137C124.616 32.5643 127.719 39.1379 128.451 45.9652L128.594 47.2984L129.935 47.3052C133.478 47.3232 137.006 48.7886 139.51 51.2868L140.451 50.3431L139.51 51.2868C142.029 53.8009 143.5 57.3464 143.5 60.9007C143.5 64.4552 142.029 68.0028 139.51 70.5167C136.99 73.0306 133.434 74.5 129.87 74.5H23.7043C17.8953 74.5 12.1088 72.108 8.00218 68.0099C3.89574 63.9119 1.5 58.1387 1.5 52.3444C1.5 46.5503 3.89564 40.779 8.00217 36.6809C11.1485 33.5411 15.2885 31.4025 19.6773 30.5758L20.5721 30.4072L20.8355 29.5357C22.7115 23.3287 26.0933 17.5771 30.6762 13.0036Z"
            stroke="#4E4544"
            strokeOpacity="0.3"
            strokeWidth="3"
          />
        </g>
        <g id="BK">
          <path
            d="M38.4432 64V29.0909H51.8068C54.3295 29.0909 56.4261 29.4886 58.0966 30.2841C59.7784 31.0682 61.0341 32.142 61.8636 33.5057C62.7045 34.8693 63.125 36.4148 63.125 38.142C63.125 39.5625 62.8523 40.7784 62.3068 41.7898C61.7614 42.7898 61.0284 43.6023 60.108 44.2273C59.1875 44.8523 58.1591 45.3011 57.0227 45.5739V45.9148C58.2614 45.983 59.4489 46.3636 60.5852 47.0568C61.733 47.7386 62.6705 48.7045 63.3977 49.9545C64.125 51.2045 64.4886 52.7159 64.4886 54.4886C64.4886 56.2955 64.0511 57.9205 63.1761 59.3636C62.3011 60.7955 60.983 61.9261 59.2216 62.7557C57.4602 63.5852 55.2443 64 52.5739 64H38.4432ZM44.767 58.7159H51.5682C53.8636 58.7159 55.517 58.2784 56.5284 57.4034C57.5511 56.517 58.0625 55.3807 58.0625 53.9943C58.0625 52.9602 57.8068 52.0284 57.2955 51.1989C56.7841 50.358 56.0568 49.6989 55.1136 49.2216C54.1705 48.733 53.0455 48.4886 51.7386 48.4886H44.767V58.7159ZM44.767 43.9375H51.0227C52.1136 43.9375 53.0966 43.7386 53.9716 43.3409C54.8466 42.9318 55.5341 42.358 56.0341 41.6193C56.5455 40.8693 56.8011 39.983 56.8011 38.9602C56.8011 37.608 56.3239 36.4943 55.3693 35.6193C54.4261 34.7443 53.0227 34.3068 51.1591 34.3068H44.767V43.9375Z"
            fill="#4E4544"
          />
          <path
            d="M69.9901 64V29.0909H76.3139V45.1307H76.7401L90.3594 29.0909H98.081L84.581 44.7557L98.2003 64H90.598L80.1832 49.0341L76.3139 53.6023V64H69.9901Z"
            fill="#FF6A14"
          />
        </g>
      </g>
    </svg>
  );
}
