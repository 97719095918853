import gql from "graphql-tag";

const generateClaimForm410 = gql`
  mutation generateClaimForm410($batch_id: ID!, $claim_id: ID!) {
    generateClaimForm410(batch_id: $batch_id, claim_id: $claim_id) {
      error
      error_code
      claim {
        status
      }
    }
  }
`;

export default generateClaimForm410;
