import gql from "graphql-tag";

const resolveClaimCreditor = gql`
  query resolveClaimCreditor($batch_id: ID!, $claim_id: ID!) {
    resolveClaimCreditor(batch_id: $batch_id, claim_id: $claim_id) {
      accepted
      error
      error_code
    }
  }
`;

export default resolveClaimCreditor;
