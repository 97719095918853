import React, { useState, useCallback } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Search as SearchIcon } from "react-feather";

function formatDateForServer(date) {
  const [year, month, day] = date.split("-");

  return [month, day, year].join("/");
}

export default function Filters({
  isLoading,
  onSubmit,
  onClear,
  expandOnDocketNumber
}) {
  const [filterBy, setFilterBy] = useState("");
  const [docketNumber, setDocketNumber] = useState(expandOnDocketNumber || "");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const onChangeFilterBy = useCallback(
    ({ target: { value } }) => setFilterBy(value),
    []
  );
  const onChangeDocketNumber = useCallback(
    ({ target: { value } }) => setDocketNumber(value),
    []
  );
  const onChangeDateFrom = useCallback(
    ({ target: { value } }) => setDateFrom(value),
    []
  );
  const onChangeDateTo = useCallback(
    ({ target: { value } }) => setDateTo(value),
    []
  );

  const onClickClear = useCallback(() => {
    setFilterBy("");
    setDocketNumber("");
    setDateFrom("");
    setDateTo("");
    onClear();
  }, [onClear]);

  const onFormSubmit = useCallback(
    ev => {
      ev.preventDefault();
      onSubmit({
        filterBy: filterBy ? filterBy : undefined,
        docketNumber: docketNumber ? docketNumber : undefined,
        dateFrom: dateFrom ? formatDateForServer(dateFrom) : undefined,
        dateTo: dateTo ? formatDateForServer(dateTo) : undefined
      });
    },
    [onSubmit, filterBy, docketNumber, dateFrom, dateTo]
  );

  return (
    <Form onSubmit={onFormSubmit}>
      <fieldset className="p-0" disabled={isLoading}>
        <Row>
          <Form.Group as={Col} md={3} className="mb-3">
            <Form.Label htmlFor="filter-by">Filter by</Form.Label>
            <Form.Control
              placeholder="keyword"
              id="filter-by"
              value={filterBy}
              onChange={onChangeFilterBy}
            />
          </Form.Group>

          <Form.Group as={Col} md={2} className="mb-3">
            <Form.Label htmlFor="docket-number">Docket number</Form.Label>
            <Form.Control
              placeholder="1, 3, 4-"
              id="docket-number"
              value={docketNumber}
              onChange={onChangeDocketNumber}
            />
          </Form.Group>

          <Form.Group as={Col} md={2} className="mb-3">
            <Form.Label htmlFor="date-from">Date filed from</Form.Label>
            <Form.Control
              type="date"
              id="date-from"
              value={dateFrom}
              onChange={onChangeDateFrom}
            />
          </Form.Group>

          <Form.Group as={Col} md={2} className="mb-3">
            <Form.Label htmlFor="date-to">Date filed to</Form.Label>
            <Form.Control
              type="date"
              id="date-to"
              value={dateTo}
              onChange={onChangeDateTo}
            />
          </Form.Group>

          <Form.Group
            as={Col}
            md="auto"
            className="mb-3 d-flex align-items-end"
          >
            <Button type="submit" variant="primary">
              <SearchIcon size={16} />
            </Button>
          </Form.Group>
        </Row>

        <Button
          variant="link"
          className="px-0"
          onClick={onClickClear}
          type="button"
        >
          Clear search
        </Button>
      </fieldset>
    </Form>
  );
}
