import { Fragment, useState } from "react";
import classnames from "classnames";
import { Container, Card, Row, Col, Button, Spinner } from "react-bootstrap";
import { Redirect, useRouteMatch, Link, useHistory } from "react-router-dom";
import { ChevronLeft as BackIcon } from "react-bootstrap-icons";
import { toast } from "react-toastify";

import { apolloClient } from "../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../lib/errors";

import availableApps from "./availableApps";
import disconnectApp from "./graphql/disconnectApp";

export default function Connection() {
  const [isLoading, setIsLoading] = useState(false);
  const match = useRouteMatch<{ app_id: string }>();
  const {
    params: { app_id },
  } = match;
  const history = useHistory();

  const app = availableApps.find((app) => app.id === app_id);

  const onDisconnectApp = async () => {
    try {
      setIsLoading(true);

      await apolloClient.mutate({
        mutation: disconnectApp,
        variables: {
          id: app_id,
        },
      });

      history.push("/app-store");
    } catch (error) {
      console.log(error);
      toast.error(somethingWentWrong);
      setIsLoading(false);
    }
  };

  if (!app) {
    return <Redirect to="/app-store" />;
  }

  return (
    <Container fluid="xl" className="px-0">
      <Card>
        <Card.Body>
          <Card.Title className="mb-3">
            <Row>
              <Col sm="12" md="auto" className="mb-3 mb-md-0">
                <Link
                  to="/app-store"
                  className={classnames("btn btn-link px-0 d-flex align-items-center gap-1", {
                    disabled: isLoading,
                  })}
                >
                  <BackIcon size={18} />
                  Back to App Store
                </Link>
              </Col>
              <Col sm="12" md className="d-flex align-items-center justify-content-center flex-wrap gap-2 mb-3 mb-md-0">
                <span>Configuration Settings for</span>
                <img src={app.logoUrl} alt={app.name} width="200" />
              </Col>
              <Col sm="12" md="auto" className="d-flex justify-content-center">
                <Button variant="danger" type="button" disabled={isLoading} onClick={onDisconnectApp}>
                  {isLoading ? <Spinner animation="border" size="sm" /> : "Disconnect"}
                </Button>
              </Col>
            </Row>
          </Card.Title>

          <Card.Text className="fw-bold">{app.name} Overview</Card.Text>

          <dl className="mb-0">
            {app.features.map((feature, index) => (
              <Fragment key={feature.title}>
                <dt>{feature.title}</dt>
                <dd
                  className={classnames({
                    "mb-0": index + 1 === app.features.length,
                  })}
                >
                  {feature.description}
                </dd>
              </Fragment>
            ))}
          </dl>
        </Card.Body>
      </Card>
    </Container>
  );
}
