import gql from "graphql-tag";

const getClaim = gql`
  query getClaim($claim_id: ID!) {
    getClaim(claim_id: $claim_id) {
      error
      court_code
      case_no
      form_data
      submission_status
      payment_status
      court_api_task_id
      court_api_response_status_code
      court_api_response_status_text
      court_api_response_body
      created_at
      updated_at
      deleted_at
    }
  }
`;

export default getClaim;
