import React, { useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";

import { apolloClient } from "../../../configs/apollo-client";
import DataGridTable from "../../../components/DataGridTable";

import searchCasesOnPacer from "./graphql/searchCasesOnPacer";
import addCaseFromPacer from "./graphql/addCaseFromPacer";
import headerColumns from "./headerColumns";

import "./styles.scss";

export default function SearchCases({ history, location: { search } }) {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState();
  const [paginatedData, setPaginatedData] = useState();
  const [showAddCaseModal, setShowAddCaseModal] = useState(false);

  const { searchTerm } = Object.fromEntries(new URLSearchParams(search));

  useEffect(() => {
    setIsLoading(true);

    const watchedQuery = apolloClient.watchQuery({
      query: searchCasesOnPacer,
      variables: {
        input: searchTerm,
      },
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        if (!response.partial) {
          setResponse(response.data.searchCasesOnPacer);
          setPaginatedData(response.data.searchCasesOnPacer.slice(0, 10));
          setIsLoading(false);
        }
      },
      error(err) {
        console.log("[watchQuery] error", err);
        setIsLoading(false);
      },
      complete() {
        setIsLoading(false);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, [searchTerm]);

  const onClickShowMore = useCallback(() => {
    setPaginatedData(response.slice(0, paginatedData.length + 10));
  }, [response, paginatedData]);

  const onAddCase = useCallback(
    async ({ court_code, case_number }) => {
      setShowAddCaseModal(true);

      const response = await apolloClient.mutate({
        mutation: addCaseFromPacer,
        variables: {
          input: {
            court_code,
            case_number,
          },
        },
      });

      history.push(`/cases/${response.data.addCase.id}`);
    },
    [history]
  );

  const renderRow = useCallback(
    (row) => (
      <>
        <td className="title">
          <p className="d-block d-sm-none mb-1 fw-bold">Case Title</p>
          {row.title}
        </td>
        <td className="text-nowrap text-primary case_no">{row.case_no}</td>
        <td className="court">
          <p className="d-block d-sm-none mb-1 fw-bold">Court</p>
          {row.court_name}
        </td>
        <td className="chapter">{row.chapter ? row.chapter : "-"}</td>
        <td className="date_filed">{row.date_filed ? new Date(row.date_filed).toLocaleDateString("en-US") : "-"}</td>
        <td className="date_closed">
          <p className="d-block d-sm-none mb-1 fw-bold">Closed</p>
          {row.date_closed ? new Date(row.date_closed).toLocaleDateString("en-US") : "-"}
        </td>
        <td className="disposition">
          {row.disposition && (
            <>
              <p className="d-block d-sm-none mb-1 fw-bold">Disposition</p>
              {row.disposition}
            </>
          )}
        </td>
      </>
    ),
    []
  );

  return (
    <>
      <h1 className="mt-0 mb-2">Searching Business Bankruptcy Cases</h1>
      <p>Searching for "{searchTerm}"</p>

      {!paginatedData && <Spinner animation="border" variant="primary" />}

      {paginatedData && (
        <DataGridTable
          tableClassName="search-cases"
          headerColumns={headerColumns}
          selectableRows={false}
          sortableRows={false}
          isLoading={isLoading}
          data={paginatedData}
          noRecordsFoundLabel="No cases found, try to refine your search."
          renderRow={renderRow}
          isRowClickable={() => true}
          onRowClick={(row) =>
            onAddCase({
              court_code: row.court_code,
              case_number: row.case_no,
            })
          }
        >
          {response.length > paginatedData.length && (
            <tr className="show_more">
              <td colSpan={headerColumns.length} className="text-center">
                <Button variant="link" onClick={onClickShowMore}>
                  Show more ({response.length - paginatedData.length} more results)
                </Button>
              </td>
            </tr>
          )}
        </DataGridTable>
      )}

      <Modal show={showAddCaseModal} backdrop="static" keyboard={false}>
        <Modal.Body className="text-center">
          <h2 className="mb-3">Downloading case docket</h2>
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </Modal>
    </>
  );
}
