import { useState } from "react";
import { Button, OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import {
  FileEarmarkPlus as FileEarmarkPlusIcon,
  FileEarmarkPdf as FileEarmarkPdfIcon,
  X as XIcon,
} from "react-bootstrap-icons";
import { toast, Id } from "react-toastify";
import { nanoid } from "nanoid";

import { apolloClient } from "../../../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../../../lib/errors";

import { PDFAttachment } from "./interfaces";
import getEPOCAttachmentUploadUrl from "./graphql/getEPOCAttachmentUploadUrl";

function formatFileSize(number: number) {
  if (number < 1024) {
    return `${number} bytes`;
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} KB`;
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)} MB`;
  }
}

interface Props {
  claimId: string;
  attachments: PDFAttachment[];
  onIsUploading: (loading: boolean) => void;
  onAdd: (attachment: PDFAttachment) => void;
  onRemove: (index: number) => void;
  sizeLimit: number;
}
export default function Upload({ claimId, attachments, onIsUploading, onAdd, onRemove, sizeLimit }: Props) {
  const [invalidFiletypeError, showInvalidFiletypeError] = useState(false);
  const [maximumFileSizeReached, showMaximumFileSizeReached] = useState(false);

  const onFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFile = event.target.files[0];

      if (selectedFile.type !== "application/pdf") {
        showInvalidFiletypeError(true);
        return;
      } else {
        showInvalidFiletypeError(false);
      }

      console.log("Selected file size", selectedFile.size);
      const totalFileSize = attachments.reduce((total, { size }) => total + size, 0) + selectedFile.size;

      if (totalFileSize >= sizeLimit) {
        showMaximumFileSizeReached(true);
        return;
      } else {
        showMaximumFileSizeReached(false);
      }

      const shortFileName = (function () {
        if (selectedFile.name.length <= 10) {
          return selectedFile.name;
        }

        const [extension, ...parts] = selectedFile.name.split(".").reverse();
        return `${parts.reverse().join("").slice(0, 7)}...${extension}`;
      })();

      let toastId: Id | null = null;
      try {
        onIsUploading(true);

        toastId = toast.info(`Uploading "${selectedFile.name}"`, {
          autoClose: false,
          closeButton: false,
          draggable: false,
          isLoading: true,
          position: "top-right",
          hideProgressBar: true,
        });

        const response = await apolloClient.query({
          query: getEPOCAttachmentUploadUrl,
          variables: {
            claim_id: claimId,
            filename: selectedFile.name,
          },
        });
        const { error, s3_key, url } = response.data.getEPOCAttachmentUploadUrl;

        if (error) {
          toast.error(error);
          return;
        }

        await fetch(url, {
          method: "PUT",
          body: selectedFile,
          headers: {
            "Content-Type": "application/pdf",
          },
        });

        onAdd({
          id: nanoid(),
          filename: selectedFile.name,
          short_filename: shortFileName,
          size: selectedFile.size,
          s3_key,
        });
      } catch (error) {
        toast.error(somethingWentWrong);
      } finally {
        if (toastId) toast.done(toastId);

        onIsUploading(false);
      }
    }
  };

  return (
    <>
      <p>Attach redacted copies of any documents that show that the debt exists, a lien secures the debt, or both.</p>
      <p>
        Also attach redacted copies of any documents that show perfection of any security interest or any assignments or
        transfers of the debt. In addition to the documents, a summary may be added. Federal Rule of Bankruptcy
        Procedure (called "Bankruptcy Rule") 3001(c) and (d).
      </p>

      <input type="file" className="d-none" id="select-file" onChange={onFileSelected} accept=".pdf" />

      {invalidFiletypeError && (
        <Alert variant="danger">
          <div className="alert-message">Only PDF files are allowed</div>
        </Alert>
      )}

      {maximumFileSizeReached && (
        <Alert variant="danger">
          <div className="alert-message">
            Attachments to the Proof of Claim are NOT to exceed {formatFileSize(sizeLimit)} Mb in size for this Court
          </div>
        </Alert>
      )}

      <p className="font-weight-bold fs-4">
        {attachments.length} file{attachments.length > 2 ? "s" : ""} attached, total size: ~
        {formatFileSize(attachments.reduce((total, { size }) => total + size, 0))}
      </p>

      <div className="bg-white rounded p-4 mb-3 d-flex flex-wrap gap-3">
        {attachments.map(function ({ id, filename, short_filename, size }, index) {
          return (
            <div className="rounded border p-3 d-flex flex-column align-items-center gap-1 position-relative" key={id}>
              <Button
                type="button"
                variant="danger"
                className="position-absolute p-0 rounded-circle d-flex align-items-center justify-content-center"
                style={{
                  top: 0,
                  right: 0,
                  width: 22,
                  height: 22,
                  marginTop: -9,
                  marginRight: -11,
                }}
                onClick={() => {
                  onRemove(index);
                  showMaximumFileSizeReached(false);
                }}
              >
                <XIcon size={18} />
              </Button>

              <FileEarmarkPdfIcon size={24} />

              <OverlayTrigger placement="top" trigger={["hover", "focus"]} overlay={<Tooltip>{filename}</Tooltip>}>
                <span>{short_filename}</span>
              </OverlayTrigger>

              <span>{formatFileSize(size)}</span>
            </div>
          );
        })}

        <Button
          as="label"
          htmlFor="select-file"
          variant="outline-primary"
          className="mb-0 d-flex flex-column p-3 align-items-center justify-content-center gap-2"
          role="button"
        >
          <FileEarmarkPlusIcon size={24} />
          Add file
        </Button>
      </div>

      <p>
        <span className="text-danger">
          Do not upload a completed Proof of Claim form as an attachment to this filing.
        </span>{" "}
        Attaching a completed Proof of Claim will result in multiple versions of the form being filed (the
        electronically created proof of claim form plus the proof of claim attached). If filing an Amended Proof of
        Claim, the attachment of the previously filed claim is allowed.
      </p>
    </>
  );
}
