const headerColumns = [
  {
    id: "date_filed",
    label: "Date"
  },
  {
    id: "docket_seq",
    label: "#",
    sortable: false
  },
  {
    id: "docket_text",
    label: "Docket Text",
    columnWidth: "90%"
  },
  {
    id: "documents",
    label: "",
    sortable: false
  }
];

export default headerColumns;
