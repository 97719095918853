import gql from "graphql-tag";

const getClaimAttachmentUploadUrl = gql`
  query getClaimAttachmentUploadUrl($batch_id: ID!, $claim_id: ID!, $filename: String!) {
    getClaimAttachmentUploadUrl(batch_id: $batch_id, claim_id: $claim_id, filename: $filename) {
      error
      error_code
      url
      s3_key
    }
  }
`;

export default getClaimAttachmentUploadUrl;
