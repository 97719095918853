import gql from "graphql-tag";

const connectApp = gql`
  mutation connectApp($id: ID!) {
    connectApp(id: $id) {
      id
      is_active
      created_at
      updated_at
    }
  }
`;

export default connectApp;
