import gql from "graphql-tag";

const adminListUsers = gql`
  query adminListUsers($input: AdminListUsersInput!) {
    adminListUsers(input: $input) {
      users {
        id
        email
        name
        active
        cognito_user_status
        cognito_user_status_description
        created_at
      }
      pagination {
        total_records_count
        total_pages
      }
    }
  }
`;

export default adminListUsers;
