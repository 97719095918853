import gql from "graphql-tag";

const listClaims = gql`
  query listClaims {
    listClaims {
      id
      court_code
      case_no
      case_title
      submission_status
      payment_status
      court_api_response_status_code
      court_api_response_status_text
      court_api_response_body
      created_at
      updated_at
    }
  }
`;

export default listClaims;
