import { useState, useCallback, useEffect } from "react";
import { Container, Nav, Card, Alert, Spinner } from "react-bootstrap";
import { NavLink, useParams, useHistory } from "react-router-dom";
import Papa from "papaparse";
import { toast } from "react-toastify";

import { somethingWentWrong } from "../../lib/errors";
import AuthService from "../../services/AuthService";

import Faqs from "./Faqs";
import UploadCSV from "./UploadCSV";
import PasteCSV from "./PasteCSV";
import ManualImport from "./ManualImport";
import csvHeader from "./csvHeader";
import { ParsedCSVResult } from "./types";

import "./index.scss";

async function validateCSVFileHeader(file: File): Promise<[boolean, string[]]> {
  return new Promise(function (resolve, reject) {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      preview: 1,
      complete: function (results: ParsedCSVResult) {
        const columns = results.meta.fields;
        const missingColumns = csvHeader.filter((col) => !columns.includes(col));
        const isValid = missingColumns.length === 0;

        resolve([isValid, missingColumns]);
      },
      error: reject,
    });
  });
}

export default function Import() {
  const { type } = useParams<{ type: "paste" | "manual" | undefined }>();
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [currentStep, setCurrentStep] = useState<"import" | "upload">("import");
  const [missingColumns, setMissingColumns] = useState<string[]>([]);

  const onFileSelected = useCallback((csvFile: File) => setSelectedFile(csvFile), []);

  useEffect(() => {
    if (!selectedFile) return;

    async function upload(csvFile: File) {
      try {
        const [headerValid, missingColumns] = await validateCSVFileHeader(csvFile);

        if (!headerValid) {
          setMissingColumns(missingColumns);
          return;
        }

        setMissingColumns([]);
        setCurrentStep("upload");

        const formData = new FormData();
        formData.append("file", csvFile);

        const Authorization = await AuthService.getJwtToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/import/debtors`, {
          method: "POST",
          headers: {
            Authorization: Authorization || "",
          },
          body: formData,
        });
        if (!response.ok) {
          toast.error("Failed to upload the CSV file, please try again");
        }

        const json = await response.json();
        if (json.success) history.push(`/tracking`);
      } catch (error) {
        console.log(error);
        toast.error(somethingWentWrong);
      }
    }

    upload(selectedFile);
  }, [selectedFile, history]);

  if (currentStep === "upload") {
    return (
      <Container fluid="xl" className="py-3">
        <Card>
          <Card.Body>
            <Card.Title className="mb-3 d-flex flex-column justify-content-center">
              <h1 className="text-center">Please wait, we're uploading your CSV file</h1>
              <h2 className="text-center">Don't close this page</h2>
            </Card.Title>

            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  return (
    <Container fluid="xl" className="px-0">
      <h1 className="text-center mb-4">Import your list of companies or individuals to track</h1>

      {missingColumns.length > 0 && (
        <Alert variant="danger" onClose={() => setMissingColumns([])} dismissible>
          <div className="alert-message">
            <Alert.Heading>The CSV header is missing the following columns:</Alert.Heading>
            <ul>
              {missingColumns.map((col) => (
                <li key={col}>{col}</li>
              ))}
            </ul>
          </div>
        </Alert>
      )}

      <Nav variant="tabs" className="import-tabs">
        <Nav.Item>
          <NavLink className="nav-link" activeClassName="active" to="/import" exact>
            Upload CSV
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink className="nav-link" activeClassName="active" to="/import/paste">
            Paste CSV
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink className="nav-link" activeClassName="active" to="/import/manual">
            Insert manually
          </NavLink>
        </Nav.Item>
      </Nav>

      {!type ? (
        <UploadCSV onFileSelected={onFileSelected} />
      ) : type === "paste" ? (
        <PasteCSV onFileSelected={onFileSelected} />
      ) : (
        <ManualImport onFileSelected={onFileSelected} />
      )}

      <h2 className="text-center mb-3">How will BKalerts monitor your bankruptcies?</h2>
      <Faqs />
    </Container>
  );
}
