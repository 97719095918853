import { useEffect, useState, useCallback, forwardRef } from "react";
import classnames from "classnames";
import { toast } from "react-toastify";
import {
  Row,
  Col,
  Spinner,
  Card,
  Table,
  OverlayTrigger,
  Tooltip,
  Button,
  Modal,
  Dropdown,
  Container,
} from "react-bootstrap";
import {
  FileEarmarkPlus as FileEarmarkPlusIcon,
  Download as DownloadIcon,
  Eye as EyeIcon,
  PencilSquare as PencilIcon,
  Image as ImageIcon,
  Trash as TrashIcon,
  Send as SendIcon,
  ThreeDotsVertical as ThreeDotsVerticalIcon,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";

import { apolloClient } from "../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../lib/errors";
import courts from "../New/steps/lib/courts.json";

import listClaims from "./graphql/listClaims";
import getCourtSubmissionScreenshot from "./graphql/getCourtSubmissionScreenshot";
import getClaimsRegisterScreenshot from "./graphql/getClaimsRegisterScreenshot";
import discardClaimDraft from "./graphql/discardClaimDraft";

import "./Claims.scss";

type StatusLabels = {
  draft: "Draft";
  ready: "Ready to submit";
  pending: "Submitting";
  finished: "Submitted";
};

type Claim = {
  id: string;
  court_code: string;
  case_no: string;
  case_title: string;
  payment_status: string;
  submission_status: keyof StatusLabels;
  court_api_response_status_code: number;
  court_api_response_status_text: string;
  court_api_response_body: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

type Attachment = {
  name: string;
  download_link: string;
  preview_link: string;
};
type Document = Attachment;
type CourtApiResponse = {
  attachments: Attachment[];
  documents: Document[];
  claim_number: number;
  links: {
    original_screenshot: {
      pdf: string;
    };
  };
};

type ClaimID = string;

export default function Claims() {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<Claim[] | undefined>(undefined);
  const [showConfirmModal, setShowConfirmModal] = useState<false | ClaimID>(false);

  useEffect(() => {
    const watchedQuery = apolloClient.watchQuery({
      query: listClaims,
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        if (response.data) {
          setResponse(response.data.listClaims as Claim[]);
        }

        setIsLoading(false);
      },
      error(err) {
        console.log("[watchQuery] error", err);
        toast.error(somethingWentWrong);
        setIsLoading(false);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, []);

  const onDiscardConfirm = useCallback(async () => {
    if (response && response.length > 0) {
      // Optmistic update
      setResponse(response.filter((record) => record.id !== showConfirmModal));
      setShowConfirmModal(false);

      // Fire mutation and forget
      const saving = apolloClient.mutate({
        mutation: discardClaimDraft,
        variables: {
          claim_id: showConfirmModal,
        },
        fetchPolicy: "no-cache",
      });

      toast.promise(saving, {
        pending: "Discarding draft...",
        success: "Draft discarded!",
        error: somethingWentWrong,
      });
    }
  }, [response, showConfirmModal]);

  const closeConfirmationModal = useCallback(() => setShowConfirmModal(false), []);

  return (
    <Container fluid="xl" className="px-0">
      <Card>
        <Card.Body>
          <Row className="d-flex align-items-center">
            <Col xs="auto" sm className="me-auto">
              <Card.Title as="h1" className="mb-0">
                Your Claims
              </Card.Title>
            </Col>
            <Col xs="auto">
              <Link className="btn btn-primary btn-sm d-flex align-items-center gap-1" to="/epoc/new">
                <FileEarmarkPlusIcon size={16} />
                New Proof of Claim
              </Link>
            </Col>
          </Row>
        </Card.Body>

        {isLoading && (
          <Card.Body className="d-flex align-items-center justify-content-center">
            <Spinner animation="border" variant="primary" />
          </Card.Body>
        )}

        {response && (
          <Table hover size="sm" className="your-claims-table">
            <thead className="table-light position-sticky sticky-top z-2 shadow-lg">
              <tr>
                <th className="court">Court</th>
                <th className="case">Case</th>
                <th className="updated_at">Last updated</th>
                <th className="status">Status</th>
                <th className="actions min-content-width">Actions</th>
              </tr>
            </thead>

            <tbody>
              {response.length > 0 ? (
                response.map(
                  ({
                    id,
                    court_code,
                    case_no,
                    case_title,
                    submission_status,
                    updated_at,
                    court_api_response_status_code,
                    court_api_response_body,
                  }) => {
                    const statusLabels: StatusLabels = {
                      draft: "Draft",
                      ready: "Ready to submit",
                      pending: "Submitting",
                      finished: "Submitted",
                    };

                    const successfull = submission_status === "finished" && court_api_response_status_code === 200;
                    const responseBody: CourtApiResponse = JSON.parse(court_api_response_body);

                    const court_name = courts.find((court) => court.court_code === court_code)?.court_name;
                    const updated_at_fragment = (
                      <>
                        {new Date(updated_at).toLocaleDateString()}
                        <br />
                        {new Date(updated_at).toLocaleTimeString()}
                      </>
                    );
                    const status_fragment = (
                      <>
                        <span
                          className={classnames({
                            "text-success": submission_status === "finished" && successfull,
                            "text-danger": submission_status === "finished" && !successfull,
                            "text-warning": submission_status === "ready",
                            "text-primary": submission_status === "pending" || submission_status === "draft",
                          })}
                        >
                          {statusLabels[submission_status]}
                          {submission_status === "finished" && !successfull ? " with errors" : ""}
                        </span>
                        <br />
                        {submission_status === "finished" && successfull && (
                          <strong>Claim number: {responseBody.claim_number}</strong>
                        )}
                      </>
                    );

                    return (
                      <tr key={id}>
                        <td className="court">{court_name}</td>
                        <td className="case">
                          <div className="d-block d-md-none">
                            <strong>{court_name}</strong>
                          </div>
                          <div>
                            <strong>{case_no}</strong>
                          </div>
                          <div>{case_title}</div>
                          <div className="d-block d-md-none">
                            <strong>Updated at:</strong> {updated_at_fragment}
                          </div>
                          <div className="d-block d-md-none">
                            <strong>Status:</strong> {status_fragment}
                          </div>
                        </td>
                        <td className="updated_at">{updated_at_fragment}</td>
                        <td className="status">{status_fragment}</td>
                        <td className="actions">
                          <Actions
                            submissionStatus={submission_status}
                            responseStatusCode={court_api_response_status_code}
                            responseBody={responseBody}
                            claimId={id}
                            onDiscard={() => setShowConfirmModal(id)}
                          />
                        </td>
                      </tr>
                    );
                  }
                )
              ) : (
                <tr>
                  <td className="py-3" colSpan={5}>
                    No records yet, <Link to="/epoc/new">click here to file a new proof of Claim</Link>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </Card>

      <Modal show={!!showConfirmModal} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>

        <Modal.Body>All data inserted will be lost</Modal.Body>

        <Modal.Footer className="justify-content-between">
          <Button variant="light" onClick={closeConfirmationModal}>
            Cancel
          </Button>

          <Button variant="success" onClick={onDiscardConfirm}>
            Discard
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

function downloadFile(filePath: string) {
  const link = document.createElement("a");
  link.href = filePath;
  link.target = "_blank";
  link.click();
}

interface Props {
  submissionStatus: keyof StatusLabels;
  responseStatusCode: number;
  responseBody: CourtApiResponse;
  claimId: string;
  onDiscard: () => void;
}
function Actions({ submissionStatus, responseStatusCode, responseBody, claimId, onDiscard }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  async function downloadScreenshot(query: typeof getCourtSubmissionScreenshot | typeof getClaimsRegisterScreenshot) {
    const toastId = toast.info("Downloading screenshot...", {
      autoClose: false,
      closeButton: false,
      draggable: false,
      isLoading: true,
      position: "top-right",
      hideProgressBar: true,
    });

    try {
      setIsLoading(true);

      const response = await apolloClient.query({
        query,
        variables: {
          claim_id: claimId,
        },
        fetchPolicy: "network-only",
      });

      const data: {
        error: string;
        url: string;
      } =
        query === getCourtSubmissionScreenshot
          ? response.data.getCourtSubmissionScreenshot
          : response.data.getClaimsRegisterScreenshot;

      if (data.error) {
        toast.update(toastId, {
          render: () => data.error,
          type: "error",
          isLoading: false,
          closeButton: true,
        });
      } else {
        toast.done(toastId);
        downloadFile(data.url);
      }
    } catch (error) {
      console.log(error);
      toast.update(toastId, {
        render: () => somethingWentWrong,
        type: "error",
        isLoading: false,
        closeButton: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  if (submissionStatus === "finished") {
    if (responseStatusCode === 200) {
      return (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} disabled={isLoading} />

          <Dropdown.Menu className="p-2">
            <Dropdown.Item
              href={responseBody.documents[0].download_link}
              className="d-flex align-items-center gap-2 px-2 rounded"
            >
              <DownloadIcon size={16} className="text-success" />
              Download
            </Dropdown.Item>

            <Dropdown.Item as={Link} to={`/claims/${claimId}`} className="d-flex align-items-center gap-2 px-2 rounded">
              <EyeIcon size={16} className="text-primary" />
              Preview
            </Dropdown.Item>

            <Dropdown.Item
              className="d-flex align-items-center gap-2 px-2 rounded"
              onClick={() => {
                downloadScreenshot(getCourtSubmissionScreenshot);
              }}
            >
              <ImageIcon size={16} className="text-info" />
              Court submission screenshot
            </Dropdown.Item>

            <Dropdown.Item
              className="d-flex align-items-center gap-2 px-2 rounded"
              onClick={() => {
                downloadScreenshot(getClaimsRegisterScreenshot);
              }}
            >
              <ImageIcon size={16} className="text-info" />
              Claims register screenshot
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <OverlayTrigger placement="top" trigger={["hover", "focus"]} overlay={<Tooltip>Check error</Tooltip>}>
        <Link className="btn btn-outline-danger btn-sm" to={`/claims/${claimId}`}>
          <EyeIcon size={16} />
        </Link>
      </OverlayTrigger>
    );
  }

  if (submissionStatus === "ready") {
    return (
      <OverlayTrigger placement="top" trigger={["hover", "focus"]} overlay={<Tooltip>Submit</Tooltip>}>
        <Link className="btn btn-outline-warning btn-sm" to={`/claims/${claimId}`}>
          <SendIcon size={16} />
        </Link>
      </OverlayTrigger>
    );
  }

  if (submissionStatus === "pending") {
    return (
      <OverlayTrigger placement="top" trigger={["hover", "focus"]} overlay={<Tooltip>Check progress</Tooltip>}>
        <Link className="btn btn-outline-primary btn-sm" to={`/claims/${claimId}`}>
          <Spinner size="sm" animation="border" />
        </Link>
      </OverlayTrigger>
    );
  }

  if (submissionStatus === "draft") {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} />

        <Dropdown.Menu className="p-2">
          <Dropdown.Item
            as={Link}
            to={`/epoc/edit/${claimId}`}
            className="d-flex align-items-center gap-2 px-2 rounded"
          >
            <PencilIcon size={16} className="text-primary" />
            Edit
          </Dropdown.Item>
          <Dropdown.Item className="d-flex align-items-center gap-2 px-2 rounded" onClick={onDiscard}>
            <TrashIcon size={16} className="text-danger" />
            Discard
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return null;
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
type ButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};
const CustomToggle = forwardRef<HTMLButtonElement, ButtonProps>(({ onClick }, ref) => (
  <button type="button" className="btn btn-light btn-sm" ref={ref} onClick={onClick}>
    <ThreeDotsVerticalIcon size={16} />
  </button>
));
