import gql from "graphql-tag";

const searchByCaseNumber = gql`
  query searchByCaseNumber($court_code: String!, $case_no: String!) {
    searchByCaseNumber(court_code: $court_code, case_no: $case_no) {
      case_no
      case_title
      chapter
      court_code
      date_filed
      date_closed
    } 
  }
`;

export default searchByCaseNumber;
