import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Amplify } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub";
import { configure } from "mobx";
import PostHog from "posthog-js";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { config } from "./configs/aws-exports";
import AuthService from "./services/AuthService";
import store from "./redux/store/index";
import App from "./App";

// Configure MobX
configure({
  enforceActions: "never",
});

if (process.env.REACT_APP_SELF_HOSTED) {
  console.log("Running in self-hosted mode");
}

// Google Analytics
if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  console.log("[ReactGA] Initializing Google Analytics");
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

// PostHog
let postHogInitialized = false;
if (process.env.REACT_APP_PUBLIC_POSTHOG_KEY && process.env.REACT_APP_PUBLIC_POSTHOG_HOST) {
  console.log("[PostHog] Initializing Posthog");

  PostHog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    session_recording: {
      maskAllInputs: false,
    },
  });
  postHogInitialized = true;
}

if (process.env.REACT_APP_SENTRY_DSN) {
  console.log("[Sentry] Initializing Sentry");

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
      ...(postHogInitialized
        ? [
            new PostHog.SentryIntegration(
              PostHog,
              process.env.REACT_APP_SENTRY_ORGANIZATION,
              Number(process.env.REACT_APP_SENTRY_PROJECT_ID)
            ),
          ]
        : []),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

if (process.env.REACT_APP_AWS_PUBSUB_ENDPOINT) {
  console.log("[Amplify.addPluggable] AWSIoTProvider");

  Amplify.addPluggable(
    new AWSIoTProvider({
      aws_pubsub_region: process.env.REACT_APP_AWS_REGION,
      aws_pubsub_endpoint: process.env.REACT_APP_AWS_PUBSUB_ENDPOINT,
    })
  );
}

Amplify.configure({
  Auth: {
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    mandatorySignIn: false,
  },
  Analytics: {
    disabled: true,
  },
});

AuthService.startAuthListener();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
