import { useState, useEffect, useCallback } from "react";
import { Card, Table, Button, Container, Spinner, Row, Col } from "react-bootstrap";
import { PencilSquare as EditIcon, Trash as TrashIcon, FileEarmarkPlus as NewBatchIcon } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";

import { apolloClient } from "../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../lib/errors";
import Pagination from "../../../../components/atoms/Pagination/Pagination";
import ConfirmModal from "../../../../components/ConfirmModal";

import listClaimBatches from "./graphql/listClaimBatches";
import createClaimBatch from "./graphql/createClaimBatch";
import deleteClaimBatch from "./graphql/deleteClaimBatch";

type Batch = {
  id: string;
  name: string;
  no_of_claims: number;
  status: string;
  created_at: string;
  updated_at: string;
};

type ListClaimBatchesResponse = {
  batches: Batch[];
  pagination: {
    total_records_count: number;
    total_pages: number;
  };
};

export default function BulkPOC() {
  const [isLoading, setIsLoading] = useState(true);
  const [claimBatchesResponse, setClaimBatchesResponse] = useState<ListClaimBatchesResponse | undefined>(undefined);
  const [pageNumber, setPageNumber] = useState(1);
  const [reload, setReload] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState<false | string>(false);
  const history = useHistory();

  const pageSize = 10;

  useEffect(() => {
    setIsLoading(true);

    const watchedQuery = apolloClient.watchQuery({
      query: listClaimBatches,
      variables: {
        page_number: pageNumber - 1,
        page_size: pageSize,
      },
      fetchPolicy: "network-only",
    });

    let sub: ReturnType<typeof watchedQuery.subscribe> | null = watchedQuery.subscribe({
      next(response) {
        const listClaimBatchesResponse: ListClaimBatchesResponse = response.data.listClaimBatches;
        sub && sub.unsubscribe();
        sub = null;

        setClaimBatchesResponse(listClaimBatchesResponse);
        setIsLoading(false);
      },
      error(err) {
        console.log("[watchQuery] error", err);
        toast.error(somethingWentWrong);
        setIsLoading(false);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub && sub.unsubscribe();
    };
  }, [pageNumber, reload]);

  const createNewBatch = useCallback(async () => {
    setIsLoading(true);

    const response = await apolloClient.mutate({
      mutation: createClaimBatch,
    });

    history.push(`/epoc/bulk/${response.data.createClaimBatch.id}?editBatchName=true`);
  }, [history]);

  const deleteBatch = useCallback(async () => {
    try {
      setShowConfirmModal(false);
      setClaimBatchesResponse((prev) => {
        const data = prev as ListClaimBatchesResponse;

        return {
          batches: data.batches.filter((batch) => batch.id !== showConfirmModal),
          pagination: {
            ...data.pagination,
            total_records_count: Math.max(data.pagination.total_records_count - 1, 0),
          },
        };
      });

      const response = await apolloClient.mutate({
        mutation: deleteClaimBatch,
        variables: {
          batch_id: showConfirmModal,
        },
      });

      if (response.data.deleteClaimBatch && response.data.deleteClaimBatch.error) {
        toast.error(response.data.deleteClaimBatch.error);
        setReload((prev) => prev + 1);
      }
    } catch (error) {
      toast.error(somethingWentWrong);
      setReload((prev) => prev + 1);
    }
  }, [showConfirmModal]);

  return (
    <Container fluid="xl" className="px-0">
      <div className="bulk-poc">
        <Card>
          <Card.Body>
            <div className="d-flex align-items-center gap-3">
              <Card.Title as="h1" className="mb-0">
                Your batches{" "}
                {!isLoading && claimBatchesResponse && `(${claimBatchesResponse.pagination.total_records_count})`}
              </Card.Title>

              <div className="ms-auto d-flex align-items-center gap-2">
                {isLoading && (
                  <span className="d-flex align-items-center gap-2">
                    <Spinner animation="border" size="sm" variant="primary" />
                    Loading...
                  </span>
                )}

                <Button
                  type="button"
                  variant="primary"
                  disabled={isLoading}
                  className="ms-auto d-flex align-items-center gap-1"
                  onClick={createNewBatch}
                  size="sm"
                >
                  <NewBatchIcon size="16" />
                  Create new batch
                </Button>
              </div>
            </div>
          </Card.Body>

          {isLoading && claimBatchesResponse === undefined && (
            <Card.Body className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </Card.Body>
          )}

          {claimBatchesResponse && claimBatchesResponse.batches.length > 0 && (
            <Table responsive hover size="sm">
              <thead className="table-light z-2 shadow-lg text-nowrap">
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Nr. of Claims</th>
                  <th>Created at</th>
                  <th>Updated at</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {claimBatchesResponse.batches.map((batch) => (
                  <tr key={batch.id}>
                    <td>
                      <Link to={`/epoc/bulk/${batch.id}`}>{batch.name}</Link>
                    </td>
                    <td>{batch.status}</td>
                    <td>{batch.no_of_claims}</td>
                    <td>
                      {new Date(batch.created_at).toLocaleDateString("en-US")}
                      <br />
                      {new Date(batch.created_at).toLocaleTimeString("en-US")}
                    </td>
                    <td>
                      {new Date(batch.updated_at).toLocaleDateString("en-US")}
                      <br />
                      {new Date(batch.updated_at).toLocaleTimeString("en-US")}
                    </td>
                    <td>
                      <div className="d-flex gap-1">
                        <Link
                          to={`/epoc/bulk/${batch.id}`}
                          className="btn btn-sm btn-outline-primary text-nowrap d-flex align-items-center gap-1"
                        >
                          <EditIcon size="16" />
                          Edit
                        </Link>

                        <Button
                          type="button"
                          variant="outline-danger"
                          size="sm"
                          className="d-flex align-items-center gap-1 text-nowrap"
                          onClick={() => setShowConfirmModal(batch.id)}
                        >
                          <TrashIcon size="16" />
                          Delete batch
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {!isLoading && claimBatchesResponse && claimBatchesResponse.batches.length === 0 && (
            <Card.Body>There are no batches yet. Create one by clicking the button above.</Card.Body>
          )}

          {claimBatchesResponse && claimBatchesResponse.pagination.total_pages > 0 && (
            <Card.Body className="table-footer border-top">
              <Row>
                <Col xs="auto">
                  <Pagination
                    currentPage={pageNumber}
                    totalPages={claimBatchesResponse.pagination.total_pages}
                    disabled={false}
                    onClick={(pageNumber: number) => {
                      setPageNumber(pageNumber);
                    }}
                  />
                </Col>
                <Col xs={12} sm="auto" className="d-flex align-items-center ms-auto mt-2 mt-md-0">
                  Results: {(pageSize * (pageNumber - 1) + 1).toLocaleString("en-US")} -{" "}
                  {(claimBatchesResponse.batches.length + pageSize * (pageNumber - 1)).toLocaleString("en-US")} of{" "}
                  {claimBatchesResponse.pagination.total_records_count.toLocaleString("en-US")}
                </Col>
              </Row>
            </Card.Body>
          )}
        </Card>
      </div>

      <ConfirmModal
        show={!!showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        title="Are you sure?"
        onConfirm={deleteBatch}
      >
        Are you sure you want to delete this batch? All claims in this batch will be deleted.
      </ConfirmModal>
    </Container>
  );
}
