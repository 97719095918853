import { useState, useCallback } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { nanoid } from "nanoid";
import Papa from "papaparse";

import createCsvFile from "../createCsv";
import { Debtor } from "../types";
import { validateSSN, validateTaxId, serializeAddressField } from "../lib";

import ImportHelp from "../ImportHelp";
import InputRow from "./InputRow";

type Row = {
  id: string;
  values: Debtor;
  errors: { [key in keyof Debtor]?: string };
};

function newRow(): Row {
  return {
    id: nanoid(),
    values: {
      ssn: "",
      taxid: "",
      firstName: "",
      middleName: "",
      lastName: "",
      generation: "",
      address: "",
      phone: "",
    },
    errors: {},
  };
}

export default function ManualImport({ onFileSelected }: { onFileSelected: (file: File) => void }) {
  const [rows, setRows] = useState<Row[]>([newRow()]);
  const [loading, setLoading] = useState(false);

  const onChangeRow = useCallback((id: string, key: keyof Debtor, value: string) => {
    setRows((rows) => {
      const row = rows.find((row) => row.id === id)!;
      row.values[key] = value;
      return [...rows];
    });
  }, []);

  const onRemoveInputRow = useCallback((id: string) => {
    setRows((rows) => rows.filter((row) => row.id !== id));
  }, []);

  const onClickAddRow = useCallback(() => {
    setRows((rows) => [...rows, newRow()]);
  }, []);

  const onImport = useCallback(async () => {
    // Validate rows
    let hasAnyError = false;

    const newRows = rows.map((row) => {
      row.errors = {};
      if ((!row.values.ssn && !row.values.taxid) || (row.values.ssn && row.values.taxid)) {
        hasAnyError = true;
        row.errors.ssn = "Insert SSN or Tax ID";
        row.errors.taxid = "Insert SSN or Tax ID";
      }

      if (row.values.ssn && !validateSSN(row.values.ssn)) {
        hasAnyError = true;
        row.errors.ssn = "Invalid SSN";
      }

      if (row.values.taxid && !validateTaxId(row.values.taxid)) {
        hasAnyError = true;
        row.errors.taxid = "Invalid Tax ID";
      }

      // check if last name is present
      if (row.values.taxid && row.values.lastName.trim() === "") {
        hasAnyError = true;
        row.errors.lastName = "Company name is required";
      }

      return row;
    });

    setRows(newRows);

    if (hasAnyError) {
      return;
    }

    setLoading(true);
    // If rows are valid, then create CSV file and pass it to onFileSelected
    const csvData = Papa.unparse(
      rows.map((row) => ({
        SSN: row.values.ssn,
        TAXID: row.values.taxid,
        "First name": row.values.firstName,
        "Middle name": row.values.middleName,
        "Last name": row.values.lastName,
        Generation: row.values.generation,
        ...serializeAddressField(
          typeof row.values.address === "string" ? row.values.address : row.values.address.address_components
        ),
        Phone: row.values.phone,
      }))
    );

    const today = new Date();
    const date = [
      today.toLocaleDateString("en-US", { year: "numeric" }),
      today.toLocaleDateString("en-US", { month: "numeric" }),
      today.toLocaleDateString("en-US", { day: "numeric" }),
    ].join("-");
    const file = await createCsvFile(csvData, `manual-import-${date}.csv`);
    setLoading(false);
    onFileSelected(file);
  }, [onFileSelected, rows]);

  return (
    <Card className="import-card">
      <Card.Body>
        <ImportHelp />

        <fieldset disabled={loading}>
          {rows.map((row, index) => (
            <InputRow
              key={row.id}
              id={row.id}
              index={index}
              data={row.values}
              errors={row.errors}
              onChange={onChangeRow}
              onRemove={onRemoveInputRow}
            />
          ))}

          <Form.Group className="mb-3">
            <Button type="button" variant="link" className="px-0" onClick={onClickAddRow}>
              Add row
            </Button>
          </Form.Group>

          <Button type="button" variant="primary" onClick={onImport}>
            Import
          </Button>
        </fieldset>
      </Card.Body>
    </Card>
  );
}
