import React, { useEffect, useCallback, useReducer, useContext } from "react";
import classNames from "classnames";
import { Table, Form, Row, Col, Button, Spinner, Alert, Card } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";

import { apolloClient } from "../../../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../../../lib/errors";
import { Creditor } from "../interfaces";
import courts from "../lib/courts.json";
import UserContext from "../../../../../../components/Routing/components/UserContext";

import getCaseCreditorsAndCourtInfos from "./graphql/getCaseCreditorsAndCourtInfos";
import { ActionTypes, State, Action, Response, SelectedCase } from "./interfaces";

type Reducer = (state: State, action: Action) => State;
const reducer: Reducer = function reducer(state, action) {
  switch (action.type) {
    case ActionTypes.Loading:
      return {
        ...state,
        is_loading: action.data,
      };
    case ActionTypes.SetCourtCode:
      if (action.data) {
        const court = courts.find(({ court_code }) => court_code === action.data.value);
        const requiresCreditorName = !!court?.requires_creditor_name;

        return {
          ...state,
          court_code: action.data,
          response: null,
          requires_creditor_name: requiresCreditorName,
          creditor_name: "",
        };
      }

      return {
        ...state,
        court_code: action.data,
        response: null,
        requires_creditor_name: false,
        creditor_name: "",
      };
    case ActionTypes.SetCaseNumber:
      return {
        ...state,
        case_no: action.data,
      };
    case ActionTypes.SetCreditorName:
      return {
        ...state,
        creditor_name: action.data,
      };
    case ActionTypes.SetResponse: {
      const data = action.data as Response;

      const finalCaseNo = (function () {
        // Reset the field if the service returns suggested cases
        if (data.getCaseCreditors.cases) {
          return "";
        }

        // If the service returns a case, use the normalized case no from the response
        if (data.getCaseCreditors.case && data.getCaseCreditors.case.case_no) {
          return data.getCaseCreditors.case.case_no;
        }

        // As is in all other cases
        return state.case_no;
      })();

      return {
        ...state,
        is_loading: false,
        case_no: finalCaseNo,
        response: data,
        attachment_size_limit: data.getCourtInfos.attachment_size_limit,
      };
    }
    case ActionTypes.SetSelectedCase:
      return {
        ...state,
        selected_case: action.data,
        response: null,
      };
  }
};

const initialState: State = {
  is_loading: false,
  court_code: null,
  case_no: "",
  response: null,
  attachment_size_limit: null,
  selected_case: null,
  requires_creditor_name: false,
  creditor_name: "",
};

interface Props {
  autoSubmit: boolean;
  selectedCase?: SelectedCase;
  onNext: ({
    court_code,
    case_no,
    case_title,
    creditors,
    attachment_size_limit,
  }: {
    court_code: string;
    case_no: string;
    case_title: string;
    creditor_name?: string;
    creditors: Creditor[];
    attachment_size_limit: number;
  }) => void;
}
export default function CaseForm({ autoSubmit, selectedCase, onNext }: Props) {
  const { user } = useContext(UserContext);
  const { role } = user.signInUserSession.idToken.payload;
  const [state, dispatch] = useReducer<Reducer>(reducer, {
    ...initialState,
    // This is just for filling form values
    // Not an actual selected case
    ...(selectedCase && {
      court_code: {
        value: selectedCase.court_code,
        label: courts.find(({ court_code }) => court_code === selectedCase.court_code)?.court_name,
      },
      case_no: selectedCase.case_no,
      ...(typeof selectedCase.creditor_name === "string" && {
        requires_creditor_name: true,
        creditor_name: selectedCase.creditor_name,
      }),
    }),
    // For auto submit
    ...(selectedCase &&
      autoSubmit && {
        selected_case: {
          court_code: selectedCase.court_code,
          case_no: selectedCase.case_no,
        } as SelectedCase,
      }),
  });

  // Using an effect to fetch the case creditors
  // so it can be later cleaned up
  useEffect(() => {
    if (!state.selected_case) {
      return;
    }

    dispatch({ type: ActionTypes.Loading, data: true });

    const watchedQuery = apolloClient.watchQuery({
      query: getCaseCreditorsAndCourtInfos,
      variables: {
        input: {
          court_code: state.selected_case.court_code,
          case_number: state.selected_case.case_no,
          ...(state.selected_case.creditor_name && {
            creditor_name: state.selected_case.creditor_name,
          }),
        },
        court_code: state.selected_case.court_code,
      },
      fetchPolicy: "network-only",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        const { getCaseCreditors: getCaseCreditorsResponse, getCourtInfos: getCourtInfosResponse } =
          response.data as Response;

        dispatch({
          type: ActionTypes.SetResponse,
          data: response.data,
        });

        if (getCaseCreditorsResponse.error) {
          return;
        }

        // case found go next step
        if (getCaseCreditorsResponse.creditors && getCaseCreditorsResponse.case) {
          onNext({
            court_code: state.selected_case!.court_code,
            case_no: getCaseCreditorsResponse.case.case_no,
            case_title: getCaseCreditorsResponse.case.case_title,
            ...(state.selected_case!.creditor_name && { creditor_name: state.selected_case!.creditor_name }),
            creditors: getCaseCreditorsResponse.creditors,
            // TODO to check: if the call fails size limit is zero?
            attachment_size_limit: getCourtInfosResponse.attachment_size_limit || 0,
          });
        }
      },
      error(err) {
        console.log(err);
        toast.error(somethingWentWrong);
        dispatch({ type: ActionTypes.Loading, data: false });
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, [onNext, state.selected_case]);

  const onSubmit = useCallback(
    function (event: React.SyntheticEvent) {
      event.preventDefault();

      if (state.case_no.trim() && state.court_code) {
        dispatch({
          type: ActionTypes.SetSelectedCase,
          data: {
            court_code: state.court_code.value,
            case_no: state.case_no,
            ...(state.requires_creditor_name && {
              creditor_name: state.creditor_name,
            }),
          } as SelectedCase,
        });
      }
    },
    [state.case_no, state.court_code, state.creditor_name, state.requires_creditor_name]
  );

  if (autoSubmit && state.is_loading) {
    return (
      <div className="text-center">
        <h1>Please wait</h1>

        <p className="lead">
          Getting case infos for: <strong>{state.case_no}</strong>
        </p>

        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  const shouldDisableNextButton =
    state.is_loading ||
    !state.court_code ||
    !(state.case_no || "").trim() ||
    (state.requires_creditor_name && state.creditor_name.trim().length < 2);

  return (
    <Form onSubmit={onSubmit}>
      {state.response && state.response.getCaseCreditors.error && (
        <Alert variant="danger" className="mb-3">
          <div className="alert-message">{state.response.getCaseCreditors.error}</div>
        </Alert>
      )}

      <Row className="mb-3">
        <Col sm="auto">
          <Form.Group className="mb-3">
            <Form.Label htmlFor="court">Court</Form.Label>

            <Select
              inputId="court"
              openMenuOnFocus
              isClearable
              isDisabled={state.is_loading}
              options={courts
                .filter(({ train, test }) => {
                  // return the whole list for super admins
                  if (role === "super_admin") {
                    return true;
                  }

                  // return only train or test courts in development for non super admins
                  if (process.env.NODE_ENV === "development") {
                    return train || test;
                  }

                  // Live courts for production and non super admins
                  return !train && !test;
                })
                .map(({ court_code, court_name }) => ({
                  value: court_code,
                  label: court_name,
                }))}
              value={state.court_code}
              onChange={(value) => dispatch({ type: ActionTypes.SetCourtCode, data: value })}
            />
          </Form.Group>

          <Form.Group className={state.requires_creditor_name ? "mb-3" : ""}>
            <Form.Label htmlFor="case-number">Case number</Form.Label>
            <Form.Control
              type="text"
              size="lg"
              id="case-number"
              placeholder={
                state.response && state.response.getCaseCreditors.cases ? "" : "Example 14-00002 or o:yy-tp-nnnnn"
              }
              disabled={state.is_loading}
              value={state.case_no}
              onChange={(event) =>
                dispatch({
                  type: ActionTypes.SetCaseNumber,
                  data: event.target.value,
                })
              }
              htmlSize={"Example 14-00002 or oo:yy-tp-nnnnn".length}
              className="w-100"
            />
          </Form.Group>

          {state.requires_creditor_name && (
            <Form.Group>
              <Form.Label htmlFor="creditor-name">Creditor name</Form.Label>
              <Form.Control
                type="text"
                size="lg"
                id="creditor-name"
                placeholder="John Doe"
                disabled={state.is_loading}
                value={state.creditor_name}
                onChange={(event) =>
                  dispatch({
                    type: ActionTypes.SetCreditorName,
                    data: event.target.value,
                  })
                }
                maxLength={50}
                className="w-100"
              />
              <Form.Text className="text-muted">Creditor name must contain at least 2 characters</Form.Text>
            </Form.Group>
          )}
        </Col>
      </Row>

      {state.response && state.response.getCaseCreditors.cases && (
        <Card>
          <Card.Body>
            <Card.Title as="h1" className="mb-0 text-warning">
              More than one case found, please select one:
            </Card.Title>
          </Card.Body>

          <Table hover>
            <thead className="table-light">
              <tr className="text-nowrap">
                <th className="min-content-width"></th>
                <th className="min-content-width">Case No</th>
                <th>Case Title</th>
              </tr>
            </thead>

            <tbody>
              {state.response.getCaseCreditors.cases.map(function ({ case_title, case_no }) {
                return (
                  <tr
                    key={case_no}
                    role="button"
                    onClick={() =>
                      dispatch({
                        type: ActionTypes.SetCaseNumber,
                        data: case_no,
                      })
                    }
                    className={classNames({
                      "table-primary": state.case_no.trim() === case_no,
                    })}
                  >
                    <td>
                      <Form.Check
                        type="radio"
                        name="suggested-case"
                        checked={state.case_no.trim() === case_no}
                        readOnly
                        className="pe-none"
                      />
                    </td>
                    <td className="text-primary text-nowrap">{case_no}</td>
                    <td>{case_title}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      )}

      <hr />

      <Button
        type="submit"
        variant="primary"
        size="lg"
        disabled={shouldDisableNextButton}
        className="d-flex align-items-center gap-2"
      >
        {state.is_loading ? (
          <>
            <Spinner animation="border" size="sm" />
            Getting creditors list
          </>
        ) : (
          "Continue"
        )}
      </Button>
    </Form>
  );
}
