import { useCallback, useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";

import { somethingWentWrong } from "../../../../../lib/errors";
import AuthService from "../../../../../services/AuthService";

const schema = yup.object().shape({
  currentPassword: yup.string().required("Current password is required"),
  newPassword: yup
    .string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
      "Password must be at least 8 characters, lowercase, uppercase, and numbers for greater security."
    )
    .required("New password is required"),
  confirmPassword: yup.string().test("passwords-match", "Passwords must match", function (value) {
    return this.parent.newPassword === value;
  }),
});

export default function ChangePassword() {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async ({ currentPassword, newPassword }) => {
      try {
        setIsLoading(true);
        const user = await AuthService.currentAuthenticatedUser();

        await AuthService.changePassword(user, currentPassword, newPassword);

        //reset form
        reset();

        toast.success("Password changed successfully");
        setIsLoading(false);
      } catch (error) {
        if (error.code && error.code === "NotAuthorizedException") {
          toast.error("Current password don't match");
        } else {
          toast.error(error.message || somethingWentWrong);
        }

        setIsLoading(false);
      }
    },
    [reset]
  );

  return (
    <>
      <Card.Title>
        <h3>Change password</h3>
      </Card.Title>

      <Row>
        <Col sm={12} md={6} lg={5} xl={4}>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Form.Group controlId="currentPassword" className="mb-3">
              <Form.Label>Current password</Form.Label>
              <Form.Control
                {...register("currentPassword")}
                type="password"
                isInvalid={errors.currentPassword}
                disabled={isLoading}
                htmlSize={30}
              />
              <Form.Control.Feedback type="invalid">
                {errors.currentPassword && errors.currentPassword.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="newPassword" className="mb-3">
              <Form.Label>New password</Form.Label>
              <Form.Control
                {...register("newPassword")}
                type="password"
                isInvalid={errors.newPassword}
                disabled={isLoading}
                htmlSize={30}
              />
              <Form.Control.Feedback type="invalid">
                {errors.newPassword && errors.newPassword.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="confirmPassword" className="mb-3">
              <Form.Label>Confirm new password</Form.Label>
              <Form.Control
                {...register("confirmPassword")}
                type="password"
                isInvalid={errors.confirmPassword}
                disabled={isLoading}
                htmlSize={30}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword && errors.confirmPassword.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Button type="submit" disabled={isLoading}>
              {isLoading ? <Spinner animation="border" variant="light" size="sm" /> : "Confirm"}
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
}
