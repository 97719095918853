import gql from "graphql-tag";

const purchaseClaim = gql`
  mutation purchaseClaim($claim_id: ID!) {
    purchaseClaim(claim_id: $claim_id) {
      error
    }
  }
`;

export default purchaseClaim;
