import { useEffect, useReducer, useCallback, useContext } from "react";
import classnames from "classnames";
import { Spinner, Row, Col, Nav, Card, Container, Button, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import {
  ArrowLeft as GoBackIcon,
  Check as CheckIcon,
  Trash as TrashIcon,
  CloudArrowUp as CloudArrowUpIcon,
  FiletypePdf as FiletypePdfIcon,
  Folder2Open as Folder2OpenIcon,
  CloudCheck as CloudIcon,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { Subscription } from "zen-observable-ts";
import PostHog from "posthog-js";

import { apolloClient } from "../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../lib/errors";
import UserContext from "../../../../components/Routing/components/UserContext";

import SelectCaseStep from "./steps/SelectCase/SelectCase";
import SelectCreditorStep from "./steps/SelectCreditor/SelectCreditor";
import DocumentationStep from "./steps/Documentation/Documentation";
import SignStep from "./steps/Sign/Sign";
import SubmitStep from "./steps/Submit";
import { SelectedCase, Creditor, Signee } from "./steps/interfaces";
import { FormValues as Documentation } from "./steps/Documentation/interfaces";
import { FilingProfile, FormState, WizardSteps } from "./interfaces";
import StartOverModal from "./StartOverModal";
import PreviewModal from "./PreviewModal";
import saveClaimDraft from "./graphql/saveClaimDraft";
import discardClaimDraft from "./graphql/discardClaimDraft";
import getClaimAndFilingProfiles from "./graphql/getClaimAndFilingProfiles";
import getFilingProfiles from "./graphql/getFilingProfiles";
import courts from "./steps/lib/courts.json";

import "./styles.scss";

enum ActionType {
  SetLoading,
  InitialLoadFinished,
  StartOver,
  SetSelectedCase,
  OnChangeCreditor,
  SetSelectedCreditor,
  OnChangeDocumentation,
  OnSubmitDocumentation,
  OnChangeSignee,
  OnSubmitSignee,
  GoBack,
  ChangeStep,
  SetSavingDraft,
  SetClaimId,
  SetShowStartOverModal,
  SetShowPreviewModal,
  SelectFilingProfile,
}
interface Action {
  type: ActionType;
  data?: any;
}
function reducer(state: FormState, action: Action): FormState {
  switch (action.type) {
    case ActionType.SetLoading:
      // trick to avoid unnecessary re-renders
      state.loading = action.data as boolean;
      return state;
    case ActionType.InitialLoadFinished: {
      const formData: Partial<FormState> = (function getDraftFormData() {
        const draftFormData = action.data.draftFormData;
        if (!draftFormData) {
          return {};
        }

        // if (!!draftFormData.signee) {
        //   return {
        //     currentStep: WizardSteps.Sign,
        //     selectedCase: draftFormData.selectedCase,
        //     selectedCreditor: draftFormData.selectedCreditor,
        //     documentation: draftFormData.documentation,
        //     signee: draftFormData.signee,
        //     claimUpdatedAt: action.data.claimUpdatedAt,
        //     selectedFilingProfileId: draftFormData.selectedFilingProfileId,
        //   };
        // }

        return {
          currentStep: WizardSteps.Documentation,
          selectedCase: draftFormData.selectedCase,
          selectedCreditor: draftFormData.selectedCreditor,
          documentation: draftFormData.documentation,
          ...(!!draftFormData.signee && { signee: draftFormData.signee }),
          claimUpdatedAt: action.data.claimUpdatedAt,
          selectedFilingProfileId: draftFormData.selectedFilingProfileId,
        };
      })();
      const filingProfiles = action.data.filingProfiles;

      return {
        ...state,
        loading: false,
        ...formData,
        filingProfiles,
      };
    }
    case ActionType.StartOver:
      return {
        loading: false,
        claimId: undefined,
        claimUpdatedAt: undefined,
        filingProfiles: state.filingProfiles,
        selectedFilingProfileId: "",
        currentStep: WizardSteps.SelectCase,
        selectedCase: undefined,
        selectedCreditor: undefined,
        documentation: undefined,
        signee: undefined,
        savingDraft: false,
        showStartOverModal: false,
        showPreviewModal: false,
      };
    case ActionType.SetSelectedCase:
      return {
        ...state,
        currentStep: WizardSteps.SelectCreditor,
        selectedCase: action.data as SelectedCase,
        selectedCreditor: undefined,
      };
    case ActionType.OnChangeCreditor:
      return {
        ...state,
        selectedCreditor: action.data as Creditor,
        // when changing creditor reset amend_claim if documentation is present
        ...(state.documentation
          ? {
              documentation: {
                ...state.documentation,
                amend_claim: null,
              },
            }
          : {}),
      };
    case ActionType.SetSelectedCreditor: {
      const selectedCreditor = action.data as Creditor;

      return {
        ...state,
        currentStep: WizardSteps.Documentation,
        selectedCreditor,
        // when changing creditor reset amend_claim if documentation is present
        ...(state.documentation
          ? {
              documentation: {
                ...state.documentation,
                amend_claim: null,
              },
            }
          : {}),
      };
    }
    case ActionType.OnChangeDocumentation:
      return {
        ...state,
        documentation: action.data as Documentation,
      };
    case ActionType.OnSubmitDocumentation:
      return {
        ...state,
        currentStep: WizardSteps.Sign,
        documentation: action.data as Documentation,
      };
    case ActionType.OnChangeSignee:
      return {
        ...state,
        signee: action.data as Signee,
      };
    case ActionType.OnSubmitSignee:
      return {
        ...state,
        currentStep: WizardSteps.SaveClaim,
        signee: action.data as Signee,
      };
    case ActionType.GoBack: {
      if (state.currentStep === WizardSteps.Sign) {
        return {
          ...state,
          currentStep: WizardSteps.Documentation,
        };
      } else if (state.currentStep === WizardSteps.Documentation) {
        return {
          ...state,
          currentStep: WizardSteps.SelectCreditor,
        };
      } else if (state.currentStep === WizardSteps.SelectCreditor) {
        return {
          ...state,
          currentStep: WizardSteps.SelectCase,
          selectedCreditor: undefined,
        };
      }
      break;
    }
    case ActionType.ChangeStep: {
      const stepId = action.data;

      return {
        ...state,
        currentStep: stepId,
        ...(stepId === WizardSteps.SelectCase ? { selectedCreditor: undefined } : {}),
      };
    }
    case ActionType.SetClaimId:
      return {
        ...state,
        claimId: action.data.claimId as string,
        claimUpdatedAt: action.data.claimUpdatedAt as string,
        savingDraft: false,
      };
    case ActionType.SetSavingDraft:
      return {
        ...state,
        savingDraft: action.data as boolean,
      };
    case ActionType.SetShowStartOverModal:
      return {
        ...state,
        showStartOverModal: action.data as boolean,
      };
    case ActionType.SetShowPreviewModal:
      return {
        ...state,
        showPreviewModal: action.data as boolean,
      };
    case ActionType.SelectFilingProfile: {
      const filingProfile = state.filingProfiles.find(({ id }) => id === action.data);
      if (!filingProfile) {
        return {
          ...state,
          selectedFilingProfileId: action.data as string,
        };
      }

      const rawFormData = JSON.parse(filingProfile.raw_form_data);

      return {
        ...state,
        selectedFilingProfileId: action.data as string,
        documentation: {
          ...state.documentation,
          ...rawFormData.documentation,
        },
        signee: {
          ...state.signee,
          ...rawFormData.signee,
        },
      };
    }
  }

  return state;
}

enum SubmissionStatus {
  Draft = "draft",
  Ready = "ready",
  Pending = "pending",
  Finished = "finished",
}

type DraftResponse = {
  error: string | null;
  raw_form_data: string | null;
  submission_status: SubmissionStatus | null;
  updated_at: string;
};

export default function Epoc() {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const {
    params: { claim_id },
  }: {
    params: {
      claim_id?: string;
    };
  } = match;

  console.log("claim_id", claim_id);
  const queryParams = new URLSearchParams(location.search);
  const selectedCourt = queryParams.has("court")
    ? courts.find(({ court_code }) => court_code === queryParams.get("court"))
    : null;
  // Should prefill first step only not editing a draft and case_no and court are present
  const shouldPrefillCaseStep = !claim_id && queryParams.has("case_no") && !!selectedCourt;
  const creditorNameIsRequired = shouldPrefillCaseStep && !!selectedCourt?.requires_creditor_name;
  const autoSubmitCaseStep = shouldPrefillCaseStep && !creditorNameIsRequired;

  const { user } = useContext(UserContext);
  const { role } = user.signInUserSession.idToken.payload;

  if (shouldPrefillCaseStep) {
    console.log("Prefill ePOC with", queryParams.get("court"), queryParams.get("case_no"));
    console.log("autoSubmitCaseStep?", autoSubmitCaseStep);
  }

  const [formState, dispatch] = useReducer(reducer, {
    loading: true,
    claimId: claim_id,
    currentStep: WizardSteps.SelectCase,
    selectedCase: shouldPrefillCaseStep
      ? ({
          court_code: queryParams.get("court")!,
          case_no: queryParams.get("case_no")!,
          ...(creditorNameIsRequired ? { creditor_name: "" } : {}),
        } as SelectedCase)
      : undefined,
    savingDraft: false,
    showStartOverModal: false,
    showPreviewModal: false,
    filingProfiles: [],
    selectedFilingProfileId: "",
  });

  // Initial load effect
  useEffect(() => {
    console.log("Initial load effect");
    let sub: Subscription | null = null;

    dispatch({ type: ActionType.SetLoading, data: true });

    if (claim_id) {
      const watchedQuery = apolloClient.watchQuery({
        query: getClaimAndFilingProfiles,
        variables: {
          claim_id,
        },
        fetchPolicy: "network-only",
      });

      sub = watchedQuery.subscribe({
        next(response) {
          const getClaimResponse: DraftResponse = response.data.getClaim;
          const filingProfiles: FilingProfile[] = response.data.getFilingProfiles;
          // const getClaimAddressesResponse: ClaimAddress[] = response.data.getClaimAddresses;

          if (getClaimResponse.error) {
            toast.error(getClaimResponse.error);
            // defer history push
            setTimeout(() => history.push("/claims"), 0);
          } else if (getClaimResponse.submission_status !== SubmissionStatus.Draft) {
            toast.error(`Can't edit claim, claim status is "${getClaimResponse.submission_status}"`);
            // defer history push
            setTimeout(() => history.push(`/claims/${claim_id}`), 0);
          } else {
            console.log("Fill form with draft data");

            dispatch({
              type: ActionType.InitialLoadFinished,
              data: {
                draftFormData: JSON.parse(getClaimResponse.raw_form_data!),
                filingProfiles,
                claimUpdatedAt: getClaimResponse.updated_at,
              },
            });
          }
        },
        error(err) {
          console.log("[watchQuery] error", err);
          toast.error(somethingWentWrong);
          // defer history push
          setTimeout(() => history.push("/claims"), 0);
        },
      });
    } else {
      const watchedQuery = apolloClient.watchQuery({
        query: getFilingProfiles,
        fetchPolicy: "network-only",
      });

      sub = watchedQuery.subscribe({
        next(response) {
          const filingProfiles: FilingProfile[] = response.data.getFilingProfiles;

          dispatch({
            type: ActionType.InitialLoadFinished,
            data: {
              filingProfiles,
            },
          });
        },
        error(err) {
          console.log("[watchQuery] error", err);
          toast.error(somethingWentWrong);
        },
      });
    }

    return () => {
      if (sub) {
        console.log("[watchQuery] Clean up");
        sub.unsubscribe();
      }
    };
  }, [claim_id, history]);

  const onSaveDraft = useCallback(async () => {
    try {
      dispatch({ type: ActionType.SetSavingDraft, data: true });

      const { claimId, selectedCase, selectedCreditor, documentation, signee, selectedFilingProfileId } = formState;

      console.log("Saving draft");
      console.log({
        claimId,
        selectedCase,
        selectedCreditor,
        documentation,
        signee,
        selectedFilingProfileId,
      });

      const response = await apolloClient.mutate({
        mutation: saveClaimDraft,
        variables: {
          input: {
            ...(claimId ? { claim_id: claimId } : {}),
            court_code: selectedCase?.court_code,
            case_no: selectedCase?.case_no,
            case_title: selectedCase?.case_title,
            raw_form_data: JSON.stringify({
              selectedCase,
              selectedCreditor,
              documentation,
              signee,
              selectedFilingProfileId,
            }),
          },
        },
        fetchPolicy: "no-cache",
      });

      dispatch({
        type: ActionType.SetClaimId,
        data: {
          claimId: response.data.saveClaimDraft.claim_id,
          claimUpdatedAt: response.data.saveClaimDraft.updated_at,
        },
      });

      // HACK using window.history.replaceState we don't want to trigger a route change
      setTimeout(() => window.history.replaceState(null, "", `/epoc/edit/${response.data.saveClaimDraft.claim_id}`), 0);
    } catch (error) {
      console.log(error);
      toast.error(somethingWentWrong);
      dispatch({ type: ActionType.SetSavingDraft, data: false });
    }
  }, [formState]);

  // Steps callbacks
  const onSubmitCase = useCallback(
    (data: SelectedCase) => {
      if (shouldPrefillCaseStep) {
        const queryParams = new URLSearchParams(location.search);

        queryParams.delete("court");
        queryParams.delete("case_no");

        // defer history replace
        setTimeout(
          () =>
            history.replace({
              search: queryParams.toString(),
            }),
          0
        );
      }

      PostHog.__loaded &&
        PostHog.capture("ePOC", {
          step: "Select Case",
          data: {
            court_code: data.court_code,
            case_no: data.case_no,
            case_title: data.case_title,
          },
        });

      scrollToTop();
      dispatch({ type: ActionType.SetSelectedCase, data });
    },
    [history, location.search, shouldPrefillCaseStep]
  );

  const onChangeCreditor = useCallback((data: Creditor) => {
    dispatch({ type: ActionType.OnChangeCreditor, data });
  }, []);

  const onSubmitCreditor = useCallback(
    (data: Creditor) => {
      PostHog.__loaded &&
        PostHog.capture("ePOC", {
          step: "Select Creditor",
          data: {
            name: data.name,
          },
        });

      scrollToTop();
      dispatch({ type: ActionType.SetSelectedCreditor, data });
      onSaveDraft();
    },
    [onSaveDraft]
  );

  const onChangeDocumentation = useCallback((data: Documentation) => {
    dispatch({
      type: ActionType.OnChangeDocumentation,
      data,
    });
  }, []);

  const onSubmitDocumentation = useCallback(
    (data: Documentation) => {
      PostHog.__loaded &&
        PostHog.capture("ePOC", {
          step: "Documentation",
          data: {},
        });

      scrollToTop();
      dispatch({
        type: ActionType.OnSubmitDocumentation,
        data,
      });
      onSaveDraft();
    },
    [onSaveDraft]
  );

  const onChangeSignee = useCallback((data: Signee) => {
    dispatch({ type: ActionType.OnChangeSignee, data });
  }, []);

  const onSubmitSignee = useCallback((data: Signee) => {
    PostHog.__loaded &&
      PostHog.capture("ePOC", {
        step: "Signee",
        data: {},
      });

    scrollToTop();
    dispatch({ type: ActionType.OnSubmitSignee, data });
  }, []);

  // Header callbacks
  const onDiscard = useCallback(() => {
    dispatch({ type: ActionType.SetShowStartOverModal, data: true });
  }, []);

  const onShowPreview = useCallback(() => dispatch({ type: ActionType.SetShowPreviewModal, data: true }), []);

  const onGoBack = useCallback(() => {
    scrollToTop();

    dispatch({ type: ActionType.GoBack });
  }, []);

  // const onChangeStep = useCallback(
  //   (stepId: keyof typeof WizardSteps) => dispatch({ type: ActionType.ChangeStep, data: stepId }),
  //   []
  // );

  // SubmitStep callbacks
  const onClaimSaved = useCallback((claimId: string) => history.push(`/claims/${claimId}`), [history]);

  // StartOverModal callbacks
  const onHideStartOverModal = useCallback(() => dispatch({ type: ActionType.SetShowStartOverModal, data: false }), []);

  const onConfirmStartOver = useCallback(() => {
    if (formState.claimId) {
      // Fire mutation and forget
      const saving = apolloClient.mutate({
        mutation: discardClaimDraft,
        variables: {
          claim_id: formState.claimId,
        },
        fetchPolicy: "no-cache",
      });

      toast.promise(saving, {
        pending: "Discarding draft...",
        success: "Draft discarded!",
        error: somethingWentWrong,
      });

      setTimeout(() => history.push("/epoc/new"), 0);
    }

    dispatch({ type: ActionType.StartOver });
  }, [formState.claimId, history]);

  // PreviewModal callbacks
  const onHidePreviewModal = useCallback(() => dispatch({ type: ActionType.SetShowPreviewModal, data: false }), []);

  if (formState.loading)
    return (
      <div className="d-flex align-items-center justify-content-center p-3">
        <Spinner variant="primary" animation="border" />
      </div>
    );

  if (formState.currentStep === WizardSteps.SaveClaim)
    return (
      <SubmitStep
        claimId={formState.claimId}
        selectedCase={formState.selectedCase!}
        selectedCreditor={formState.selectedCreditor!}
        documentation={formState.documentation!}
        signee={formState.signee!}
        onClaimSaved={onClaimSaved}
      />
    );

  return (
    <div className="epoc p-2 d-flex flex-column" style={{ minHeight: "100%" }}>
      <Card className="d-flex flex-column flex-grow-1 mb-0">
        <Card.Body
          className={classnames("flex-grow-0", {
            "pb-0": formState.currentStep !== WizardSteps.SelectCase,
            "border-bottom": formState.currentStep === WizardSteps.SelectCase,
          })}
        >
          <Row className="d-flex align-items-center">
            <Col xs="auto" sm="auto" className="me-auto">
              <Card.Title as="h1" className="mb-0">
                {formState.claimId ? "Editing draft" : "New Proof of Claim"}
              </Card.Title>
            </Col>

            <Col xs="auto">
              <Link className="btn btn-primary btn-sm d-flex align-items-center gap-1" to="/claims">
                <Folder2OpenIcon size={16} />
                Your claims
              </Link>
            </Col>
          </Row>
        </Card.Body>

        {formState.currentStep !== WizardSteps.SelectCase && (
          <Card.Body
            className="border-bottom sticky-top bg-white shadow py-0 d-flex align-items-center gap-2"
            style={{ flex: "0 0 60px" }}
          >
            <OverlayTrigger placement="top" trigger={["hover", "focus"]} overlay={<Tooltip>Go back</Tooltip>}>
              <Button type="button" variant="outline-primary" size="sm" onClick={onGoBack}>
                <GoBackIcon size={16} />
              </Button>
            </OverlayTrigger>

            {(formState.currentStep === WizardSteps.Documentation || formState.currentStep === WizardSteps.Sign) && (
              <>
                <OverlayTrigger placement="top" trigger={["hover", "focus"]} overlay={<Tooltip>Save</Tooltip>}>
                  <Button variant="outline-success" size="sm" onClick={onSaveDraft} disabled={formState.savingDraft}>
                    <CloudArrowUpIcon size={18} />
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip>Preview form 410</Tooltip>}
                >
                  <Button variant="outline-primary" size="sm" onClick={onShowPreview}>
                    <FiletypePdfIcon size={16} />
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip>Discard{formState.claimId ? " draft" : ""}</Tooltip>}
                >
                  <Button variant="outline-danger" size="sm" onClick={onDiscard} disabled={formState.savingDraft}>
                    <TrashIcon size={16} />
                  </Button>
                </OverlayTrigger>

                {formState.claimUpdatedAt && (
                  <OverlayTrigger
                    placement="top"
                    trigger={["focus", "hover"]}
                    overlay={
                      <Tooltip>Last updated: {new Date(formState.claimUpdatedAt).toLocaleString("en-US")}</Tooltip>
                    }
                  >
                    <div className="d-flex align-items-center gap-1 ms-3">
                      {formState.savingDraft ? (
                        <>
                          <Spinner animation="border" variant="primary" size="sm" />
                          <span>Saving...</span>
                        </>
                      ) : (
                        <>
                          <CloudIcon size="22" className="text-success" />
                          <span>All changes saved</span>
                        </>
                      )}
                    </div>
                  </OverlayTrigger>
                )}
              </>
            )}
          </Card.Body>
        )}

        <Row className="gx-0 flex-grow-1">
          <Col lg="auto" className="d-none d-lg-block border-end bg-white" style={{ width: 280 }}>
            <div className="steps-wrapper p-3">
              {role === "super_admin" &&
                ["Documentation", "Sign"].includes(formState.currentStep) &&
                formState.filingProfiles.length > 0 && (
                  <Form.Group className="mb-3" controlId="filing-profile-selector">
                    <Form.Label>Filing profile</Form.Label>
                    <Form.Select
                      value={formState.selectedFilingProfileId}
                      className="w-100"
                      onChange={(event) => {
                        dispatch({ type: ActionType.SelectFilingProfile, data: event.target.value });
                      }}
                    >
                      <option value="">Choose...</option>
                      {formState.filingProfiles.map((filingProfile) => (
                        <option key={filingProfile.id} value={filingProfile.id}>
                          {filingProfile.name} - {new Date(filingProfile.created_at).toLocaleString("en-US")}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}

              <div className="steps">
                <SidebarSelectCaseStep currentStep={formState.currentStep} selectedCase={formState.selectedCase} />
                <SidebarSelectCreditorStep
                  currentStep={formState.currentStep}
                  selectedCreditor={formState.selectedCreditor}
                />
                <SidebarDocumentationStep currentStep={formState.currentStep} />
                <SidebarSignStep currentStep={formState.currentStep} />
              </div>
            </div>
          </Col>

          <Col md={12} lg className="bg-body">
            <Container fluid="lg" className="p-3 mx-0">
              {formState.currentStep === WizardSteps.SelectCase && (
                <SelectCaseStep
                  autoSubmit={autoSubmitCaseStep}
                  selectedCase={formState.selectedCase}
                  onNext={onSubmitCase}
                />
              )}

              {formState.currentStep === WizardSteps.SelectCreditor && (
                <SelectCreditorStep
                  selectedCase={formState.selectedCase!}
                  selectedCreditor={formState.selectedCreditor}
                  onChange={onChangeCreditor}
                  onNext={onSubmitCreditor}
                />
              )}

              {formState.currentStep === WizardSteps.Documentation && (
                <DocumentationStep
                  // Key by selectedFilingProfileId to reset the form when changing filing profile
                  key={formState.selectedFilingProfileId}
                  selectedCase={formState.selectedCase!}
                  selectedCreditor={formState.selectedCreditor!}
                  documentation={formState.documentation}
                  claimId={formState.claimId!} // claimId can't be undefined here, a draft has been saved so an id is assigned
                  onChange={onChangeDocumentation}
                  onNext={onSubmitDocumentation}
                />
              )}

              {formState.currentStep === WizardSteps.Sign && (
                <SignStep
                  // Key by selectedFilingProfileId to reset the form when changing filing profile
                  key={formState.selectedFilingProfileId}
                  signee={formState.signee}
                  filedBy={formState.documentation!.filed_by}
                  onChange={onChangeSignee}
                  onNext={onSubmitSignee}
                />
              )}
            </Container>
          </Col>
        </Row>
      </Card>

      <StartOverModal
        show={formState.showStartOverModal}
        onClose={onHideStartOverModal}
        onConfirm={onConfirmStartOver}
      />

      <PreviewModal formState={formState} show={formState.showPreviewModal} onClose={onHidePreviewModal} />
    </div>
  );
}

function SidebarStep({
  current,
  done,
  stepNumber,
  label,
  children,
  last = false,
}: {
  current: boolean;
  done: boolean;
  stepNumber: number;
  label: string;
  children?: React.ReactNode;
  last?: boolean;
}) {
  return (
    <div className={classnames("step", { current, done })}>
      <div className="d-flex gap-2">
        <div className="d-flex flex-row flex-lg-column">
          <div className="step-circle">
            <div className="step-number">{done ? <CheckIcon size={16} /> : stepNumber}</div>
          </div>

          {!last && <div className="separator" />}
        </div>

        <div className="d-none d-lg-block">
          <div className="d-flex align-items-center mb-2" style={{ height: 32 }}>
            <h4
              className={classnames("mb-0 text-nowrap", {
                "text-primary": current,
                "text-success": done,
                "fw-normal": !current && !done,
              })}
            >
              {label}
            </h4>
          </div>

          {children}
        </div>
      </div>
    </div>
  );
}

enum SidebarSteps {
  SelectCase = 0,
  SelectCreditor = 1,
  Documentation = 2,
  Sign = 3,
  SaveClaim = 4,
}

function stepStringToNum(step: FormState["currentStep"]): SidebarSteps {
  switch (step) {
    case "SelectCase":
      return SidebarSteps.SelectCase;
    case "SelectCreditor":
      return SidebarSteps.SelectCreditor;
    case "Documentation":
      return SidebarSteps.Documentation;
    case "Sign":
      return SidebarSteps.Sign;
    case "SaveClaim":
      return SidebarSteps.SaveClaim;
  }
}

function stepStringToLabel(step: FormState["currentStep"]): string {
  switch (step) {
    case "SelectCase":
      return "Select Case";
    case "SelectCreditor":
      return "Select Creditor";
    case "Documentation":
      return "Documentation";
    case "Sign":
      return "Sign";
    case "SaveClaim":
      return "Save";
  }
}

function SidebarSelectCaseStep({
  currentStep,
  selectedCase,
}: {
  currentStep: FormState["currentStep"];
  selectedCase?: SelectedCase;
}) {
  const current = "SelectCase" === currentStep;
  const done = stepStringToNum(currentStep) > SidebarSteps.SelectCase;

  return (
    <SidebarStep current={current} done={done} stepNumber={1} label={stepStringToLabel("SelectCase")}>
      {selectedCase && (
        <dl>
          <dt>Court</dt>
          <dd>
            {courts.find(({ court_code }) => court_code === selectedCase.court_code)?.court_name} (
            {selectedCase.court_code})
          </dd>

          <dt>Case Number</dt>
          <dd>{selectedCase.case_no}</dd>

          <dt>Case Title</dt>
          <dd>{selectedCase.case_title}</dd>
        </dl>
      )}
    </SidebarStep>
  );
}

function SidebarSelectCreditorStep({
  currentStep,
  selectedCreditor,
}: {
  currentStep: FormState["currentStep"];
  selectedCreditor?: Creditor;
}) {
  const current = "SelectCreditor" === currentStep;
  const done = stepStringToNum(currentStep) > SidebarSteps.SelectCreditor;

  return (
    <SidebarStep current={current} done={done} stepNumber={2} label={stepStringToLabel("SelectCreditor")}>
      {selectedCreditor && (
        <dl>
          <dt>Creditor name</dt>
          <dd>{selectedCreditor.name}</dd>

          <dt>Address</dt>
          <dd>
            {Array.isArray(selectedCreditor.address)
              ? selectedCreditor.address.join(" ")
              : [
                  [
                    selectedCreditor.address.address1,
                    selectedCreditor.address.address2,
                    selectedCreditor.address.address3,
                    selectedCreditor.address.city,
                    selectedCreditor.address.state,
                  ]
                    .filter(Boolean)
                    .join(", "),
                  [selectedCreditor.address.zip, selectedCreditor.address.zip4].filter(Boolean).join(" - "),
                ].join(" ")}
          </dd>

          {selectedCreditor.phone && (
            <>
              <dt>Phone</dt>
              <dd>{selectedCreditor.phone}</dd>
            </>
          )}

          {selectedCreditor.email && (
            <>
              <dt>Email</dt>
              <dd>{selectedCreditor.email}</dd>
            </>
          )}
        </dl>
      )}
    </SidebarStep>
  );
}

function SidebarDocumentationStep({ currentStep }: { currentStep: FormState["currentStep"] }) {
  const current = "Documentation" === currentStep;
  const done = stepStringToNum(currentStep) > SidebarSteps.Documentation;

  return (
    <SidebarStep current={current} done={done} stepNumber={3} label={stepStringToLabel("Documentation")}>
      {current && (
        <Nav className="flex-row flex-lg-column mb-2" variant="pills" id="epoc-nav-wrapper">
          {[
            { id: "part-1", label: "Identify the Claim" },
            { id: "part-2", label: "Claim information" },
            { id: "part-3", label: "Documents" },
          ].map(({ id, label }, index) => (
            <Nav.Item key={id}>
              <Nav.Link
                href={`#${id}`}
                onClick={(event) => {
                  event.preventDefault();

                  document.getElementById(id)?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
              >
                {index + 1}. {label}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      )}
    </SidebarStep>
  );
}

function SidebarSignStep({ currentStep }: { currentStep: FormState["currentStep"] }) {
  const current = "Sign" === currentStep;
  const done = stepStringToNum(currentStep) > SidebarSteps.Sign;

  return <SidebarStep current={current} done={done} stepNumber={4} label={stepStringToLabel("Sign")} last />;
}

function scrollToTop() {
  document.querySelector("main.app-main")!.scrollTo({
    top: 0,
  });
}
