import gql from "graphql-tag";

const purchaseCase = gql`
  mutation purchaseCase($input: PurchaseCaseInput!) {
    purchaseCase(input: $input) {
      error
      stripe_redirect_url
    }
  }
`;

export default purchaseCase;
