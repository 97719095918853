import { Creditor } from "../interfaces";

export enum ActionTypes {
  Loading = 0,
  SetCourtCode,
  SetCaseNumber,
  SetResponse,
  SetSelectedCase,
  SetCreditorName,
}

interface Case {
  case_no: string;
  case_title: string;
}

type SuggestedCase = Case;

export interface CaseCreditorsResponse {
  cases: SuggestedCase[] | null;
  case: Case | null;
  creditors: Creditor[] | null;
  error: string | null;
}

export interface CourtInfosResponse {
  error: string | null;
  attachment_size_limit: number | null;
}

export interface Response {
  getCaseCreditors: CaseCreditorsResponse;
  getCourtInfos: CourtInfosResponse;
}

export interface SelectedCase {
  court_code: string;
  case_no: string;
  case_title: string;
  creditor_name?: string;
}

export interface State {
  is_loading: boolean;
  court_code: {
    value: string;
    label?: string;
  } | null;
  case_no: string;
  response: Response | null;
  attachment_size_limit: number | null;
  selected_case: SelectedCase | null;
  requires_creditor_name: boolean;
  creditor_name: string;
}

export interface Action {
  type: ActionTypes;
  data?: any;
}
