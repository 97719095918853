import gql from "graphql-tag";

const getPACERCredentials = gql`
  query getPACERCredentials {
    getPACERCredentials {
      error
      error_code
      username
    }
  }
`;

export default getPACERCredentials;
