import { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Row, Col, Form, Button, Alert, Badge, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm, useFieldArray, Controller, FieldErrors, get } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ScrollSpy } from "bootstrap";
import {
  ExclamationTriangle as WarningIcon,
  ChevronDown as ArrowDownIcon,
  ChevronUp as ArrowUpIcon,
} from "react-bootstrap-icons";

import { SelectedCase, Creditor } from "../interfaces";

import { FormValues, defaultFormValues, FiledBy } from "./interfaces";
import CreditorsAttorney from "./CreditorsAttorney/CreditorsAttorney";
import AmendClaim from "./AmendClaim";
import PaymentAddress from "./PaymentAddress/PaymentAddress";
import Securities from "./Securities";
import Priorities from "./Priorities";
import Upload from "./Upload";
import ConfirmModal from "./ConfirmModal";
import AutoHeightTextarea from "./AutoHeightTextarea";
import AmountInput from "./AmountInput";

function transformNumber(value: any, originalValue: string, context: { isType: (arg0: any) => any }) {
  // check to see if the previous transform already parsed the number
  if (context.isType(value)) {
    return value;
  }

  // check for empty strings, cast them to undefined nullable, avoid NaN
  if (typeof originalValue === "string" && originalValue.trim() === "") {
    return undefined;
  }

  return value;
}

const addressSchema = yup.object({
  address1: yup.string().nullable().required("Insert the address"),
  city: yup.string().nullable().required("Insert the City"),
  state: yup.string().nullable().required("Choose a State"),
  zip: yup.string().nullable().required("Insert the ZIP code").matches(/\d{5}/, "Insert a valid ZIP code"),
  zip4: yup.string().nullable().matches(/\d{4}/, {
    message: "Insert a valid ZIP code",
    excludeEmptyString: true,
  }),
});

const schema = yup.object({
  creditor_email: yup.string().email("Insert a valid email address"),
  filed_by: yup.string().required("Select filed by"),
  creditors_attorney: yup.object().when("filed_by", {
    is: "CAT",
    then: (schema) =>
      schema.shape({
        name: yup.string().nullable().required("Insert the Attorney name"),
        address: addressSchema,
      }),
    otherwise: (schema) => schema.nullable(),
  }),
  amending_claim: yup.string().nullable().required("Select claim amend option"),
  amend_claim: yup.string().when("amending_claim", {
    is: "yes",
    then: (schema) => schema.nullable().required("Specify previously filed claim"),
    otherwise: (schema) => schema.nullable(),
  }),
  acquired_from: yup.string().nullable().required("Select acquired from option"),
  acquired_from_whom: yup.string().when("acquired_from", {
    is: "yes",
    then: (schema) => schema.nullable().required("Specify acquired from whom"),
    otherwise: (schema) => schema.nullable(),
  }),
  payment_address: yup.object().when("payment_address_differ", {
    is: true,
    then: (schema) =>
      schema.shape({
        name: yup.string().nullable().required("Insert name"),
        address: addressSchema,
        email: yup.string().nullable().email("Insert a valid email address"),
      }),
    otherwise: (schema) => schema.nullable(),
  }),
  anyone_else_filed: yup.string().nullable().required("Select anyone filed option"),
  other_proof_name: yup.string().when("anyone_else_filed", {
    is: "yes",
    then: (schema) => schema.nullable().required("Specify who made the earlier filing"),
    otherwise: (schema) => schema.nullable(),
  }),
  identify_debtor: yup.string().nullable().required("Select identify debtor option"),
  debtor_number: yup.string().when("identify_debtor", {
    is: "yes",
    then: (schema) => schema.nullable().required("Specify debtor identification number"),
    otherwise: (schema) => schema.nullable(),
  }),
  claim_amount: yup.number().typeError("Insert a valid claim amount"),
  include_interest: yup.string().nullable().required("Select if amount include interest"),
  claim_comment: yup.string().when("claim_amount", {
    is: 0,
    then: (schema) => schema.required("Specify why amount claimed is zero"),
    otherwise: (schema) => schema.nullable(),
  }),
  claim_basis: yup.string().required("Insert basis of the claim"),
  claim_secured: yup.string().nullable().required("Select if claim is secured"),
  securities: yup.object().when("claim_secured", {
    is: "yes",
    then: (schema) =>
      schema
        .shape({
          other_desc: yup.string().when("other", {
            is: true,
            then: (schema) => schema.nullable().required("Specify other nature description"),
            otherwise: (schema) => schema.nullable(),
          }),
          value: yup.number().typeError("Insert a valid value of property"),
          amount_secured: yup.number().typeError("Insert a valid secured amount"),
          amount_unsecured: yup.number().typeError("Insert a valid unsecured amount"),
          cure_default: yup
            .number()
            .nullable(true)
            .transform(transformNumber)
            .typeError("Insert a valid cure default amount"),
          annual_interest_rate: yup.number().when("interest_rates", {
            is: (value: string) => ["fixed", "variable"].includes(value),
            then: (schema) => schema.typeError("Insert a valid interest rate amount"),
            otherwise: (schema) => schema.nullable(true),
          }),
        })
        .test("at-least-one-checked", "Check all that apply on nature of property", function () {
          return this.parent.securities.real_estate || this.parent.securities.vehicle || this.parent.securities.other;
        }),
    otherwise: (schema) => schema.nullable(),
  }),
  claim_lease_based: yup.string().nullable().required("Select if claim based on a lease"),
  lease_default: yup.number().when("claim_lease_based", {
    is: "yes",
    then: (schema) => schema.typeError("Insert a valid cure default amount"),
    otherwise: (schema) => schema.nullable(true),
  }),
  right_of_set_off: yup.string().nullable().required("Select if claim is subject to a right of setoff"),
  set_off: yup.string().when("right_of_set_off", {
    is: "yes",
    then: (schema) => schema.nullable().required("Specify the property"),
    otherwise: (schema) => schema.nullable(),
  }),
  priority_entitled: yup.string().nullable().required("Select if claim entitled to priority"),
  priorities: yup.object().when("priority_entitled", {
    is: "yes",
    then: (schema) =>
      schema.shape({
        amount: yup.number().typeError("Specify amount entitled to priority"),
        code: yup
          .string()
          .nullable()
          .oneOf(["507_1", "507_7", "507_4", "507_8", "507_5", "507_other"])
          .required("Specify priority section"),

        code_507_other_sub: yup.string().when("code", {
          is: "507_other",
          then: (schema) => schema.nullable().required("Specify priority subsection"),
          otherwise: (schema) => schema.nullable(),
        }),
      }),
    otherwise: (schema) => schema.nullable(),
  }),
});

interface Props {
  selectedCase: SelectedCase;
  selectedCreditor: Creditor;
  documentation: FormValues | undefined;
  claimId: string;
  onChange: (data: FormValues) => void;
  onNext: (data: FormValues) => void;
}
export default function Documentation({
  selectedCase,
  selectedCreditor,
  documentation,
  claimId,
  onChange,
  onNext,
}: Props) {
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    getValues,
    resetField,
    setFocus,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      ...defaultFormValues,
      ...documentation,
      amending_claim: selectedCreditor.id ? documentation?.amending_claim || "" : "no",
    },
  });
  const {
    fields: attachments,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "attachments",
  });
  const [isUploadingAttachment, setIsUploadingAttachment] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const scrollSpyRef = useRef<ScrollSpy | null>(null);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      // Notes
      // - Use resetField instead of setValue, setValue would use
      //   the default values, which is wrong in case of a draft
      // - setFocus is deferred, otherwise won't work

      if (name === "payment_address_differ" && !value.payment_address_differ) {
        resetField("payment_address", {
          defaultValue: defaultFormValues.payment_address,
        });
      }

      if (name === "filed_by") {
        if (value.filed_by !== FiledBy.CreditorAttorney) {
          resetField("creditors_attorney", {
            defaultValue: defaultFormValues.creditors_attorney,
          });
        }
      }

      if (name === "amending_claim" && value.amending_claim === "no") {
        resetField("amend_claim", {
          defaultValue: defaultFormValues.amend_claim,
          keepError: false,
        });
      }

      if (name === "acquired_from") {
        if (value.acquired_from === "no") {
          resetField("acquired_from_whom", {
            defaultValue: defaultFormValues.acquired_from_whom,
          });
        } else {
          setTimeout(() => setFocus("acquired_from_whom"));
        }
      }

      if (name === "anyone_else_filed") {
        if (value.anyone_else_filed === "no") {
          resetField("other_proof_name", {
            defaultValue: defaultFormValues.other_proof_name,
          });
        } else {
          setTimeout(() => setFocus("other_proof_name"));
        }
      }

      if (name === "identify_debtor") {
        if (value.identify_debtor === "no") {
          resetField("debtor_number", {
            defaultValue: defaultFormValues.debtor_number,
          });
        } else {
          setTimeout(() => setFocus("debtor_number"));
        }
      }

      // Securities section
      if (name === "claim_secured" && value.claim_secured === "no") {
        resetField("securities", {
          defaultValue: defaultFormValues.securities,
        });
      }

      if (name === "claim_lease_based") {
        if (value.claim_lease_based === "no") {
          resetField("lease_default", {
            defaultValue: defaultFormValues.lease_default,
          });
        } else {
          setTimeout(() => setFocus("lease_default"));
        }
      }

      if (name === "right_of_set_off") {
        if (value.right_of_set_off === "no") {
          resetField("set_off", { defaultValue: defaultFormValues.set_off });
        } else {
          setTimeout(() => setFocus("set_off"));
        }
      }

      // Priorities section
      if (name === "priority_entitled" && value.priority_entitled === "no") {
        resetField("priorities", {
          defaultValue: defaultFormValues.priorities,
        });
      }

      // refresh scrollSpy to address any dom change
      setTimeout(() => scrollSpyRef.current?.refresh());
    });

    return () => subscription.unsubscribe();
  }, [watch, resetField, setFocus]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log("Documentation onChange");
      console.log(value, name, type);
      onChange(getValues());
    });
    return () => subscription.unsubscribe();
  }, [getValues, onChange, watch]);

  useEffect(() => {
    const scrollSpy = new ScrollSpy(document.querySelector("main.app-main") as HTMLElement, {
      target: "#epoc-nav-wrapper",
    });

    scrollSpyRef.current = scrollSpy;

    return () => {
      scrollSpyRef.current = null;
      scrollSpy.dispose();
    };
  }, []);

  return (
    <>
      <FormErrors errors={errors} />

      <Form
        autoComplete="off"
        onSubmit={handleSubmit((data: FormValues) => {
          if (data.attachments.length === 0) {
            setShowConfirmModal(true);
          } else {
            onNext(data);
          }
        })}
      >
        <fieldset disabled={isUploadingAttachment}>
          <section id="part-1">
            <Alert variant="primary">
              <div className="alert-message d-flex align-items-center gap-2 fs-4">
                <Badge pill bg="light" text="dark">
                  Part 1:
                </Badge>
                Identify the Claim
              </div>
            </Alert>

            <div className="d-grid gap-2 mb-3" style={{ gridTemplateColumns: "auto 1fr" }}>
              <div className="font-weight-bold">Name of Creditor</div>
              <div>{selectedCreditor.name}</div>
              <div className="font-weight-bold">Address where notices should be sent</div>
              <div>
                {Array.isArray(selectedCreditor.address)
                  ? selectedCreditor.address.join(" ")
                  : [
                      [
                        selectedCreditor.address.address1,
                        selectedCreditor.address.address2,
                        selectedCreditor.address.address3,
                        selectedCreditor.address.city,
                        selectedCreditor.address.state,
                      ]
                        .filter(Boolean)
                        .join(", "),
                      [selectedCreditor.address.zip, selectedCreditor.address.zip4].filter(Boolean).join(" - "),
                    ].join(" ")}
              </div>
              {selectedCreditor.id ? (
                <>
                  <Form.Label className="mb-0 d-flex align-items-center" htmlFor="creditor-phone-number">
                    Telephone Number
                  </Form.Label>
                  <div>
                    <Form.Control
                      type="text"
                      id="creditor-phone-number"
                      placeholder="818-252-9950"
                      maxLength={20}
                      inputMode="tel"
                      {...register("creditor_phone")}
                    />
                  </div>
                  <Form.Label className="mb-0 d-flex align-items-center" htmlFor="creditor-email">
                    Email
                  </Form.Label>
                  <div>
                    <Form.Control
                      type="email"
                      placeholder="johndoe@company.com"
                      id="creditor-email"
                      maxLength={40}
                      inputMode="email"
                      isInvalid={!!errors.creditor_email?.message}
                      {...register("creditor_email")}
                    />
                    <Form.Control.Feedback type="invalid">{errors.creditor_email?.message}</Form.Control.Feedback>
                  </div>
                </>
              ) : (
                <>
                  <div className="font-weight-bold">Telephone Number</div>
                  <div>{selectedCreditor.phone}</div>
                  <div className="font-weight-bold">Email</div>
                  <div>{selectedCreditor.email}</div>
                </>
              )}

              <Form.Label className="mb-0 d-flex align-items-center" htmlFor="creditor-alias">
                Other names the creditor used with the debtor
              </Form.Label>
              <div>
                <Form.Control type="text" id="creditor-alias" maxLength={200} {...register("creditor_alias")} />
              </div>
            </div>

            <hr />

            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                id="payment-address"
                label="Check if Payment Address differs from Notice Address"
                {...register("payment_address_differ")}
              />
            </Form.Group>

            {watch("payment_address_differ") && (
              <PaymentAddress register={register} errors={errors} setValue={setValue} control={control} />
            )}

            <hr />

            <Form.Group controlId="uniform-claim-identifier">
              <Form.Label>Uniform claim identifier for electronic payments in chapter 13 (if you use one):</Form.Label>

              <Form.Control type="text" maxLength={24} {...register("uniform_identifier")} />
            </Form.Group>

            <hr />

            <Form.Group className="mb-3" controlId="filed-by">
              <Form.Label>
                Filed by <span className="text-danger">(required)</span>
              </Form.Label>

              <Form.Select {...register("filed_by")} isInvalid={!!errors.filed_by?.message}>
                <option value="">Choose...</option>
                {[
                  { value: FiledBy.Creditor, label: "Creditor" },
                  {
                    value: FiledBy.CreditorAttorney,
                    label: "Creditor Attorney",
                  },
                  { value: FiledBy.Debtor, label: "Debtor" },
                  {
                    value: FiledBy.DebtorAttorney,
                    label: "Debtor Attorney",
                  },
                  { value: FiledBy.Trustee, label: "Trustee" },
                ].map(function ({ value, label }) {
                  return (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  );
                })}
              </Form.Select>

              <Form.Control.Feedback type="invalid">{errors.filed_by?.message}</Form.Control.Feedback>
            </Form.Group>

            {watch("filed_by") === FiledBy.CreditorAttorney && (
              <CreditorsAttorney register={register} errors={errors} setValue={setValue} control={control} />
            )}

            <hr />

            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm="auto">
                Does this claim amend one already filed?
              </Form.Label>

              <Col
                className={classNames("d-flex align-items-center gap-3", {
                  "is-invalid": !!errors.amending_claim?.message,
                })}
              >
                <Form.Check
                  type="radio"
                  id="amend-claim-no"
                  label="No"
                  inline
                  className="m-0"
                  {...register("amending_claim")}
                  value="no"
                  disabled={!selectedCreditor.id}
                />
                <Form.Check
                  type="radio"
                  id="amend-claim-yes"
                  label="Yes"
                  inline
                  className="m-0"
                  {...register("amending_claim")}
                  value="yes"
                  disabled={!selectedCreditor.id}
                />
              </Col>

              <Form.Control.Feedback type="invalid">{errors.amending_claim?.message}</Form.Control.Feedback>
            </Form.Group>

            {watch("amending_claim") === "yes" && selectedCreditor.id && (
              <AmendClaim
                creditorId={selectedCreditor.id}
                courtCode={selectedCase.court_code}
                caseNo={selectedCase.case_no}
                register={register}
                errors={errors}
              />
            )}

            <hr />

            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm="auto">
                Has this claim been acquired from someone else?
              </Form.Label>

              <Col
                className={classNames("d-flex align-items-center gap-3", {
                  "is-invalid": !!errors.acquired_from?.message,
                })}
              >
                <Form.Check
                  type="radio"
                  id="acquired-from-no"
                  label="No"
                  inline
                  className="m-0"
                  {...register("acquired_from")}
                  value="no"
                />
                <Form.Check
                  type="radio"
                  id="acquired-from-yes"
                  label="Yes"
                  inline
                  className="m-0"
                  {...register("acquired_from")}
                  value="yes"
                />
              </Col>

              <Form.Control.Feedback type="invalid">{errors.acquired_from?.message}</Form.Control.Feedback>
            </Form.Group>

            {watch("acquired_from") === "yes" && (
              <Form.Group controlId="acquired_from_whom">
                <Form.Label>
                  From whom? <span className="text-danger">(required)</span>
                </Form.Label>

                <Form.Control
                  type="text"
                  isInvalid={!!errors.acquired_from_whom?.message}
                  {...register("acquired_from_whom")}
                />

                <Form.Control.Feedback type="invalid">{errors.acquired_from_whom?.message}</Form.Control.Feedback>
              </Form.Group>
            )}

            <hr />

            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm="auto">
                Do you know if anyone else has filed a proof of claim for this claim?
              </Form.Label>

              <Col
                className={classNames("d-flex gap-3 align-items-center", {
                  "is-invalid": !!errors.anyone_else_filed?.message,
                })}
              >
                <Form.Check
                  type="radio"
                  id="anyone-else-filed-no"
                  label="No"
                  inline
                  className="m-0"
                  {...register("anyone_else_filed")}
                  value="no"
                />
                <Form.Check
                  type="radio"
                  id="anyone-else-filed-yes"
                  label="Yes"
                  inline
                  className="m-0"
                  {...register("anyone_else_filed")}
                  value="yes"
                />
              </Col>

              <Form.Control.Feedback type="invalid">{errors.anyone_else_filed?.message}</Form.Control.Feedback>
            </Form.Group>

            {watch("anyone_else_filed") === "yes" && (
              <Form.Group controlId="other-proof-name">
                <Form.Label>
                  Who made the earlier filing? <span className="text-danger">(required)</span>
                </Form.Label>

                <Form.Control
                  type="text"
                  maxLength={100}
                  isInvalid={!!errors.other_proof_name?.message}
                  {...register("other_proof_name")}
                />

                <Form.Control.Feedback type="invalid">{errors.other_proof_name?.message}</Form.Control.Feedback>
              </Form.Group>
            )}
          </section>
          <hr />

          <section id="part-2">
            <Alert variant="primary">
              <div className="alert-message d-flex align-items-center gap-2 fs-4">
                <Badge pill bg="light" text="dark">
                  Part 2:
                </Badge>
                Give Information About the Claim as of the Date the Case Was Filed
              </div>
            </Alert>

            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm="auto">
                Do you have any number you use to identify the debtor?
              </Form.Label>

              <Col
                className={classNames("d-flex gap-3 align-items-center", {
                  "is-invalid": !!errors.identify_debtor?.message,
                })}
              >
                <Form.Check
                  type="radio"
                  id="identity-debtor-no"
                  label="No"
                  inline
                  className="m-0"
                  {...register("identify_debtor")}
                  value="no"
                />
                <Form.Check
                  type="radio"
                  id="identity-debtor-yes"
                  label="Yes"
                  inline
                  className="m-0"
                  {...register("identify_debtor")}
                  value="yes"
                />
              </Col>

              <Form.Control.Feedback type="invalid">{errors.identify_debtor?.message}</Form.Control.Feedback>
            </Form.Group>

            {watch("identify_debtor") === "yes" && (
              <Form.Group controlId="debtor_number">
                <Form.Label>
                  Last 4 digits of the debtor's account or any number you use to identify the debtor{" "}
                  <span className="text-danger">(required)</span>
                </Form.Label>

                <Form.Control
                  type="text"
                  maxLength={4}
                  isInvalid={!!errors.debtor_number?.message}
                  {...register("debtor_number")}
                />

                <Form.Control.Feedback type="invalid">{errors.debtor_number?.message}</Form.Control.Feedback>
              </Form.Group>
            )}

            <hr />

            <Form.Group className="mb-3" controlId="claim-amount">
              <Form.Label>
                How much is the claim? <span className="text-danger">(required)</span>
              </Form.Label>

              <InputGroup hasValidation>
                <InputGroup.Text>$</InputGroup.Text>

                <AmountInput
                  isInvalid={!!errors.claim_amount?.message}
                  className="flex-grow-0 w-auto"
                  register={register}
                  name="claim_amount"
                  registerOptions={{ deps: "claim_comment" }}
                  setValue={setValue}
                />

                <Form.Control.Feedback type="invalid">{errors.claim_amount?.message}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="auto">
                Does this amount include interest or other charges?
              </Form.Label>

              <Col
                className={classNames("d-flex gap-3 align-items-center", {
                  "is-invalid": !!errors.include_interest?.message,
                })}
              >
                <Form.Check
                  type="radio"
                  id="include-interest-no"
                  label="No"
                  className="m-0"
                  {...register("include_interest")}
                  value="no"
                />
                <Form.Check
                  type="radio"
                  id="include-interest-yes"
                  label="Yes"
                  {...register("include_interest")}
                  className="m-0"
                  value="yes"
                />
              </Col>

              <Form.Control.Feedback type="invalid">{errors.include_interest?.message}</Form.Control.Feedback>
            </Form.Group>

            {watch("include_interest") === "yes" && (
              <Alert variant="info">
                <div className="alert-message">
                  Attach statement itemizing interest, fees, expenses, or other charges required by Bankruptcy Rule
                  3001(c)(2)(A)
                </div>
              </Alert>
            )}

            <Form.Group controlId="claim-comment">
              <Form.Label>Comment</Form.Label>

              <Controller
                name="claim_comment"
                control={control}
                render={({ field }) => {
                  // exclude ref
                  const { ref, ...rest } = field;
                  return (
                    <AutoHeightTextarea
                      rows={3}
                      cols={30}
                      maxLength={215}
                      isInvalid={!!errors.claim_comment?.message}
                      {...rest}
                    />
                  );
                }}
              />

              <Form.Control.Feedback type="invalid">{errors.claim_comment?.message}</Form.Control.Feedback>

              <Form.Text>
                If you have entered a claim amount of $0, the claim amount is
                <br />
                unknown, or the claim is unliquidated, please enter a brief
                <br />
                explanation.
              </Form.Text>
            </Form.Group>

            <hr />

            <Form.Group controlId="claim-basis">
              <Form.Label>
                What is the basis of the claim? <span className="text-danger">(required)</span>
              </Form.Label>

              <Form.Control
                type="text"
                maxLength={115}
                isInvalid={!!errors.claim_basis?.message}
                {...register("claim_basis")}
              />

              <Form.Control.Feedback type="invalid">{errors.claim_basis?.message}</Form.Control.Feedback>

              <Form.Text>
                Examples: Goods sold, money loaned, lease, services performed, personal injury or wrongful death, or
                credit card.
                <br />
                Attach redacted copies of any documents supporting the claim required by Bankruptcy Rule 3001(c).
                <br />
                Limit disclosing information that is entitled to privacy, such as healthcare information.
              </Form.Text>
            </Form.Group>

            <hr />

            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm="auto">
                Is all or part of the claim secured?
              </Form.Label>

              <Col
                sm="auto"
                className={classNames("d-flex gap-3 align-items-center", {
                  "is-invalid": !!errors.claim_secured?.message,
                })}
              >
                <Form.Check
                  type="radio"
                  id="claim-secured-no"
                  label="No"
                  className="m-0"
                  {...register("claim_secured")}
                  value="no"
                />
                <Form.Check
                  type="radio"
                  id="claim-secured-yes"
                  label="Yes. The claim is secured by a lien on property."
                  {...register("claim_secured")}
                  className="m-0"
                  value="yes"
                />
              </Col>

              <Form.Control.Feedback type="invalid">{errors.claim_secured?.message}</Form.Control.Feedback>
            </Form.Group>

            <Securities register={register} watch={watch} setValue={setValue} setFocus={setFocus} errors={errors} />

            <hr />

            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm="auto">
                Is this claim based on a lease?
              </Form.Label>

              <Col
                className={classNames("d-flex gap-3 align-items-center", {
                  "is-invalid": !!errors.claim_lease_based?.message,
                })}
              >
                <Form.Check
                  type="radio"
                  id="lease-default-no"
                  label="No"
                  className="m-0"
                  {...register("claim_lease_based")}
                  value="no"
                />
                <Form.Check
                  type="radio"
                  id="lease-default-yes"
                  label="Yes"
                  {...register("claim_lease_based")}
                  className="m-0"
                  value="yes"
                />
              </Col>

              <Form.Control.Feedback type="invalid">{errors.claim_lease_based?.message}</Form.Control.Feedback>
            </Form.Group>

            {watch("claim_lease_based") === "yes" && (
              <Form.Group controlId="lease-default">
                <Form.Label>
                  Amount necessary to cure any default as of the date of the petition{" "}
                  <span className="text-danger">(required)</span>
                </Form.Label>

                <InputGroup hasValidation>
                  <InputGroup.Text>$</InputGroup.Text>

                  <AmountInput
                    isInvalid={!!errors.lease_default?.message}
                    className="flex-grow-0 w-auto"
                    register={register}
                    name="lease_default"
                    setValue={setValue}
                  />

                  <Form.Control.Feedback type="invalid">{errors.lease_default?.message}</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            )}

            <hr />

            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm="auto">
                Is this claim subject to a right of setoff?
              </Form.Label>

              <Col
                className={classNames("d-flex gap-3 align-items-center", {
                  "is-invalid": !!errors.right_of_set_off?.message,
                })}
              >
                <Form.Check
                  type="radio"
                  id="right-of-set-off-no"
                  label="No"
                  className="m-0"
                  {...register("right_of_set_off")}
                  value="no"
                />
                <Form.Check
                  type="radio"
                  id="right-of-set-off-yes"
                  label="Yes"
                  {...register("right_of_set_off")}
                  className="m-0"
                  value="yes"
                />
              </Col>

              <Form.Control.Feedback type="invalid">{errors.right_of_set_off?.message}</Form.Control.Feedback>
            </Form.Group>

            {watch("right_of_set_off") === "yes" && (
              <Form.Group controlId="set-off">
                <Form.Label>
                  Identify the property <span className="text-danger">(required)</span>
                </Form.Label>

                <Form.Control
                  type="text"
                  maxLength={60}
                  isInvalid={!!errors.set_off?.message}
                  {...register("set_off")}
                />

                <Form.Control.Feedback type="invalid">{errors.set_off?.message}</Form.Control.Feedback>
              </Form.Group>
            )}

            <hr />

            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm="auto">
                Is all or part of the claim entitled to priority under 11 U.S.C. § 507(a)?
              </Form.Label>

              <Col
                className={classNames("d-flex gap-3 align-items-center", {
                  "is-invalid": !!errors.priority_entitled?.message,
                })}
              >
                <Form.Check
                  type="radio"
                  id="entitled-to-priority-no"
                  label="No"
                  className="m-0"
                  {...register("priority_entitled")}
                  value="no"
                />
                <Form.Check
                  type="radio"
                  id="entitled-to-priority-yes"
                  label="Yes"
                  {...register("priority_entitled")}
                  className="m-0"
                  value="yes"
                />
              </Col>

              <Form.Control.Feedback type="invalid">{errors.priority_entitled?.message}</Form.Control.Feedback>
            </Form.Group>

            <p>
              A claim may be partly priority and partly nonpriority. For example, in some categories, the law limits the
              amount entitled to priority.
            </p>

            <Priorities register={register} watch={watch} setValue={setValue} setFocus={setFocus} errors={errors} />
          </section>
          <hr />

          <section id="part-3">
            <Alert variant="primary">
              <div className="alert-message d-flex align-items-center gap-2 fs-4">
                <Badge pill bg="light" text="dark">
                  Part 3:
                </Badge>
                Documents
              </div>
            </Alert>

            <Upload
              claimId={claimId}
              attachments={attachments}
              onIsUploading={(loading) => setIsUploadingAttachment(loading)}
              onAdd={(attachment) => append(attachment)}
              onRemove={(index) => remove(index)}
              sizeLimit={selectedCase.attachment_size_limit}
            />
          </section>

          <div className="sticky-bottom bg-body border-top py-3">
            <Button type="submit" variant="primary" size="lg">
              Continue
            </Button>
          </div>
        </fieldset>
      </Form>

      <ConfirmModal
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => onNext(getValues())}
      />
    </>
  );
}

function FormErrors({ errors }: { errors: FieldErrors<FormValues> }) {
  const [currentErrorIndex, setCurrentErrorIndex] = useState(0);
  const keys = Object.keys(errors);
  const key = keys[currentErrorIndex];
  let errorMessage = key && get(errors, key).message;

  // special cases for nested objects
  if (!errorMessage) {
    switch (key) {
      case "securities":
        errorMessage = "Complete Securities section";
        break;
      case "priorities":
        errorMessage = "Complete Priorities section";
        break;
      case "creditors_attorney":
        errorMessage = "Complete Attorney section";
        break;
      case "payment_address":
        errorMessage = "Complete Payment Address section";
        break;
    }
  }

  if (keys.length === 0) return null;

  function navigateToError(key: string) {
    // If error comes from securities or priorities scroll to section
    if (["securities", "priorities", "creditors_attorney", "payment_address"].includes(key)) {
      const sectionElement = document.getElementById(`${key}-container`);

      sectionElement?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      const formElement = document.querySelector<HTMLInputElement>(`[name="${key}"]`);

      if (formElement) {
        formElement?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        setTimeout(() => formElement.focus({ preventScroll: true }), 0);
      }
    }
  }

  return (
    <Alert variant="danger" className="errors-header shadow">
      <div className="border-end border-white p-3">
        <WarningIcon size="24" className="text-danger flex-shrink-0" />
      </div>

      <div className="px-3 flex-grow-1">
        <div className="d-none d-lg-block">There are some items that require your attention:</div>

        <div className="d-flex align-items-center gap-1">
          {errorMessage && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              href="#"
              className="me-2 text-danger fw-bold text-decoration-underline"
              onClick={(event) => {
                event.preventDefault();
                navigateToError(key);
              }}
            >
              {errorMessage}
            </a>
          )}

          <Row className="gx-0 gap-1 ms-auto ms-lg-0">
            <Col xs={12} lg className="d-flex align-items-center justify-content-center">
              <OverlayTrigger
                placement="top"
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Focus previous error</Tooltip>}
              >
                <ArrowUpIcon
                  size="18"
                  role="button"
                  onClick={() => {
                    const nextErrorIndex = (currentErrorIndex - 1 + keys.length) % keys.length;
                    setCurrentErrorIndex(nextErrorIndex);
                    const key = keys[nextErrorIndex];

                    navigateToError(key);
                  }}
                />
              </OverlayTrigger>
            </Col>
            <Col xs={12} lg className="d-flex align-items-center justify-content-center">
              <OverlayTrigger
                placement="top"
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Focus next error</Tooltip>}
              >
                <ArrowDownIcon
                  size="18"
                  role="button"
                  onClick={() => {
                    const nextErrorIndex = (currentErrorIndex + 1) % keys.length;
                    setCurrentErrorIndex(nextErrorIndex);
                    const key = keys[nextErrorIndex];

                    navigateToError(key);
                  }}
                />
              </OverlayTrigger>
            </Col>
          </Row>
        </div>
      </div>
    </Alert>
  );
}
