import gql from "graphql-tag";

const updateUserName = gql`
  mutation updateUserName($name: String!) {
    updateUserName(name: $name) {
      id
      name
      email
    }
  }
`;

export default updateUserName;
