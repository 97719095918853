import gql from "graphql-tag";

const getJobStatus = gql`
  query getJobStatus($job_id: ID!) {
    getJobStatus(job_id: $job_id) {
      error
      status
    }
  }
`;

export default getJobStatus;
