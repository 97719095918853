import { useEffect } from "react";
import classnames from "classnames";
import {
  Container,
  Card,
  Table,
  Button,
  Row,
  Col,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import {
  CaretDownFill as CaretDownIcon,
  CaretUpFill as CaretUpIcon,
  Trash as TrashIcon,
  ArrowClockwise as ArrowClockwiseIcon,
  ArrowUpCircleFill as BackToTopIcon,
  BoxArrowUpRight as ExternalLinkIcon,
  FileEarmarkPlus as AddCaseIcon,
} from "react-bootstrap-icons";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useLocalObservable, Observer } from "mobx-react-lite";

import { apolloClient } from "../../../configs/apollo-client";
import SearchInput from "../../../components/SearchInput/SearchInput";
import IndeterminateCheckbox from "../../../components/IndeterminateCheckbox";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import Pagination from "../../../components/atoms/Pagination/Pagination";
import PageSize from "../../../components/PageSize";
import ConfirmModal from "../../../components/ConfirmModal";
import getPurchaseDetails from "../../../lib/getPurchaseDetails";
import { somethingWentWrong } from "../../../lib/errors";
import AuthService from "../../../services/AuthService";

import listUserTrackedCases from "./graphql/listUserTrackedCases";
import deleteTrackedCases from "./graphql/deleteTrackedCases";
import AddCaseModal from "./AddCaseModal";

import "./index.scss";

type SortBy = "ein" | "company_name" | "case_title" | "case_number" | "chapter" | "claim_deadline" | "updated";

type SortOrder = "asc" | "desc";

type PageSizeOptions = 50 | 100 | 500;

type CasesResponse = {
  page: {
    total_records_count: number;
    total_pages: number;
  };
  data: {
    id: string;
    company_id: string;
    ein: string;
    company_name: string;
    court_name: string;
    case_title: string;
    case_number: string;
    date_filed: string;
    chapter: string;
    updated: string;
    claim_deadline: string;
    jurisdiction: string;
    purchase_details?: {
      id: string;
      purchase_type: string;
      payment_status: string;
      expire_at: string;
    };
  }[];
};

const headerColumns: { id: SortBy; label: string }[] = [
  { id: "ein", label: "EIN" },
  { id: "company_name", label: "Lead Debtor" },
  { id: "case_title", label: "Case Title" },
  { id: "case_number", label: "Case No" },
  { id: "chapter", label: "Chapter" },
  { id: "claim_deadline", label: "Claim Deadline" },
  { id: "updated", label: "Updated at" },
];

export default function Cases() {
  const history = useHistory();
  const state = useLocalObservable(() => ({
    isLoading: true,
    response: null as CasesResponse | null,
    pageSize: 50 as PageSizeOptions,
    pageNumber: 1,
    sortBy: "updated" as SortBy,
    sortOrder: "desc" as SortOrder,
    filterBy: "",
    selectedRows: [] as string[],
    hasCourtDriveInstalled: false,
    showConfirmModal: false,
    showAddCaseModal: false,
    latestPromise: null as any,
    onMount: function* () {
      // Get user current session
      // Not from context, it may be outdated
      // And check if courtdrive is an installed app
      const installedApps: string[] = yield AuthService.getInstalledApps();
      this.hasCourtDriveInstalled = installedApps.includes("courtdrive");

      yield* this.listTrackedCases();
    },
    listTrackedCases: function* () {
      try {
        this.isLoading = true;

        const {
          data: { listUserTrackedCases: response },
        } = yield apolloClient.query({
          query: listUserTrackedCases,
          variables: {
            input: {
              page_size: this.pageSize,
              page_number: this.pageNumber - 1,
              sort_by: this.sortBy,
              sort_order: this.sortOrder,
              filter_by: this.filterBy,
            },
          },
          fetchPolicy: "network-only",
        });

        this.response = response;
      } catch (error) {
        console.log(error);
        toast.error(somethingWentWrong);
      } finally {
        this.isLoading = false;
        this.latestPromise = null;
      }
    },
    reload() {
      if (this.latestPromise) {
        console.log("Canceling previous promise");
        this.latestPromise.cancel();
      }
      this.latestPromise = this.listTrackedCases();
      this.latestPromise.catch(() => {}); // Ignore cancellation error
    },
    onSortBy(by: SortBy) {
      this.sortBy = by;
      this.sortOrder = this.sortOrder === "desc" ? "asc" : "desc";
      this.pageNumber = 1;
      this.selectedRows = [];

      this.reload();
    },
    onFilterBy(value: string) {
      this.pageNumber = 1;
      this.selectedRows = [];
      this.filterBy = value;

      this.reload();
    },
    refreshView() {
      this.selectedRows = [];

      this.reload();
    },
    toggleSelectAll() {
      if (this.selectedRows.length === 0) {
        this.selectedRows = this.response?.data.map((trackedCase) => trackedCase.id) || [];
      } else {
        this.selectedRows = [];
      }
    },
    selectAll() {
      this.selectedRows = this.response?.data.map((trackedCase) => trackedCase.id) || [];
    },
    selectNone() {
      this.selectedRows = [];
    },
    selectRow(rowId: string) {
      const checked = this.selectedRows.includes(rowId);

      if (!checked) {
        this.selectedRows = [...this.selectedRows, rowId];
      } else {
        this.selectedRows = this.selectedRows.filter((id) => id !== rowId);
      }
    },
    onChangePage(pageNumber: number) {
      this.selectedRows = [];
      this.pageNumber = pageNumber;

      this.reload();
    },
    onChangePageSize(pageSize: PageSizeOptions) {
      // Reset pagination and selected rows
      this.pageNumber = 1;
      this.selectedRows = [];
      this.pageSize = pageSize;

      this.reload();
    },
    onShowConfirmModal() {
      this.showConfirmModal = true;
    },
    onCloseConfirmModal() {
      this.showConfirmModal = false;
    },
    onShowAddCaseModal() {
      this.showAddCaseModal = true;
    },
    onCloseAddCaseModal() {
      this.showAddCaseModal = false;
    },
    onConfirmDeletion: function* () {
      try {
        this.showConfirmModal = false;
        this.isLoading = true;

        yield apolloClient.mutate({
          mutation: deleteTrackedCases,
          variables: {
            ids: this.selectedRows,
          },
        });

        toast.success(
          `${this.selectedRows.length} record${
            this.selectedRows.length > 1 ? "s have been" : " has been"
          } successfully deleted`,
          {
            position: "top-right",
            hideProgressBar: true,
            progress: undefined,
          }
        );

        this.selectedRows = [];
        this.filterBy = "";

        this.reload();
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        toast.error(somethingWentWrong);
      }
    },
    onAddCase(newCaseId: string) {
      this.onCloseAddCaseModal();

      toast.success("Case successfully added!");

      if (this.hasCourtDriveInstalled) {
        // If it has CD app installed, reload list of tracked cases
        // Go to first page, set sort by updated at desc so the new case is on top
        this.pageNumber = 1;
        this.sortBy = "updated";
        this.sortOrder = "desc";

        this.reload();
      } else {
        // Otherwise redirect to the case docket page
        history.push(`/cases/${newCaseId}`);
      }
    },
  }));

  console.log("Cases page render");

  useEffect(() => {
    console.log("Cases page useEffect");
    const promise = state.onMount();

    // @ts-ignore
    promise.catch(() => {}); // Ignore cancellation error

    return () => {
      // @ts-ignore
      promise.cancel();
    };
  });

  return (
    <Container fluid className="px-0">
      <Card>
        <Card.Body className="pb-0">
          <Row>
            <Col className="d-flex align-items-center">
              <Observer>
                {() => (
                  <Card.Title as="h1" className="mb-0">
                    Your Cases{" "}
                    {state.response && `(${state.response.page.total_records_count.toLocaleString("en-US")})`}
                  </Card.Title>
                )}
              </Observer>
            </Col>

            <Col>
              <Button
                type="button"
                variant="primary"
                className="ms-auto d-flex align-items-center gap-1"
                size="sm"
                onClick={state.onShowAddCaseModal}
              >
                <AddCaseIcon size="16" />
                Add Case
              </Button>
            </Col>
          </Row>
        </Card.Body>

        <Table hover className="tracked-cases-table" size="sm">
          <thead className="table-light z-2 shadow-lg">
            <tr>
              <th colSpan={headerColumns.length + 2} className="bg-white fw-normal py-3">
                <Row className="flex-grow-1">
                  <Col
                    xs={{ order: 2, span: 12 }}
                    sm={{ order: 1, span: "auto" }}
                    className="me-auto d-flex flex-wrap gap-2"
                  >
                    <OverlayTrigger placement="top" trigger={["hover", "focus"]} overlay={<Tooltip>Refresh</Tooltip>}>
                      <Button type="button" variant="outline-primary" onClick={state.refreshView} size="sm">
                        <ArrowClockwiseIcon size="16" />
                      </Button>
                    </OverlayTrigger>

                    <Observer>
                      {() => {
                        if (state.selectedRows.length > 0)
                          return (
                            <OverlayTrigger
                              placement="top"
                              trigger={["hover", "focus"]}
                              overlay={<Tooltip>Delete</Tooltip>}
                            >
                              <Button
                                type="button"
                                variant="outline-danger"
                                onClick={state.onShowConfirmModal}
                                size="sm"
                              >
                                <TrashIcon size="16" />
                              </Button>
                            </OverlayTrigger>
                          );

                        return null;
                      }}
                    </Observer>

                    <Observer>
                      {() => {
                        if (state.isLoading)
                          return (
                            <div className="d-flex align-items-center gap-2">
                              <Spinner animation="border" size="sm" variant="primary" />
                              Loading...
                            </div>
                          );

                        return null;
                      }}
                    </Observer>
                  </Col>

                  <Col xs={{ order: 1, span: 12 }} sm={{ order: 2, span: "auto" }} className="mb-3 mb-sm-0">
                    <SearchInput onSubmit={(value) => state.onFilterBy(value)} />
                  </Col>
                </Row>
              </th>
            </tr>

            <tr>
              <th className="select_all min-content-width">
                <Dropdown as={ButtonGroup}>
                  <Button variant="light" onClick={() => state.toggleSelectAll()}>
                    <Observer>
                      {() => (
                        <IndeterminateCheckbox
                          indeterminate={
                            state.selectedRows.length > 0 && state.selectedRows.length !== state.response?.data.length
                          }
                          readOnly
                          checked={
                            state.selectedRows.length === state.response?.data.length && state.response?.data.length > 0
                          }
                          style={{ pointerEvents: "none" }}
                        />
                      )}
                    </Observer>
                  </Button>

                  <Dropdown.Toggle split variant="light" />

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => state.selectAll()}>All</Dropdown.Item>
                    <Dropdown.Item onClick={() => state.selectNone()}>None</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </th>

              {/* Purchase details */}
              <th></th>

              <Observer>
                {() => (
                  <>
                    {headerColumns.map((column) => (
                      <th key={column.id}>
                        <Button
                          variant="link"
                          type="button"
                          className="d-flex align-items-center gap-1 p-0 text-nowrap"
                          onClick={() => state.onSortBy(column.id)}
                          title={`Sort by ${column.label}`}
                        >
                          {column.label}
                          {state.sortBy === column.id ? (
                            state.sortOrder === "desc" ? (
                              <CaretDownIcon size="14" />
                            ) : (
                              <CaretUpIcon size="14" />
                            )
                          ) : null}
                        </Button>
                      </th>
                    ))}
                  </>
                )}
              </Observer>
            </tr>
          </thead>

          <Observer>
            {() => (
              <tbody>
                {state.response &&
                  state.response.data.length > 0 &&
                  state.response.data.map((row) => (
                    <tr key={row.id} className={classnames({ "table-primary": state.selectedRows.includes(row.id) })}>
                      <CustomCheckbox
                        className="selectrecord"
                        as="td"
                        onClick={() => state.selectRow(row.id)}
                        checked={state.selectedRows.includes(row.id)}
                      />
                      <td>
                        {row.purchase_details && (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${row.id}`}>
                                {getPurchaseDetails({
                                  type: row.purchase_details.purchase_type,
                                  expireAt: row.purchase_details.expire_at,
                                })}
                              </Tooltip>
                            }
                          >
                            <span className="text-success">
                              <span className="d-none d-sm-inline">$</span>
                              <span className="d-inline d-sm-none">
                                {getPurchaseDetails({
                                  type: row.purchase_details.purchase_type,
                                  expireAt: row.purchase_details.expire_at,
                                })}
                              </span>
                            </span>
                          </OverlayTrigger>
                        )}
                      </td>
                      <td>{row.ein}</td>
                      <td>{row.company_name}</td>
                      <td>{row.case_title}</td>
                      <td className="text-nowrap">
                        {state.hasCourtDriveInstalled ? (
                          <OverlayTrigger
                            placement="top"
                            trigger={["hover", "focus"]}
                            overlay={<Tooltip>Open case on CourtDrive</Tooltip>}
                          >
                            <a
                              href={`https://v2.courtdrive.com/cases/pacer/${row.court_name}/${row.case_number}/dockets`}
                              target="_blank"
                              rel="noreferrer"
                              className="d-flex align-items-center gap-2"
                            >
                              <span style={{ width: 100 }}>{row.case_number}</span>
                              <ExternalLinkIcon size="14" />
                            </a>
                          </OverlayTrigger>
                        ) : (
                          <Link to={`/cases/${row.id}`}>{row.case_number}</Link>
                        )}
                      </td>
                      <td>{row.chapter}</td>
                      <td>{row.claim_deadline}</td>
                      <td>
                        <span className="d-flex flex-row flex-wrap gap-2">
                          <span>{new Date(row.updated).toLocaleDateString("en-US")}</span>
                          <span>{new Date(row.updated).toLocaleTimeString("en-US")}</span>
                        </span>
                      </td>
                    </tr>
                  ))}
                {state.response && state.response.data.length === 0 && !state.isLoading && (
                  <tr>
                    <td colSpan={headerColumns.length + 2}>
                      {state.filterBy === ""
                        ? "Looks like you have no cases yet, you can add one by searching above."
                        : `No results found for "${state.filterBy}". Try to refine your search.`}
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </Observer>
        </Table>

        <Observer>
          {() => {
            if (state.response !== null && state.response.page.total_records_count > 0)
              return (
                <Card.Body className="border-top sticky-bottom bg-white">
                  <Row className="gx-3">
                    <Col xs="auto">
                      <Pagination
                        currentPage={state.pageNumber}
                        totalPages={state.response.page.total_pages}
                        disabled={false}
                        onClick={state.onChangePage}
                      />
                    </Col>
                    <Col xs="auto" className="me-auto">
                      <PageSize
                        options={[50, 100, 500]}
                        value={state.pageSize}
                        onChange={(value) => {
                          state.onChangePageSize(value as PageSizeOptions);
                        }}
                      />
                    </Col>
                    <Col xs="auto" className="d-flex align-items-center">
                      <Button
                        variant="link"
                        className="p-0 d-flex align-items-center gap-2"
                        onClick={() => {
                          (document.querySelector("main.app-main") as HTMLElement).scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Back to Top
                        <BackToTopIcon size="18" />
                      </Button>
                    </Col>
                    <Col xs={12} sm="auto" className="d-flex align-items-center mt-2 mt-md-0">
                      Results: {(state.pageSize * (state.pageNumber - 1) + 1).toLocaleString("en-US")} -{" "}
                      {(state.response.data.length + state.pageSize * (state.pageNumber - 1)).toLocaleString("en-US")}
                    </Col>
                  </Row>
                </Card.Body>
              );

            return null;
          }}
        </Observer>
      </Card>

      <Observer>
        {() => (
          <ConfirmModal
            show={state.showConfirmModal}
            onClose={state.onCloseConfirmModal}
            title="Are you sure?"
            onConfirm={state.onConfirmDeletion}
          >
            Are you sure you want to delete {state.selectedRows.length} case
            {state.selectedRows.length > 1 ? "s" : ""}?
          </ConfirmModal>
        )}
      </Observer>

      <Observer>
        {() => (
          <AddCaseModal show={state.showAddCaseModal} onClose={state.onCloseAddCaseModal} onAddCase={state.onAddCase} />
        )}
      </Observer>
    </Container>
  );
}
