import { Row, Col } from "react-bootstrap";

export default function Footer() {
  return (
    <footer className="app-footer footer mt-auto">
      <Row>
        <Col xs="auto" className="me-auto">
          <ul className="list-inline mb-0">
            <li className="list-inline-item">
              <a
                href="https://www.bkalerts.com/legal/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.bkalerts.com/legal/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </li>
          </ul>
        </Col>
        <Col xs="auto">
          <p className="mb-0">&copy; {new Date().getFullYear()} - BKalerts</p>
        </Col>
      </Row>
    </footer>
  );
}
