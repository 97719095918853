import { useState, useEffect, useCallback } from "react";
import { Modal, Form, Spinner, InputGroup, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { X as XIcon } from "react-bootstrap-icons";
import { Subscription } from "zen-observable-ts";

import { apolloClient } from "../../configs/apollo-client";
import { somethingWentWrong } from "../../lib/errors";

import listUserFeatures from "./graphql/listUserFeatures";
import addUserFeature from "./graphql/addUserFeature";
import removeUserFeature from "./graphql/removeUserFeature";

function useRefetch() {
  const [shouldRefetch, setShouldRefetch] = useState(0);
  const refetch = useCallback(() => setShouldRefetch((value) => value + 1), []);

  return [shouldRefetch, refetch] as const;
}

type UserFeature = {
  id: string;
  enabled_feature: string;
  created_at: string;
};

type UserFeaturesResponse = UserFeature[];

interface Props {
  show: boolean;
  userId: string;
  onClose: () => void;
}
export default function UserFeaturesModal({ show, userId, onClose }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<UserFeaturesResponse | undefined>(undefined);
  const [selectedFeature, setSelectedFeature] = useState("");
  const [shouldRefetch, refetch] = useRefetch();

  useEffect(() => {
    let sub: Subscription | null = null;

    if (show) {
      setIsLoading(true);

      const watchedQuery = apolloClient.watchQuery({
        query: listUserFeatures,
        variables: {
          user_id: userId,
        },
        fetchPolicy: "network-only",
      });

      sub = watchedQuery.subscribe({
        next(response) {
          if (response.data) {
            setResponse(response.data.listUserFeatures as UserFeaturesResponse);
            setIsLoading(false);
          }
        },
        error(err) {
          console.log("[watchQuery] error", err);
          toast.error(somethingWentWrong);
          setIsLoading(false);
        },
      });
    }

    setSelectedFeature("");

    return () => {
      console.log("[watchQuery] Clean up");
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [show, userId, shouldRefetch]);

  const onAddFeature = useCallback(async () => {
    try {
      setIsLoading(true);

      await apolloClient.mutate({
        mutation: addUserFeature,
        variables: {
          user_id: userId,
          feature_id: selectedFeature,
        },
        fetchPolicy: "no-cache",
      });

      refetch();
    } catch (error) {
      console.log(error);
      toast.error(somethingWentWrong);
      setIsLoading(false);
    }
  }, [refetch, selectedFeature, userId]);

  const onRemoveFeature = useCallback(
    async (featureId: string) => {
      try {
        setIsLoading(true);

        await apolloClient.mutate({
          mutation: removeUserFeature,
          variables: {
            user_id: userId,
            feature_id: featureId,
          },
          fetchPolicy: "no-cache",
        });

        refetch();
      } catch (error) {
        console.log(error);
        toast.error(somethingWentWrong);
        setIsLoading(false);
      }
    },
    [userId, refetch]
  );

  const availableFeatures = (function () {
    const list = [
      { id: "empty", value: "", label: "Choose..." },
      { id: "free_ePOC", value: "free_ePOC", label: "Free ePOC" },
    ];
    if (response && response.length) {
      const enabledFeatures = response.map((userFeature) => userFeature.enabled_feature);
      return list.filter((item) => !enabledFeatures.includes(item.id));
    }

    return list;
  })();

  return (
    <Modal show={show} onHide={onClose} {...(isLoading ? { backdrop: "static", keyboard: false } : {})}>
      {show ? (
        <>
          <Modal.Header closeButton={!isLoading}>
            <Modal.Title>Enable/Disable features</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="enabled-features" className="mb-3">
              <Form.Label>Enabled features</Form.Label>
              {isLoading ? (
                <div>
                  <Spinner size="sm" variant="primary" animation="border" />
                </div>
              ) : response && response.length > 0 ? (
                <div className="d-flex gap-2">
                  {response.map((userFeature) => (
                    <div
                      key={userFeature.id}
                      className="border border-success rounded-pill py-1 ps-3 pe-0 fs-5 d-inline-flex align-items-center gap-1 text-success"
                    >
                      {userFeature.enabled_feature}
                      <Button
                        variant="link"
                        className="p-0 ms-auto me-1 text-success"
                        onClick={() => onRemoveFeature(userFeature.id)}
                      >
                        <XIcon size={24} />
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <div>No features added yet</div>
              )}
            </Form.Group>

            <Form.Group controlId="features-list">
              <Form.Label>Available features</Form.Label>
              <InputGroup>
                <Form.Select
                  value={selectedFeature}
                  onChange={(ev) => setSelectedFeature(ev.target.value)}
                  disabled={isLoading}
                >
                  {availableFeatures.map(({ id, label, value }) => (
                    <option key={id} value={value}>
                      {label}
                    </option>
                  ))}
                </Form.Select>
                <Button variant="success" disabled={selectedFeature === "" || isLoading} onClick={onAddFeature}>
                  Add
                </Button>
              </InputGroup>
            </Form.Group>
          </Modal.Body>
        </>
      ) : null}
    </Modal>
  );
}
