import gql from "graphql-tag";

const listImportJobs = gql`
  query {
    listImportJobs {
      id
      filename
      csv_file_download_link
      records_count
      invalid_records_count
      csv_errors_file_download_link
      job_status
      created_at
      updated_at
    }
  }
`;

export default listImportJobs;
