import gql from "graphql-tag";

const savePACERCredentials = gql`
  mutation savePACERCredentials($username: String!, $password: String!) {
    savePACERCredentials(username: $username, password: $password) {
      error
      error_code
      username
    }
  }
`;

export default savePACERCredentials;
