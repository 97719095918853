import { useEffect } from "react";
import classNames from "classnames";
import { usePlacesWidget } from "react-google-autocomplete";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Creditor, Address } from "../interfaces";
import states from "../lib/states.json";

interface Props {
  selectedCreditor?: Creditor;
  onChange: (newCreditor: Creditor) => void;
  onSubmit: (newCreditor: Creditor) => void;
}

interface GoogleMapsAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

type FormValues = {
  name: string;
  address: Address;
  phone: string;
  email: string;
};

const schema = yup.object({
  name: yup.string().required("Insert the Creditor name"),
  address: yup.object({
    address1: yup.string().required("Insert the address"),
    city: yup.string().required("Insert the City"),
    state: yup.string().required("Choose a State"),
    zip: yup.string().required("Insert the ZIP code").matches(/\d{5}/, "Insert a valid ZIP code"),
    zip4: yup.string().matches(/\d{4}/, {
      message: "Insert a valid ZIP code",
      excludeEmptyString: true,
    }),
  }),
  email: yup.string().nullable().email("Insert a valid email address"),
});

export default function NewCreditor({ selectedCreditor, onChange, onSubmit }: Props) {
  const {
    watch,
    getValues,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      name: "",
      address: {
        address1: "",
        address2: "",
        address3: "",
        city: "",
        state: "",
        zip: "",
        zip4: "",
      },
      phone: "",
      email: "",
      ...(selectedCreditor && {
        ...selectedCreditor,
        address: selectedCreditor?.address as Address,
      }),
    },
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      onChange({
        id: null,
        ...getValues(),
      });
    });
    return () => subscription.unsubscribe();
  }, [getValues, onChange, watch]);

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      const addressComponents: GoogleMapsAddressComponent[] = place.address_components;
      console.log("addressComponents", addressComponents);
      if (!addressComponents) {
        return;
      }

      // Reference
      // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
      const address1 = [
        addressComponents.find(({ types }) => types.includes("street_number"))?.long_name || "",
        addressComponents.find(({ types }) => types.includes("route"))?.long_name || "",
      ]
        .filter(Boolean)
        .join(" ");
      const city =
        addressComponents.find(({ types }) => types.includes("locality"))?.long_name ||
        addressComponents.find(({ types }) => types.includes("neighborhood") && types.includes("political"))
          ?.long_name ||
        "";
      const state =
        addressComponents.find(({ types }) => types.includes("administrative_area_level_1"))?.short_name || "";
      const zip = addressComponents.find(({ types }) => types.includes("postal_code"))?.long_name || "";
      const zip4 = addressComponents.find(({ types }) => types.includes("postal_code_suffix"))?.long_name || "";

      // according to docs more verbose but more performant than overwriting entire object field
      setValue("address.address1", address1, { shouldValidate: true });
      setValue("address.city", city, { shouldValidate: true });
      setValue("address.state", state, { shouldValidate: true });
      setValue("address.zip", zip, { shouldValidate: true });
      setValue("address.zip4", zip4, { shouldValidate: true });
    },
    options: {
      componentRestrictions: { country: "us" },
      types: ["address"],
    },
  });

  const submitStep = (data: Partial<Creditor>) => {
    onSubmit({
      id: null,
      ...data,
    } as Creditor);
  };

  return (
    <Form onSubmit={handleSubmit(submitStep)}>
      <Card>
        <Card.Body>
          <Form.Group controlId="creditor-name" className="mb-3">
            <Form.Label>
              Name of Creditor <span className="text-danger">(required)</span>
            </Form.Label>

            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="text"
                  placeholder="John Doe"
                  isInvalid={!!errors.name?.message}
                  autoFocus
                  {...field}
                />
              )}
            />

            {!errors.name?.message && <Form.Text>The person or entity to be paid for this claim</Form.Text>}

            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="creditor-address-1">
              Address where notices should be sent <span className="text-danger">(required)</span>
            </Form.Label>

            <Controller
              name="address.address1"
              control={control}
              render={({ field }) => (
                <Form.Control
                  id="creditor-address-1"
                  type="text"
                  placeholder="123 Main St"
                  className="mb-1 mw-100"
                  isInvalid={!!errors.address?.address1?.message}
                  {...field}
                  // @ts-ignore
                  ref={ref}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                    }
                  }}
                  htmlSize={40}
                />
              )}
            />

            <Controller
              name="address.address2"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="text"
                  className="mb-1 mw-100"
                  placeholder="Apartment, studio, or floor"
                  htmlSize={40}
                  {...field}
                />
              )}
            />

            <Controller
              name="address.address3"
              control={control}
              render={({ field }) => (
                <Form.Control type="text" placeholder="c/o" htmlSize={40} className="mw-100" {...field} />
              )}
            />

            {!errors.address?.address1?.message && <Form.Text>Do not add the creditor's name in the address</Form.Text>}

            <Form.Control.Feedback type="invalid">{errors.address?.address1?.message}</Form.Control.Feedback>
          </Form.Group>

          <Row className="mb-3 gx-0 gap-3">
            <Form.Group as={Col} sm="auto" controlId="creditor-address-city">
              <Form.Label>City</Form.Label>

              <Controller
                name="address.city"
                control={control}
                render={({ field }) => (
                  <Form.Control maxLength={30} isInvalid={!!errors.address?.city?.message} {...field} />
                )}
              />

              <Form.Control.Feedback type="invalid">{errors.address?.city?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm="auto" controlId="creditor-address-state">
              <Form.Label>State</Form.Label>

              <Controller
                name="address.state"
                control={control}
                render={({ field }) => (
                  <Form.Select isInvalid={!!errors.address?.state?.message} {...field}>
                    <option value="">Choose...</option>
                    {states.map(function ({ name, abbreviation }) {
                      return (
                        <option key={abbreviation} value={abbreviation}>
                          {name} ({abbreviation})
                        </option>
                      );
                    })}
                  </Form.Select>
                )}
              />

              <Form.Control.Feedback type="invalid">{errors.address?.state?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm="auto">
              <Form.Label htmlFor="creditor-address-zip-5">Zip</Form.Label>

              <div
                className={classNames("d-flex gap-1 align-items-center", {
                  "is-invalid": !!errors.address?.zip?.message || !!errors.address?.zip4?.message,
                })}
              >
                <Controller
                  name="address.zip"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      placeholder="12345"
                      id="creditor-address-zip-5"
                      maxLength={5}
                      inputMode="numeric"
                      isInvalid={!!errors.address?.zip?.message}
                      htmlSize={5}
                      {...field}
                    />
                  )}
                />
                -
                <Controller
                  name="address.zip4"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      placeholder="1234"
                      maxLength={4}
                      inputMode="numeric"
                      isInvalid={!!errors.address?.zip4?.message}
                      htmlSize={4}
                      {...field}
                    />
                  )}
                />
              </div>

              <Form.Control.Feedback type="invalid">
                {errors.address?.zip?.message || errors.address?.zip4?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Form.Group controlId="creditor-phone-number" className="mb-3">
            <Form.Label>Telephone number</Form.Label>

            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <Form.Control type="text" placeholder="818-252-9950" maxLength={20} inputMode="tel" {...field} />
              )}
            />
          </Form.Group>

          <Form.Group controlId="creditor-email">
            <Form.Label>Email</Form.Label>

            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="email"
                  placeholder="johndoe@company.com"
                  isInvalid={!!errors.email?.message}
                  maxLength={40}
                  inputMode="email"
                  {...field}
                />
              )}
            />

            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
          </Form.Group>
        </Card.Body>

        <Card.Body className="border-top bg-white sticky-bottom">
          <Button type="submit" variant="primary" size="lg">
            Continue
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
}
