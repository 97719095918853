import { Alert } from "react-bootstrap";
import { InfoCircleFill as InfoCircleFillIcon } from "react-bootstrap-icons";

export default function ImportHelp() {
  return (
    <Alert variant="primary">
      <div className="alert-message d-flex align-items-start gap-2">
        <InfoCircleFillIcon size={20} className="text-primary flex-shrink-0" />
        <div className="d-flex flex-column align-items-start gap-1">
          <span>Every record should contain at least an SSN or a TAXID.</span>
          <span>
            Partial SSNs are allowed, e.g. <code>xxx-xx-1234</code>.
          </span>
          <span>The more fields get filled in each row, the greater the chance of a match.</span>
        </div>
      </div>
    </Alert>
  );
}
