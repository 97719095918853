import { useEffect } from "react";
import classnames from "classnames";
import { UseFormRegister, UseFormWatch, UseFormSetValue, UseFormSetFocus, FieldErrors } from "react-hook-form";
import { Form, InputGroup, Row, Col } from "react-bootstrap";

import { FormValues } from "./interfaces";
import AmountInput from "./AmountInput";

interface Props {
  register: UseFormRegister<FormValues>;
  watch: UseFormWatch<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  setFocus: UseFormSetFocus<FormValues>;
  errors: FieldErrors<FormValues>;
}
export default function Priorities({ register, watch, setValue, setFocus, errors }: Props) {
  const priorityEntitled = watch("priority_entitled");

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (!name?.startsWith("priorities")) {
        return;
      }

      if (name === "priorities.code" && type === "change") {
        if (value.priorities?.code === "507_other") {
          setTimeout(() => setFocus("priorities.code_507_other_sub"));
        } else {
          setValue("priorities.code_507_other_sub", "", {
            shouldValidate: true,
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch, setFocus]);

  const options = [
    {
      label:
        "Domestic support obligations (including alimony and child support) under 11 U.S.C. § 507(a)(1)(A) or (a)(1)(B).",
      value: "507_1" as const,
    },
    {
      label:
        "Up to $3,350 of deposits toward purchase, lease, or rental of property or services for personal, family, or household use. 11 U.S.C. § 507(a)(7).",
      value: "507_7" as const,
    },
    {
      label:
        "Wages, salaries, or commissions (up to $15,150) earned within 180 days before the bankruptcy petition is filed or the debtor's business ends, whichever is earlier. 11 U.S.C. § 507(a)(4).",
      value: "507_4" as const,
    },
    {
      label: "Taxes or penalties owed to governmental units. 11 U.S.C. § 507(a)(8).",
      value: "507_8" as const,
    },
    {
      label: "Contributions to an employee benefit plan. 11 U.S.C. § 507(a)(5).",
      value: "507_5" as const,
    },
    { label: "Other.", value: "507_other" as const },
  ];

  if (!priorityEntitled || priorityEntitled === "no") {
    return null;
  }

  return (
    <div id="priorities-container">
      <Form.Label>
        Choose one <span className="text-danger">(required)</span>
      </Form.Label>

      <div className="mb-3 has-validation">
        <div
          className={classnames("d-flex flex-column gap-2", {
            "is-invalid": !!errors.priorities?.code?.message || !!errors.priorities?.code_507_other_sub?.message,
          })}
        >
          {options.map((option) => {
            const label = (
              <Form.Label
                key={option.value}
                htmlFor={`priorities.code.${option.value}`}
                className={classnames("d-flex align-items-center gap-2 border rounded p-2 bg-white shadow mb-0", {
                  "border-primary text-primary": watch("priorities.code") === option.value,
                })}
                role="button"
              >
                <Form.Check
                  type="radio"
                  value={option.value}
                  id={`priorities.code.${option.value}`}
                  {...register("priorities.code")}
                  className="pe-none"
                />
                {option.label}
              </Form.Label>
            );

            if (option.value === "507_other")
              return (
                <Row key={option.value} className="gx-0 gap-2">
                  <Col sm="auto">{label}</Col>
                  <Col className="d-flex align-items-center flex-wrap gap-2">
                    <Form.Label htmlFor="priorities.code_507_other_sub" className="mb-0">
                      Specify subsection of 11 U.S.C. § 507(a) that applies
                    </Form.Label>

                    <Form.Control
                      type="text"
                      id="priorities.code_507_other_sub"
                      disabled={watch("priorities.code") !== "507_other"}
                      isInvalid={!!errors.priorities?.code_507_other_sub?.message}
                      {...register("priorities.code_507_other_sub")}
                    />
                  </Col>
                </Row>
              );

            return label;
          })}
        </div>

        <Form.Control.Feedback type="invalid">
          {errors.priorities?.code?.message || errors.priorities?.code_507_other_sub?.message}
        </Form.Control.Feedback>
      </div>

      <Form.Label htmlFor="priorities.amount">
        Amount entitled to priority <span className="text-danger">(required)</span>
      </Form.Label>

      <InputGroup hasValidation>
        <InputGroup.Text>$</InputGroup.Text>

        <AmountInput
          isInvalid={!!errors.priorities?.amount?.message}
          className="flex-grow-0 w-auto"
          register={register}
          id="priorities.amount"
          name="priorities.amount"
          setValue={setValue}
          inputMode="numeric"
        />

        <Form.Control.Feedback type="invalid">{errors.priorities?.amount?.message}</Form.Control.Feedback>
      </InputGroup>
    </div>
  );
}
