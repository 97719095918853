import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import PasswordStrengthInfo from "../PasswordStrengthInfo";

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, "Invalid password"),
  confirmPassword: yup
    .string()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.newPassword === value;
    }),
});

export default function NewPasswordRequiredForm({ isLoading, onSubmit }) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const newPassword = watch("newPassword", "");

  return (
    <>
      <h3>Please set a new password to access the system.</h3>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="newPassword" className="mb-3">
          <Form.Label>New password</Form.Label>
          <Form.Control
            {...register("newPassword")}
            type="password"
            isInvalid={errors.newPassword}
            disabled={isLoading}
            autoFocus
          />

          <PasswordStrengthInfo password={newPassword} />
        </Form.Group>

        <Form.Group controlId="confirmPassword" className="mb-3">
          <Form.Label>Confirm password</Form.Label>
          <Form.Control
            {...register("confirmPassword")}
            type="password"
            isInvalid={errors.confirmPassword}
            disabled={isLoading}
          />
          <Form.Control.Feedback type="invalid">
            {errors.confirmPassword && errors.confirmPassword.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Button type="submit" disabled={isLoading}>
          {isLoading ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            "Update Password and Sign in"
          )}
        </Button>
      </Form>
    </>
  );
}
