import gql from "graphql-tag";

const resetUserPassword = gql`
  mutation resetUserPassword($input: AdminResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      success
      password
    }
  }
`;

export default resetUserPassword;
