import gql from "graphql-tag";

const getFilingProfiles = gql`
  query {
    getFilingProfiles {
      id
      name
      raw_form_data
      created_at
    }
  }
`;

export default getFilingProfiles;
