// Authentication
export const SIGNED_IN = "SIGNED_IN";
export const SIGNED_OUT = "SIGNED_OUT";
export const CHANGE_NAME = "CHANGE_NAME";
export const SET_REDIRECT_AFTER_LOGIN = "SET_REDIRECT_AFTER_LOGIN";

// impersonation
export const CHECK_IMPERSONATION = "CHECK_IMPERSONATION";
export const SET_IMPERSONATED_USER = "SET_IMPERSONATED_USER";
export const END_IMPERSONATING = "END_IMPERSONATING";
export const CHANGE_IMPERSONATED_USER_NAME = "CHANGE_IMPERSONATED_USER_NAME";
export const RESET_SUPER_ADMIN_DATA = "RESET_SUPER_ADMIN_DATA";
