import gql from "graphql-tag";

const listTrackedDebtors = gql`
  query listTrackedDebtors($input: TrackedDebtorsInput!) {
    listTrackedDebtors(input: $input) {
      tracked_debtors {
        id
        ssn
        taxid
        full_name
        first_name
        middle_name
        last_name
        generation
        full_address
        address_line_1
        address_line_2
        address_line_3
        city
        state
        zip_code
        country
        phone
        last_check
        created_at
        updated_at
      }

      pagination {
        partial_records_count
        total_records_count
        total_pages
      }
    }
  }
`;

export default listTrackedDebtors;
