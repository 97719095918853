const csvHeader = [
  "SSN",
  "TAXID",
  "First name",
  "Middle name",
  "Last name",
  "Generation",
  "Address line 1",
  "Address line 2",
  "Address line 3",
  "City",
  "State",
  "Zip code",
  "Country",
  "Phone",
];

export default csvHeader;
