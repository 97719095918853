import gql from "graphql-tag";

const previewDocketDocument = gql`
  query previewDocketDocument($input: DocketDocumentActionInput!) {
    previewDocketDocument(input: $input) {
      preview_url
    }
  }
`;

export default previewDocketDocument;
