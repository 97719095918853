import { Modal, Container, Row, Col, Form } from "react-bootstrap";

import { FormState } from "./interfaces";
import courts from "./steps/lib/courts.json";

import draftImage from "./draft.png";

interface Props {
  formState: Partial<FormState>;
  show: boolean;
  onClose: () => void;
}
export default function PreviewModal({ formState, show, onClose }: Props) {
  return (
    <Modal show={show} onHide={onClose} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>Preview</Modal.Title>
      </Modal.Header>

      <Modal.Body
        className="bg-body"
        style={{
          backgroundImage: `url(${draftImage})`,
          backgroundRepeat: "repeat",
        }}
      >
        <Preview formState={formState} />
      </Modal.Body>
    </Modal>
  );
}

function Preview({ formState }: { formState: Partial<FormState> }) {
  return (
    <Container className="bg-white p-4">
      <Row>
        <Col sm={8}>
          <div className="border border-dark mb-5">
            <header className="bg-dark text-white p-2">Fill in this information to identify the case:</header>
            <div className="p-2 text-dark">
              <div className="d-flex gap-3">
                <span>Debtor 1:</span>
                <span className="fw-bold">{formState.selectedCase?.case_title}</span>
              </div>

              <div className="d-flex gap-3">
                <span>
                  Debtor 2:
                  <br />
                  (Spouse, if filing)
                </span>
                <span></span>
              </div>
              <div className="d-flex gap-3">
                <span>United States Bankruptcy Court for the:</span>
                <span className="fw-bold">
                  {courts.find(({ court_code }) => court_code === formState.selectedCase?.court_code)?.court_name}
                </span>
              </div>
              <div className="d-flex gap-3">
                <span>Case number:</span>
                <span className="fw-bold">{formState.selectedCase?.case_no}</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <div className="text-dark mb-3">
        <h3 className="text-decoration-underline">Form 410</h3>
        <h1 className="border-bottom border-dark border-4">Proof of Claim</h1>
        <p className="fw-bold mb-2">
          Read the instructions before filling out this form. This form is for making a claim for payment in a
          bankruptcy case. Do not use this form to make a request for payment of an administrative expense. Make such a
          request according to 11 U.S.C. § 503.
        </p>
        <p className="mb-2">
          <span className="fw-bold">Filers must leave out or redact</span> information that is entitled to privacy on
          this form or on any attached documents. Attach redacted copies of any documents that support the claim, such
          as promissory notes, purchase orders, invoices, itemized statements of running accounts, contracts, judgments,
          mortgages, and security agreements. <span className="fw-bold">Do not send original documents;</span> they may
          be destroyed after scanning. If the documents are not available, explain in an attachment
        </p>
        <p className="mb-2">
          A person who files a fraudulent claim could be fined up to $500,000, imprisoned for up to 5 years, or both. 18
          U.S.C. §§ 152, 157, and 3571.
        </p>
        <p className="fw-bold mb-2">
          Fill in all the information about the claim as of the date the case was filed. That date is on the notice of
          bankruptcy (Form 309) that you received.
        </p>
      </div>

      <div className="text-dark mb-5">
        <header className="d-flex border-bottom border-dark border-3">
          <span className="bg-dark p-2 text-white fw-bold">Part 1:</span>
          <span className="fw-bold p-2">Identify the Claim</span>
        </header>

        <div className="border-start border-end border-bottom border-dark px-2 py-2">
          <div className="d-grid gap-2" style={{ gridTemplateColumns: "1fr 4fr" }}>
            <span className="fw-bold">1. Who is the current creditor?</span>
            <div>
              <div className="border-bottom border-dark">{formState.selectedCreditor?.name}</div>
              <div className="mb-3">Name of the current creditor (the person or entity to be paid for this claim)</div>
              <div className="d-flex gap-2">
                <span>Other names the creditor used with the debtor</span>
                <span className="border-bottom border-dark flex-grow-1">{formState.documentation?.creditor_alias}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="border-start border-end border-bottom border-dark px-2 py-2">
          <div className="d-grid gap-2" style={{ gridTemplateColumns: "1fr 4fr" }}>
            <span className="fw-bold">2. Has this claim been acquired from someone else?</span>
            <div className="d-flex gap-4">
              <div className="d-flex flex-column justify-content-between">
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.acquired_from === "no"}
                  readOnly
                  label="No"
                  className="mb-0"
                />
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.acquired_from === "yes"}
                  readOnly
                  label="Yes"
                  className="mb-0"
                />
              </div>
              <div className="d-flex gap-2 align-self-end flex-grow-1">
                <span>From whom?</span>
                <span className="border-bottom border-dark flex-grow-1">
                  {formState.documentation?.acquired_from_whom}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="border-start border-end border-bottom border-dark px-2 py-2">
          <div className="d-grid gap-2" style={{ gridTemplateColumns: "1fr 4fr" }}>
            <div>
              <div className="fw-bold mb-3">3. Where should notices and payments to the creditor be sent?</div>
              <span>Federal Rule of Bankruptcy Procedure (FRBP) 2002(g)</span>
            </div>
            <Row className="mb-3">
              <Col sm={6}>
                <div className="bg-light text-dark fw-bold p-2 mb-3">Where should notices to the creditor be sent?</div>

                <div className="border-bottom border-dark">{formState.selectedCreditor?.name}</div>
                <div className="mb-3">Name</div>

                {Array.isArray(formState.selectedCreditor?.address) ? (
                  <div className="mb-3">
                    <div className="border-bottom border-dark">{formState.selectedCreditor?.address.join(" ")}</div>
                    <div>Address</div>
                  </div>
                ) : (
                  <div className="mb-3">
                    <div className="border-bottom border-dark">
                      {[
                        formState.selectedCreditor?.address.address1,
                        formState.selectedCreditor?.address.address2,
                        formState.selectedCreditor?.address.address3,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </div>
                    <div className="d-flex gap-4 mb-3">
                      <span>Number</span>
                      <span>Street</span>
                    </div>
                    <div className="d-flex gap-2 flex-grow-1">
                      <div className="flex-grow-1">
                        <div className="border-bottom border-dark">{formState.selectedCreditor?.address.city}</div>
                        <span>City</span>
                      </div>
                      <div className="flex-grow-1">
                        <div className="border-bottom border-dark">{formState.selectedCreditor?.address.state}</div>
                        <span>State</span>
                      </div>
                      <div>
                        <div className="border-bottom border-dark">
                          {[formState.selectedCreditor?.address.zip, formState.selectedCreditor?.address.zip4]
                            .filter(Boolean)
                            .join(" - ")}
                        </div>
                        <span>ZIP Code</span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex gap-2 flex-grow-1 mb-3">
                  <span>Contact phone</span>
                  <div className="border-bottom border-dark flex-grow-1">
                    {formState.selectedCreditor?.id
                      ? formState.documentation?.creditor_phone
                      : formState.selectedCreditor?.phone}
                  </div>
                </div>

                <div className="d-flex gap-2 flex-grow-1">
                  <span>Contact email</span>
                  <div className="border-bottom border-dark flex-grow-1">
                    {formState.selectedCreditor?.id
                      ? formState.documentation?.creditor_email
                      : formState.selectedCreditor?.email}
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="bg-light text-dark fw-bold p-2 mb-3">
                  Where should payments to the creditor be sent?
                </div>

                <div className="border-bottom border-dark">{formState.documentation?.payment_address?.name}</div>
                <div className="mb-3">Name</div>

                <div className="mb-3">
                  <div className="border-bottom border-dark">
                    {[
                      formState.documentation?.payment_address?.address.address1,
                      formState.documentation?.payment_address?.address.address2,
                      formState.documentation?.payment_address?.address.address3,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </div>
                  <div className="d-flex gap-4 mb-3">
                    <span>Number</span>
                    <span>Street</span>
                  </div>
                  <div className="d-flex gap-2 flex-grow-1">
                    <div className="flex-grow-1">
                      <div className="border-bottom border-dark">
                        {formState.documentation?.payment_address?.address.city}
                      </div>
                      <span>City</span>
                    </div>
                    <div className="flex-grow-1">
                      <div className="border-bottom border-dark">
                        {formState.documentation?.payment_address?.address.state}
                      </div>
                      <span>State</span>
                    </div>
                    <div>
                      <div className="border-bottom border-dark">
                        {[
                          formState.documentation?.payment_address?.address.zip,
                          formState.documentation?.payment_address?.address.zip4,
                        ]
                          .filter(Boolean)
                          .join(" - ")}
                      </div>
                      <span>ZIP Code</span>
                    </div>
                  </div>
                </div>

                <div className="d-flex gap-2 flex-grow-1 mb-3">
                  <span>Contact phone</span>
                  <div className="border-bottom border-dark flex-grow-1">
                    {formState.documentation?.payment_address?.phone}
                  </div>
                </div>

                <div className="d-flex gap-2 flex-grow-1">
                  <span>Contact email</span>
                  <div className="border-bottom border-dark flex-grow-1">
                    {formState.documentation?.payment_address?.email}
                  </div>
                </div>
              </Col>
            </Row>

            <div />
            <div>
              <p className="mb-2">Uniform claim identifier for electronic payments in chapter 13 (if you use one):</p>
              <div className="border-bottom border-dark">{formState.documentation?.uniform_identifier}</div>
            </div>
          </div>
        </div>

        <div className="border-start border-end border-bottom border-dark px-2 py-2">
          <div className="d-grid gap-2" style={{ gridTemplateColumns: "1fr 4fr" }}>
            <div className="fw-bold">4. Does this claim amend one already filed?</div>
            <div className="d-flex gap-4">
              <div className="d-flex flex-column justify-content-between">
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.amending_claim === "no"}
                  readOnly
                  label="No"
                  className="mb-0"
                />
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.amending_claim === "yes"}
                  readOnly
                  label="Yes"
                  className="mb-0"
                />
              </div>
              <div className="d-flex gap-2 align-self-end flex-grow-1">
                <span>Claim number on court claims registry (if known)</span>
                <span className="border-bottom border-dark flex-grow-1">
                  {formState.documentation?.amend_claim ? JSON.parse(formState.documentation.amend_claim).number : null}
                </span>
              </div>
              <div className="d-flex gap-2 align-self-end flex-grow-1">
                <span>Filed on</span>
                <span className="border-bottom border-dark flex-grow-1">
                  {formState.documentation?.amend_claim
                    ? JSON.parse(formState.documentation.amend_claim).filed_on
                    : null}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="border-start border-end border-bottom border-dark px-2 py-2">
          <div className="d-grid gap-2" style={{ gridTemplateColumns: "1fr 4fr" }}>
            <div className="fw-bold">5. Do you know if anyone else has filed a proof of claim for this claim?</div>
            <div className="d-flex gap-4">
              <div className="d-flex flex-column justify-content-between">
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.anyone_else_filed === "no"}
                  readOnly
                  label="No"
                  className="mb-0"
                />
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.anyone_else_filed === "yes"}
                  readOnly
                  label="Yes"
                  className="mb-0"
                />
              </div>
              <div className="d-flex gap-2 align-self-end flex-grow-1">
                <span>Who made the earlier filing?</span>
                <span className="border-bottom border-dark flex-grow-1">
                  {formState.documentation?.other_proof_name}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-dark mb-5">
        <header className="d-flex border-bottom border-dark border-3">
          <span className="bg-dark p-2 text-white fw-bold">Part 2:</span>
          <span className="fw-bold p-2">Give Information About the Claim as of the Date the Case Was Filed</span>
        </header>

        <div className="border-start border-end border-bottom border-dark px-2 py-2">
          <div className="d-grid gap-2" style={{ gridTemplateColumns: "1fr 4fr" }}>
            <span className="fw-bold">6. Do you have any number you use to identify the debtor?</span>
            <div className="d-flex gap-4">
              <div className="d-flex flex-column justify-content-between">
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.identify_debtor === "no"}
                  readOnly
                  label="No"
                  className="mb-0"
                />
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.identify_debtor === "yes"}
                  readOnly
                  label="Yes"
                  className="mb-0"
                />
              </div>
              <div className="d-flex gap-2 align-self-end flex-grow-1">
                <span>Last 4 digits of the debtor's account or any number you use to identify the debtor:</span>
                <span className="border-bottom border-dark flex-grow-1">{formState.documentation?.debtor_number}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="border-start border-end border-bottom border-dark px-2 py-2">
          <div className="d-grid gap-2" style={{ gridTemplateColumns: "1fr 4fr" }}>
            <span className="fw-bold">7. How much is the claim?</span>
            <div className="d-flex gap-4">
              <div className="border-bottom border-dark align-self-start flex-grow-1">
                <span className="me-2">$</span>
                {formState.documentation?.claim_amount}
              </div>
              <div>
                <div className="mb-1">Does this amount include interest or other charges?</div>
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.include_interest === "no"}
                  readOnly
                  label="No"
                  className="mb-0"
                />
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.include_interest === "yes"}
                  readOnly
                  label="Yes. Attach statement itemizing interest, fees, expenses, or other
                  charges required by Bankruptcy Rule 3001(c)(2)(A)."
                  className="mb-0"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="border-start border-end border-bottom border-dark px-2 py-2">
          <div className="d-grid gap-2" style={{ gridTemplateColumns: "1fr 4fr" }}>
            <span className="fw-bold">8. What is the basis of the claim?</span>
            <div>
              <p className="mb-2">
                Examples: Goods sold, money loaned, lease, services performed, personal injury or wrongful death, or
                credit card.
              </p>
              <p className="mb-2">
                Attach redacted copies of any documents supporting the claim required by Bankruptcy Rule 3001(c).
              </p>
              <p className="mb-3">
                Limit disclosing information that is entitled to privacy, such as health care information.
              </p>
              <div className="border-bottom border-dark">{formState.documentation?.claim_basis}</div>
            </div>
          </div>
        </div>

        <div className="border-start border-end border-bottom border-dark px-2 py-2">
          <div className="d-grid gap-2" style={{ gridTemplateColumns: "1fr 4fr" }}>
            <span className="fw-bold">9. Is all or part of the claim secured?</span>
            <div>
              <div className="mb-3">
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.claim_secured === "no"}
                  readOnly
                  label="No"
                  className="mb-0"
                />
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.claim_secured === "yes"}
                  readOnly
                  label="Yes. The claim is secured by a lien on property."
                  className="mb-0"
                />
              </div>

              <div className="ps-4">
                <p className="fw-bold mb-2">Nature of property:</p>

                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.securities?.real_estate || false}
                  readOnly
                  label="Real estate. If the claim is secured by the debtor's principal residence, file a Mortgage Proof of Claim
                  Attachment (Official Form 410-A) with this Proof of Claim."
                  className="mb-2"
                />
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.securities?.vehicle || false}
                  readOnly
                  label="Motor vehicle"
                  className="mb-2"
                />

                <div className="d-flex mb-3 gap-3">
                  <Form.Check
                    type="checkbox"
                    checked={formState.documentation?.securities?.other || false}
                    readOnly
                    label="Other. Describe:"
                    className="mb-0"
                  />
                  <div className="border-bottom border-dark flex-grow-1">
                    {formState.documentation?.securities?.other_desc}
                  </div>
                </div>

                <div className="d-flex mb-3 gap-3">
                  <span className="fw-bold">Basis for perfection:</span>
                  <div className="border-bottom border-dark flex-grow-1">
                    {formState.documentation?.securities?.basis}
                  </div>
                </div>

                <p>
                  Attach redacted copies of documents, if any, that show evidence of perfection of a security interest
                  (for example, a mortgage, lien, certificate of title, financing statement, or other document that
                  shows the lien has been filed or recorded.)
                </p>

                <div className="d-grid gap-3 mb-3" style={{ gridTemplateColumns: "auto 1fr 2fr" }}>
                  <span className="fw-bold">Value of property:</span>
                  <div className="border-bottom border-dark align-self-start">
                    <span className="me-2">$</span>
                    {formState.documentation?.securities?.value}
                  </div>
                  <div />
                  <span className="fw-bold">Amount of the claim that is secured:</span>
                  <div className="border-bottom border-dark align-self-start">
                    <span className="me-2">$</span>
                    {formState.documentation?.securities?.amount_secured}
                  </div>
                  <div />
                  <span className="fw-bold">Amount of the claim that is unsecured:</span>
                  <div className="border-bottom border-dark align-self-start">
                    <span className="me-2">$</span>
                    {formState.documentation?.securities?.amount_unsecured}
                  </div>
                  <div>(The sum of the secured and unsecured amounts should match the amount in line 7.)</div>
                </div>

                <div className="d-flex gap-3 mb-3">
                  <span className="fw-bold">Amount necessary to cure any default as of the date of the petition:</span>
                  <div className="border-bottom border-dark flex-grow-1">
                    <span className="me-2">$</span>
                    {formState.documentation?.securities?.cure_default}
                  </div>
                </div>

                <div className="d-flex gap-3">
                  <div>
                    <span className="fw-bold">Annual Interest Rate (when case was filed)</span>
                  </div>
                  <div className="border-bottom border-dark d-flex" style={{ minWidth: 100 }}>
                    <span className="ms-auto">{formState.documentation?.securities?.annual_interest_rate}</span>
                    <span className="ms-1">%</span>
                  </div>
                </div>

                <div>
                  <Form.Check
                    type="checkbox"
                    checked={formState.documentation?.securities?.interest_rates === "fixed"}
                    readOnly
                    label="Fixed"
                    className="mb-0"
                  />
                  <Form.Check
                    type="checkbox"
                    checked={formState.documentation?.securities?.interest_rates === "variable"}
                    readOnly
                    label="Variable"
                    className="mb-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-start border-end border-bottom border-dark px-2 py-2">
          <div className="d-grid gap-2" style={{ gridTemplateColumns: "1fr 4fr" }}>
            <span className="fw-bold">10. Is this claim based on a lease?</span>

            <div className="d-flex gap-4">
              <div className="d-flex flex-column justify-content-between">
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.claim_lease_based === "no"}
                  readOnly
                  label="No"
                  className="mb-0"
                />
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.claim_lease_based === "yes"}
                  readOnly
                  label="Yes."
                  className="mb-0"
                />
              </div>
              <div className="d-flex gap-2 align-self-end flex-grow-1">
                <span className="fw-bold">Amount necessary to cure any default as of the date of the petition:</span>
                <span className="border-bottom border-dark flex-grow-1">
                  <span className="me-2">$</span>
                  {formState.documentation?.lease_default}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="border-start border-end border-bottom border-dark px-2 py-2">
          <div className="d-grid gap-2" style={{ gridTemplateColumns: "1fr 4fr" }}>
            <span className="fw-bold">11. Is this claim subject to a right of setoff?</span>

            <div className="d-flex gap-4">
              <div className="d-flex flex-column justify-content-between">
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.right_of_set_off === "no"}
                  readOnly
                  label="No"
                  className="mb-0"
                />
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.right_of_set_off === "yes"}
                  readOnly
                  label="Yes."
                  className="mb-0"
                />
              </div>
              <div className="d-flex gap-2 align-self-end flex-grow-1">
                <span>Identify the property:</span>
                <span className="border-bottom border-dark flex-grow-1">{formState.documentation?.set_off}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="border-start border-end border-bottom border-dark px-2 py-2">
          <div className="d-grid gap-2" style={{ gridTemplateColumns: "1fr 4fr" }}>
            <div>
              <div className="fw-bold mb-3">
                12. Is all or part of the claim entitled to priority under 11 U.S.C. § 507(a)?
              </div>

              <span>
                A claim may be partly priority and partly nonpriority. For example, in some categories, the law limits
                the amount entitled to priority.
              </span>
            </div>

            <div>
              <Form.Check
                type="checkbox"
                checked={formState.documentation?.priority_entitled === "no"}
                readOnly
                label="No"
                className="mb-0"
              />
              <Form.Check
                type="checkbox"
                checked={formState.documentation?.priority_entitled === "yes"}
                readOnly
                label={
                  <span>
                    Yes. <i>Check one:</i>
                  </span>
                }
                className="mb-0"
              />

              <div className="ps-4 d-grid gap-3 mb-3" style={{ gridTemplateColumns: "3fr 1fr" }}>
                <div />
                <div className="bg-light text-dark fw-bold p-2">Amount entitled to priority</div>
                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.priorities?.code === "507_1" || false}
                  readOnly
                  label="Domestic support obligations (including alimony and child support) under
                  11 U.S.C. § 507(a)(1)(A) or (a)(1)(B)."
                  className="mb-0"
                />
                <div className="border-bottom border-dark align-self-start">
                  <span className="me-2">$</span>
                  {formState.documentation?.priorities?.code === "507_1" && formState.documentation?.priorities?.amount}
                </div>

                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.priorities?.code === "507_7" || false}
                  readOnly
                  label="Up to $2,775* of deposits toward purchase, lease, or rental of property or services for
                  personal, family, or household use. 11 U.S.C. § 507(a)(7)."
                  className="mb-0"
                />
                <div className="border-bottom border-dark align-self-start">
                  <span className="me-2">$</span>
                  {formState.documentation?.priorities?.code === "507_7" && formState.documentation?.priorities?.amount}
                </div>

                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.priorities?.code === "507_4" || false}
                  readOnly
                  label="Wages, salaries, or commissions (up to $12,475*) earned within 180 days before the
                  bankruptcy petition is filed or the debtor's business ends, whichever is earlier.
                  11 U.S.C. § 507(a)(4)."
                  className="mb-0"
                />
                <div className="border-bottom border-dark align-self-start">
                  <span className="me-2">$</span>
                  {formState.documentation?.priorities?.code === "507_4" && formState.documentation?.priorities?.amount}
                </div>

                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.priorities?.code === "507_8" || false}
                  readOnly
                  label="Taxes or penalties owed to governmental units. 11 U.S.C. § 507(a)(8)."
                  className="mb-0"
                />
                <div className="border-bottom border-dark align-self-start">
                  <span className="me-2">$</span>
                  {formState.documentation?.priorities?.code === "507_8" && formState.documentation?.priorities?.amount}
                </div>

                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.priorities?.code === "507_5" || false}
                  readOnly
                  label="Contributions to an employee benefit plan. 11 U.S.C. § 507(a)(5)."
                  className="mb-0"
                />
                <div className="border-bottom border-dark align-self-start">
                  <span className="me-2">$</span>
                  {formState.documentation?.priorities?.code === "507_5" && formState.documentation?.priorities?.amount}
                </div>

                <Form.Check
                  type="checkbox"
                  checked={formState.documentation?.priorities?.code === "507_other" || false}
                  readOnly
                  label={`Other. Specify subsection of 11 U.S.C. § 507(a)(${formState.documentation?.priorities?.code_507_other_sub}) that applies.`}
                  className="mb-0"
                />
                <div className="border-bottom border-dark align-self-start">
                  <span className="me-2">$</span>
                  {formState.documentation?.priorities?.code === "507_other" &&
                    formState.documentation?.priorities?.amount}
                </div>
              </div>

              <div className="ps-4">
                * Amounts are subject to adjustment on 4/01/16 and every 3 years after that for cases begun on or after
                the date of adjustment
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-dark">
        <header className="d-flex border-bottom border-dark border-3">
          <span className="bg-dark p-2 text-white fw-bold">Part 3:</span>
          <span className="fw-bold p-2">Sign Below</span>
        </header>

        <div className="border-start border-end border-bottom border-dark px-2 py-2">
          <div className="d-grid gap-2" style={{ gridTemplateColumns: "1fr 4fr" }}>
            <div>
              <div className="fw-bold mb-3">
                The person completing this proof of claim must sign and date it. FRBP 9011(b).
              </div>

              <div className="mb-3">
                If you file this claim electronically, FRBP 5005(a)(2) authorizes courts to establish local rules
                specifying what a signature is.
              </div>

              <div className="fw-bold">
                A person who files a fraudulent claim could be fined up to $500,000, imprisoned for up to 5 years, or
                both. 18 U.S.C. §§ 152, 157, and 3571.
              </div>
            </div>

            <div>
              <p className="fst-italic">Check the appropriate box:</p>
              <div className="mb-5">
                <Form.Check
                  type="checkbox"
                  checked={formState.signee?.role === "creditor"}
                  readOnly
                  label="I am the creditor."
                />
                <Form.Check
                  type="checkbox"
                  checked={formState.signee?.role === "creditor_attorney"}
                  readOnly
                  label="I am the creditor's attorney or authorized agent."
                />
                <Form.Check
                  type="checkbox"
                  checked={formState.signee?.role === "rule_3004"}
                  readOnly
                  label="I am the trustee, or the debtor, or their authorized agent. Bankruptcy Rule 3004"
                />
                <Form.Check
                  type="checkbox"
                  checked={formState.signee?.role === "rule_3005"}
                  readOnly
                  label="I am a guarantor, surety, endorser, or other codebtor. Bankruptcy Rule 3005."
                />
              </div>

              <p>
                I understand that an authorized signature on this Proof of Claim serves as an acknowledgment that when
                calculating the amount of the claim, the creditor gave the debtor credit for any payments received
                toward the debt.
              </p>

              <p>
                I have examined the information in this Proof of Claim and have a reasonable belief that the information
                is true and correct.
              </p>

              <p>I declare under penalty of perjury that the foregoing is true and correct.</p>

              <div className="d-flex gap-4 mb-5">
                Executed on date
                <div>
                  <div className="border-bottom border-dark">{new Date().toLocaleDateString("en-US")}</div>
                  MM / DD / YYYY
                </div>
              </div>

              <div className="mb-3">
                <div className="border-bottom border-dark">{formState.signee?.signature}</div>
                Signature
              </div>

              <p className="fw-bold">Print the name of the person who is completing and signing this claim:</p>

              <div className="d-grid gap-3" style={{ gridTemplateColumns: "auto 4fr" }}>
                <span>Name</span>
                <div className="border-bottom border-dark">{formState.signee?.signature}</div>

                <span>Title</span>
                <div className="border-bottom border-dark">{formState.signee?.title}</div>

                <span>Company</span>
                <div className="border-bottom border-dark">{formState.signee?.company}</div>

                <span>Address</span>
                <div>
                  <div className="mb-3">
                    <div className="border-bottom border-dark">
                      {[
                        formState.signee?.address.address1,
                        formState.signee?.address.address2,
                        formState.signee?.address.address3,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </div>
                    <span className="me-5">Number</span>
                    <span>Street</span>
                  </div>
                  <div className="d-flex gap-3">
                    <div className="flex-grow-1">
                      <div className="border-bottom border-dark">{formState.signee?.address.city}</div>
                      City
                    </div>
                    <div className="flex-grow-1">
                      <div className="border-bottom border-dark">{formState.signee?.address.state}</div>
                      State
                    </div>
                    <div>
                      <div className="border-bottom border-dark">
                        {[formState.signee?.address.zip, formState.signee?.address.zip4].filter(Boolean).join(" - ")}
                      </div>
                      ZIP Code
                    </div>
                  </div>
                </div>

                <span>Contact phone</span>
                <div className="d-flex gap-3">
                  <div className="border-bottom border-dark flex-grow-1">{formState.signee?.phone}</div>

                  <span className="ms-auto">Email</span>
                  <div className="border-bottom border-dark flex-grow-1">{formState.signee?.email}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
