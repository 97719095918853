import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import { apolloClient } from "../../../configs/apollo-client";
import getPurchaseDetails from "../../../lib/getPurchaseDetails";

import getUserTrackedCase from "./graphql/getUserTrackedCase";
import DocketHeader from "./DocketHeader";
import Docket from "./Docket";
import ModalPaywall from "./ModalPaywall";

export default function Case({ match: { params }, location: { search } }) {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState();
  const [activeTabKey, setActiveTabKey] = useState();
  const [showModalPaywall, setShowModalPaywall] = useState(false);
  const { case_id } = params;
  const { expandOnDocketNumber } = Object.fromEntries(new URLSearchParams(search));

  useEffect(() => {
    const watchedQuery = apolloClient.watchQuery({
      query: getUserTrackedCase,
      variables: {
        case_id,
      },
      fetchPolicy: "cache-first",
    });

    const sub = watchedQuery.subscribe({
      next(response) {
        if (!response.partial) {
          setResponse(response.data.getUserTrackedCase);
          //Case purchased? If so default tab is docket otherwise header
          if (response.data.getUserTrackedCase.purchase_details) {
            setActiveTabKey("docket");
          } else {
            setActiveTabKey("header");
          }
          setIsLoading(false);
        }
      },
      error(err) {
        console.log("[watchQuery] error", err);
        setIsLoading(false);
      },
    });

    return () => {
      console.log("[watchQuery] Clean up");
      sub.unsubscribe();
    };
  }, [case_id]);

  if (isLoading || !response) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  const caseData = response.data;
  const purchaseDetails = response.purchase_details;
  const error = response.error;

  if (error) {
    return <h1 className="mt-0 mb-4">Error: {error}</h1>;
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/cases" }}>
          Cases
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{caseData.case_number}</Breadcrumb.Item>
      </Breadcrumb>

      <h2>{caseData.case_title}</h2>
      <h3>{caseData.court_name} Bankruptcy Court</h3>

      {purchaseDetails && (
        <h4 className="text-success">
          {getPurchaseDetails({
            type: purchaseDetails.purchase_type,
            expireAt: purchaseDetails.expire_at,
          })}
        </h4>
      )}

      <Tabs
        activeKey={activeTabKey}
        onSelect={(key) => {
          if (key === "docket" && purchaseDetails === null) {
            setShowModalPaywall(true);
          } else {
            setActiveTabKey(key);
          }
        }}
      >
        <Tab eventKey="header" title="Header">
          <DocketHeader caseId={case_id} />
        </Tab>
        <Tab eventKey="docket" title="Docket">
          {purchaseDetails && <Docket caseId={case_id} expandOnDocketNumber={expandOnDocketNumber} />}
        </Tab>
      </Tabs>

      <ModalPaywall show={showModalPaywall} onCancel={() => setShowModalPaywall(false)} caseId={case_id} />
    </>
  );
}
