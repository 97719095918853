import gql from "graphql-tag";

const getDashboard = gql`
  query getDashboard($input: TrackedDebtorsInput!) {
    listClaims {
      id
      court_code
      case_no
      case_title
      created_at
      updated_at
    }

    listTrackedDebtors(input: $input) {
      pagination {
        total_records_count
      }
    }

    listTrackedDebtorsMatches {
      id
    }
  }
`;

export default getDashboard;
