import gql from "graphql-tag";

const getCaseCreditorsAndCourtInfos = gql`
  query ($input: CaseInput!, $court_code: ID!) {
    getCaseCreditors(input: $input) {
      cases {
        case_no
        case_title
      }
      error
      case {
        case_no
        case_title
      }
      creditors {
        id
        name
        address
      }
    }

    getCourtInfos(court_code: $court_code) {
      error
      attachment_size_limit
    }
  }
`;

export default getCaseCreditorsAndCourtInfos;
