import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import AuthLayout from "../../layouts/Auth";
import { setRedirectAfterLogin } from "../../../redux/actions/authActions";

import UserContext from "./UserContext";

export default function PublicRoute({ component: Component, renderAuthLayout = true, ...rest }) {
  const { user } = useContext(UserContext);
  const redirectAfterLogin = useSelector((state) => state.authentication.redirectAfterLogin);
  const dispatch = useDispatch();

  return (
    <Route
      exact
      {...rest}
      render={function (props) {
        if (user) {
          if (redirectAfterLogin) {
            // Clear
            setTimeout(() => dispatch(setRedirectAfterLogin(null)), 0);

            return <Redirect to={redirectAfterLogin} />;
          }

          return <Redirect to="/dashboard" />;
        }

        if (renderAuthLayout) {
          return (
            <AuthLayout>
              <Component {...props} />
            </AuthLayout>
          );
        }

        return <Component {...props} />;
      }}
    />
  );
}
