import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

import { config } from "../aws-exports";

export const refreshSession = async (email, refresh_token) => {
  const userPool = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: config.cognito.USER_POOL_ID,
    ClientId: config.cognito.APP_CLIENT_ID
  });

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username: email,
    Pool: userPool
  });

  const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
    RefreshToken: refresh_token
  });

  await new Promise((resolve, reject) => {
    cognitoUser.refreshSession(RefreshToken, (error, result) => {
      if (error) {
        reject(error);
      } else {
        resolve(result.idToken.jwtToken);
      }
    });
  });
};
