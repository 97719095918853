import { useEffect, Fragment } from "react";
import classnames from "classnames";
import { Modal, Spinner, Button, Table, Form, Alert } from "react-bootstrap";
import { Observer, useLocalObservable, observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import { toast } from "react-toastify";
import { InfoCircleFill as InfoIcon } from "react-bootstrap-icons";

import { Claim, ClaimCase } from "../types";
import { apolloClient } from "../../../../../configs/apollo-client";
import { somethingWentWrong } from "../../../../../lib/errors";

import districts from "./districts";
import resolveClaimCaseNumber from "../graphql/resolveClaimCaseNumber";
import getClaimSearchCaseProgress from "../graphql/getClaimSearchCaseProgress";

interface IProps {
  show: boolean;
  onClose: () => void;
  claim: Claim | null;
  batchId: string | null;
  saveClaimCase: (claim: Claim, { case_no, case_title }: { case_no: string; case_title: string }) => void;
}
export default function ClaimCaseNumberResolverModal({ show, onClose, claim, batchId, saveClaimCase }: IProps) {
  return (
    <Modal show={show} onHide={onClose} size="lg">
      {claim && batchId && (
        <ModalContent onClose={onClose} claim={claim} batchId={batchId} saveClaimCase={saveClaimCase} />
      )}
    </Modal>
  );
}

function ModalContent({
  onClose,
  claim,
  batchId,
  saveClaimCase,
}: {
  onClose: () => void;
  claim: Claim;
  batchId: string;
  saveClaimCase: (claim: Claim, { case_no, case_title }: { case_no: string; case_title: string }) => void;
}) {
  console.log("[ModalContent] rendering");

  const state = useLocalObservable(() => ({
    searchingClaimCases: !claim.found_cases,
    selectedCaseIndex: claim.found_cases && claim.found_cases.length === 1 ? 0 : null,
    searchClaimCases: function* searchClaimCases() {
      this.searchingClaimCases = true;

      try {
        console.log("[ModalContent] Running resolveClaimCaseNumber query");
        const resolveClaimCaseNumberResponse: {
          data: {
            resolveClaimCaseNumber: { error?: string };
          };
        } = yield apolloClient.query({
          query: resolveClaimCaseNumber,
          variables: {
            batch_id: batchId,
            claim_id: claim.id,
          },
          fetchPolicy: "network-only",
        });
        console.log("[ModalContent] response");
        console.log(resolveClaimCaseNumberResponse);

        if (resolveClaimCaseNumberResponse.data.resolveClaimCaseNumber.error) {
          toast.error(resolveClaimCaseNumberResponse.data.resolveClaimCaseNumber.error);
          onClose();
          return;
        }

        // Loop to check progress
        let taskIsPending = true;
        const sleepingTime = 2000;

        let response: {
          done?: boolean;
          error?: string;
          error_code?: string;
          claim?: Claim;
        } = {};

        while (taskIsPending) {
          console.log("[ModalContent] Polling getClaimSearchCaseProgress");
          const progressResponse: {
            data: {
              getClaimSearchCaseProgress: {
                done?: boolean;
                error?: string;
                error_code?: string;
                claim?: Claim;
              };
            };
          } = yield apolloClient.query({
            query: getClaimSearchCaseProgress,
            variables: {
              batch_id: batchId,
              claim_id: claim.id,
            },
            fetchPolicy: "network-only",
          });

          response = progressResponse.data.getClaimSearchCaseProgress;

          if (response.error) {
            toast.error(response.error);
            onClose();
            return;
          }

          if (!response.done) {
            console.log(`[ModalContent] Not done, sleeping ${sleepingTime}s`);
            yield sleep(sleepingTime);
            continue;
          } else {
            console.log("[ModalContent] done, found cases");

            taskIsPending = false;
          }
        }

        runInAction(() => {
          this.searchingClaimCases = false;

          // Update claim locally
          if (response.claim?.status) claim.status = response.claim.status;

          claim.found_cases = response.claim?.found_cases;
          claim.cases_search_error = response.claim?.cases_search_error;
          this.selectedCaseIndex = claim.found_cases && claim.found_cases.length === 1 ? 0 : null;
        });
      } catch (error) {
        console.log(error);
        toast.error(somethingWentWrong);
        onClose();
      }
    },
  }));

  useEffect(() => {
    let promise: any;

    if (state.searchingClaimCases) {
      console.log("[ModalContent] useEffect searchingClaimCases");
      promise = state.searchClaimCases();
    }

    return () => {
      if (promise) {
        console.log("[ModalContent] canceling promise");
        promise.cancel();
      }
    };
  });

  return (
    <Observer>
      {() => {
        console.log("[ModalContent] observer rendering");

        const districtName = districts.find(
          (district) => district.name.toLowerCase() === claim.district?.toLowerCase()
        )?.formName;

        if (state.searchingClaimCases)
          return (
            <Modal.Body>
              <h2 className="text-center fw-normal">
                Looking for case number <strong>{claim.case_no}</strong> on {districtName}.
              </h2>

              <p className="lead text-center">Please wait, this request may take a little while.</p>

              <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="primary" />
              </div>
            </Modal.Body>
          );

        if (claim.cases_search_error)
          return (
            <>
              <Modal.Body>
                <h2 className="text-danger fw-normal mb-3">
                  An error occured while searching for case number "{claim.case_no}" on {districtName}.
                </h2>
                <p className="lead">Please try again or contact support if problem persist</p>
              </Modal.Body>

              <Modal.Body className="d-flex justify-content-between">
                <Button type="button" variant="secondary" onClick={onClose}>
                  Cancel
                </Button>

                <Button type="button" variant="primary" onClick={() => state.searchClaimCases()}>
                  Retry
                </Button>
              </Modal.Body>
            </>
          );

        if (claim.found_cases) {
          return (
            <>
              <Modal.Body>
                {claim.found_cases.length === 0 ? (
                  <>
                    <h2 className="text-danger fw-normal mb-3">
                      We haven't found any matching case for "{claim.case_no}" on {districtName}.
                    </h2>
                    <p className="lead">
                      We won't be able to file this claim. Please check the correctness of the case number.
                    </p>
                  </>
                ) : claim.found_cases.length === 1 ? (
                  <>
                    <h2 className="fw-normal mb-3">
                      We found a case matching for "{claim.case_no}" on {districtName}.
                    </h2>
                    <p className="lead">Please confirm that's what you've been looking for.</p>
                  </>
                ) : (
                  <>
                    <h2 className="fw-normal mb-3">
                      We found some cases for "{claim.case_no}" on {districtName}.
                    </h2>
                    <p className="lead">Please select a case.</p>
                  </>
                )}

                <CasesSearchResults
                  cases={claim.found_cases}
                  selectedCaseIndex={state.selectedCaseIndex}
                  onSelectCase={(index) => (state.selectedCaseIndex = index)}
                />
              </Modal.Body>

              {typeof state.selectedCaseIndex === "number" && (
                <Modal.Body className="py-0">
                  <Alert variant="warning">
                    <div className="alert-message">
                      We will update the case title and case number with data from the case selected.
                    </div>
                  </Alert>
                </Modal.Body>
              )}

              {claim.found_cases.length === 0 ? (
                <Modal.Footer>
                  <Button type="button" variant="primary" onClick={onClose}>
                    Close
                  </Button>
                </Modal.Footer>
              ) : (
                <Modal.Footer className="justify-content-between">
                  <Button type="button" variant="secondary" onClick={onClose}>
                    Cancel
                  </Button>

                  <Button
                    type="button"
                    variant="success"
                    disabled={typeof state.selectedCaseIndex === "undefined"}
                    onClick={() => {
                      saveClaimCase(claim, {
                        case_no: claim.found_cases![state.selectedCaseIndex!].case_no,
                        case_title: claim.found_cases![state.selectedCaseIndex!].case_title,
                      });
                      onClose();
                    }}
                  >
                    Confirm
                  </Button>
                </Modal.Footer>
              )}
            </>
          );
        }

        return null;
      }}
    </Observer>
  );
}

export const CasesSearchResults = observer(function CasesSearchResults({
  cases,
  selectedCaseIndex,
  onSelectCase,
  selectInputName = "select-claim-found-case",
}: {
  cases: ClaimCase[];
  selectedCaseIndex: number | null;
  onSelectCase: (index: number) => void;
  selectInputName?: string;
}) {
  if (cases.length > 0) {
    return (
      <Table className="mb-0" hover>
        <thead className="table-light">
          <tr>
            <th className="min-content-width"></th>
            <th className="text-center">Match</th>
            <th>Case Title</th>
            <th>Court</th>
            <th>Case No</th>
            <th>Chapter</th>
            <th>Date Filed</th>
            <th>Date Closed</th>
          </tr>
        </thead>
        <tbody>
          {cases.map((bkCase, index) => {
            const likelyAMatch = typeof bkCase.match_pct === "number" && bkCase.match_pct > 0.6;
            const selected = selectedCaseIndex === index;

            return (
              <Fragment key={index}>
                <tr
                  className={classnames({
                    "table-primary": selected,
                  })}
                  role="button"
                  onClick={() => onSelectCase(index)}
                >
                  <td>
                    <Form.Check type="radio" name={selectInputName} className="pe-none" checked={selected} readOnly />
                  </td>
                  <td className="text-center position-relative">
                    {likelyAMatch && (
                      <>
                        <strong
                          className="text-success"
                          style={{
                            filter: `brightness(${(bkCase.match_pct! * 100).toFixed(0)}%)`,
                          }}
                        >
                          {(bkCase.match_pct! * 100).toFixed(0)}%
                        </strong>
                        <span
                          style={{
                            position: "absolute",
                            bottom: -1,
                            left: "calc(50% - 8px)",
                            width: 0,
                            height: 0,
                            borderLeft: "8px solid transparent",
                            borderRight: "8px solid transparent",
                            borderBottom: "8px solid rgb(var(--bs-success-rgb))",
                          }}
                        />
                      </>
                    )}
                  </td>
                  <td>{bkCase.case_title}</td>
                  <td>{bkCase.court_code}</td>
                  <td>{bkCase.case_no}</td>
                  <td>{bkCase.chapter}</td>
                  <td>{bkCase.date_filed}</td>
                  <td>{bkCase.date_closed}</td>
                </tr>
                {likelyAMatch && (
                  <tr className="bg-success text-white pe-none">
                    <td colSpan={8} className="py-1">
                      <div className="d-flex align-items-center gap-2">
                        <InfoIcon size="18" className="text-white" />
                        Based on a match on the Case Title, that's very likely what you've been looking for.
                      </div>
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    );
  }

  return null;
});

const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));
