import gql from "graphql-tag";

const addCase = gql`
  mutation addCase($input: AddCaseInput!) {
    addCase(input: $input) {
      id
      company_id
      ein
      company_name
      court_name
      case_title
      case_number
      date_filed
      chapter
      updated
      claim_deadline
      jurisdiction
    }
  }
`;

export default addCase;
